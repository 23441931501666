import styled from '@emotion/styled';
import MuiTextField, { OutlinedTextFieldProps } from '@mui/material/TextField';
import { getInputStyle, getLabelStyle } from './utils';

export type TextFieldProps = Omit<OutlinedTextFieldProps, 'variant'> & {
  variant?: 'standard' | 'alt';
  /** @deprecated */
  label?: OutlinedTextFieldProps['label'];
};

export const TextField: React.FC<TextFieldProps> = styled(
  ({ variant, SelectProps, ...props }: TextFieldProps) => (
    <MuiTextField
      {...props}
      SelectProps={{
        ...SelectProps,
        MenuProps: {
          ...SelectProps?.MenuProps,
          elevation: 4,
        },
      }}
    />
  )
)(({ size = 'medium' }) => ({
  '.MuiInputBase-input': getInputStyle(size),
  '.MuiSelect-select': getInputStyle(size),
  '> .MuiInputLabel-root': getLabelStyle(size),
}));
