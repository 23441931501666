import { useState, ChangeEvent } from "react";
import { Input } from "../cataylst/input";

interface iTag {
  tags: string[];
  addTag: (tag: string) => void;
  removeTag: (tag: string) => void;
  maxTags: number;
  color: 'primary' | 'success' | 'warning' | 'purple' | 'pink' | 'danger';
  placeholder?: string;
}

export const useTags = (maxTags = 5) => {
  // Keep track of the tags array.

  const [tags, setTags] = useState<string[]>([]);

  // Function to handle adding the tag to the array

  const handleAddTag = (newTag: string) => {
    if (newTag && !tags.includes(newTag) && tags.length < maxTags) {
      setTags([...tags, newTag]);
    }
  };

  // Function to remove tag from array
  const handleRemoveTag = (tag: string) =>
    setTags(tags.filter((t) => t !== tag));

  // Return tags and functions from the hook

  return { tags, handleAddTag, handleRemoveTag };
};

export const TagField = ({ tags, addTag, removeTag, maxTags, color, placeholder }: iTag) => {
  // track the use input

  const [userInput, setUserInput] = useState<string>(" ");

  // Handle input onChange

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setUserInput(e.target.value);
  };

  // handle Enter key press

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      e.preventDefault(); // Prevent form submission or new line creation

      if (
        userInput.trim() !== "" &&
        tags.length < maxTags
      ) {
        addTag(userInput);
        setUserInput(""); // Clear the input after adding a tag
      }
    }
  };

  const colorMap = {
    'primary': 'bg-[#DBEAFE] border-[#DBEAFE]',
    'success': 'bg-[#01DB652E] border-[#01DB652E]',
    'pink': 'bg-[#EE2E5C1F] border-[#EE2E5C1F]',
    'purple': 'bg-[#7A3DFA26] border-[#7A3DFA26]'
  }

  return (
    <div className="flex flex-col">
      {/* ===== Render the tags here ===== */}
      <div className="flex flex-row flex-wrap gap-1 mb-2">
        {tags.map((tag: string, index: number) => (
          <span
            key={`${index}-${tag}`}
            className={`inline-flex items-center border border-solid justify-start px-2.5 py-1 rounded-md text-xs shadow-sm font-medium text-black ${colorMap[color] || colorMap['primary']}`}
          >
            {tag}
            <button
              className="ml-1"
              onClick={() => removeTag(tag)}
              title={`Remove ${tag}`}
            >
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M10.964 3.96404C11.2203 3.70776 11.2203 3.29224 10.964 3.03596C10.7078 2.77968 10.2922 2.77968 10.036 3.03596L7 6.07192L3.96404 3.03596C3.70776 2.77968 3.29224 2.77968 3.03596 3.03596C2.77968 3.29224 2.77968 3.70776 3.03596 3.96404L6.07192 7L3.03596 10.036C2.77968 10.2922 2.77968 10.7078 3.03596 10.964C3.29224 11.2203 3.70776 11.2203 3.96404 10.964L7 7.92808L10.036 10.964C10.2922 11.2203 10.7078 11.2203 10.964 10.964C11.2203 10.7078 11.2203 10.2922 10.964 10.036L7.92808 7L10.964 3.96404Z"
                  fill="black"
                  fill-opacity="0.4"
                />
              </svg>
            </button>
          </span>
        ))}
      </div>
      <Input
        name="keyword_tags"
        style={{height: '40px'}}
        type="text"
        placeholder={
          tags.length < maxTags
            ? (placeholder || "Type to add keywords...")
            : `You can only enter max. of ${maxTags} items`
        }
        className="w-full border border-gray-300 rounded-md px-4 py-2"
        onKeyDown={handleKeyPress}
        onChange={handleInputChange}
        value={userInput}
        disabled={tags.length === maxTags}
      />
    </div>
  );
};
