import React from 'react';
import SvgIcon, { SvgIconProps } from '../SvgIcon';

export const GitlabIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props} style={{ fill: 'transparent' }} viewBox="5.5 5.5 28 28">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M20 0H0V20V40H20H40V20V0H20Z"
      fill="#554488"
    />
    <path
      d="M31.5737 17.6663L31.54 17.5801L28.2734 9.05497C28.207 8.88788 28.0893 8.74614 27.9373 8.65008C27.7852 8.55565 27.6077 8.51017 27.429 8.51978C27.2502 8.52939 27.0787 8.59363 26.9375 8.70381C26.798 8.81716 26.6968 8.97076 26.6476 9.14369L24.442 15.8918H15.5107L13.3051 9.14369C13.2573 8.96981 13.1558 8.81545 13.0152 8.70256C12.8741 8.59238 12.7025 8.52814 12.5238 8.51853C12.345 8.50892 12.1676 8.5544 12.0155 8.64883C11.8638 8.74527 11.7462 8.88689 11.6793 9.05372L8.40646 17.5751L8.37397 17.6613C7.90373 18.89 7.84569 20.2382 8.20859 21.5028C8.5715 22.7673 9.33567 23.8796 10.3859 24.6719L10.3972 24.6806L10.4271 24.7019L15.4033 28.4283L17.8651 30.2915L19.3647 31.4237C19.5401 31.5569 19.7542 31.629 19.9745 31.629C20.1947 31.629 20.4089 31.5569 20.5843 31.4237L22.0839 30.2915L24.5457 28.4283L29.5518 24.6794L29.5643 24.6694C30.6122 23.8769 31.3746 22.7658 31.7369 21.503C32.0993 20.2401 32.042 18.8938 31.5737 17.6663Z"
      fill="#E24329"
    />
    <path
      d="M31.5737 17.6663L31.54 17.5801C29.9483 17.9068 28.4484 18.581 27.1475 19.5545L19.9732 24.9793C22.4163 26.8275 24.5432 28.4333 24.5432 28.4333L29.5493 24.6843L29.5618 24.6743C30.6112 23.8819 31.3748 22.7701 31.7376 21.5062C32.1005 20.2423 32.043 18.8947 31.5737 17.6663Z"
      fill="#FC6D26"
    />
    <path
      d="M15.4033 28.4333L17.8651 30.2965L19.3647 31.4287C19.5401 31.5619 19.7542 31.634 19.9745 31.634C20.1947 31.634 20.4089 31.5619 20.5843 31.4287L22.0839 30.2965L24.5457 28.4333C24.5457 28.4333 22.4163 26.8225 19.9732 24.9793C17.5302 26.8225 15.4033 28.4333 15.4033 28.4333Z"
      fill="#FCA326"
    />
    <path
      d="M12.7977 19.5545C11.4978 18.579 9.99827 17.903 8.40646 17.5751L8.37397 17.6613C7.90373 18.89 7.84569 20.2382 8.20859 21.5027C8.5715 22.7673 9.33567 23.8795 10.3859 24.6718L10.3972 24.6806L10.4271 24.7018L15.4033 28.4283C15.4033 28.4283 17.5277 26.8225 19.9732 24.9743L12.7977 19.5545Z"
      fill="#FC6D26"
    />
  </SvgIcon>
);
