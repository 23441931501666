import React from 'react';
import SvgIcon, { SvgIconProps } from '../SvgIcon';

export const NotionIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon
    {...props}
    style={{ fill: 'transparent' }}
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.4488 5.65522C6.0973 6.18212 6.3405 6.14192 7.5582 6.06062L19.038 5.37132C19.2815 5.37132 19.079 5.12842 18.9978 5.08812L17.0913 3.70982C16.726 3.42622 16.2393 3.10142 15.3065 3.18262L4.1906 3.99342C3.7852 4.03362 3.7042 4.23632 3.8656 4.39872L5.4488 5.65522ZM6.138 8.33063V20.4093C6.138 21.0585 6.4624 21.3013 7.1925 21.2612L19.8088 20.5312C20.5393 20.491 20.6207 20.0445 20.6207 19.5172V7.51952C20.6207 6.99302 20.4181 6.70912 19.971 6.74962L6.7869 7.51952C6.3003 7.56042 6.138 7.80383 6.138 8.33063ZM18.5928 8.97852C18.6737 9.34372 18.5928 9.70852 18.2269 9.74962L17.6191 9.87073V18.788C17.0913 19.0717 16.6046 19.2338 16.199 19.2338C15.5497 19.2338 15.3871 19.031 14.9007 18.4234L10.9245 12.1812V18.2207L12.1827 18.5046C12.1827 18.5046 12.1827 19.2338 11.1676 19.2338L8.3691 19.3962C8.2878 19.2338 8.3691 18.8288 8.653 18.7477L9.3833 18.5453V10.56L8.3693 10.4787C8.288 10.1136 8.4905 9.58702 9.0589 9.54622L12.061 9.34382L16.199 15.6672V10.0734L15.144 9.95232C15.063 9.50592 15.3871 9.18172 15.7928 9.14152L18.5928 8.97852ZM3.2573 2.89902L14.8196 2.04752C16.2394 1.92579 16.6048 2.00732 17.4972 2.65562L21.188 5.24972C21.797 5.69582 22 5.81722 22 6.30352V20.5312C22 21.4228 21.6752 21.9502 20.5395 22.0308L7.1122 22.8417C6.2597 22.8823 5.854 22.7608 5.4075 22.193L2.6895 18.6666C2.2025 18.0174 2 17.5318 2 16.9637V4.31722C2 3.58802 2.3249 2.97972 3.2573 2.89902Z"
      fill="black"
    />
    <path
      d="M5.4488 5.65522C6.0973 6.18212 6.3405 6.14192 7.5582 6.06062L19.038 5.37132C19.2815 5.37132 19.079 5.12842 18.9978 5.08812L17.0913 3.70982C16.726 3.42622 16.2393 3.10142 15.3065 3.18262L4.1906 3.99342C3.7852 4.03362 3.7042 4.23632 3.8656 4.39872L5.4488 5.65522Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.138 8.33063V20.4093C6.138 21.0585 6.4624 21.3013 7.1925 21.2612L19.8088 20.5312C20.5393 20.491 20.6207 20.0445 20.6207 19.5172V7.51952C20.6207 6.99302 20.4181 6.70912 19.971 6.74962L6.7869 7.51952C6.3003 7.56042 6.138 7.80383 6.138 8.33063ZM18.2269 9.74962C18.5928 9.70852 18.6737 9.34372 18.5928 8.97852L15.7928 9.14152C15.3871 9.18172 15.063 9.50592 15.144 9.95232L16.199 10.0734V15.6672L12.061 9.34382L9.0589 9.54622C8.4905 9.58702 8.288 10.1136 8.3693 10.4787L9.3833 10.56V18.5453L8.653 18.7477C8.3691 18.8288 8.2878 19.2338 8.3691 19.3962L11.1676 19.2338C12.1827 19.2338 12.1827 18.5046 12.1827 18.5046L10.9245 18.2207V12.1812L14.9007 18.4234C15.3871 19.031 15.5497 19.2338 16.199 19.2338C16.6046 19.2338 17.0913 19.0717 17.6191 18.788V9.87073L18.2269 9.74962Z"
      fill="white"
    />
  </SvgIcon>
);
