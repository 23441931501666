import axios from "axios"
import { IFetchTokenPayload } from "./beefree.type"
import moment from "moment"

export const fetchToken = ({ authUrl, clientId, clientSecret }: IFetchTokenPayload) => {
  const payload = {
    grant_type: 'password',
    client_id: clientId,
    client_secret: clientSecret
  }
  return axios.post(authUrl, payload)
}

export function isTokenValid(token: string) {
  if (!token || !token['.expires']) {
    return false;
  }

  return moment(token['.expires']).isAfter();
}
