import React from 'react';
import SvgIcon, { SvgIconProps } from '../SvgIcon';

export const REAIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props} style={{ fill: 'transparent' }} viewBox="8 7 45 45">
    <svg
      width="62"
      height="62"
      viewBox="0 0 62 62"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0.000499814 30.6853C-0.0955002 47.7293 13.6498 61.6187 30.6898 61.7107C47.7352 61.8041 61.6205 48.06 61.7085 31.0173C61.8003 13.9747 48.0618 0.0919937 31.0205 0H30.8485C13.8845 0 0.0911707 13.7053 0.000499814 30.6853Z" />
      <path
        d="M40.9239 28.5932V45.0093H20.7825V28.5932L15.4839 28.5906L30.8559 13.2973L46.2266 28.5906L40.9239 28.5932ZM51.0865 26.6173L32.7585 8.35728C32.2852 7.84663 31.6066 7.5293 30.8559 7.5293C30.1066 7.5293 29.4252 7.84678 28.9519 8.35728L10.6199 26.6159C9.91325 27.332 9.47583 28.3146 9.47583 29.3999C9.47583 31.5799 11.2412 33.3533 13.4212 33.3599L15.9439 33.3562C15.9439 33.3562 15.9401 47.2175 15.9401 47.2495C15.9401 48.6788 17.0908 49.8348 18.5135 49.8468H43.1961C44.6201 49.8355 45.7694 48.6788 45.7694 47.2495C45.7694 47.2193 45.7657 33.3562 45.7657 33.3562L48.2896 33.3599C50.4696 33.3524 52.235 31.5799 52.235 29.3999C52.235 28.3146 51.7958 27.3333 51.0865 26.6173Z"
        fill="white"
      />
    </svg>
  </SvgIcon>
);
