import React from 'react';
import SvgIcon, { SvgIconProps } from '../SvgIcon';

export const ClickupIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon
    {...props}
    style={{ ...props.style, fill: 'transparent' }}
    viewBox="0 0 17 20"
  >
    <path
      d="M0 15.3656L3.07539 13.0097C4.70931 15.1422 6.44525 16.1251 8.37755 16.1251C10.2995 16.1251 11.9864 15.1538 13.5467 13.0381L16.666 15.3372C14.4147 18.388 11.617 20 8.37755 20C5.14846 20 2.32366 18.3984 0 15.3656Z"
      fill="url(#paint0_clickup)"
    />
    <path
      d="M8.36755 5.12522L2.8936 9.84229L0.363281 6.90768L8.3792 0L16.3318 6.91285L13.7898 9.83713L8.36755 5.12522Z"
      fill="url(#paint1_clickup)"
    />
    <defs>
      <linearGradient
        id="paint0_clickup"
        x1="0"
        y1="17.7638"
        x2="16.666"
        y2="17.7638"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#8930FD" />
        <stop offset="1" stopColor="#49CCF9" />
      </linearGradient>
      <linearGradient
        id="paint1_clickup"
        x1="0.363281"
        y1="6.69374"
        x2="16.3318"
        y2="6.69374"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF02F0" />
        <stop offset="1" stopColor="#FFC800" />
      </linearGradient>
    </defs>
  </SvgIcon>
);
