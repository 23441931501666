import { Input, InputGroup } from "@/components/cataylst/input";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/cataylst/table";
import { DaveIcon, DocIcon, ExternalLinkIcon, PlusIcon, SarahIcon, SearchIcon } from "@/components/custom/icons";
import { MenuIcon } from "@/components/custom/menu-icon";
import MainSidebar from "@/components/custom/sidebar";
import { useParams } from "react-router-dom";
import { EllipsisHorizontalIcon } from "@heroicons/react/16/solid";
import { formatDateTime } from "@/utils/format";
import { Avatar } from "@/components/cataylst/avatar";
import { Button } from "@/components/custom/button";
import { Checkbox } from "@/components/cataylst/checkbox";
import { Switch } from "@/components/cataylst/switch";
import DemoCompanyProfileAside from "./DemoCompanyProfileAside";

const mockCompanyNames = {
  zoom: 'Zoom'
};

const mockDocuments = [
  {
    id: 0,
    icon: DocIcon,
    name: 'Zoom Proposal',
    author: {
      name: 'David Wallace',
      avatar: DaveIcon
    },
    lastUpdated: new Date(),
    includeInKB: true
  },
  {
    id: 1,
    icon: DocIcon,
    name: 'Security & Compliance',
    author: {
      name: 'David Wallace',
      avatar: DaveIcon
    },
    lastUpdated: new Date(),
    includeInKB: true
  },
  {
    id: 2,
    icon: DocIcon,
    name: 'Brand Guidelines',
    author: {
      name: 'Benek Lisefski',
      avatar: SarahIcon
    },
    lastUpdated: new Date(),
    includeInKB: false
  },
  {
    id: 3,
    icon: ExternalLinkIcon,
    name: 'Dev Docs',
    author: {
      name: 'David Wallace',
      avatar: DaveIcon
    },
    lastUpdated: new Date(),
    includeInKB: false
  },
];

export default function DemoCompanyDetailDocumentsPage() {
  const { companyId } = useParams();

  return (
    <main>
      <MainSidebar pageTitle={mockCompanyNames[companyId!]}>
        <div className="flex h-full gap-0.5 bg-zinc-100">
          <div className="text-cell px-8 py-5 flex-1 bg-white">
            <div className="flex mb-5 justify-between">
              <div className="flex gap-5 items-center">
                <div className="text-xl leading-5 font-p22 font-medium">Documents</div>
              </div>
              <div className="flex gap-3">
                <div className="w-[250px]">
                  <InputGroup>
                    <MenuIcon src={SearchIcon} data-slot="icon" className="!size-4" />
                    <Input placeholder="Search contacts" className="h-9 pl-9 pr-2 !rounded-full" />
                  </InputGroup>
                </div>
                <Button color="white" className="gap-2 flex items-center">
                  <img src={PlusIcon} className="w-4 h-4 invert" />
                  Add doc
                </Button>
              </div>
            </div>
            <Table grid dense={true} fixedHeader={true}>
              <TableHead>
                <TableRow>
                  <TableHeader>
                    <div className="flex gap-4 items-center">
                      <Checkbox />
                      Document
                    </div>
                  </TableHeader>
                  <TableHeader>Author</TableHeader>
                  <TableHeader>Last updated</TableHeader>
                  <TableHeader>Include in KB</TableHeader>
                  <TableHeader></TableHeader>
                </TableRow>
              </TableHead>
              <TableBody>
                {mockDocuments.map((document) => (
                  <TableRow key={document.id}>
                    <TableCell>
                      <div className="flex gap-4 items-center">
                        <Checkbox />
                        <div className="flex gap-2 items-center">
                          <img src={document.icon} className="w-4 h-4" />
                          {document.name}
                        </div>
                      </div>
                    </TableCell>
                    <TableCell>
                      <div className="flex gap-2 items-center">
                        <Avatar square={false} src={document.author.avatar} outline={false} className="size-4" />
                        <div>{document.author.name}</div>
                      </div>
                    </TableCell>
                    <TableCell>
                      {formatDateTime(document.lastUpdated)}
                    </TableCell>
                    <TableCell>
                      <Switch checked={document.includeInKB} />
                    </TableCell>
                    <TableCell>
                      <EllipsisHorizontalIcon height={16} />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
          <div className="min-w-[456px] bg-white">
            <DemoCompanyProfileAside />
          </div>
        </div>
      </MainSidebar>
    </main>
  )
}