import React from 'react';
import SvgIcon, { SvgIconProps } from '../SvgIcon';

export const GoogleAnalyticsIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props} style={{ fill: 'transparent' }} viewBox="5 5 30 30">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M20 0H0V20V40H20H40V20V0H20Z"
      fill="white"
    />
    <path
      d="M22.5 11.3864V26.3864C22.5 28.066 23.6577 29 24.8864 29C26.0227 29 27.2727 28.2045 27.2727 26.3864V11.5C27.2727 9.96236 26.1364 9 24.8864 9C23.6364 9 22.5 10.0618 22.5 11.3864Z"
      fill="#F8AB00"
    />
    <path
      d="M16.25 19V26.3864C16.25 28.066 17.4077 29 18.6364 29C19.7727 29 21.0227 28.2045 21.0227 26.3864V19.1136C21.0227 17.576 19.8864 16.6136 18.6364 16.6136C17.3864 16.6136 16.25 17.6754 16.25 19Z"
      fill="#E37300"
    />
    <path
      d="M14.7727 26.6136C14.7727 27.9311 13.7038 29 12.3864 29C11.0689 29 10 27.9311 10 26.6136C10 25.2962 11.0689 24.2273 12.3864 24.2273C13.7038 24.2273 14.7727 25.2962 14.7727 26.6136Z"
      fill="#E37300"
    />
  </SvgIcon>
);
