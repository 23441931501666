import React from 'react';
import SvgIcon, { SvgIconProps } from '../SvgIcon';

export const GoogleMailIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props} style={{ fill: 'transparent' }} viewBox="7 7 26 26">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20 0H0V20V40H20H40V20V0H20Z"
      fill="white"
    />
    <g clip-path="url(#clip0_1542_187)">
      <path
        d="M9.63636 29H13.4545V19.7273L8 15.6364V27.3636C8 28.2691 8.73364 29 9.63636 29Z"
        fill="#4285F4"
      />
      <path
        d="M26.5454 29H30.3636C31.2691 29 32 28.2664 32 27.3636V15.6364L26.5454 19.7273"
        fill="#34A853"
      />
      <path
        d="M26.5454 12.6364V19.7273L32 15.6364V13.4545C32 11.4309 29.69 10.2773 28.0727 11.4909"
        fill="#FBBC04"
      />
      <path
        d="M13.4546 19.7273V12.6364L20 17.5455L26.5455 12.6364V19.7273L20 24.6364"
        fill="#EA4335"
      />
      <path
        d="M8 13.4545V15.6364L13.4545 19.7273V12.6364L11.9273 11.4909C10.3073 10.2773 8 11.4309 8 13.4545Z"
        fill="#C5221F"
      />
    </g>
  </SvgIcon>
);
