export type FormValidationFn = () => Promise<boolean>;

export type ValidationFn = (
  value: string | string[]
) => string | Promise<string>;

export const required: ValidationFn = (value: string | string[]) => {
  let isFilled = !!value;

  if (value instanceof Array) {
    isFilled = value?.length > 0;
  }

  return isFilled ? '' : 'Required field';
};

const emailRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w+)+$/;

export const email: ValidationFn = (value: string | string[]) => {
  const v = value instanceof Array ? value : [value];
  const allValid = v.every((e) => emailRegex.test(e));

  return allValid ? '' : 'Invalid email address';
};

const phoneRegex = /^\+[0-9]{1,15}$/;

export const phoneNumber: ValidationFn = (value: string | string[]) => {
  const v = value instanceof Array ? value : [value];
  const allValid = v.every((e) => !e || phoneRegex.test(e));

  return allValid ? '' : 'Invalid phone number. Expected format: +NN{N1,12}';
};
