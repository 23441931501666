import React from 'react';
import SvgIcon, { SvgIconProps } from '../SvgIcon';

export const SnowflakeIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props} style={{ fill: 'transparent' }} viewBox="0 0 20 21">
    <path
      d="M17.1876 15.2806L12.9553 12.8356C12.3607 12.4933 11.6004 12.6969 11.2563 13.2915C11.1229 13.5257 11.0707 13.7833 11.0959 14.0338V18.812C11.0959 19.4948 11.6508 20.0498 12.3355 20.0498C13.0184 20.0498 13.5733 19.4948 13.5733 18.812V16.0643L15.9444 17.4336C16.539 17.7778 17.3011 17.5724 17.6434 16.9778C17.9876 16.3832 17.784 15.6229 17.1876 15.2806Z"
      fill="currentColor"
    />
    <path
      d="M6.66197 10.0411C6.66917 9.59971 6.43675 9.18891 6.05478 8.9673L1.82249 6.52414C1.63872 6.41784 1.42791 6.36198 1.21711 6.36198C0.78289 6.36198 0.3793 6.59441 0.163092 6.96917C-0.172032 7.54933 0.0279614 8.29345 0.608121 8.62857L3.04768 10.0357L0.608121 11.4447C0.32705 11.6068 0.125255 11.8681 0.0423753 12.1816C-0.0423066 12.4951 0.00093518 12.823 0.163092 13.1041C0.3793 13.4789 0.78289 13.7113 1.21531 13.7113C1.42791 13.7113 1.63872 13.6554 1.82249 13.5491L6.05478 11.106C6.43315 10.8862 6.66557 10.479 6.66197 10.0411Z"
      fill="currentColor"
    />
    <path
      d="M2.78289 4.79263L7.01518 7.23579C7.50885 7.52226 8.11784 7.42857 8.50702 7.04841C8.75385 6.82139 8.907 6.49707 8.907 6.13673V1.2396C8.907 0.554936 8.35207 0 7.66921 0C6.98455 0 6.42961 0.554936 6.42961 1.2396V4.02689L4.02609 2.63955C3.43152 2.29542 2.67118 2.49901 2.32705 3.09359C1.98292 3.68816 2.18832 4.4485 2.78289 4.79263Z"
      fill="currentColor"
    />
    <path
      d="M12.2997 10.2393C12.2997 10.333 12.2456 10.4627 12.1789 10.5312L10.5015 12.2086C10.4349 12.2753 10.3033 12.3293 10.2096 12.3293H9.78263C9.68894 12.3293 9.55741 12.2753 9.49075 12.2086L7.81153 10.5312C7.74486 10.4627 7.69081 10.333 7.69081 10.2393V9.81232C7.69081 9.71682 7.74486 9.5871 7.81153 9.52044L9.49075 7.84121C9.55741 7.77455 9.68894 7.7205 9.78263 7.7205H10.2096C10.3033 7.7205 10.4349 7.77455 10.5015 7.84121L12.1789 9.52044C12.2456 9.5871 12.2997 9.71682 12.2997 9.81232V10.2393ZM10.8024 10.0339V10.0159C10.8024 9.94745 10.7628 9.85196 10.7141 9.80151L10.2187 9.30783C10.17 9.25738 10.0745 9.21774 10.0042 9.21774H9.98623C9.91776 9.21774 9.82227 9.25738 9.77182 9.30783L9.27814 9.80151C9.2295 9.85015 9.18986 9.94565 9.18986 10.0159V10.0339C9.18986 10.1042 9.2295 10.1997 9.27814 10.2483L9.77182 10.7438C9.82227 10.7925 9.91776 10.8321 9.98623 10.8321H10.0042C10.0745 10.8321 10.17 10.7925 10.2187 10.7438L10.7141 10.2483C10.7628 10.1997 10.8024 10.1042 10.8024 10.0339Z"
      fill="currentColor"
    />
    <path
      d="M12.9555 7.23579L17.1877 4.79263C17.7823 4.4503 17.9877 3.68816 17.6436 3.09359C17.2994 2.49901 16.5391 2.29542 15.9445 2.63955L13.5734 4.00887V1.2396C13.5734 0.554936 13.0185 0 12.3357 0C11.651 0 11.0961 0.554936 11.0961 1.2396V6.03943C11.0726 6.28807 11.1213 6.54752 11.2564 6.78175C11.6005 7.37632 12.3609 7.57992 12.9555 7.23579Z"
      fill="currentColor"
    />
    <path
      d="M7.8638 12.6915C7.58272 12.6374 7.28183 12.6825 7.01518 12.8356L2.78289 15.2806C2.18832 15.6229 1.98292 16.3832 2.32705 16.9778C2.67118 17.5742 3.43152 17.7778 4.02609 17.4337L6.42961 16.0463V18.812C6.42961 19.4948 6.98455 20.0498 7.66921 20.0498C8.35207 20.0498 8.907 19.4948 8.907 18.812V13.9149C8.907 13.2969 8.45477 12.7852 7.8638 12.6915Z"
      fill="currentColor"
    />
    <path
      d="M19.8327 6.95475C19.4903 6.35838 18.7282 6.15478 18.1336 6.49891L13.9013 8.94207C13.4959 9.1763 13.2725 9.60511 13.2797 10.0411C13.2761 10.4754 13.4996 10.8988 13.9013 11.1294L18.1336 13.5743C18.7282 13.9167 19.4885 13.7131 19.8327 13.1185C20.1768 12.5239 19.9714 11.7636 19.3768 11.4195L16.9805 10.0357L19.3768 8.65199C19.9732 8.30966 20.1768 7.54933 19.8327 6.95475Z"
      fill="currentColor"
    />
  </SvgIcon>
);
