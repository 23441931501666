import React from 'react';
import SvgIcon, { SvgIconProps } from '../SvgIcon';

export const PushoverIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props} style={{ fill: 'transparent' }} viewBox="0 0 20 20">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.25433 17.3821C-0.822663 13.6565 -1.10758 7.33133 2.61795 3.25433C6.34347 -0.822663 12.6687 -1.10758 16.7457 2.61795C20.8227 6.34347 21.1076 12.6687 17.3821 16.7457C13.6565 20.8227 7.33133 21.1076 3.25433 17.3821ZM11.9125 5.41886L9.40357 5.74899L4.73229 16.247H7.10919L9.20549 11.5427H9.28802C10.0253 11.5427 10.7213 11.4106 11.3759 11.1465C12.0308 10.8824 12.6113 10.5386 13.1175 10.1151C13.6237 9.69129 14.0445 9.2181 14.38 8.69553C14.7158 8.17272 14.9442 7.6527 15.0652 7.13552C15.1643 6.70635 15.1835 6.2937 15.1228 5.89754C15.0624 5.50139 14.9112 5.15476 14.6691 4.85765C14.427 4.56054 14.0886 4.32113 13.6538 4.13946C13.2192 3.95799 12.6828 3.86727 12.0446 3.86727C11.3183 3.86727 10.5727 3.98561 9.8078 4.22233C9.04312 4.4588 8.33884 4.77781 7.695 5.17935C7.05136 5.58113 6.49577 6.0461 6.0282 6.5743C5.5604 7.1025 5.26049 7.65823 5.12844 8.24144C4.99639 8.80265 5.05962 9.196 5.3181 9.42146C5.57681 9.64716 5.98677 9.76001 6.54798 9.76001C6.53697 9.71598 6.52315 9.67199 6.50655 9.62796C6.49014 9.58393 6.47926 9.5206 6.47387 9.43797C6.46825 9.35554 6.47094 9.25101 6.48196 9.12435C6.49297 8.99793 6.52598 8.83017 6.58099 8.62108C6.72406 7.98283 6.96064 7.41343 7.29076 6.91285C7.62089 6.41204 8.00872 5.99389 8.45429 5.65837C8.90006 5.32262 9.38426 5.06674 9.90684 4.89066C10.4297 4.71458 10.9606 4.62656 11.4999 4.62656C11.962 4.62656 12.3279 4.6871 12.5974 4.80813C12.8671 4.92917 13.068 5.08874 13.2 5.28681C13.3321 5.48489 13.4009 5.71598 13.4065 5.98008C13.4119 6.24418 13.3816 6.51929 13.3156 6.80539C13.2055 7.26757 13.0239 7.73806 12.7708 8.21684C12.5178 8.69542 12.218 9.13008 11.8714 9.52084C11.5247 9.91136 11.1449 10.236 10.7322 10.4947C10.3196 10.7532 9.89875 10.8989 9.46959 10.932L11.9125 5.41886Z"
      fill="currentColor"
    />
  </SvgIcon>
);
