import React from 'react';
import SvgIcon, { SvgIconProps } from '../SvgIcon';

export const GoogleBigqueryIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props} style={{ fill: 'transparent' }} viewBox="8 7.5 24.9 24.9">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20 0H0V20V40H20H40V20V0H20Z"
      fill="white"
    />
    <path
      d="M13.6327 30.1901L8.21706 20.8097C7.92765 20.3087 7.92765 19.6909 8.21706 19.1899L13.6327 9.8099C13.9218 9.30926 14.4555 9.00078 15.0332 9H25.8696C26.4453 9.00272 26.9763 9.3112 27.2642 9.8099L32.6791 19.1899C32.9685 19.6909 32.9685 20.3087 32.6791 20.8097L27.2634 30.1901C26.9743 30.6907 26.4406 30.9992 25.863 31H15.0293C14.4524 30.9981 13.9206 30.6896 13.6316 30.1901H13.6327Z"
      fill="#4386FA"
    />
    <path
      opacity="0.1"
      d="M23.8311 16.9788C23.8311 16.9788 25.3366 20.5899 23.2842 22.6368C21.2318 24.6837 17.4947 23.3736 17.4947 23.3736L25.0966 30.9965H25.8684C26.4464 30.9957 26.9801 30.6868 27.2688 30.1866L30.8538 23.9789L23.8311 16.9788Z"
      fill="white"
    />
    <path
      d="M25.6089 24.3678L23.949 22.7041C23.931 22.6856 23.9103 22.6698 23.8876 22.6574C24.5936 21.748 24.9264 20.6036 24.818 19.4573C24.7097 18.3111 24.1684 17.2493 23.3045 16.4883C22.4405 15.7272 21.3189 15.3242 20.1682 15.3613C19.0175 15.3984 17.9242 15.8729 17.1111 16.688C16.298 17.5031 15.8262 18.5975 15.7919 19.7484C15.7576 20.8992 16.1634 22.0198 16.9266 22.8819C17.6897 23.7439 18.7529 24.2826 19.8994 24.3881C21.0458 24.4936 22.1894 24.1581 23.0971 23.4498C23.109 23.4712 23.1238 23.4909 23.1411 23.5082L24.8048 25.1719C24.8537 25.2207 24.92 25.2481 24.989 25.2481C25.0581 25.2481 25.1244 25.2207 25.1732 25.1719L25.6089 24.7362C25.6577 24.6874 25.6852 24.6211 25.6852 24.552C25.6852 24.483 25.6577 24.4167 25.6089 24.3678ZM20.308 23.2919C19.6348 23.2919 18.9766 23.0924 18.4168 22.7184C17.857 22.3444 17.4207 21.8128 17.163 21.1908C16.9053 20.5689 16.8379 19.8845 16.9692 19.2242C17.1005 18.5638 17.4247 17.9573 17.9008 17.4813C18.3768 17.0052 18.9833 16.681 19.6437 16.5497C20.304 16.4184 20.9884 16.4858 21.6103 16.7435C22.2323 17.0012 22.7639 17.4375 23.1379 17.9973C23.5119 18.5571 23.7114 19.2153 23.7114 19.8885C23.7113 20.7911 23.3527 21.6567 22.7144 22.2949C22.0762 22.9332 21.2106 23.2918 20.308 23.2919ZM18.1323 19.7068V21.1135C18.349 21.4958 18.6625 21.8148 19.0418 22.0373V19.6983L18.1323 19.7068ZM19.8342 18.5406V22.3419C20.1348 22.3973 20.443 22.3973 20.7437 22.3419V18.5406H19.8342ZM22.4654 21.1103V20.2771H21.5567V22.0276C21.9354 21.8057 22.2491 21.488 22.4662 21.1065L22.4654 21.1103Z"
      fill="white"
    />
  </SvgIcon>
);
