import { PropsWithChildren, ReactNode } from "react";
import { ExclaimIcon } from "./icons";
import { Tooltip } from "react-tooltip";

interface ITooltipClaimProps {
  id: string;
  icon?: ReactNode;
};

export function TooltipClaim(props: PropsWithChildren<ITooltipClaimProps>) {
  return (
    <div className="size-4">
      <a data-tooltip-id={props.id} data-tooltip-offset={10} className="cursor-pointer">
        {props.icon || <img src={ExclaimIcon} />}
      </a>
      <Tooltip id={props.id} arrowColor="transparent" className="z-[10000]">
        <div className="font-normal text-sm/4">
          {props.children}
        </div>
      </Tooltip>
    </div>
  )
}