import { apiClient } from "./api";

export interface IPageParams {
  cursor?: number;
  limit?: number;
  orderBy?: string;
};

export const getPageParamString = (pageParams?: IPageParams) => {
  let result = '';
  if (pageParams?.cursor !== undefined)
    result += `cursor=${pageParams.cursor}&`;
  if (pageParams?.limit !== undefined)
    result += `limit=${pageParams.limit}&`;
  if (pageParams?.orderBy !== undefined)
    result += `orderBy=${pageParams.orderBy}&`;
  return result;
}

export const getBusinesses = async (params?: IPageParams) => {
  return apiClient.get(`/agents/orgs/x-functions/businesses?${getPageParamString(params)}`);
};

export const getBusinessById = async (businessId: number) => {
  return apiClient.get(`/agents/orgs/x-functions/businesses/${businessId}`);
};

export const getIndividuals = async (params?: IPageParams) => {
  return apiClient.get(`/agents/orgs/x-functions/individuals?${getPageParamString(params)}`);
};

export const getIndividualById = async (individualId: number) => {
  return apiClient.get(`/agents/orgs/x-functions/individuals/${individualId}`);
};

export const getBusinessEmployees = async (businessId: number, params?: IPageParams) => {
  return apiClient.get(`/agents/orgs/x-functions/business/${businessId}/employees?${getPageParamString(params)}`);
};