import { JSONSchema, JSONSchemaType } from '@makeropsinc/workflow-types';
import { useContext } from 'react';
import { context } from '../form-from-schema';
import { followRefs } from '../json-schemas';
import { TextProperty } from './text-property';
import { ObjectProperty } from './object-property';
import { PropertyDetails } from './property-details';

interface Props {
  path: string;
  propName: string;
  property: JSONSchema;
  required?: boolean;
  value: JSONSchemaType;
  onChange: (propName: string, value: JSONSchemaType) => void;
  onDelete?: (propName: string) => void;
  maxLevel?: number;
  level: number;
  noSelects: boolean;
}

const OBJECT_PROP_TYPES = ['object', 'array'];

export const Property: React.FC<Props> = ({
  path,
  propName,
  property,
  required,
  value,
  onChange,
  onDelete,
  level,
  maxLevel = Number.MAX_SAFE_INTEGER,
  noSelects,
}) => {
  const ctx = useContext(context);
  const prop = followRefs(property, ctx.schema);

  const getPropControl = () => {
    if (level >= maxLevel) return TextProperty;

    return OBJECT_PROP_TYPES.includes(prop.type ?? '')
      ? ObjectProperty
      : TextProperty;
  };

  const PropControl = getPropControl();

  return (
    <PropControl
      path={path}
      property={prop}
      propName={propName}
      value={value}
      onChange={onChange}
      onDelete={onDelete}
      required={required}
      noSelects={noSelects}
    >
      <PropertyDetails
        path={path}
        property={prop}
        propName={propName}
        value={value}
        onChange={onChange}
        required={required}
        level={level}
        maxLevel={maxLevel}
        noSelects={noSelects}
      />
    </PropControl>
  );
};
