import React from "react";
import SvgIcon, { SvgIconProps } from "../SvgIcon";

export const GeminiIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon
    {...props}
    style={{ fill: "transparent" }}
    width="32"
    height="32"
    viewBox="0 0 32 32"
  >
    <path
      d="M0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16Z"
      fill="white"
    />
    <path
      d="M0.363636 16C0.363636 7.36427 7.36427 0.363636 16 0.363636C24.6357 0.363636 31.6364 7.36427 31.6364 16C31.6364 24.6357 24.6357 31.6364 16 31.6364C7.36427 31.6364 0.363636 24.6357 0.363636 16Z"
      stroke="black"
      stroke-opacity="0.1"
      stroke-width="0.727273"
    />
    <path
      d="M25.455 16.0189C23.0018 16.1695 20.6883 17.2119 18.9503 18.9499C17.2123 20.6878 16.1699 23.0013 16.0194 25.4546H15.9815C15.8312 23.0012 14.7889 20.6876 13.0509 18.9496C11.3129 17.2116 8.99921 16.1692 6.5459 16.0189L6.5459 15.9811C8.99921 15.8308 11.3129 14.7884 13.0509 13.0504C14.7889 11.3124 15.8312 8.99879 15.9815 6.54547L16.0194 6.54547C16.1699 8.9987 17.2123 11.3122 18.9503 13.0502C20.6883 14.7881 23.0018 15.8306 25.455 15.9811V16.0189Z"
      fill="url(#paint0_radial_1205_4648)"
    />
    <defs>
      <radialGradient
        id="paint0_radial_1205_4648"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(8.42263 14.2308) rotate(18.6832) scale(20.1264 161.225)"
      >
        <stop offset="0.067" stop-color="#9168C0" />
        <stop offset="0.343" stop-color="#5684D1" />
        <stop offset="0.672" stop-color="#1BA1E3" />
      </radialGradient>
    </defs>
  </SvgIcon>
);
