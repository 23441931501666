import styled from '@emotion/styled';
import MuiTab, { TabProps } from '@mui/material/Tab';
import MuiTabs, { TabsProps as MuiTabsProps } from '@mui/material/Tabs';

export type { TabProps } from '@mui/material/Tab';

export type TabsProps = MuiTabsProps & {
  stretch?: boolean;
};

export const Tabs = styled((props: TabsProps) => (
  <MuiTabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))(({ orientation = 'horizontal', stretch }) => ({
  '&.MuiTabs-root': {
    minHeight: 40,
    height: stretch ? '100%' : undefined,
  },
  '& .MuiTabs-flexContainer': {
    gap: orientation === 'vertical' ? '4px' : 0,
    alignItems: 'flex-end',
    height: stretch ? '100%' : undefined,
  },
  '& .MuiTabs-indicator': {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
  },
  '& .MuiTabs-indicatorSpan': {
    flex: 1,
    ...(orientation === 'horizontal' && {
      marginLeft: '2px',
      marginRight: '2px',
    }),
    backgroundColor: 'gray',
  },
}));

export const Tab = styled((props: TabProps) => (
  <MuiTab disableRipple {...props} />
))(({ }) => ({
  minHeight: 40,
  alignSelf: 'stretch',
  maxWidth: 'initial',
  textAlign: 'right',
  paddingLeft: '2px',
  paddingRight: '2px',
  minWidth: 0,
  alignItems: 'flex-end',
  fontSize: 12,
  letterSpacing: 0.46,
  '&:hover': {
    backgroundColor: 'gray',
  },
}));
