import AgentInput from "@/components/custom/agent-input";
import { CameraIcon, DocIcon, TableIcon, ThunderIcon } from "@/components/custom/icons";
import MainSidebar from "@/components/custom/sidebar";

interface ITopic {
  id: number;
  icon: string;
  description: string;
};

const mockTopics = [
  {
    id: 0,
    icon: ThunderIcon,
    description: 'Suggest actions to advanced stalled deals'
  },
  {
    id: 1,
    icon: DocIcon,
    description: 'Generate personalised customer content'
  },
  {
    id: 2,
    icon: TableIcon,
    description: 'Generate multi-scenario revenue forecasts'
  },
  {
    id: 3,
    icon: CameraIcon,
    description: 'Optimize sales stages and processes'
  }
];

interface ITopicSectionProps {
  topic: ITopic;
};

const TopicSection = ({ topic }: ITopicSectionProps) => {
  return (
    <div className="h-[110px] bg-[#F2F2F299] p-4 font-firstext">
      <img src={topic.icon} className="size-4" />
      <div className="mt-3 text-sm">
        {topic.description}
      </div>
    </div>
  )
};

export default function DashboardPage() {

  return (
    <main>
      <MainSidebar>
        <div className="max-w-[640px] mx-auto h-full flex flex-col justify-center">
          <h1 className="text-xl leading-[20px] text-center mb-8 font-medium">Good morning James. How can I help you today?</h1>
          <div className="mb-8">
            <AgentInput size="lg" rounded />
          </div>
          <div className="mt-5 flex gap-3 flex-wrap px-4">
            {mockTopics.map(topic => (
              <div className="w-[calc(50%-10px)]">
                <TopicSection key={topic.id} topic={topic} />
              </div>
            ))}
          </div>
        </div>
      </MainSidebar>
    </main>
  )
}