import React from 'react';
import SvgIcon, { SvgIconProps } from '../SvgIcon';

export const RedshiftIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props} style={{ fill: 'transparent' }} viewBox="7.5 7.5 24 24">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20 0H0V20V40H20H40V20V0H20Z"
      fill="#D1E9FF"
    />
    <g clipPath="url(#clip0_346_783)">
      <path
        d="M20 25.2002L27.56 27.0002V12.8399L20 14.6399V25.2002Z"
        fill="#205B97"
      />
      <path
        d="M27.56 12.8399L29 13.5599V26.2802L27.56 27.0002V12.8399ZM20 25.2002L12.44 27.0002V12.8399L20 14.6399V25.2002Z"
        fill="#5193CE"
      />
      <path
        d="M12.44 12.8399L11 13.5599V26.2802L12.44 27.0002V12.8399Z"
        fill="#205B97"
      />
      <path
        d="M21.9204 29.8802L24.8004 28.4402V11.44L21.9204 10L21.0399 19.4402L21.9204 29.8802Z"
        fill="#5193CE"
      />
      <path
        d="M18.0796 29.8802L15.1996 28.4402V11.44L18.0796 10L18.9601 19.4402L18.0796 29.8802Z"
        fill="#205B97"
      />
      <path d="M18.0796 10H21.9594V29.8401H18.0796V10Z" fill="#2E73B7" />
    </g>
    <defs>
      <clipPath id="clip0_346_783">
        <rect
          width="18"
          height="19.8802"
          fill="white"
          transform="translate(11 10)"
        />
      </clipPath>
    </defs>
  </SvgIcon>
);
