import MetaIcon from '@/assets/demo/meta.png';
import SarahIcon from '@/assets/demo/sarah.png';
import { CalendarIcon, DaveIcon } from '@/components/custom/icons';
import { apiClient } from '@/services/api';
import { useEffect, useState } from 'react';

export interface IContact {
  id: string;
  name: string;
  avatar: string;
  role: string;
  company: {
    name: string;
    logo: string;
  };
  tags: string[];
  lastInteractionDate: Date;
  engagementScore: number;
  salesRep: {
    avatar: string;
    name: string;
  };
};

const mockCustomers = [
  {
    id: '0',
    name: 'Sarah Hughes',
    avatar: SarahIcon,
    role: 'Developer',
    company: {
      name: 'Meta',
      logo: MetaIcon
    },
    tags: ['react', 'llm'],
    lastInteractionDate: new Date(),
    engagementScore: 50,
    salesRep: {
      avatar: SarahIcon,
      name: 'Sarah Hughes'
    }
  }
];

export function useContactList() {
  const [data, setData] = useState<IContact[]>([]);
  const [isLoading, setLoading] = useState(false);
  const [isError, setError] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError(false);
      try {
        const { data: { data: contactItems } } = await apiClient.get(`/audience/orgs/x-functions/users\?userType\=contact`);
        const contactList: any[] = [];
        console.log("@Contact: ", contactItems.filter(item => item.name === 'Cody Christ'))
        for (const item of contactItems.slice(-10)) {
          const { userId } = item;
          const { data: contactDetails } = await apiClient.get(`/audience/orgs/x-functions/users/${userId}`);

          const getAttributeValue = (attributeName: string, defaultValue?: any) => {
            const attribute = contactDetails.attributes.find(attr => attr.attributeName === attributeName);
            if (attribute) {
              const value = attribute.attributeValues[0];
              return JSON.parse(value);
            }
            return defaultValue;
          };

          const contact: IContact = mockCustomers[0] || {
            id: contactDetails.user.userId,
            name: contactDetails.user.name,
            avatar: DaveIcon,
            role: getAttributeValue('role', 'N/A'),
            company: {
              name: getAttributeValue('company', {}).name || 'N/A',
              logo: MetaIcon,
            },
            tags: getAttributeValue('tags', []),
            lastInteractionDate: getAttributeValue('lastInteractionDate', 'N/A'),
            engagementScore: getAttributeValue('engagementScore', 0),
            salesRep: {
              avatar: SarahIcon,
              name: getAttributeValue('salesRep', {}).name || 'N/A'
            }
          };
          contactList.push(contact);
        }
        console.log({ contactList });
        setData(contactList);
      } catch (err) {
        console.error(err);
        setError(true);
      }
      setLoading(false);
    };
    fetchData();
  }, []);

  return {
    data,
    isLoading,
    isError
  };
};
