import { JSONSchema, JSONSchemaType } from '@makeropsinc/workflow-types';
import { useCallback, useContext } from 'react';
import { context } from '../form-from-schema';
import { followRefs } from '../json-schemas';
import { parseFieldName } from '../utils';
import { CrossSmallIcon } from '@/assets/app-icons';

interface Props {
  path: string;
  propName: string;
  property: JSONSchema;
  required?: boolean;
  value: JSONSchemaType;
  onChange: (propName: string, value: JSONSchemaType) => void;
  onDelete?: (propName: string) => void;
  children?: React.ReactNode;
  noSelects: boolean;
}

export const ObjectProperty: React.FC<Props> = ({
  path,
  propName,
  property,
  required,
  onDelete,
  children,
}) => {
  const ctx = useContext(context);
  const errorMessages = ctx.getErrors(path);
  const prop = followRefs(property, ctx.schema);

  const handleDelete = useCallback(
    () => onDelete && onDelete(propName),
    [onDelete, propName]
  );

  return (
    <div>
      <div className='text-xs mt-4'>
        {`${prop.title || parseFieldName(propName)}${required ? '*' : ''}`}
      </div>
      {errorMessages?.length > 0 ? (
        <div color="error.main">
          {`\n${errorMessages.map((e) => e.message).join('\n')}\n`}
        </div>
      ) : null}
      {onDelete && (
        <button onClick={handleDelete}>
          <CrossSmallIcon />
        </button>
      )}
      <div>{children ?? null}</div>
    </div>
  );
};
