import React from 'react';
import SvgIcon, { SvgIconProps } from '../SvgIcon';

export const ConvertKitIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon
    {...props}
    style={{ ...props.style, fill: 'transparent' }}
    viewBox="0 0 20 19"
  >
    <path
      d="M9.62791 14.6512C13.1008 14.6512 15.7829 12.031 15.7829 8.7907C15.7829 5.76744 13.2558 3.90697 11.5969 3.90697C9.27132 3.90697 7.41085 5.53488 7.14729 7.95349C7.10078 8.4031 6.74419 8.7752 6.29457 8.7752C5.55039 8.7752 4.44961 8.75969 3.76744 8.75969C3.47287 8.75969 3.22481 8.52713 3.24031 8.21705C3.34884 6.12403 4.04651 4.15504 5.37985 2.65116C6.86822 0.976745 9.03876 0 11.6124 0C15.9225 0 20 3.90698 20 8.7752C20 14.1705 15.5349 18.5271 9.73643 18.5271C5.6124 18.5271 1.44186 15.8295 0 12.062C0 12.0465 0 12.031 0 12.0155C0.0155039 11.907 0.0620153 11.7984 0.093023 11.7054C0.155039 11.5659 0.20155 11.4109 0.20155 11.2558L0.170542 10.7287C0.155039 10.4651 0.310078 10.2326 0.542636 10.124L0.75969 10.031C0.992248 9.92248 1.16279 9.70543 1.2093 9.44186C1.28682 9.05426 1.6124 8.7752 2.0155 8.7752C2.63566 8.7752 3.16279 9.17829 3.36434 9.76744C4.31008 12.5891 5.84496 14.6512 9.62791 14.6512Z"
      fill="currentColor"
    />
  </SvgIcon>
);
