import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/cataylst/table";
import { AIStarIcon, AlertRed32Icon, CalendarIcon, EmailIcon, EyePurple32Icon, ForwardGreen32Icon, PenIcon, ThunderColoredIcon, VideoBlue32Icon } from "@/components/custom/icons";
import MainSidebar from "@/components/custom/sidebar";
import { useParams } from "react-router-dom";
import { formatDateTime } from "@/utils/format";
import { Badge } from "@/components/cataylst/badge";
import { Button } from "@/components/custom/button";
import DemoCompanyProfileAside from "./DemoCompanyProfileAside";

const mockCompanyNames = {
  zoom: 'Zoom'
};

interface IAction {
  id: number;
  icon: string;
  title: string;
  description: string;
  dueDate?: Date;
  button: {
    icon: string;
    title: string;
  };
};

const mockNextActions: IAction[] = [
  {
    id: 0,
    icon: VideoBlue32Icon,
    title: 'Security demo',
    description: `Schedule a product demo focusing on Apple's new data security features, as indicated to be a current priority for Zoom.`,
    dueDate: new Date(),
    button: {
      icon: PenIcon,
      title: 'Draft demo script'
    }
  }
];

const mockInsights: IAction[] = [
  {
    id: 1,
    icon: ForwardGreen32Icon,
    title: 'Upsell opportunity – subscription tier upgrade',
    description: `Zoom nearing their usage limits on the current subscription tier. They will soon require the capacity and features available in the Premium Plan.`,
    button: {
      icon: PenIcon,
      title: 'Draft proposal'
    }
  },
  {
    id: 2,
    icon: AlertRed32Icon,
    title: 'Risk alert – low engagement',
    description: `No engagement in the last two weeks days. Reach out to Sarah to find out her current concerns.`,
    button: {
      icon: CalendarIcon,
      title: 'Schedule call'
    }
  },
  {
    id: 3,
    icon: EyePurple32Icon,
    title: 'Competitive intelligence',
    description: `Zoom has recently engaged with Microsoft regarding LLM integration. Follow up with Sarah to outlines Apple’s advantages.`,
    button: {
      icon: EmailIcon,
      title: 'Draft email'
    }
  },
];

const mockProgressList = [
  {
    id: 0,
    salesStage: 'Negotiation',
    customerJourney: 'Decision',
    notes: 'Proposal sent by James Charlesworth at 3:45pm yesterday'
  }
];

interface IActionSectionProps {
  action: IAction;
  borderTop?: boolean;
};

function ActionSection({ action, borderTop }: IActionSectionProps) {
  return (
    <div className={`flex gap-3 font-firstext py-4 ${borderTop && 'border-t border-solid border-t-gray-200'}`}>
      <img src={action.icon} className="w-8 h-8" />
      <div className="flex flex-col gap-1 flex-1">
        <div className="font-semibold text-sm leading-[17.5px]">
          {action.title}
        </div>
        <div className="text-sm leading-[21px] opacity-60 pr-10">
          {action.description}
        </div>
        <div className="font-medium text-sm leading-[17.5px]">
          {action.dueDate && (
            <div>
              Due: {formatDateTime(action.dueDate)}
            </div>
          )}
        </div>
      </div>
      <Button color="white" className="gap-2 flex items-center">
        <img src={action.button.icon} className="w-4 h-4" />
        {action.button.title}
      </Button>
    </div>
  )
}

export default function DemoCompanyDetailInsightsPage() {
  const { companyId } = useParams();

  return (
    <main>
      <MainSidebar pageTitle={mockCompanyNames[companyId!]}>
        <div className="flex h-full gap-0.5 bg-zinc-100">
          <div className="flex flex-1 flex-col gap-1">
            <div className="rounded pt-7 pb-8 px-8 bg-white">
              <div className="font-medium text-xs font-firstext">
                <div className="flex gap-2 items-center tracking-widest">
                  <img src={ThunderColoredIcon} className="w-4 h-4" />
                  NEXT ACTION
                </div>
                <div className="mt-2">
                  {mockNextActions.map(action => (
                    <ActionSection key={action.id} action={action} />
                  ))}
                </div>
              </div>
            </div>
            <div className="rounded pt-7 pb-8 px-8 bg-white">
              <div className="font-medium text-xs font-firstext">
                <div className="flex gap-2 items-center tracking-widest">
                  <img src={AIStarIcon} className="w-4 h-4" />
                  AI INSIGHTS
                </div>
                <div className="mt-2">
                  {mockInsights.map((action, index) => (
                    <ActionSection key={action.id} action={action} borderTop={index > 0} />
                  ))}
                </div>
              </div>
            </div>
            <div className="rounded pt-7 pb-8 px-8 bg-white">
              <div className="font-medium text-xs tracking-widest font-firstext">
                DEAL PROGRESS
              </div>
              <div className="mt-4">
                <Table grid dense={true} fixedHeader={true}>
                  <TableHead>
                    <TableRow>
                      <TableHeader>Sales stage</TableHeader>
                      <TableHeader>Customer journey</TableHeader>
                      <TableHeader>Notes</TableHeader>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {mockProgressList.map((progress) => (
                      <TableRow key={progress.id}>
                        <TableCell>
                          <Badge color="customPurpleMain">{progress.salesStage}</Badge>
                        </TableCell>
                        <TableCell>
                          <Badge color="customPurpleLight">{progress.customerJourney}</Badge>
                        </TableCell>
                        <TableCell>
                          <div className="text-ellipsis overflow-hidden max-w-[400px]">
                            {progress.notes}
                          </div>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </div>
            </div>
            <div className="rounded pt-7 pb-8 px-8 bg-white flex-1">
              <div className="font-medium text-xs tracking-widest font-firstext">
                PERFORMANCE
              </div>
            </div>
          </div>
          <div className="min-w-[456px] bg-white">
            <DemoCompanyProfileAside />
          </div>
        </div>
      </MainSidebar>
    </main>
  )
}