import React from 'react';
import SvgIcon, { SvgIconProps } from '../SvgIcon';

export const TaigaIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props} style={{ fill: 'transparent' }} viewBox="0 0 24 24">
    <path
      opacity="0.8"
      d="M21.0675 4.08121L19.9415 12.0061L12.0166 10.8802L13.1426 2.95526L21.0675 4.08121Z"
      fill="#A295AE"
    />
    <path
      opacity="0.8"
      d="M2.95703 19.9287L4.08298 12.0038L12.0079 13.1298L10.8819 21.0547L2.95703 19.9287Z"
      fill="#5D6F6D"
    />
    <path
      opacity="0.8"
      d="M4.08298 2.96579L12.0079 4.09174L10.8819 12.0166L2.95703 10.8907L4.08298 2.96579Z"
      fill="#8CD592"
    />
    <path
      opacity="0.8"
      d="M19.9308 21.0556L12.0059 19.9296L13.1318 12.0047L21.0567 13.1307L19.9308 21.0556Z"
      fill="#665E74"
    />
    <path
      opacity="0.8"
      d="M11.2158 24.0045L6.4082 17.6046L12.8081 12.797L17.6157 19.1969L11.2158 24.0045Z"
      fill="#3C3647"
    />
    <path
      opacity="0.8"
      d="M24.0005 12.7849L17.6006 17.5925L12.793 11.1926L19.1929 6.38496L24.0005 12.7849Z"
      fill="#837193"
    />
    <path
      opacity="0.8"
      d="M12.7974 0L17.605 6.39992L11.205 11.2075L6.39746 4.80759L12.7974 0Z"
      fill="#A2F4AC"
    />
    <path
      opacity="0.8"
      d="M0 11.2141L6.39992 6.40648L11.2075 12.8064L4.80759 17.614L0 11.2141Z"
      fill="#7EA685"
    />
    <path
      d="M11.9992 8.95915L15.0423 12.0023L11.9992 15.0454L8.95605 12.0023L11.9992 8.95915Z"
      fill="#3C3647"
    />
  </SvgIcon>
);
