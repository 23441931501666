import { JSONSchema, JSONSchemaType } from '@makeropsinc/workflow-types';
import { useContext } from 'react';
import { context } from '../form-from-schema';
import { followRefs } from '../json-schemas';
import { ObjectDetails } from './object-details';
import { ArrayDetails } from './array-details';

interface Props {
  path: string;
  propName: string;
  property: JSONSchema;
  required?: boolean;
  value: JSONSchemaType;
  onChange: (propName: string, value: JSONSchemaType) => void;
  level: number;
  maxLevel: number;
  noSelects: boolean;
}

export const PropertyDetails: React.FC<Props> = ({
  value,
  property,
  ...rest
}) => {
  const ctx = useContext(context);
  const prop = followRefs(property, ctx.schema);

  switch (prop.type) {
    case 'object':
      return (
        <ObjectDetails
          property={prop}
          value={value as Record<string, JSONSchemaType>}
          {...rest}
        />
      );
    case 'array':
      return (
        <ArrayDetails
          property={prop}
          value={value as Array<JSONSchemaType>}
          {...rest}
        />
      );

    default:
      return null;
  }
};
