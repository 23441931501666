import React from 'react';
import SvgIcon, { SvgIconProps } from '../SvgIcon';

export const PaddleIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props} style={{ fill: 'transparent' }} viewBox="0 0 16 20">
    <path
      d="M0 7.42881V6.75643C0.828144 6.75643 1.62277 6.42912 2.21036 5.84745C2.79597 5.26774 3.12526 4.47706 3.12723 3.65286H3.75228C3.75228 4.47904 4.0796 5.26972 4.66719 5.85139C5.25477 6.43701 6.05137 6.76432 6.87951 6.76235V7.43472C6.05137 7.43472 5.25675 7.76204 4.66916 8.34371C4.08354 8.92341 3.75426 9.71409 3.75228 10.5383H3.12723C3.12723 9.71212 2.79992 8.92144 2.21233 8.33977C1.62277 7.75612 0.828144 7.42881 0 7.42881Z"
      fill="#FFDD35"
    />
    <path
      d="M3.12795 2.48755H8.50497C11.1314 2.48755 13.0085 4.35087 13.0085 7.08178C13.0085 9.81268 11.1333 11.676 8.50497 11.676H3.12598V19.5H5.37577V13.6636H8.503C12.2809 13.6636 15.2583 10.7079 15.2583 7.08178C15.2583 3.45569 12.2809 0.5 8.503 0.5H3.12598V2.48755H3.12795Z"
      fill="#FFDD35"
    />
  </SvgIcon>
);
