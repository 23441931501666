import React from 'react';
import SvgIcon, { SvgIconProps } from '../SvgIcon';

export const BitwardenIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon
    {...props}
    style={{ ...props.style, fill: 'transparent' }}
    viewBox="0 0 16 20"
  >
    <path
      d="M15.7627 0.737257C15.607 0.581557 15.4143 0.5 15.1993 0.5H0.800742C0.585728 0.5 0.392957 0.581557 0.237258 0.737257C0.0815579 0.892956 0 1.08573 0 1.30074V10.9022C0 11.6214 0.140872 12.3258 0.4152 13.0301C0.696942 13.7345 1.038 14.3573 1.4532 14.9059C1.8684 15.4546 2.35774 15.981 2.92864 16.5C3.49954 17.019 4.02595 17.449 4.50788 17.7901C4.98981 18.1311 5.49398 18.4574 6.02039 18.7613C6.5468 19.0653 6.91752 19.2729 7.13995 19.3767C7.36237 19.4879 7.54032 19.5695 7.67377 19.6288C7.77016 19.6807 7.88137 19.703 8 19.703C8.11863 19.703 8.22243 19.6807 8.32623 19.6288C8.45969 19.5695 8.63763 19.4879 8.86006 19.3767C9.08248 19.2655 9.4532 19.0653 9.97961 18.7613C10.506 18.4574 11.0102 18.1311 11.4921 17.7901C11.974 17.449 12.5005 17.019 13.0714 16.5C13.6423 15.981 14.1316 15.4546 14.5468 14.9059C14.962 14.3573 15.3031 13.7345 15.5848 13.0301C15.8665 12.3258 16 11.614 16 10.9022V1.30074C16 1.08573 15.9184 0.900371 15.7627 0.737257ZM13.9092 10.9912C13.9092 14.4685 8.00741 17.4564 8.00741 17.4564V2.55375H13.9092C13.9092 2.56117 13.9092 7.5139 13.9092 10.9912Z"
      fill="currentColor"
    />
  </SvgIcon>
);
