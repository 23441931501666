import React from 'react';
import SvgIcon, { SvgIconProps } from '../SvgIcon';

export const ShopifyIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props} style={{ fill: 'transparent' }} viewBox="0 0 20 23">
    <path
      d="M13.6271 2.60133C13.6271 2.60133 13.3716 2.67227 12.9502 2.79746C12.8778 2.56378 12.7671 2.29671 12.6224 2.00878C12.1456 1.11161 11.4347 0.62756 10.5917 0.62756C10.5364 0.62756 10.4811 0.62756 10.4087 0.644252C10.3917 0.606696 10.3533 0.590004 10.3363 0.556621C9.9702 0.160197 9.4934 -0.0192367 8.9272 0.00162768C7.82886 0.0391836 6.73052 0.806994 5.85355 2.18822C5.23201 3.15633 4.75521 4.37481 4.6275 5.32622C3.36313 5.70178 2.48616 5.97302 2.46914 5.98971C1.83057 6.18584 1.80928 6.2067 1.73691 6.79508C1.68157 7.24158 0 19.8729 0 19.8729L13.8357 22.2222V2.58464C13.7207 2.58464 13.6654 2.60133 13.6271 2.60133ZM10.4257 3.56944C9.69349 3.78643 8.88889 4.0368 8.10132 4.27049C8.32269 3.42756 8.76117 2.58464 9.27203 2.02965C9.47212 1.83352 9.74883 1.59984 10.0596 1.45379C10.3704 2.10059 10.4427 2.98107 10.4257 3.56944ZM8.9272 0.736054C9.18263 0.736054 9.404 0.790303 9.58706 0.898798C9.29331 1.04068 8.99957 1.27436 8.72712 1.54559C8.03321 2.28002 7.50106 3.42756 7.27969 4.52503C6.61984 4.72116 5.95998 4.92145 5.35973 5.10089C5.7599 3.37332 7.24138 0.77361 8.9272 0.736054ZM6.80289 10.6049C6.87527 11.7525 9.9702 12.0028 10.1533 14.711C10.281 16.8476 8.99957 18.2997 7.15198 18.4082C4.92124 18.5501 3.69519 17.2607 3.69519 17.2607L4.17199 15.2869C4.17199 15.2869 5.39804 16.2008 6.3857 16.1298C7.02427 16.0923 7.26266 15.5748 7.24564 15.216C7.15624 13.7095 4.6275 13.7972 4.46573 11.3227C4.32099 9.24039 5.70881 7.14143 8.78672 6.9453C9.97446 6.87436 10.579 7.16229 10.579 7.16229L9.88506 9.74531C9.88506 9.74531 9.09749 9.38644 8.16518 9.45738C6.80289 9.54501 6.78587 10.3879 6.80289 10.6049ZM11.1579 3.35662C11.1579 2.81832 11.0856 2.04634 10.8301 1.39954C11.6731 1.56229 12.0732 2.47615 12.2563 3.03114C11.9285 3.12294 11.5624 3.23144 11.1579 3.35662ZM14.2529 22.193L20 20.7951C20 20.7951 17.5309 4.41654 17.5096 4.30804C17.4925 4.19955 17.3989 4.12861 17.3095 4.12861C17.2201 4.12861 15.6066 4.09105 15.6066 4.09105C15.6066 4.09105 14.619 3.15633 14.2529 2.79746V22.193Z"
      fill="currentColor"
    />
  </SvgIcon>
);
