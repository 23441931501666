import { JSONSchema, JSONSchemaType } from '@makeropsinc/workflow-types';
import { useCallback, useContext } from 'react';
import { context } from '../form-from-schema';
import { createValueFromSchemaType, followRefs } from '../json-schemas';
import { Button } from '../../button';
import { Property } from '.';

interface Props {
  path: string;
  propName: string;
  property: JSONSchema;
  required?: boolean;
  value: Array<JSONSchemaType>;
  onChange: (propName: string, value: JSONSchemaType) => void;
  level: number;
  maxLevel: number;
  noSelects: boolean;
}

export const ArrayDetails: React.FC<Props> = ({
  path,
  propName,
  property,
  value,
  required,
  onChange,
  level,
  maxLevel,
  noSelects,
}) => {
  const ctx = useContext(context);
  const prop = followRefs(property, ctx.schema);

  const handleAddItemClick = useCallback(
    () =>
      onChange(
        propName,
        (value || []).concat(
          createValueFromSchemaType(prop.items as JSONSchema, ctx.schema)
        )
      ),
    [value, onChange, propName, prop, ctx.schema]
  );

  const handleItemChange = useCallback(
    (idx: number) => (_: string, itm: JSONSchemaType) => {
      onChange(propName, [
        ...value.slice(0, idx),
        itm,
        ...value.slice(idx + 1),
      ]);
    },
    [propName, value, onChange]
  );

  const handleItemDelete = useCallback(
    (idx: number) => () => {
      onChange(propName, [...value.slice(0, idx), ...value.slice(idx + 1)]);
    },
    [propName, value, onChange]
  );

  if (prop.type !== 'array') return null;

  return (
    <>
      <Button onClick={handleAddItemClick}>
        Add item
      </Button>
      {(value || []).map((val, i) => (
        <Property
          path={`${path}${i}/`}
          key={i}
          propName={`${propName} item ${i + 1}`}
          property={prop?.items as JSONSchema}
          required={required}
          value={val}
          onChange={handleItemChange(i)}
          onDelete={handleItemDelete(i)}
          level={level + 1}
          maxLevel={maxLevel}
          noSelects={noSelects}
        />
      ))}
    </>
  );
};
