import React, { useEffect, useRef, useState } from 'react';
import { Input } from '../cataylst/input';

interface ColorInputProps {
  value: string;
  onChange: (value: string, color: string) => void;
  color?: string;
}

const COLORS = [
  '#FFB800', // yellow
  '#FF9900', // orange
  '#FF4D4D', // red
  '#FF66B2', // pink
  '#9933FF', // purple
  '#3366FF', // blue
  '#00B3B3', // teal
  '#00CC66', // green
  '#66CC00', // lime
];

export const ColorInput: React.FC<ColorInputProps> = ({
  value,
  onChange,
  color = COLORS[0],
}) => {
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [selectedColor, setSelectedColor] = useState(color);
  const colorPickerRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        colorPickerRef.current && 
        buttonRef.current && 
        !colorPickerRef.current.contains(event.target as Node) &&
        !buttonRef.current.contains(event.target as Node)
      ) {
        setShowColorPicker(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    setSelectedColor(color);
  }, [color]);

  const handleColorSelect = (color: string) => {
    setSelectedColor(color);
    onChange(value, color);
    setShowColorPicker(false);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value, selectedColor);
  };

  console.log({ selectedColor });

  return (
    <div className="flex flex-col gap-2 w-full">
      <div className="relative flex flex-col gap-2">
        <div className="relative flex items-center w-full bg-white rounded-lg border border-gray-200">
          <div className="absolute left-3 top-2.5 z-10">
            <button
              ref={buttonRef}
              type="button"
              onClick={() => setShowColorPicker(!showColorPicker)}
              className="size-3 rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              style={{ backgroundColor: selectedColor }}
            />
          </div>
          <Input
            type="text"
            value={value}
            onChange={handleInputChange}
            className="w-full !pl-7 pr-3 py-2 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            placeholder="Discovery"
          />
        </div>
        {showColorPicker && (
          <div ref={colorPickerRef} className="flex top-10 z-[1000] absolute w-fit bg-white p-4 rounded-lg shadow-lg border border-gray-200">
            <div className="flex flex-wrap justify-between gap-1 w-[108px]">
              {COLORS.map((color) => (
                <button
                  key={color}
                  onClick={() => handleColorSelect(color)}
                  className="size-5 rounded-full hover:scale-110 transition-transform focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                  style={{ backgroundColor: color }}
                />
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
