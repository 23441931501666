import React from 'react';
import SvgIcon, { SvgIconProps } from '../SvgIcon';

export const VeroIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props} style={{ fill: 'transparent' }} viewBox="0 0 14 17">
    <path
      d="M22.5433 8.53584C24.4847 6.58291 24.4847 3.41748 22.5433 1.46454C21.6138 0.527239 20.3485 0 19.0285 0C17.7085 0 16.4432 0.527239 15.5138 1.46454L11.999 5.00019L15.503 8.52505L15.5138 8.53584C16.4421 9.47217 17.7056 9.99943 19.0241 10.0007C20.3426 10.002 21.6071 9.47713 22.5372 8.54259L22.5439 8.53584H22.5433Z"
      fill="url(#paint0_vero)"
    />
    <path
      d="M4.97017 12.0722L1.4561 8.53584C-0.485368 6.58358 -0.485368 3.41748 1.4561 1.46454C2.38554 0.527239 3.65085 0 4.97085 0C6.29084 0 7.55616 0.527239 8.48559 1.46454L12.0003 5.00019L4.97017 12.0722Z"
      fill="#17DA88"
    />
    <path
      d="M22.5434 8.53584C21.6122 9.47505 20.3437 10.0025 19.0211 10.0004C17.6984 9.99841 16.4316 9.46711 15.5032 8.52505L11.9992 5.00019L4.96973 12.0722L6.97796 14.0919C8.30576 15.4309 10.1134 16.1842 11.9992 16.1842C13.885 16.1842 15.6927 15.4309 17.0205 14.0919L22.5434 8.53584Z"
      fill="url(#paint1_vero)"
    />
    <defs>
      <linearGradient
        id="paint0_vero"
        x1="1444.96"
        y1="112.268"
        x2="-205.805"
        y2="131.731"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#2936A3" />
        <stop offset="1" stopColor="#162286" />
      </linearGradient>
      <linearGradient
        id="paint1_vero"
        x1="-379.704"
        y1="158.266"
        x2="1919.73"
        y2="19.1281"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#2297F4" />
        <stop offset="1" stopColor="#31A0F9" />
      </linearGradient>
    </defs>
  </SvgIcon>
);
