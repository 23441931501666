import React from 'react';
import SvgIcon, { SvgIconProps } from '../SvgIcon';

export const HarvestIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props} style={{ fill: 'transparent' }} viewBox="0 0 16 17">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 0.993282V16.0435H1.00917C1.56276 16.0435 1.99848 15.5866 1.99848 15.0303V0H1.00917C0.454261 0 0 0.437044 0 0.993282ZM12.0035 0H12.9928V15.0303C12.9928 15.5866 12.5385 16.0435 12.0035 16.0435H10.9943V5.00349C10.9943 4.44725 11.4486 4.01021 12.0035 4.01021V0ZM7.00081 10.0494V16.0435H5.99163V7.02979C5.99163 6.47355 6.4459 6.03783 7.00081 6.03783H9.98728V9.03622C9.98728 9.59246 9.55156 10.0494 8.99797 10.0494H7.00081ZM3.98467 16.0435H2.99536V1.01315C2.99536 0.45691 3.44962 0 3.98467 0H4.99384V11.04C4.99384 11.5962 4.53958 12.0333 3.98467 12.0333V16.0435ZM14.0016 0.993282V16.0435H15.0108C15.5644 16.0435 16.0001 15.5866 16.0001 15.0303V0H15.0108C14.4559 0 14.0016 0.437044 14.0016 0.993282Z"
      fill="currentColor"
    />
  </SvgIcon>
);
