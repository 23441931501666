import MainSidebar from "@/components/custom/sidebar";
import { Button } from "@/components/custom/button";
import { TagField, useTags } from "@/components/custom/tag-input";
import { Select } from "@/components/cataylst/select";
import { Textarea } from "@/components/cataylst/textarea";
import { MultiTextGroup } from "@/components/custom/multi-text-group";
import { useParams } from "react-router-dom";
import { IProduct, mockProducts } from "./DemoProductsPage";
import { useCallback, useEffect, useState } from "react";
import { apiClient } from "@/services/api";
import { Environment } from "@/utils/constants";
import axios from "axios";

export default function DemoProductDetailPage() {
  const [productDetails, setProductDetails] = useState<IProduct>({
    id: 0,
    title: '',
    description: '',
    icon: mockProducts[0].icon,
    challenges: [],
    differentiates: [],
    customerBenefits: [],
    capabilities: []
  });
  const {
    tags: tagKeywords,
    handleAddTag: handleAddTagKeyword,
    handleRemoveTag: handleRemoveTagKeyword,
  } = useTags(5);
  const {
    tags: tagTerms,
    handleAddTag: handleAddTagTerm,
    handleRemoveTag: handleRemoveTagTerm,
  } = useTags(5);
  const {
    tags: tagJargons,
    handleAddTag: handleAddTagJargon,
    handleRemoveTag: handleRemoveTagJargon,
  } = useTags(5);

  const { productId } = useParams();

  useEffect(() => {
    const product = mockProducts.find(
      (prod) => String(prod.id) === String(productId)
    );
    if (product) {
      setProductDetails(product);
    }
  }, [productId]);

  const onProductDetailsChange = (fieldName: string, texts: string[]) => {
    const newProductDetails = { ...productDetails };
    newProductDetails[fieldName] = texts;
    setProductDetails(newProductDetails);
  };

  const onProductDetailsSingleChange = (fieldName: string, texts: string) => {
    const newProductDetails = { ...productDetails };
    newProductDetails[fieldName] = texts;
    setProductDetails(newProductDetails);
  };

  const onAddKnowledgebaseFile = async () => {
    const { data: newKnowledgebaseFile } = await apiClient.post(`/agents/orgs/x-functions/knowledgebases/${Environment.KNOWLEDGE_BASE_ID}/files/upload-url`, {
      fileName: `New-Product-Knowledgebase-File-${new Date().getTime()}.txt`,
      contentType: 'text'
    });
    const filePresignedUrl = newKnowledgebaseFile.uploadUrl;
    await axios.put(filePresignedUrl, JSON.stringify({...productDetails, icon: undefined}), {
      headers: {
        'Content-Type': 'text/plain'
      }
    });
  }

  return (
    <main>
      <MainSidebar
        pageTitle={`Products/${productDetails ? productDetails.title : "New"}`}
        shouldHideActionButton
        toolbar={
          <Button color="dark" size="lg" onClick={onAddKnowledgebaseFile}>
            Save
          </Button>
        }
      >
        <div className="text-cell px-8 py-5 max-w-[852px]">
          <div className="flex flex-col gap-2">
            <div className="font-medium">Product name</div>
            <Textarea
              resizable={false}
              rows={1}
              withItems={null}
              type="text"
              value={productDetails?.title}
              onChange={(e) => {
                onProductDetailsSingleChange("title", e.target.value)
              }}
            />
          </div>
          <div className="flex flex-col gap-2 mt-8">
            <div className="font-medium">Description</div>
            <Textarea
              resizable={false}
              withItems={null}
              type="text"
              value={productDetails?.description}
              rows={4}
              onChange={(e) => {
                onProductDetailsSingleChange("description", e.target.value)
              }}
            />
          </div>
          <div className="mt-8">
            <MultiTextGroup
              buttonName="Add challenge"
              label={"Challenges - status quo"}
              defaultValues={productDetails?.challenges}
              onChange={(texts: string[]) =>
                onProductDetailsChange("challenges", texts)
              }
            />
          </div>
          <div className="mt-8">
            <MultiTextGroup
              buttonName="Add value prop"
              label={"Differentiated value"}
              defaultValues={productDetails?.differentiates}
              onChange={(texts: string[]) =>
                onProductDetailsChange("differentiates", texts)
              }
            />
          </div>
          <div className="mt-8">
            <MultiTextGroup
              buttonName="Add capability"
              label={"Capabilities"}
              defaultValues={productDetails?.capabilities}
              onChange={(texts: string[]) =>
                onProductDetailsChange("capabilities", texts)
              }
            />
          </div>
          <div className="mt-8">
            <MultiTextGroup
              buttonName="Add benefit"
              label={"Customer benefits"}
              defaultValues={productDetails?.customerBenefits}
              onChange={(texts: string[]) =>
                onProductDetailsChange("customerBenefits", texts)
              }
            />
          </div>
          <div className="flex flex-col gap-2 mt-8">
            <div className="font-medium">Integration capabilities</div>
            <TagField
              tags={tagKeywords}
              addTag={handleAddTagKeyword}
              removeTag={handleRemoveTagKeyword}
              maxTags={5}
              color="success"
            />
          </div>
          <div className="flex flex-col gap-2 mt-8">
            <div className="font-medium">Product ICP</div>
            <TagField
              tags={tagJargons}
              addTag={handleAddTagJargon}
              removeTag={handleRemoveTagJargon}
              maxTags={5}
              color="primary"
            />
          </div>
          <div className="flex flex-col gap-2 mt-8">
            <div className="font-medium">Product documentation</div>
            <div className="w-full border border-[#106EF6] border-dashed rounded-lg h-[64px] bg-[#F8FAFE] flex items-center justify-center text-sm cursor-pointer">
              Drag here to upload logo, or&nbsp;
              <span className="underline">browse files</span>
            </div>
          </div>
          <div className="mt-20"></div>
        </div>
      </MainSidebar>
    </main>
  );
}
