import {
  useState,
  useCallback,
  useEffect,
  useContext,
  ComponentType,
  createContext,
} from 'react';
import { TextField, TextFieldProps } from './text-field';

export interface Field {
  name: string;
  validate: () => string | Promise<string>;
}

type RegisterFieldFn = (arg0: Field) => void;
type UnregisterFieldFn = (name: string) => void;

export const FormContext = createContext({
  register: (() => undefined) as RegisterFieldFn,
  unregister: (() => undefined) as UnregisterFieldFn,
});


type Props = TextFieldProps & {
  Component?: ComponentType<any>;
  name: string;
  onValidate: (value: string) => string | Promise<string>;
};

export const Field: React.FC<Props> = ({
  Component = TextField,
  name,
  value,
  onValidate,
  onChange,
  ...rest
}) => {
  const [error, setError] = useState('');

  const validate = useCallback(
    async (val?: string) => {
      if (!onValidate) return '';
      const error = await onValidate((val ?? value) as string);
      setError(error);
      return error;
    },
    [onValidate, value]
  );

  const handleInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      if (!onChange) return;

      const { value } = e.target;
      onChange(e);
      if (error) validate(value);
    },
    [error, validate, onChange]
  );

  const handleBlur = useCallback(() => validate(), [validate]);

  const { register, unregister } = useContext(FormContext);

  useEffect(() => {
    register({
      validate: validate,
      name,
    });

    return () => unregister(name);
  }, [validate, name, register, unregister]);

  return (
    <Component
      {...rest}
      name={name}
      value={value}
      error={!!error}
      helperText={error}
      onChange={handleInputChange}
      onBlur={handleBlur}
    />
  );
};
