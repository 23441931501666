import React from 'react';
import SvgIcon, { SvgIconProps } from '../SvgIcon';

export const WordpressIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props} style={{ fill: 'transparent' }} viewBox="0 0 22 22">
    <path
      d="M11.0005 0.5C5.21031 0.5 0.5 5.21009 0.5 10.9999C0.5 16.7901 5.21053 21.5007 11.0003 21.5007C16.7899 21.5007 21.5 16.7901 21.5 10.9999C21.5002 5.21031 16.7901 0.5 11.0005 0.5ZM1.55963 10.9999C1.55963 9.63149 1.85313 8.33182 2.37705 7.15806L6.8805 19.4966C3.73149 17.9661 1.55963 14.7368 1.55963 10.9999ZM11.0005 20.4412C10.0738 20.4412 9.17935 20.3053 8.33357 20.0565L11.1659 11.8258L14.0684 19.7759C14.0871 19.8228 14.1101 19.8656 14.1356 19.906C13.1543 20.2512 12.0999 20.4412 11.0005 20.4412ZM12.3013 6.57369C12.8695 6.5438 13.3821 6.48379 13.3821 6.48379C13.8905 6.42356 13.831 5.67619 13.3217 5.70608C13.3217 5.70608 11.7927 5.82588 10.8052 5.82588C9.87785 5.82588 8.31851 5.70608 8.31851 5.70608C7.80964 5.67619 7.75029 6.4539 8.25894 6.48379C8.25894 6.48379 8.74075 6.5438 9.24896 6.57369L10.7197 10.6038L8.65368 16.7999L5.21642 6.57391C5.78551 6.54423 6.297 6.48401 6.297 6.48401C6.80543 6.424 6.74543 5.67619 6.23634 5.70674C6.23634 5.70674 4.70799 5.82654 3.72036 5.82654C3.54296 5.82654 3.33413 5.82195 3.11286 5.81519C4.80051 3.25186 7.7025 1.55963 11.0005 1.55963C13.4587 1.55963 15.6962 2.49925 17.3756 4.03786C17.3348 4.03568 17.2953 4.03022 17.253 4.03022C16.326 4.03022 15.6679 4.83804 15.6679 5.70587C15.6679 6.48357 16.1163 7.14213 16.5948 7.91984C16.9542 8.54872 17.3732 9.35676 17.3732 10.5235C17.3732 11.3318 17.0629 12.2692 16.6546 13.5754L15.713 16.7223L12.3013 6.57369ZM15.7464 19.1606L18.6301 10.8231C19.1693 9.47656 19.348 8.39968 19.348 7.44152C19.348 7.09435 19.3253 6.77161 19.2843 6.4707C20.0218 7.81553 20.4415 9.35894 20.441 11.0003C20.4408 14.483 18.5528 17.524 15.7464 19.1606Z"
      fill="currentColor"
    />
  </SvgIcon>
);
