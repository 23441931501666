import React from 'react';
import SvgIcon, { SvgIconProps } from '../SvgIcon';

export const AirtableIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon
    {...props}
    style={{ ...props.style, fill: 'transparent' }}
    viewBox="0 0 20 17"
  >
    <path
      d="M8.93022 0.211344L1.46953 3.30183C1.3776 3.33989 1.29914 3.40455 1.24419 3.4875C1.18925 3.57045 1.16033 3.66793 1.16115 3.76742C1.16197 3.86692 1.19248 3.96391 1.24879 4.04594C1.30509 4.12798 1.38461 4.19134 1.47715 4.22788L8.96831 7.19867C9.62636 7.45987 10.3593 7.45987 11.0174 7.19867L18.5085 4.22788C18.6011 4.19134 18.6806 4.12798 18.7369 4.04594C18.7932 3.96391 18.8237 3.86692 18.8245 3.76742C18.8254 3.66793 18.7964 3.57045 18.7415 3.4875C18.6866 3.40455 18.6081 3.33989 18.5162 3.30183L11.0555 0.211344C10.3751 -0.070448 9.61062 -0.070448 8.93022 0.211344Z"
      fill="#FEBF10"
    />
    <path
      d="M10.6523 8.82116V16.2416C10.6522 16.3232 10.6721 16.4036 10.7103 16.4758C10.7485 16.548 10.8037 16.6097 10.8713 16.6555C10.9388 16.7014 11.0166 16.73 11.0977 16.7388C11.1789 16.7477 11.261 16.7365 11.3368 16.7062L19.6844 13.4667C19.7774 13.4297 19.8572 13.3656 19.9135 13.2828C19.9698 13.2 19.9999 13.1022 20 13.002V5.58051C19.9998 5.49896 19.9798 5.41869 19.9415 5.34667C19.9033 5.27465 19.848 5.21306 19.7806 5.16726C19.7131 5.12146 19.6354 5.09283 19.5544 5.08387C19.4733 5.07491 19.3913 5.08589 19.3155 5.11585L10.9679 8.3565C10.8747 8.39326 10.7947 8.45725 10.7384 8.54014C10.6821 8.62303 10.6521 8.72096 10.6523 8.82116Z"
      fill="#48B2E5"
    />
    <path
      d="M8.70776 9.20422L6.22994 10.4012L5.97856 10.522L0.75521 13.0249C0.42875 13.1849 0 12.9433 0 12.5755V5.61099C0.00231342 5.54715 0.0177041 5.48447 0.0452193 5.42682C0.0727345 5.36917 0.111789 5.31778 0.159965 5.27583C0.197533 5.23866 0.240003 5.20681 0.286196 5.18115C0.354718 5.14206 0.431059 5.11866 0.509717 5.11264C0.588375 5.10662 0.667389 5.11812 0.741063 5.14633L8.67185 8.28796C8.76238 8.32307 8.84073 8.38374 8.89738 8.46259C8.95403 8.54145 8.98653 8.63507 8.99091 8.73206C8.99529 8.82906 8.97137 8.92523 8.92207 9.00887C8.87276 9.09251 8.80019 9.16001 8.7132 9.20313"
      fill="#ED334A"
    />
    <path
      d="M8.70754 9.20423L6.22971 10.4013L0.154297 5.27692C0.191865 5.23976 0.234335 5.20791 0.280528 5.18225C0.34905 5.14316 0.425391 5.11976 0.504049 5.11374C0.582707 5.10772 0.661721 5.11922 0.735395 5.14743L8.66619 8.28906C8.75671 8.32417 8.83507 8.38484 8.89172 8.46369C8.94836 8.54255 8.98086 8.63617 8.98524 8.73316C8.98962 8.83016 8.9657 8.92633 8.9164 9.00997C8.86709 9.09361 8.79452 9.1611 8.70754 9.20423Z"
      fill="#010101"
      fill-opacity="0.25"
    />
  </SvgIcon>
);
