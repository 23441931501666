import React from "react";
import SvgIcon, { SvgIconProps } from "../SvgIcon";

export const AnthropicIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon
    {...props}
    style={{ fill: "transparent" }}
    width="32"
    height="32"
    viewBox="0 0 32 32"
  >
    <rect width="32" height="32" rx="16" fill="black" />
    <path
      d="M10.7563 15.9912L8.72754 21.1136C8.72754 21.1339 9.23979 21.1541 9.86662 21.1541H11.0057L11.2483 20.534L11.6527 19.4691L11.8145 19.031L13.9376 19.0107L16.0608 18.9973L16.263 19.5162L16.6809 20.5947L16.8966 21.1541H18.0356C18.6625 21.1541 19.1747 21.1406 19.1747 21.1271C19.1747 21.1136 18.6018 19.6645 17.9008 17.9054L15.8721 12.8098L15.1172 10.9091H13.9511H12.7851L10.7563 15.9912ZM14.6184 15.2902L15.2654 16.9887C15.2654 17.0224 14.6723 17.0426 13.9511 17.0426C13.2299 17.0426 12.6368 17.0224 12.6368 16.9887C12.6368 16.9617 12.866 16.3484 13.1491 15.6272L13.7961 13.9556C13.8702 13.7602 13.9376 13.6052 13.9511 13.6186C13.9646 13.6321 14.2679 14.3803 14.6184 15.2902ZM17.0179 10.99C17.0179 11.0372 17.9211 13.3356 19.0264 16.099L21.0282 21.1204L22.1673 21.1406C22.8346 21.1473 23.2862 21.1339 23.2727 21.0934C23.266 21.0597 22.3426 18.7546 21.2372 15.9709L19.2152 10.9091H18.1165C17.2807 10.9091 17.0179 10.9293 17.0179 10.99Z"
      fill="white"
    />
  </SvgIcon>
);
