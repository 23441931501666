import React from 'react';
import SvgIcon, { SvgIconProps } from '../SvgIcon';

export const SalesmateIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props} style={{ fill: 'transparent' }} viewBox="0 0 26 13">
    <rect width="26" height="12.6905" fill="url(#pattern0_salesmate)" />
    <defs>
      <pattern
        id="pattern0_salesmate"
        patternContentUnits="objectBoundingBox"
        width="1"
        height="1"
      >
        <use
          xlinkHref="#image0_salesmate"
          href="#image0_salesmate"
          transform="scale(0.0119048 0.0243902)"
        />
      </pattern>
      <image
        id="image0_salesmate"
        width="84"
        height="41"
        xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFQAAAApCAYAAACr1w7eAAAAAXNSR0IArs4c6QAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAAVKADAAQAAAABAAAAKQAAAAC2Kuk+AAAEoElEQVRoBe2Z75WbOBTF45z9HkqgBKeCsBXEqSCkgjgVDFPBTirwpIKkA9xB3IFIBetUMPu7HJGj1QBCMjD+4HfOHelJ7899DyywZ/MqUZ6enra4CvlAiONmszm6e/hUru7NT+gnfBpvfVQlZo5BATT2iWL+cDfw2aNn7pozT+Lh+E+fijz4B/wLQlL5kUMOdr9mLH1fV2c/A3fAgJAcXF/NcTAhJ/Z/gtL3nUUnsApQI2Ok8pPHOGNrwLYnxmfWp1xQzFpJbWjnb5iUPo9knWC6KxU0Vio/aWwAa3+nOMx1UWu7FjNc2tAuV8vDr8nX//IXXJ1IOXoNNL6UVPBQ7h14dseuSEo8cs7jT2M5Bxsa0cwzCQRf+tYa38jq+cB6t1x1k5FxiMfvHp+mZy1jTRiTkr40NPV+zKh3D8cDGBKdYe0V63VOWCReAR6HEg6sz8qDHDpWdiDEo4gqkYAqbkhqNkJXMiqfa0xs5TYgJDUGues755zYJTCgT0xULiJ8B8aC4Y98jwqUaEy2HJg/WZ9PdK4vLqQVD/eNwqB3KJIJEGQLdMUWuzN9cuRSMbm7ji4e+kj+b921mXtucxaMq9U+dw23eLcO3DqwWAc2fmR7VuxYz+3eiVE/dJytvtpgz8qChDlQfv3QcWRcXby+hLnIAdwB96mG2orh791aVZBLD6Qa9IlhsbxqLhBUASIakp8YZEsWQ3w9yfsuqs9t8QuczAXH2mc7oh+Waig5p17Yjt7uirjsWy4wKzt2EWOxRCHkP0RwkKnu5EU+McQ9gBhpubymMR8TmjP7nQFzNaaM5CKfbaTPVPNiqqG1E5edGppC6H1ksinmKTwUdzcleIwNF1dc8hgfa7tVQ7MEx2tyebMAmdSevFFDzwmEUnxCaVJj/goFTthvEnzk8lsNPSU4p/iE0jQYpDR1di58eUjlcnzFeVHEPMqsbU7xswuxq0guZnYSNuBFXHCuIwp5WLAIfVszEVzKq+RCASpE34JC8rhUAV1cCOibkgkRYb/qfJYayZEDA0LSzwUvfeRMj7deWvdLEffjkkuFPPbw0FINCt9nKZ1cUVw2fUQs4dzu6b98xz67pddUDDm2ILO59KtXY+erDnARhwJ0XPTL12lVErdktw7cOnBtHRg6QzPOh7PI2rNjy/Qd+LbWGUZe5cyAeAgS6QX4sSKPnc2bfmZSzAPoE71aqahFhRx6ddKbxZBcA49ichOoQq8KQ2LYyCcHizQk9kcw1ky2WzH8XZLHZ+IP8agjy2o/6lVLe/jPga0duLgoxQAlqEGsHHAQD93VF3168BePPajBmKTVTMR6LGpgr/KvYsB+qe1DDw9zQbK9H8/V9WvTmHxgsxkzWGHvTI634NsKuUIp7nkYPowZjTYU55cupoH83/DQE7Zkfg9eSr7AoQolH22onAlytsV8Qm20tpJ8Jc9bcp+6fLagtXkcLY/RO7PjGD1y9kx9eFR+8Annls62CmS+r69jM5VHyhmqp3sNCj9vSN+EDIb2SaaityAfsHn2IoxP6dnKtwFnIHvNo8ThIT6CL89+3MFnh5Frm6OfLU6M8pEeLf8BO0DAt9LF/ccAAAAASUVORK5CYII="
        href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFQAAAApCAYAAACr1w7eAAAAAXNSR0IArs4c6QAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAAVKADAAQAAAABAAAAKQAAAAC2Kuk+AAAEoElEQVRoBe2Z75WbOBTF45z9HkqgBKeCsBXEqSCkgjgVDFPBTirwpIKkA9xB3IFIBetUMPu7HJGj1QBCMjD+4HfOHelJ7899DyywZ/MqUZ6enra4CvlAiONmszm6e/hUru7NT+gnfBpvfVQlZo5BATT2iWL+cDfw2aNn7pozT+Lh+E+fijz4B/wLQlL5kUMOdr9mLH1fV2c/A3fAgJAcXF/NcTAhJ/Z/gtL3nUUnsApQI2Ok8pPHOGNrwLYnxmfWp1xQzFpJbWjnb5iUPo9knWC6KxU0Vio/aWwAa3+nOMx1UWu7FjNc2tAuV8vDr8nX//IXXJ1IOXoNNL6UVPBQ7h14dseuSEo8cs7jT2M5Bxsa0cwzCQRf+tYa38jq+cB6t1x1k5FxiMfvHp+mZy1jTRiTkr40NPV+zKh3D8cDGBKdYe0V63VOWCReAR6HEg6sz8qDHDpWdiDEo4gqkYAqbkhqNkJXMiqfa0xs5TYgJDUGues755zYJTCgT0xULiJ8B8aC4Y98jwqUaEy2HJg/WZ9PdK4vLqQVD/eNwqB3KJIJEGQLdMUWuzN9cuRSMbm7ji4e+kj+b921mXtucxaMq9U+dw23eLcO3DqwWAc2fmR7VuxYz+3eiVE/dJytvtpgz8qChDlQfv3QcWRcXby+hLnIAdwB96mG2orh791aVZBLD6Qa9IlhsbxqLhBUASIakp8YZEsWQ3w9yfsuqs9t8QuczAXH2mc7oh+Waig5p17Yjt7uirjsWy4wKzt2EWOxRCHkP0RwkKnu5EU+McQ9gBhpubymMR8TmjP7nQFzNaaM5CKfbaTPVPNiqqG1E5edGppC6H1ksinmKTwUdzcleIwNF1dc8hgfa7tVQ7MEx2tyebMAmdSevFFDzwmEUnxCaVJj/goFTthvEnzk8lsNPSU4p/iE0jQYpDR1di58eUjlcnzFeVHEPMqsbU7xswuxq0guZnYSNuBFXHCuIwp5WLAIfVszEVzKq+RCASpE34JC8rhUAV1cCOibkgkRYb/qfJYayZEDA0LSzwUvfeRMj7deWvdLEffjkkuFPPbw0FINCt9nKZ1cUVw2fUQs4dzu6b98xz67pddUDDm2ILO59KtXY+erDnARhwJ0XPTL12lVErdktw7cOnBtHRg6QzPOh7PI2rNjy/Qd+LbWGUZe5cyAeAgS6QX4sSKPnc2bfmZSzAPoE71aqahFhRx6ddKbxZBcA49ichOoQq8KQ2LYyCcHizQk9kcw1ky2WzH8XZLHZ+IP8agjy2o/6lVLe/jPga0duLgoxQAlqEGsHHAQD93VF3168BePPajBmKTVTMR6LGpgr/KvYsB+qe1DDw9zQbK9H8/V9WvTmHxgsxkzWGHvTI634NsKuUIp7nkYPowZjTYU55cupoH83/DQE7Zkfg9eSr7AoQolH22onAlytsV8Qm20tpJ8Jc9bcp+6fLagtXkcLY/RO7PjGD1y9kx9eFR+8Annls62CmS+r69jM5VHyhmqp3sNCj9vSN+EDIb2SaaityAfsHn2IoxP6dnKtwFnIHvNo8ThIT6CL89+3MFnh5Frm6OfLU6M8pEeLf8BO0DAt9LF/ccAAAAASUVORK5CYII="
      />
    </defs>
  </SvgIcon>
);
