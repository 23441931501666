import React from 'react';
import SvgIcon, { SvgIconProps } from '../SvgIcon';

export const MediumIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props} style={{ fill: 'transparent' }} viewBox="6 6 28 28">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20 0H0V20V40H20H40V20V0H20Z"
      fill="black"
    />
    <path
      d="M21.5374 19.9999C21.5374 23.7638 18.5069 26.8152 14.7685 26.8152C11.0301 26.8152 7.99988 23.7647 7.99988 19.9999C7.99988 16.2351 11.0303 13.1848 14.7685 13.1848C18.5067 13.1848 21.5374 16.236 21.5374 19.9999ZM28.9629 19.9999C28.9629 23.5431 27.4475 26.4153 25.5784 26.4153C23.7094 26.4153 22.194 23.5422 22.194 19.9999C22.194 16.4576 23.7094 13.5845 25.5784 13.5845C27.4475 13.5845 28.9629 16.4576 28.9629 19.9999ZM32 19.9999C32 23.1745 31.467 25.7478 30.8097 25.7478C30.1523 25.7478 29.6193 23.1736 29.6193 19.9999C29.6193 16.8262 30.1523 14.252 30.8099 14.252C31.4675 14.252 32 16.8255 32 19.9999Z"
      fill="white"
    />
  </SvgIcon>
);
