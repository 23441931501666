import React from 'react';
import SvgIcon, { SvgIconProps } from '../SvgIcon';

export const BambooHrIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon
    {...props}
    style={{ ...props.style, fill: 'transparent' }}
    viewBox="0 0 22 18"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.2866 1.41433C7.65421 2.86916 7.79751 3.68069 8.28193 5.0919L9.09502 7.7757C9.09502 7.7757 8.29283 7.62305 7.63707 7.52337C6.87539 7.40654 6.53738 7.29284 5.56854 6.89564C4.59969 6.49689 3.72274 5.52492 2.86449 4.43925C2.00467 3.35514 0 1.64953 0 1.64953C0 1.64953 0.619938 1.69003 1.24922 1.84891C1.7648 1.97819 3.10592 2.41745 4.4891 3.14174C4.79595 3.30218 4.96573 3.40654 5.26791 3.59502C5.72741 3.88162 6.21184 4.30841 6.67601 4.78193C7.21963 5.33801 7.69626 5.78505 8.10436 6.28349C7.66199 5.46106 6.51246 2.50156 4.26636 0.05919C4.04673 -0.180685 5.81776 0.917446 6.2866 1.41589V1.41433ZM16.0623 5.39875C19.2492 5.39875 22 8.16978 22 11.3941C22 14.866 19.3629 17.5685 15.8785 17.5685C12.7165 17.5685 9.9595 15.3224 9.9595 11.7165V0H11.7913V7.15888L12.0093 6.92835C12.8069 6.13084 13.9424 5.39875 16.0639 5.39875H16.0623ZM15.8785 15.8863C18.4766 15.8863 20.1277 14.0234 20.1277 11.5654C20.1277 9.30685 18.5685 7.14953 15.9206 7.14953C13.2726 7.14953 11.6308 9.16044 11.6308 11.6106C11.6308 14.0607 13.5779 15.8863 15.8785 15.8863Z"
      fill="currentColor"
    />
  </SvgIcon>
);
