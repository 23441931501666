import { fetcher } from '@/services/api'
import useSWR from 'swr'

export function useBusiness(businessId: number) {
  const { data, error } = useSWR(`/agents/orgs/x-functions/businesses/${businessId}`, fetcher);

  return {
    data,
    isLoading: !error && !data,
    isError: !!error
  }
};
