import { Input, InputGroup } from "@/components/cataylst/input";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/cataylst/table";
import { DaveIcon, SarahIcon, SearchIcon } from "@/components/custom/icons";
import { MenuIcon } from "@/components/custom/menu-icon";
import MainSidebar from "@/components/custom/sidebar";
import { useParams, useSearchParams } from "react-router-dom";
import { formatDateTime } from "@/utils/format";
import { Badge } from "@/components/cataylst/badge";
import { ProgressBar } from "@/components/custom/progressbar";
import { Avatar } from "@/components/cataylst/avatar";
import DemoCompanyProfileAside from "./DemoCompanyProfileAside";
import { useBusinessEmployees } from "@/hooks/useBusinessEmployees";
import { useEffect, useState } from "react";
import { DEFAULT_PAGE_SIZE } from "@/utils/constants";
import { Pagination, PaginationList, PaginationNext, PaginationPage, PaginationPrevious } from "@/components/cataylst/pagination";

const mockCompanyNames = {
  zoom: 'Zoom'
};

const mockContacts = [
  {
    id: 0,
    name: 'Sarah Hughes',
    avatar: SarahIcon,
    title: 'CRO',
    buyerRole: 'primary contact',
    engagement: 82,
    lastActivity: new Date()
  },
  {
    id: 1,
    name: 'Andrew Bosworth',
    avatar: DaveIcon,
    title: 'CTO',
    buyerRole: 'technical buyer',
    engagement: 80,
    lastActivity: new Date()
  },
  {
    id: 2,
    name: 'Arlene McCoy',
    avatar: SarahIcon,
    title: 'VP of Sales',
    buyerRole: 'champion',
    engagement: 73,
    lastActivity: new Date()
  },
  {
    id: 3,
    name: 'David Wehner',
    avatar: SarahIcon,
    title: 'CFO',
    buyerRole: 'economic buyer',
    engagement: 40,
    lastActivity: new Date()
  },
  {
    id: 4,
    name: 'Steve McFadden',
    avatar: DaveIcon,
    title: 'Head of Procurement',
    buyerRole: '',
    engagement: 28,
    lastActivity: new Date()
  },
  {
    id: 5,
    name: 'Jennifer Newstead',
    avatar: SarahIcon,
    title: 'General Counsel',
    buyerRole: '',
    engagement: 9,
    lastActivity: new Date()
  },
];

export default function DemoCompanyDetailContactsPage() {
  const { companyId } = useParams();

  const [employees, setEmployees] = useState<any[]>([]);
  const [searchParams] = useSearchParams();
  const pageNum = Number(searchParams.get("page")) || 1;
  const { data: employeesData, isLoading } = useBusinessEmployees(Number(companyId), { cursor: (pageNum-1)*DEFAULT_PAGE_SIZE, limit: DEFAULT_PAGE_SIZE });

  useEffect(() => {
    const fetchEmployees = async () => {
      const newEmployees: any[] = [];
      for (const employee of (employeesData?.items || [])) {
        newEmployees.push({
          id: employee.individualId,
          name: `${employee.firstName} ${employee.lastName}`,
          avatar: SarahIcon,
          title: employee.position,
          buyerRole: '',
          engagement: 9,
          lastActivity: new Date()
        });
      }
      setEmployees(newEmployees);
    };
    fetchEmployees();
  }, [employeesData]);

  const totalRows = isLoading ? -1 : employeesData.totalRows;

  const renderPagination = () => {
    const totalPages = Math.ceil(totalRows / DEFAULT_PAGE_SIZE);
    const prevPage = pageNum > 1 ? pageNum - 1 : 1;
    const nextPage = pageNum < totalPages ? pageNum + 1 : totalPages;
  
    const getPageNumbers = () => {
      const pageNumbers: any[] = [];
      const showPagesCount = 5; // Number of pages to show
      
      if (totalPages <= showPagesCount) {
        // If total pages are less than showPagesCount, show all pages
        for (let i = 1; i <= totalPages; i++) {
          pageNumbers.push(i);
        }
      } else {
        // Always show first page
        pageNumbers.push(1);
        
        let startPage = Math.max(2, pageNum - 1);
        let endPage = Math.min(totalPages - 1, pageNum + 1);
        
        // Adjust startPage and endPage to always show showPagesCount-2 pages
        if (startPage <= 2) {
          endPage = showPagesCount - 1;
        } else if (endPage >= totalPages - 1) {
          startPage = totalPages - (showPagesCount - 2);
        }
        
        // Add ellipsis after first page
        if (startPage > 2) {
          pageNumbers.push('...');
        }
        
        // Add middle pages
        for (let i = startPage; i <= endPage; i++) {
          pageNumbers.push(i);
        }
        
        // Add ellipsis before last page
        if (endPage < totalPages - 1) {
          pageNumbers.push('...');
        }
        
        // Always show last page
        pageNumbers.push(totalPages);
      }
      
      return pageNumbers;
    };
  
    return (
      <Pagination className="mt-6">
        <PaginationPrevious
          href={`?page=${prevPage}`}
        />
        <PaginationList>
          {getPageNumbers().map((pageNumber, index) => (
            pageNumber === '...' ? (
              <span key={`ellipsis-${index}`} className="px-2">...</span>
            ) : (
              <PaginationPage
                key={pageNumber}
                href={`?page=${pageNumber}`}
                current={pageNumber === pageNum}
              >
                {pageNumber}
              </PaginationPage>
            )
          ))}
        </PaginationList>
        <PaginationNext href={`?page=${nextPage}`} />
      </Pagination>
    );
  };

  return (
    <main>
      <MainSidebar pageTitle={mockCompanyNames[companyId!]}>
        <div className="flex h-full gap-0.5 bg-zinc-100">
          <div className="text-cell px-8 py-5 flex-1 bg-white">
            <div className="flex mb-5 justify-between">
              <div className="flex gap-5 items-center">
                <div className="text-xl leading-5 font-p22 font-medium">Contacts</div>
              </div>
              <div className="w-[250px]">
                <InputGroup>
                  <MenuIcon src={SearchIcon} data-slot="icon" className="!size-4" />
                  <Input placeholder="Search contacts" className="h-9 pl-9 pr-2 !rounded-full" />
                </InputGroup>
              </div>
            </div>
            <Table grid dense={true} fixedHeader={true}>
              <TableHead>
                <TableRow>
                  <TableHeader>Contact</TableHeader>
                  <TableHeader>Title</TableHeader>
                  <TableHeader>Buyer role</TableHeader>
                  <TableHeader>Engagement</TableHeader>
                  <TableHeader>Last activity</TableHeader>
                </TableRow>
              </TableHead>
              <TableBody>
                {employees.map((contact) => (
                  <TableRow key={contact.id}>
                    <TableCell>
                      <div className="flex gap-2 items-center">
                        <Avatar square={false} src={contact.avatar} outline={false} className="size-4" />
                        <div>{contact.name}</div>
                      </div>
                    </TableCell>
                    <TableCell>
                      {contact.title}
                    </TableCell>
                    <TableCell>
                      {contact.buyerRole && (
                        <Badge color="customBlueLight">{contact.buyerRole}</Badge>
                      )}
                    </TableCell>
                    <TableCell>
                      <ProgressBar value={contact.engagement}>{contact.engagement}%</ProgressBar>
                    </TableCell>
                    <TableCell>
                      {formatDateTime(contact.lastActivity)}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            {renderPagination()}
          </div>
          <div className="min-w-[456px] bg-white">
            <DemoCompanyProfileAside />
          </div>
        </div>
      </MainSidebar>
    </main>
  )
}