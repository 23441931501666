import { useCallback, useContext } from 'react';
import { JSONSchema, JSONSchemaType, JSONSchemaTypeName } from '@makeropsinc/workflow-types';
import { context } from '../form-from-schema';
import { followRefs } from '../json-schemas';
import { parseFieldName } from '../utils';
import { Input, JSONChangeEvent } from './text-property-input';

interface Props {
  path: string;
  propName: string;
  property: JSONSchema;
  required?: boolean;
  value: JSONSchemaType;
  onChange: (propName: string, value: JSONSchemaType) => void;
  onDelete?: (propName: string) => void;
  noSelects: boolean;
}

export const TextProperty: React.FC<Props> = ({
  path,
  propName,
  property,
  value,
  required,
  onChange,
  onDelete,
  noSelects,
}) => {
  const ctx = useContext(context);
  const prop = followRefs(property, ctx.schema);

  const handleChange = useCallback(
    (e: JSONChangeEvent) => onChange(propName, e.target.value),
    [onChange, propName]
  );

  const handleDelete = useCallback(
    () => onDelete && onDelete(propName),
    [onDelete, propName]
  );

  return (
    <div>
      {onDelete && <button onClick={handleDelete} />}
      <Input
        path={path}
        type={prop.type as JSONSchemaTypeName}
        schema={prop}
        label={prop.title || parseFieldName(propName)}
        name={propName}
        value={value}
        onChange={handleChange}
        sx={{ pb: 1 }}
        required={required}
        noSelects={noSelects}
      />
    </div>
  );
};
