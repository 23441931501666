import React from 'react';
import SvgIcon, { SvgIconProps } from '../SvgIcon';

export const BaserowIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon
    {...props}
    style={{ ...props.style, fill: 'transparent' }}
    viewBox="0 0 16 16"
  >
    <g clip-path="url(#clip_baserow)">
      <path
        d="M0.85972 16H9.11739C9.59244 16 9.97754 15.6149 9.97754 15.1399V12.9042C9.97754 12.4292 9.59244 12.0441 9.11739 12.0441L0.85972 12.0441C0.384672 12.0441 -0.000430107 12.4292 -0.000430107 12.9042V15.1399C-0.000430107 15.6149 0.384672 16 0.85972 16Z"
        fill="#4D68C4"
      />
      <path
        d="M15.1398 9.97797L0.860159 9.97797C0.747201 9.97797 0.63535 9.95572 0.530991 9.91249C0.426631 9.86926 0.331808 9.8059 0.251935 9.72603C0.172062 9.64616 0.108702 9.55133 0.0654745 9.44698C0.0222473 9.34262 0 9.23076 0 9.11781V6.88219C0 6.76923 0.0222473 6.65738 0.0654745 6.55302C0.108702 6.44866 0.172062 6.35384 0.251935 6.27396C0.331808 6.19409 0.426631 6.13073 0.530991 6.0875C0.63535 6.04428 0.747201 6.02203 0.860159 6.02203L15.1399 6.02203C15.2528 6.02203 15.3647 6.04428 15.469 6.0875C15.5734 6.13073 15.6682 6.19409 15.7481 6.27396C15.8279 6.35383 15.8913 6.44866 15.9345 6.55301C15.9778 6.65737 16 6.76922 16 6.88218L16 9.11781C16 9.23076 15.9778 9.34262 15.9345 9.44697C15.8913 9.55133 15.8279 9.64616 15.7481 9.72603C15.6682 9.8059 15.5734 9.86926 15.469 9.91249C15.3646 9.95572 15.2528 9.97797 15.1398 9.97797Z"
        fill="#5190EF"
      />
      <path
        d="M6.88219 3.95594L15.1398 3.95594C15.6149 3.95594 16 3.57083 16 3.09578V0.860162C16 0.385109 15.6149 2.6226e-06 15.1398 2.6226e-06L6.88219 2.6226e-06C6.40714 2.6226e-06 6.02203 0.385109 6.02203 0.860162V3.09578C6.02203 3.57083 6.40714 3.95594 6.88219 3.95594Z"
        fill="#2BC3F1"
      />
      <path
        d="M12.9042 16H15.1398C15.6149 16 16 15.6149 16 15.1398V12.9042C16 12.4292 15.6149 12.0441 15.1398 12.0441H12.9042C12.4292 12.0441 12.0441 12.4292 12.0441 12.9042V15.1398C12.0441 15.6149 12.4292 16 12.9042 16Z"
        fill="#4D68C4"
      />
      <path
        d="M3.09578 0H0.86016C0.385107 0 0 0.385107 0 0.86016V3.09578C0 3.57083 0.385107 3.95594 0.86016 3.95594H3.09578C3.57083 3.95594 3.95594 3.57083 3.95594 3.09578V0.86016C3.95594 0.385107 3.57083 0 3.09578 0Z"
        fill="#2BC3F1"
      />
    </g>
    <defs>
      <clipPath id="clip_baserow">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </SvgIcon>
);
