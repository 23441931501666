import React from 'react';

import {
  GoogleCloudIcon,
  LinkedInIcon,
  OutreachIcon,
  SnowflakeIcon,
  RedshiftIcon,
  REAIcon,
  DatadogIcon,
  JiraIcon,
  SegmentIcon,
  GoogleAnalyticsIcon,
  GoogleAdsIcon,
  GoogleBigqueryIcon,
  GoogleDocumentAIIcon,
  PipedriveIcon,
  NotionIcon,
  HubspotIcon,
  GitlabIcon,
  GithubSourceIcon,
  SlackIcon,
  ClearbitIcon,
  GoogleSheetsIcon,
  GoogleDriveIcon,
  GoogleMailIcon,
  OpenAIIcon,
  HuggingfaceIcon,
  AzureSynapseIcon,
  AWSIcon,
  AWSPersonalizeIcon,
  ContentfulIcon,
  MailchimpIcon,
  SnovioIcon,
  XeroIcon,
  MediumIcon,
  EventbriteIcon,
  CalendlyIcon,
  IntercomIcon,
  TypeformIcon,
  ZoomIcon,
  PineconeIcon,
  TwitterIcon,
  ActionNetworkIcon,
  ActiveCampaignIcon,
  AdaloIcon,
  AffinityIcon,
  AgileCRMIcon,
  AirtableIcon,
  APITemplateIoIcon,
  AsanaIcon,
  AutomizyIcon,
  AutopilotIcon,
  BambooHrIcon,
  BannerbearIcon,
  BaserowIcon,
  BitbucketIcon,
  BitwardenIcon,
  BoxIcon,
  BrandfetchIcon,
  BrevoIcon,
  ChargebeeIcon,
  CircleCIIcon,
  ClickupIcon,
  ClockifyIcon,
  CloudflareIcon,
  ConvertKitIcon,
  CopperIcon,
  CortexIcon,
  CrateDbIcon,
  CustomerIoIcon,
  DeepLIcon,
  DHLIcon,
  DiscordIcon,
  DriftIcon,
  DropboxIcon,
  EGoiIcon,
  FlowIntegrationIcon,
  FreshworksCrmIcon,
  GhostIcon,
  GrafanaIcon,
  HarvestIcon,
  HighLevelIcon,
  HumanticAiIcon,
  IterableIcon,
  JenkinsIcon,
  KafkaIcon,
  LinearIcon,
  MQTTIcon,
  MagentoIcon,
  MailerLiteIcon,
  MattermostIcon,
  MauticIcon,
  MetabaseIcon,
  MindeeIcon,
  MondayComIcon,
  MongoDBIcon,
  MonicaCrmIcon,
  MySQLIcon,
  NetlifyIcon,
  NextCloudIcon,
  PaddleIcon,
  PagerDutyIcon,
  PaypalIcon,
  PhilipsHueIcon,
  PostHogIcon,
  PostgreSQLIcon,
  PushbulletIcon,
  PushoverIcon,
  QuickbooksIcon,
  RabbitMQIcon,
  RedditIcon,
  RedisIcon,
  S3Icon,
  SalesforceIcon,
  SalesmateIcon,
  SecurityScorecardIcon,
  SendGridIcon,
  SentryIoIcon,
  ServiceNowIcon,
  ShopifyIcon,
  SplunkIcon,
  StoryblokIcon,
  StrapiIcon,
  StripeIcon,
  SupabaseIcon,
  TaigaIcon,
  TelegramIcon,
  TodoistIcon,
  TravisCiIcon,
  TrelloIcon,
  TwillioIcon,
  TwistIcon,
  UrlScanIoIcon,
  VeroIcon,
  WhatsAppIcon,
  WiseIcon,
  WooCommerceIcon,
  WordpressIcon,
  ZendeskIcon,
  ZohoCrmIcon,
  ZulipIcon,
  YourAppIcon,
  EmeliaIcon,
  LlamaIcon,
  MistralIcon,
  AnthropicIcon,
  GeminiIcon,
  MSCopilotIcon
} from '@/assets/app-icons';
import { JSONSchema } from '@makeropsinc/workflow-types';

export enum SourceId {
  Snowflake = 'snowflake',
  GoogleAPIs = 'googleapis',
  GoogleAnalytics = 'googleapisAnalytics',
  GoogleAds = 'googleapisAdwords',
  GoogleBigquery = 'googleapisBigquery',
  GoogleDocumentAI = 'googleapisDocumentAI',
  GoogleDrive = 'googleapisDrive',
  GoogleMail = 'gmail',
  GoogleSheets = 'googlesheets',
  LinkedIn = 'linkedin',
  Outreach = 'outreach',
  OpenAI = 'openai',
  Datadog = 'datadog',
  Gitlab = 'gitlab',
  Clearbit = 'clearbit',
  Jira = 'jira',
  Segment = 'segment',
  Pipedrive = 'pipedrive',
  Notion = 'notion',
  Hubspot = 'hubspot',
  Slack = 'slack',
  Github = 'github',
  Redshift = 'redshift',
  REA = 'rea',
  AzureSynapse = 'azuresynapse',
  AWS = 'aws',
  AWSPersonalize = 'awspersonalize',
  Huggingface = 'huggingface',
  Contentful = 'contentful',
  Mailchimp = 'mailchimp',
  Snovio = 'snovio',
  Xero = 'xero',
  Eventbrite = 'eventbrite',
  Medium = 'medium',
  Calendly = 'calendly',
  Intercom = 'intercom',
  Typeform = 'typeform',
  Zoom = 'zoom',
  Pinecone = 'pinecone',
  ActionNetwork = 'actionNetwork',
  ActiveCampaign = 'activeCampaign',
  Adalo = 'adalo',
  Affinity = 'affinity',
  AgileCrm = 'agileCrm',
  Airtable = 'airtable',
  Amqp = 'amqp',
  ApiTemplateIo = 'apiTemplateIo',
  Asana = 'asana',
  Automizy = 'automizy',
  Autopilot = 'autopilot',
  BambooHr = 'bambooHr',
  Bannerbear = 'bannerbear',
  Baserow = 'baserow',
  Beeminder = 'beeminder',
  Bitbucket = 'bitbucket',
  Bitly = 'bitly',
  Bitwarden = 'bitwarden',
  Box = 'box',
  Brandfetch = 'brandfetch',
  Brevo = 'brevo',
  Bubble = 'bubble',
  Chargebee = 'chargebee',
  Circleci = 'circleci',
  Clickup = 'clickup',
  Clockify = 'clockify',
  Cloudflare = 'cloudflare',
  Cockpit = 'cockpit',
  Coda = 'coda',
  CustomCodeExecution = 'customCodeExecution',
  CoinGecko = 'coinGecko',
  CompareDatasets = 'compareDatasets',
  Compression = 'compression',
  ConvertKit = 'convertKit',
  Copper = 'copper',
  Cortex = 'cortex',
  CrateDb = 'crateDb',
  CrowdDev = 'crowdDev',
  Crypto = 'crypto',
  CustomerIo = 'customerIo',
  DateTime = 'dateTime',
  DeepL = 'deepL',
  Demio = 'demio',
  Dhl = 'dhl',
  Discord = 'discord',
  Discourse = 'discourse',
  Disqus = 'disqus',
  Drift = 'drift',
  Dropbox = 'dropbox',
  Dropcontact = 'dropcontact',
  Erpnext = 'erpnext',
  EditImage = 'editImage',
  Egoi = 'egoi',
  Imap = 'imap',
  Smtp = 'smtp',
  Emelia = 'emelia',
  FacebookGraphApi = 'facebookGraphApi',
  FileMaker = 'fileMaker',
  Flow = 'flow',
  Freshdesk = 'freshdesk',
  Freshservice = 'freshservice',
  FreshworksCrm = 'freshworksCrm',
  Ftp = 'ftp',
  Ghost = 'ghost',
  GoToWebinar = 'goToWebinar',
  Gotify = 'gotify',
  Grafana = 'grafana',
  Graphql = 'graphql',
  Grist = 'grist',
  HackerNews = 'hackerNews',
  HaloPSA = 'haloPSA',
  Harvest = 'harvest',
  HelpScout = 'helpScout',
  HighLevel = 'highLevel',
  HomeAssistant = 'homeAssistant',
  HumanticAi = 'humanticAi',
  Hunter = 'hunter',
  Icalendar = 'icalendar',
  InvoiceNinja = 'invoiceNinja',
  Iterable = 'iterable',
  Jenkins = 'jenkins',
  Kafka = 'kafka',
  Keap = 'keap',
  Kitemaker = 'kitemaker',
  KoBoToolbox = 'koBoToolbox',
  Ldap = 'ldap',
  Lemlist = 'lemlist',
  LineNotify = 'lineNotify',
  Linear = 'linear',
  LingvaNex = 'lingvaNex',
  Lonescale = 'lonescale',
  Mqtt = 'mqtt',
  Magento = 'magento',
  Mailcheck = 'mailcheck',
  MailerLite = 'mailerLite',
  Mailgun = 'mailgun',
  Mailjet = 'mailjet',
  Mandrill = 'mandrill',
  Marketstack = 'marketstack',
  Matrix = 'matrix',
  Mattermost = 'mattermost',
  Mautic = 'mautic',
  MessageBird = 'messageBird',
  Metabase = 'metabase',
  Mindee = 'mindee',
  Misp = 'misp',
  Mocean = 'mocean',
  MondayCom = 'mondayCom',
  Mongodb = 'mongodb',
  MonicaCrm = 'monicaCrm',
  MoveBinaryData = 'moveBinaryData',
  Msg91 = 'msg91',
  Mysql = 'mysql',
  Nasa = 'nasa',
  Netlify = 'netlify',
  NextCloud = 'nextCloud',
  Noop = 'noop',
  Nocodb = 'nocodb',
  NpmRegistry = 'npmRegistry',
  Odoo = 'odoo',
  OneSimpleApi = 'oneSimpleApi',
  Onfleet = 'onfleet',
  Openthesaurus = 'openthesaurus',
  Openweathermap = 'openweathermap',
  Orbit = 'orbit',
  Oura = 'oura',
  Paddle = 'paddle',
  PagerDuty = 'pagerDuty',
  Paypal = 'paypal',
  Peekalink = 'peekalink',
  Phantombuster = 'phantombuster',
  PhilipsHue = 'philipsHue',
  Plivo = 'plivo',
  PostBin = 'postBin',
  Posthog = 'posthog',
  Postgres = 'postgres',
  ProfitWell = 'profitWell',
  Pushbullet = 'pushbullet',
  Pushcut = 'pushcut',
  Pushover = 'pushover',
  QuestDb = 'questDb',
  Quickbase = 'quickbase',
  Quickbooks = 'quickbooks',
  Quickchart = 'quickchart',
  Rabbitmq = 'rabbitmq',
  Raindrop = 'raindrop',
  Reddit = 'reddit',
  Redis = 'redis',
  Rocketchat = 'rocketchat',
  RssFeed = 'rssFeed',
  Rundeck = 'rundeck',
  S3 = 's3',
  Salesforce = 'salesforce',
  Salesmate = 'salesmate',
  SeaTable = 'seaTable',
  SecurityScorecard = 'securityScorecard',
  Sendgrid = 'sendgrid',
  Sendy = 'sendy',
  SentryIo = 'sentryIo',
  ServiceNow = 'serviceNow',
  Shopify = 'shopify',
  Signl4 = 'signl4',
  Sms77 = 'sms77',
  SplitInBatches = 'splitInBatches',
  Splunk = 'splunk',
  Spontit = 'spontit',
  Spotify = 'spotify',
  Ssh = 'ssh',
  Stackby = 'stackby',
  Storyblok = 'storyblok',
  Strapi = 'strapi',
  Strava = 'strava',
  Stripe = 'stripe',
  Supabase = 'supabase',
  Syncromsp = 'syncromsp',
  Taiga = 'taiga',
  Tapfiliate = 'tapfiliate',
  Telegram = 'telegram',
  TheHive = 'theHive',
  TheHiveProject = 'theHiveProject',
  TimescaleDb = 'timescaleDb',
  Todoist = 'todoist',
  TravisCi = 'travisCi',
  Trello = 'trello',
  Twake = 'twake',
  Twilio = 'twilio',
  Twist = 'twist',
  Twitter = 'twitter',
  Uproc = 'uproc',
  UnleashedSoftware = 'unleashedSoftware',
  Uplead = 'uplead',
  Uptimerobot = 'uptimerobot',
  Urlscanio = 'urlscanio',
  Vero = 'vero',
  Vonage = 'vonage',
  Webflow = 'webflow',
  Wekan = 'wekan',
  WhatsAppBusinessCloud = 'whatsAppBusinessCloud',
  Wise = 'wise',
  WooCommerce = 'wooCommerce',
  Wordpress = 'wordpress',
  Yourls = 'yourls',
  Zammad = 'zammad',
  Zendesk = 'zendesk',
  ZohoCrm = 'zohoCrm',
  Zulip = 'zulip',
  Llama = 'llama',
  MSCopilot = 'microsoftCopilot',
  Mistral = 'mistral',
  Gemini = 'gemini',
  Anthropic = 'anthropic'
}

interface Props {
  id: SourceId;
}

export const SourceIcon: React.FC<Props> = ({ id, ...svgProps }) => {
  svgProps = {style: { width: '36px', height: '36px' }}
  switch (id) {
    case SourceId.Snowflake:
      return <SnowflakeIcon {...svgProps} />;

    case SourceId.GoogleAPIs:
      return <GoogleCloudIcon {...svgProps} />;

    case SourceId.GoogleAds:
      return <GoogleAdsIcon {...svgProps} />;

    case SourceId.GoogleAnalytics:
      return <GoogleAnalyticsIcon {...svgProps} />;

    case SourceId.GoogleBigquery:
      return <GoogleBigqueryIcon {...svgProps} />;

    case SourceId.GoogleDocumentAI:
      return <GoogleDocumentAIIcon {...svgProps} />;

    case SourceId.GoogleDrive:
      return <GoogleDriveIcon {...svgProps} />;

    case SourceId.GoogleSheets:
      return <GoogleSheetsIcon {...svgProps} />;

    case SourceId.GoogleMail:
      return <GoogleMailIcon {...svgProps} />;

    case SourceId.LinkedIn:
      return <LinkedInIcon {...svgProps} stroke="#fff" />;

    case SourceId.Outreach:
      return <OutreachIcon {...svgProps} />;

    case SourceId.Datadog:
      return <DatadogIcon {...svgProps} />;

    case SourceId.Jira:
      return <JiraIcon {...svgProps} />;

    case SourceId.Pipedrive:
      return <PipedriveIcon {...svgProps} />;

    case SourceId.Segment:
      return <SegmentIcon {...svgProps} />;

    case SourceId.Notion:
      return <NotionIcon {...svgProps} />;

    case SourceId.Hubspot:
      return <HubspotIcon {...svgProps} />;

    case SourceId.Gitlab:
      return <GitlabIcon {...svgProps} />;

    case SourceId.Github:
      return <GithubSourceIcon {...svgProps} />;

    case SourceId.Slack:
      return <SlackIcon {...svgProps} />;

    case SourceId.OpenAI:
      return <OpenAIIcon {...svgProps} />;

    case SourceId.Huggingface:
      return <HuggingfaceIcon {...svgProps} />;

    case SourceId.Clearbit:
      return <ClearbitIcon {...svgProps} />;

    case SourceId.Redshift:
      return <RedshiftIcon {...svgProps} />;

    case SourceId.REA:
      return <REAIcon {...svgProps} />;

    case SourceId.AzureSynapse:
      return <AzureSynapseIcon {...svgProps} />;

    case SourceId.AWS:
      return <AWSIcon {...svgProps} />;

    case SourceId.AWSPersonalize:
      return <AWSPersonalizeIcon {...svgProps} />;

    case SourceId.Contentful:
      return <ContentfulIcon {...svgProps} />;

    case SourceId.Mailchimp:
      return <MailchimpIcon {...svgProps} />;

    case SourceId.Snovio:
      return <SnovioIcon {...svgProps} />;

    case SourceId.Xero:
      return <XeroIcon {...svgProps} />;

    case SourceId.Medium:
      return <MediumIcon {...svgProps} />;

    case SourceId.Eventbrite:
      return <EventbriteIcon {...svgProps} />;

    case SourceId.Calendly:
      return <CalendlyIcon {...svgProps} />;

    case SourceId.Intercom:
      return <IntercomIcon {...svgProps} />;

    case SourceId.Typeform:
      return <TypeformIcon {...svgProps} />;

    case SourceId.Zoom:
      return <ZoomIcon {...svgProps} />;

    case SourceId.Pinecone:
      return <PineconeIcon {...svgProps} />;

    case SourceId.Twitter:
      return <TwitterIcon {...svgProps} />;

    case SourceId.ActionNetwork:
      return <ActionNetworkIcon {...svgProps} />;

    case SourceId.ActiveCampaign:
      return <ActiveCampaignIcon {...svgProps} />;

    case SourceId.Adalo:
      return <AdaloIcon {...svgProps} />;

    case SourceId.Affinity:
      return <AffinityIcon {...svgProps} />;

    case SourceId.AgileCrm:
      return <AgileCRMIcon {...svgProps} />;

    case SourceId.Airtable:
      return <AirtableIcon {...svgProps} />;

    case SourceId.ApiTemplateIo:
      return <APITemplateIoIcon {...svgProps} />;

    case SourceId.Asana:
      return <AsanaIcon {...svgProps} />;

    case SourceId.Automizy:
      return <AutomizyIcon {...svgProps} />;

    case SourceId.Autopilot:
      return <AutopilotIcon {...svgProps} />;

    case SourceId.BambooHr:
      return <BambooHrIcon {...svgProps} />;

    case SourceId.Bannerbear:
      return <BannerbearIcon {...svgProps} />;

    case SourceId.Baserow:
      return <BaserowIcon {...svgProps} />;

    case SourceId.Bitbucket:
      return <BitbucketIcon {...svgProps} />;

    case SourceId.Bitwarden:
      return <BitwardenIcon {...svgProps} />;

    case SourceId.Box:
      return <BoxIcon {...svgProps} />;

    case SourceId.Brandfetch:
      return <BrandfetchIcon {...svgProps} />;

    case SourceId.Brevo:
      return <BrevoIcon {...svgProps} />;

    case SourceId.Chargebee:
      return <ChargebeeIcon {...svgProps} />;

    case SourceId.Circleci:
      return <CircleCIIcon {...svgProps} />;

    case SourceId.Clickup:
      return <ClickupIcon {...svgProps} />;

    case SourceId.Clockify:
      return <ClockifyIcon {...svgProps} />;

    case SourceId.Cloudflare:
      return <CloudflareIcon {...svgProps} />;

    case SourceId.ConvertKit:
      return <ConvertKitIcon {...svgProps} />;

    case SourceId.Copper:
      return <CopperIcon {...svgProps} />;

    case SourceId.Cortex:
      return <CortexIcon {...svgProps} />;

    case SourceId.CrateDb:
      return <CrateDbIcon {...svgProps} />;

    case SourceId.CustomerIo:
      return <CustomerIoIcon {...svgProps} />;

    case SourceId.DeepL:
      return <DeepLIcon {...svgProps} />;

    case SourceId.Dhl:
      return <DHLIcon {...svgProps} />;

    case SourceId.Discord:
      return <DiscordIcon {...svgProps} />;

    case SourceId.Drift:
      return <DriftIcon {...svgProps} />;

    case SourceId.Dropbox:
      return <DropboxIcon {...svgProps} />;

    case SourceId.Egoi:
      return <EGoiIcon {...svgProps} />;

    case SourceId.Emelia:
      return <EmeliaIcon {...svgProps} />;

    case SourceId.Flow:
      return <FlowIntegrationIcon {...svgProps} />;

    case SourceId.FreshworksCrm:
      return <FreshworksCrmIcon {...svgProps} />;

    case SourceId.Ghost:
      return <GhostIcon {...svgProps} />;

    case SourceId.Grafana:
      return <GrafanaIcon {...svgProps} />;

    case SourceId.Harvest:
      return <HarvestIcon {...svgProps} />;

    case SourceId.HighLevel:
      return <HighLevelIcon {...svgProps} />;

    case SourceId.HumanticAi:
      return <HumanticAiIcon {...svgProps} />;

    case SourceId.Iterable:
      return <IterableIcon {...svgProps} />;

    case SourceId.Jenkins:
      return <JenkinsIcon {...svgProps} />;

    case SourceId.Kafka:
      return <KafkaIcon {...svgProps} />;

    case SourceId.Linear:
      return <LinearIcon {...svgProps} />;

    case SourceId.Mqtt:
      return <MQTTIcon {...svgProps} />;

    case SourceId.Magento:
      return <MagentoIcon {...svgProps} />;

    case SourceId.MailerLite:
      return <MailerLiteIcon {...svgProps} />;

    case SourceId.Mattermost:
      return <MattermostIcon {...svgProps} />;

    case SourceId.Mautic:
      return <MauticIcon {...svgProps} />;

    case SourceId.Metabase:
      return <MetabaseIcon {...svgProps} />;

    case SourceId.Mindee:
      return <MindeeIcon {...svgProps} />;

    case SourceId.MondayCom:
      return <MondayComIcon {...svgProps} />;

    case SourceId.Mongodb:
      return <MongoDBIcon {...svgProps} />;

    case SourceId.MonicaCrm:
      return <MonicaCrmIcon {...svgProps} />;

    case SourceId.Mysql:
      return <MySQLIcon {...svgProps} />;

    case SourceId.Netlify:
      return <NetlifyIcon {...svgProps} />;

    case SourceId.NextCloud:
      return <NextCloudIcon {...svgProps} />;

    case SourceId.Paddle:
      return <PaddleIcon {...svgProps} />;

    case SourceId.PagerDuty:
      return <PagerDutyIcon {...svgProps} />;

    case SourceId.Paypal:
      return <PaypalIcon {...svgProps} />;

    case SourceId.PhilipsHue:
      return <PhilipsHueIcon {...svgProps} />;

    case SourceId.Posthog:
      return <PostHogIcon {...svgProps} />;

    case SourceId.Postgres:
      return <PostgreSQLIcon {...svgProps} />;

    case SourceId.Pushbullet:
      return <PushbulletIcon {...svgProps} />;

    case SourceId.Pushover:
      return <PushoverIcon {...svgProps} />;

    case SourceId.Quickbooks:
      return <QuickbooksIcon {...svgProps} />;

    case SourceId.Rabbitmq:
      return <RabbitMQIcon {...svgProps} />;

    case SourceId.Reddit:
      return <RedditIcon {...svgProps} />;

    case SourceId.Redis:
      return <RedisIcon {...svgProps} />;

    case SourceId.S3:
      return <S3Icon {...svgProps} />;

    case SourceId.Salesforce:
      return <SalesforceIcon {...svgProps} />;

    case SourceId.Salesmate:
      return <SalesmateIcon {...svgProps} />;

    case SourceId.SecurityScorecard:
      return <SecurityScorecardIcon {...svgProps} />;

    case SourceId.Sendgrid:
      return <SendGridIcon {...svgProps} />;

    case SourceId.SentryIo:
      return <SentryIoIcon {...svgProps} />;

    case SourceId.ServiceNow:
      return <ServiceNowIcon {...svgProps} />;

    case SourceId.Shopify:
      return <ShopifyIcon {...svgProps} />;

    case SourceId.Splunk:
      return <SplunkIcon {...svgProps} />;

    case SourceId.Storyblok:
      return <StoryblokIcon {...svgProps} />;

    case SourceId.Strapi:
      return <StrapiIcon {...svgProps} />;

    case SourceId.Stripe:
      return <StripeIcon {...svgProps} />;

    case SourceId.Supabase:
      return <SupabaseIcon {...svgProps} />;

    case SourceId.Taiga:
      return <TaigaIcon {...svgProps} />;

    case SourceId.Telegram:
      return <TelegramIcon {...svgProps} />;

    case SourceId.Todoist:
      return <TodoistIcon {...svgProps} />;

    case SourceId.TravisCi:
      return <TravisCiIcon {...svgProps} />;

    case SourceId.Trello:
      return <TrelloIcon {...svgProps} />;

    case SourceId.Twilio:
      return <TwillioIcon {...svgProps} />;

    case SourceId.Twist:
      return <TwistIcon {...svgProps} />;

    case SourceId.Urlscanio:
      return <UrlScanIoIcon {...svgProps} />;

    case SourceId.Vero:
      return <VeroIcon {...svgProps} />;

    case SourceId.WhatsAppBusinessCloud:
      return <WhatsAppIcon {...svgProps} />;

    case SourceId.Wise:
      return <WiseIcon {...svgProps} />;

    case SourceId.WooCommerce:
      return <WooCommerceIcon {...svgProps} />;

    case SourceId.Wordpress:
      return <WordpressIcon {...svgProps} />;

    case SourceId.Zendesk:
      return <ZendeskIcon {...svgProps} />;

    case SourceId.ZohoCrm:
      return <ZohoCrmIcon {...svgProps} />;

    case SourceId.Zulip:
      return <ZulipIcon {...svgProps} />;
    
    case SourceId.Llama:
      return <LlamaIcon {...svgProps} />;

    case SourceId.MSCopilot:
      return <MSCopilotIcon {...svgProps} />;

    case SourceId.Gemini:
      return <GeminiIcon {...svgProps} />;

    case SourceId.Anthropic:
      return <AnthropicIcon {...svgProps} />;

    case SourceId.Mistral:
      return <MistralIcon {...svgProps} />;

    default:
      return <YourAppIcon {...svgProps} />;
  }
};

export enum IntegrationType {
  Credentials = 'credentials',
  OAuth2 = 'oauth2',
}

export enum SourceCategoryId {
  DataWarehouse = 'datawarehouse',
  CloudApps = 'cloudapps',
  AIML = 'aiml',
}

export interface SourceConfig {
  id: SourceId;
  title: string;
  subtitle: string;
  brandColor: string;
  category: SourceCategoryId;
  type: IntegrationType;
  credentialsSchema?: JSONSchema;
}

export interface SourceCategory {
  id: SourceCategoryId;
  title: string;
  description: string;
}

export const sourceConfigs: SourceConfig[] = [
  {
    id: SourceId.AzureSynapse,
    title: 'Azure Synapse',
    subtitle: 'Connect and manage your Azure Synapse databases',
    brandColor: '#ffffff',
    type: IntegrationType.Credentials,
    category: SourceCategoryId.DataWarehouse,
    credentialsSchema: {
      $schema: 'http://json-schema.org/draft-07/schema#',
      type: 'object',
      properties: {
        server: { type: 'string' },
        database: { type: 'string' },
        username: { type: 'string' },
        password: { type: 'string', input: { type: 'password' } },
      },
      required: ['server', 'database', 'username', 'password'],
    },
  },
  {
    id: SourceId.Snowflake,
    title: 'Snowflake',
    subtitle: 'Connect to and manage your Snowflake database',
    brandColor: '#29B5E8',
    type: IntegrationType.Credentials,
    category: SourceCategoryId.DataWarehouse,
    credentialsSchema: {
      $schema: 'http://json-schema.org/draft-07/schema#',
      type: 'object',
      properties: {
        account: { type: 'string' },
        username: { type: 'string' },
        password: { type: 'string', input: { type: 'password' } },
      },
      required: ['account', 'username', 'password'],
    },
  },
  {
    id: SourceId.Contentful,
    title: 'Contentful',
    subtitle: 'Send data to and manage your Contentful CMS',
    brandColor: '#ffffff',
    type: IntegrationType.Credentials,
    category: SourceCategoryId.CloudApps,
    credentialsSchema: {
      $schema: 'http://json-schema.org/draft-07/schema#',
      type: 'object',
      properties: {
        content_management_token: { type: 'string' },
        content_delivery_token: { type: 'string' },
      },
      required: [],
    },
  },
  {
    id: SourceId.Mailchimp,
    title: 'Mailchimp',
    subtitle: 'Manage your email campaigns with Mailchimp',
    brandColor: '#ffffff',
    type: IntegrationType.OAuth2,
    category: SourceCategoryId.CloudApps,
  },
  {
    id: SourceId.GoogleAPIs,
    title: 'Google APIs',
    subtitle: 'Connect with Google APIs (drive, gmail, etc)',
    brandColor: '#fff',
    type: IntegrationType.OAuth2,
    category: SourceCategoryId.CloudApps,
  },
  {
    id: SourceId.GoogleAds,
    title: 'Google Ads',
    subtitle: 'Connect with Google Ads',
    brandColor: '#fff',
    type: IntegrationType.OAuth2,
    category: SourceCategoryId.CloudApps,
  },
  {
    id: SourceId.GoogleAnalytics,
    title: 'Google Analytics',
    subtitle: 'Connect with Google Analytics',
    brandColor: '#fff',
    type: IntegrationType.OAuth2,
    category: SourceCategoryId.CloudApps,
  },
  {
    id: SourceId.GoogleBigquery,
    title: 'Google BigQuery',
    subtitle: 'Connect with Google Bigquery',
    brandColor: '#fff',
    type: IntegrationType.OAuth2,
    category: SourceCategoryId.DataWarehouse,
  },
  {
    id: SourceId.GoogleDrive,
    title: 'Google Drive',
    subtitle: 'Connect with Google Drive',
    brandColor: '#fff',
    type: IntegrationType.OAuth2,
    category: SourceCategoryId.CloudApps,
  },
  {
    id: SourceId.GoogleSheets,
    title: 'Google Sheets',
    subtitle: 'Connect with Google Sheets',
    brandColor: '#fff',
    type: IntegrationType.OAuth2,
    category: SourceCategoryId.CloudApps,
  },
  {
    id: SourceId.GoogleMail,
    title: 'Google Mail',
    subtitle: 'Connect with Google Mail',
    brandColor: '#fff',
    type: IntegrationType.OAuth2,
    category: SourceCategoryId.CloudApps,
  },
  {
    id: SourceId.LinkedIn,
    title: 'LinkedIn',
    subtitle: 'Collect LinkedIn ad information',
    brandColor: '#0A66C2',
    type: IntegrationType.OAuth2,
    category: SourceCategoryId.CloudApps,
  },
  {
    id: SourceId.Outreach,
    title: 'Outreach',
    subtitle: 'Integrate with Outreach to push/pull sales engagement data',
    brandColor: '#5952FF',
    type: IntegrationType.OAuth2,
    category: SourceCategoryId.CloudApps,
  },
  {
    id: SourceId.Datadog,
    title: 'Datadog',
    subtitle: 'Trigger workflows from Datadog events',
    brandColor: '#632BA6',
    type: IntegrationType.Credentials,
    category: SourceCategoryId.CloudApps,
    credentialsSchema: {
      $schema: 'http://json-schema.org/draft-07/schema#',
      type: 'object',
      properties: {
        apiKey: { type: 'string' },
        appKey: { type: 'string' },
      },
      required: ['apiKey', 'appKey'],
    },
  },
  {
    id: SourceId.Jira,
    title: 'Jira',
    subtitle: 'Integrate with Jira',
    brandColor: '#0052CC',
    type: IntegrationType.Credentials,
    category: SourceCategoryId.CloudApps,
    credentialsSchema: {
      $schema: 'http://json-schema.org/draft-07/schema#',
      type: 'object',
      properties: {
        username: { type: 'string' },
        password: { type: 'string', input: { type: 'password' } },
      },
      required: ['username', 'password'],
    },
  },
  {
    id: SourceId.Segment,
    title: 'Segment',
    subtitle: 'Integrate with Segment',
    brandColor: '#4FB58B',
    type: IntegrationType.Credentials,
    category: SourceCategoryId.CloudApps,
    credentialsSchema: {
      $schema: 'http://json-schema.org/draft-07/schema#',
      type: 'object',
      properties: {
        writekey: { type: 'string' },
      },
      required: ['writekey'],
    },
  },
  {
    id: SourceId.Pipedrive,
    title: 'Pipedrive',
    subtitle: 'Integrate with Pipedrive',
    brandColor: '#27292C',
    type: IntegrationType.Credentials,
    category: SourceCategoryId.CloudApps,
    credentialsSchema: {
      $schema: 'http://json-schema.org/draft-07/schema#',
      type: 'object',
      properties: {
        apiToken: { type: 'string' },
      },
      required: ['apiToken'],
    },
  },
  {
    id: SourceId.Gitlab,
    title: 'Gitlab',
    subtitle: 'Integrate with Gitlab',
    brandColor: '#554488',
    type: IntegrationType.Credentials,
    category: SourceCategoryId.CloudApps,
    credentialsSchema: {
      $schema: 'http://json-schema.org/draft-07/schema#',
      type: 'object',
      properties: {
        token: { type: 'string' },
      },
      required: ['token'],
    },
  },
  {
    id: SourceId.Slack,
    title: 'Slack',
    subtitle: 'Send messages to your Slack channels',
    brandColor: '#692070',
    type: IntegrationType.Credentials,
    category: SourceCategoryId.CloudApps,
    credentialsSchema: {
      $schema: 'http://json-schema.org/draft-07/schema#',
      type: 'object',
      properties: {
        webhookUrl: { type: 'string' },
      },
      required: ['webhookUrl'],
    },
  },
  {
    id: SourceId.Clearbit,
    title: 'Clearbit',
    subtitle: 'Enrich your leads with Clearbit',
    brandColor: '#499AFF',
    type: IntegrationType.Credentials,
    category: SourceCategoryId.CloudApps,
    credentialsSchema: {
      $schema: 'http://json-schema.org/draft-07/schema#',
      type: 'object',
      properties: {
        apiKey: { type: 'string' },
      },
      required: ['apiKey'],
    },
  },
  {
    id: SourceId.OpenAI,
    title: 'OpenAI',
    subtitle: 'Interact with the latest Language and Vision Models',
    brandColor: '#fff',
    type: IntegrationType.Credentials,
    category: SourceCategoryId.AIML,
    credentialsSchema: {
      $schema: 'http://json-schema.org/draft-07/schema#',
      type: 'object',
      properties: {
        apiKey: { type: 'string' },
        organisationId: { type: 'string' },
      },
      required: ['apiKey', 'organisationId'],
    },
  },
  {
    id: SourceId.Anthropic,
    title: 'Anthropic',
    subtitle: 'Interact with the latest Language and Vision Models',
    brandColor: '#fff',
    type: IntegrationType.Credentials,
    category: SourceCategoryId.AIML,
    credentialsSchema: {
      $schema: 'http://json-schema.org/draft-07/schema#',
      type: 'object',
      properties: {
        apiKey: { type: 'string' },
        organisationId: { type: 'string' },
      },
      required: ['apiKey', 'organisationId'],
    },
  },
  {
    id: SourceId.Mistral,
    title: 'Mistral',
    subtitle: 'Interact with the latest Language and Vision Models',
    brandColor: '#fff',
    type: IntegrationType.Credentials,
    category: SourceCategoryId.AIML,
    credentialsSchema: {
      $schema: 'http://json-schema.org/draft-07/schema#',
      type: 'object',
      properties: {
        apiKey: { type: 'string' },
        organisationId: { type: 'string' },
      },
      required: ['apiKey', 'organisationId'],
    },
  },
  {
    id: SourceId.MSCopilot,
    title: 'Microsoft Copilot',
    subtitle: 'Interact with the latest Language and Vision Models',
    brandColor: '#fff',
    type: IntegrationType.Credentials,
    category: SourceCategoryId.AIML,
    credentialsSchema: {
      $schema: 'http://json-schema.org/draft-07/schema#',
      type: 'object',
      properties: {
        apiKey: { type: 'string' },
        organisationId: { type: 'string' },
      },
      required: ['apiKey', 'organisationId'],
    },
  },
  {
    id: SourceId.Gemini,
    title: 'Gemini',
    subtitle: 'Interact with the latest Language and Vision Models',
    brandColor: '#fff',
    type: IntegrationType.Credentials,
    category: SourceCategoryId.AIML,
    credentialsSchema: {
      $schema: 'http://json-schema.org/draft-07/schema#',
      type: 'object',
      properties: {
        apiKey: { type: 'string' },
        organisationId: { type: 'string' },
      },
      required: ['apiKey', 'organisationId'],
    },
  },
  {
    id: SourceId.Llama,
    title: 'Llama',
    subtitle: 'Interact with the latest Language and Vision Models',
    brandColor: '#fff',
    type: IntegrationType.Credentials,
    category: SourceCategoryId.AIML,
    credentialsSchema: {
      $schema: 'http://json-schema.org/draft-07/schema#',
      type: 'object',
      properties: {
        apiKey: { type: 'string' },
        organisationId: { type: 'string' },
      },
      required: ['apiKey', 'organisationId'],
    },
  },
  {
    id: SourceId.Huggingface,
    title: 'Hugging Face',
    subtitle: 'Interact with AI / ML Models hosted on Hugging Face',
    brandColor: '#fff',
    type: IntegrationType.Credentials,
    category: SourceCategoryId.AIML,
    credentialsSchema: {
      $schema: 'http://json-schema.org/draft-07/schema#',
      type: 'object',
      properties: {
        apiToken: { type: 'string' },
      },
      required: ['apiToken'],
    },
  },
  {
    id: SourceId.Notion,
    title: 'Notion',
    subtitle: 'Integrate with Notion',
    brandColor: '#fff',
    type: IntegrationType.OAuth2,
    category: SourceCategoryId.CloudApps,
  },
  {
    id: SourceId.Hubspot,
    title: 'Hubspot',
    subtitle: 'Integrate with Hubspot',
    brandColor: '#FF5929',
    type: IntegrationType.OAuth2,
    category: SourceCategoryId.CloudApps,
  },
  {
    id: SourceId.Github,
    title: 'Github',
    subtitle: 'Integrate with Github',
    brandColor: '#000',
    type: IntegrationType.OAuth2,
    category: SourceCategoryId.CloudApps,
  },
  {
    id: SourceId.AWS,
    title: 'Amazon Web Services',
    subtitle: 'Interact with AWS resources',
    brandColor: '#FF9900',
    type: IntegrationType.Credentials,
    category: SourceCategoryId.CloudApps,
    credentialsSchema: {
      $schema: 'http://json-schema.org/draft-07/schema#',
      type: 'object',
      properties: {
        accessKeyId: { type: 'string' },
        secretAccessKey: { type: 'string' },
      },
      required: ['accessKeyId', 'secretAccessKey'],
    },
  },
  {
    id: SourceId.GoogleDocumentAI,
    title: 'Google Document AI',
    subtitle: 'Connect with Google Document AI',
    brandColor: '#fff',
    type: IntegrationType.OAuth2,
    category: SourceCategoryId.AIML,
  },
  {
    id: SourceId.AWSPersonalize,
    title: 'Amazon Personalize',
    subtitle: 'Build your own recommender system with Amazon Personalize',
    brandColor: '#2d8f7a',
    type: IntegrationType.Credentials,
    category: SourceCategoryId.AIML,
    credentialsSchema: {
      $schema: 'http://json-schema.org/draft-07/schema#',
      type: 'object',
      properties: {
        accessKeyId: { type: 'string' },
        secretAccessKey: { type: 'string' },
      },
      required: ['accessKeyId', 'secretAccessKey'],
    },
  },
  {
    id: SourceId.Pinecone,
    title: 'Pinecone',
    subtitle: 'Vector DB for building similarity search',
    brandColor: '#121142',
    type: IntegrationType.Credentials,
    category: SourceCategoryId.AIML,
    credentialsSchema: {
      $schema: 'http://json-schema.org/draft-07/schema#',
      type: 'object',
      properties: {
        apiKey: { type: 'string' },
        environment: { type: 'string' },
      },
      required: ['apiKey', 'environment'],
    },
  },
  {
    id: SourceId.Redshift,
    title: 'Redshift',
    subtitle: 'Connect to and manage your Redshift data warehouse',
    brandColor: '#d1e9ff',
    type: IntegrationType.Credentials,
    category: SourceCategoryId.DataWarehouse,
    credentialsSchema: {
      $schema: 'http://json-schema.org/draft-07/schema#',
      type: 'object',
      properties: {
        user: { type: 'string' },
        database: { type: 'string' },
        password: { type: 'string' },
        port: { type: 'string' },
        host: { type: 'string' },
      },
      required: ['user', 'database', 'password', 'port', 'host'],
    },
  },
  {
    id: SourceId.REA,
    title: 'REA Group',
    subtitle: 'Extract and organise your listing and leads data',
    brandColor: '#EC1A3B',
    type: IntegrationType.Credentials,
    category: SourceCategoryId.CloudApps,
    credentialsSchema: {
      $schema: 'http://json-schema.org/draft-07/schema#',
      type: 'object',
      properties: {
        clientId: { type: 'string' },
        clientSecret: { type: 'string' },
        agencyId: { type: 'string' },
      },
      required: ['clientId', 'clientSecret', 'agencyId'],
    },
  },
  {
    id: SourceId.Snovio,
    title: 'Snov.io',
    subtitle:
      'Scale your business and engage leads better with the Snov sales toolbox',
    brandColor: '#fff',
    type: IntegrationType.Credentials,
    category: SourceCategoryId.CloudApps,
    credentialsSchema: {
      $schema: 'http://json-schema.org/draft-07/schema#',
      type: 'object',
      properties: {
        clientId: { type: 'string' },
        clientSecret: { type: 'string' },
      },
      required: ['clientId', 'clientSecret'],
    },
  },
  {
    id: SourceId.Xero,
    title: 'Xero',
    subtitle: 'Connect to your Xero accounting app',
    brandColor: '#1AB4D7',
    type: IntegrationType.OAuth2,
    category: SourceCategoryId.CloudApps,
  },
  {
    id: SourceId.Medium,
    title: 'Medium',
    subtitle: 'Publish your blogs and get your reader insights',
    brandColor: '#000000',
    type: IntegrationType.OAuth2,
    category: SourceCategoryId.CloudApps,
  },
  {
    id: SourceId.Eventbrite,
    title: 'Eventbrite',
    subtitle: 'Manage your events and attendees with Eventbrite',
    brandColor: '#F05537',
    type: IntegrationType.OAuth2,
    category: SourceCategoryId.CloudApps,
  },
  {
    id: SourceId.Calendly,
    title: 'Calendly',
    subtitle: 'Manage your calender invites with Calendly',
    brandColor: '#016BFF',
    type: IntegrationType.OAuth2,
    category: SourceCategoryId.CloudApps,
  },
  {
    id: SourceId.Intercom,
    title: 'Intercom',
    subtitle: 'Automate your customer service solutions with Intercom',
    brandColor: '#208DEC',
    type: IntegrationType.OAuth2,
    category: SourceCategoryId.CloudApps,
  },
  {
    id: SourceId.Zoom,
    title: 'Zoom',
    subtitle: 'Manage your videocalls and attendees with Zoom',
    brandColor: '#fff',
    type: IntegrationType.Credentials,
    category: SourceCategoryId.CloudApps,
    credentialsSchema: {
      $schema: 'http://json-schema.org/draft-07/schema#',
      type: 'object',
      properties: {
        apiKey: { type: 'string' },
        apiSecret: { type: 'string' },
        chatHistoryToken: { type: 'string' },
      },
      required: ['apiKey', 'apiSecret', 'chatHistoryToken'],
    },
  },
  {
    id: SourceId.Typeform,
    title: 'Typeform',
    subtitle: 'Create and manage your online forms with Typeform',
    brandColor: '#262627',
    type: IntegrationType.OAuth2,
    category: SourceCategoryId.CloudApps,
  },
  {
    id: SourceId.ActionNetwork,
    title: 'Action Network',
    subtitle: 'Engage and mobilize with Action Network tools',
    brandColor: '#000000',
    category: SourceCategoryId.CloudApps,
    type: IntegrationType.Credentials,
    credentialsSchema: {
      $schema: 'http://json-schema.org/draft-07/schema#',
      type: 'object',
      properties: {
        apiKey: {
          type: 'string',
          input: {
            type: 'password',
          },
        },
      },
      required: ['apiKey'],
    },
  },
  {
    id: SourceId.ActiveCampaign,
    title: 'ActiveCampaign',
    subtitle: 'Automate your customer experience',
    brandColor: '#004CFF',
    category: SourceCategoryId.CloudApps,
    type: IntegrationType.Credentials,
    credentialsSchema: {
      $schema: 'http://json-schema.org/draft-07/schema#',
      type: 'object',
      properties: {
        apiUrl: {
          type: 'string',
        },
        apiKey: {
          type: 'string',
          input: {
            type: 'password',
          },
        },
      },
      required: ['apiUrl', 'apiKey'],
    },
  },
  {
    id: SourceId.Adalo,
    title: 'Adalo',
    subtitle: 'Consume Adalo API to manage your app collections',
    brandColor: '#000000',
    category: SourceCategoryId.CloudApps,
    type: IntegrationType.Credentials,
    credentialsSchema: {
      $schema: 'http://json-schema.org/draft-07/schema#',
      type: 'object',
      properties: {
        apiKey: {
          type: 'string',
          input: {
            type: 'password',
          },
        },
        appId: {
          type: 'string',
        },
      },
      required: ['apiKey', 'appId'],
    },
  },
  {
    id: SourceId.Affinity,
    title: 'Affinity',
    subtitle: 'Integrate with Affinity to manage your CRM data',
    brandColor: '#3440E8',
    category: SourceCategoryId.CloudApps,
    type: IntegrationType.Credentials,
    credentialsSchema: {
      $schema: 'http://json-schema.org/draft-07/schema#',
      type: 'object',
      properties: {
        apiKey: {
          type: 'string',
          input: {
            type: 'password',
          },
        },
      },
      required: ['apiKey'],
    },
  },
  {
    id: SourceId.AgileCrm,
    title: 'Agile CRM',
    subtitle: 'Manage your Agile CRM contacts, companies, and deals',
    brandColor: '#409DE4',
    category: SourceCategoryId.CloudApps,
    type: IntegrationType.Credentials,
    credentialsSchema: {
      $schema: 'http://json-schema.org/draft-07/schema#',
      type: 'object',
      properties: {
        email: {
          type: 'string',
        },
        apiKey: {
          type: 'string',
          input: {
            type: 'password',
          },
        },
        subdomain: {
          type: 'string',
        },
      },
      required: ['email', 'apiKey', 'subdomain'],
    },
  },
  {
    id: SourceId.Airtable,
    title: 'Airtable',
    subtitle: 'Read, update, write and delete data from Airtable',
    brandColor: '#000000',
    category: SourceCategoryId.CloudApps,
    type: IntegrationType.Credentials,
    credentialsSchema: {
      $schema: 'http://json-schema.org/draft-07/schema#',
      type: 'object',
      properties: {
        apiKey: {
          type: 'string',
          input: {
            type: 'password',
          },
        },
        accessToken: {
          type: 'string',
          input: {
            type: 'password',
          },
        },
      },
      required: ['apiKey', 'accessToken'],
    },
  },
  {
    id: SourceId.Amqp,
    title: 'AMQP',
    subtitle: 'Connect and interact with AMQP message brokers',
    brandColor: '#E3EBF2',
    category: SourceCategoryId.CloudApps,
    type: IntegrationType.Credentials,
    credentialsSchema: {
      $schema: 'http://json-schema.org/draft-07/schema#',
      type: 'object',
      properties: {
        hostname: {
          type: 'string',
        },
        port: {
          type: 'number',
        },
        username: {
          type: 'string',
        },
        password: {
          type: 'string',
          input: {
            type: 'password',
          },
        },
        transportType: {
          type: 'string',
        },
      },
      required: ['hostname', 'port'],
    },
  },
  {
    id: SourceId.ApiTemplateIo,
    title: 'APITemplate.io',
    subtitle: 'Generate images and PDFs with APITemplate.io',
    brandColor: '#FFD051',
    category: SourceCategoryId.CloudApps,
    type: IntegrationType.Credentials,
    credentialsSchema: {
      $schema: 'http://json-schema.org/draft-07/schema#',
      type: 'object',
      properties: {
        apiKey: {
          type: 'string',
          input: {
            type: 'password',
          },
        },
      },
      required: ['apiKey'],
    },
  },
  {
    id: SourceId.Asana,
    title: 'Asana',
    subtitle: 'Manage tasks and projects with Asana',
    brandColor: '#F06A6A',
    category: SourceCategoryId.CloudApps,
    type: IntegrationType.OAuth2,
  },
  {
    id: SourceId.Automizy,
    title: 'Automizy',
    subtitle: 'Automate your email marketing with Automizy',
    brandColor: '#F48120',
    category: SourceCategoryId.CloudApps,
    type: IntegrationType.Credentials,
    credentialsSchema: {
      $schema: 'http://json-schema.org/draft-07/schema#',
      type: 'object',
      properties: {
        apiToken: {
          type: 'string',
        },
      },
      required: ['apiToken'],
    },
  },
  {
    id: SourceId.Autopilot,
    title: 'Autopilot',
    subtitle: 'Automate your marketing and stay engaged with your clients',
    brandColor: '#1BDBB8',
    category: SourceCategoryId.CloudApps,
    type: IntegrationType.Credentials,
    credentialsSchema: {
      $schema: 'http://json-schema.org/draft-07/schema#',
      type: 'object',
      properties: {
        apiKey: {
          type: 'string',
          input: {
            type: 'password',
          },
        },
      },
      required: ['apiKey'],
    },
  },
  {
    id: SourceId.BambooHr,
    title: 'BambooHR',
    subtitle: 'Human resources management system',
    brandColor: '#73C41D',
    category: SourceCategoryId.CloudApps,
    type: IntegrationType.Credentials,
    credentialsSchema: {
      $schema: 'http://json-schema.org/draft-07/schema#',
      type: 'object',
      properties: {
        subdomain: {
          type: 'string',
        },
        apiKey: {
          type: 'string',
          input: {
            type: 'password',
          },
        },
      },
      required: ['subdomain', 'apiKey'],
    },
  },
  {
    id: SourceId.Bannerbear,
    title: 'Bannerbear',
    subtitle: 'Automate image generation with Bannerbear',
    brandColor: '#FFD700',
    category: SourceCategoryId.CloudApps,
    type: IntegrationType.Credentials,
    credentialsSchema: {
      $schema: 'http://json-schema.org/draft-07/schema#',
      type: 'object',
      properties: {
        apiKey: {
          type: 'string',
          input: {
            type: 'password',
          },
        },
      },
      required: ['apiKey'],
    },
  },
  {
    id: SourceId.Baserow,
    title: 'Baserow',
    subtitle: 'Connect and manage your Baserow databases',
    brandColor: '#000000',
    category: SourceCategoryId.DataWarehouse,
    type: IntegrationType.Credentials,
    credentialsSchema: {
      $schema: 'http://json-schema.org/draft-07/schema#',
      type: 'object',
      properties: {
        host: {
          type: 'string',
        },
        username: {
          type: 'string',
        },
        password: {
          type: 'string',
          input: {
            type: 'password',
          },
        },
      },
      required: ['host', 'username', 'password'],
    },
  },
  {
    id: SourceId.Beeminder,
    title: 'Beeminder',
    subtitle: 'Track your goals and data with Beeminder',
    brandColor: '#FFCE00',
    category: SourceCategoryId.CloudApps,
    type: IntegrationType.Credentials,
    credentialsSchema: {
      $schema: 'http://json-schema.org/draft-07/schema#',
      type: 'object',
      properties: {
        user: {
          type: 'string',
        },
        authToken: {
          type: 'string',
          input: {
            type: 'password',
          },
        },
      },
      required: ['user', 'authToken'],
    },
  },
  {
    id: SourceId.Bitbucket,
    title: 'Bitbucket',
    subtitle:
      'Integrate with Bitbucket to manage repositories, workspaces, and more',
    brandColor: '#0052CC',
    category: SourceCategoryId.CloudApps,
    type: IntegrationType.Credentials,
    credentialsSchema: {
      $schema: 'http://json-schema.org/draft-07/schema#',
      type: 'object',
      properties: {
        username: {
          type: 'string',
        },
        appPassword: {
          type: 'string',
          input: {
            type: 'password',
          },
        },
      },
      required: ['username', 'appPassword'],
    },
  },
  {
    id: SourceId.Bitly,
    title: 'Bitly',
    subtitle: 'Shorten, create, and manage Bitly links',
    brandColor: '#EE6123',
    category: SourceCategoryId.CloudApps,
    type: IntegrationType.OAuth2,
  },
  {
    id: SourceId.Bitwarden,
    title: 'Bitwarden',
    subtitle: 'Securely manage your passwords with Bitwarden',
    brandColor: '#175DDC',
    category: SourceCategoryId.CloudApps,
    type: IntegrationType.Credentials,
    credentialsSchema: {
      $schema: 'http://json-schema.org/draft-07/schema#',
      type: 'object',
      properties: {
        clientId: {
          type: 'string',
        },
        clientSecret: {
          type: 'string',
          input: {
            type: 'password',
          },
        },
        environment: {
          type: 'string',
          enum: ['cloudHosted', 'selfHosted'],
        },
        domain: {
          type: 'string',
        },
      },
      required: ['clientId', 'clientSecret', 'environment'],
      dependencies: {
        environment: {
          oneOf: [
            {
              properties: {
                environment: {
                  const: 'cloudHosted',
                },
              },
            },
            {
              properties: {
                environment: {
                  const: 'selfHosted',
                },
                domain: {
                  type: 'string',
                },
              },
              required: ['domain'],
            },
          ],
        },
      },
    },
  },
  {
    id: SourceId.Box,
    title: 'Box',
    subtitle: 'Manage your Box files and folders',
    brandColor: '#0075C9',
    category: SourceCategoryId.CloudApps,
    type: IntegrationType.OAuth2,
  },
  {
    id: SourceId.Brandfetch,
    title: 'Brandfetch',
    subtitle: 'Access brand data with Brandfetch',
    brandColor: '#000000',
    category: SourceCategoryId.CloudApps,
    type: IntegrationType.Credentials,
    credentialsSchema: {
      $schema: 'http://json-schema.org/draft-07/schema#',
      type: 'object',
      properties: {
        apiKey: {
          type: 'string',
          input: {
            type: 'password',
          },
        },
      },
      required: ['apiKey'],
    },
  },
  {
    id: SourceId.Brevo,
    title: 'Brevo',
    subtitle: 'Connect to Brevo for email marketing automation',
    brandColor: '#0B996E',
    category: SourceCategoryId.CloudApps,
    type: IntegrationType.Credentials,
    credentialsSchema: {
      $schema: 'http://json-schema.org/draft-07/schema#',
      type: 'object',
      properties: {
        apiKey: {
          type: 'string',
          input: {
            type: 'password',
          },
        },
      },
      required: ['apiKey'],
    },
  },
  {
    id: SourceId.Bubble,
    title: 'Bubble',
    subtitle: 'Consume the Bubble Data API',
    brandColor: '#FF6B6B',
    category: SourceCategoryId.CloudApps,
    type: IntegrationType.Credentials,
    credentialsSchema: {
      $schema: 'http://json-schema.org/draft-07/schema#',
      type: 'object',
      properties: {
        apiToken: {
          type: 'string',
          input: {
            type: 'password',
          },
        },
        appName: {
          type: 'string',
        },
        environment: {
          type: 'string',
          enum: ['live', 'development'],
        },
        hosting: {
          type: 'string',
          enum: ['bubbleHosted', 'selfHosted'],
        },
        domain: {
          type: 'string',
        },
      },
      required: ['apiToken', 'appName', 'environment', 'hosting'],
    },
  },
  {
    id: SourceId.Chargebee,
    title: 'Chargebee',
    subtitle:
      'Automate billing, invoicing, and subscription management with Chargebee',
    brandColor: '#FF3300',
    category: SourceCategoryId.CloudApps,
    type: IntegrationType.Credentials,
    credentialsSchema: {
      $schema: 'http://json-schema.org/draft-07/schema#',
      type: 'object',
      properties: {
        accountName: {
          type: 'string',
        },
        apiKey: {
          type: 'string',
          input: {
            type: 'password',
          },
        },
      },
      required: ['accountName', 'apiKey'],
    },
  },
  {
    id: SourceId.Circleci,
    title: 'CircleCI',
    subtitle: 'Automate your development process with CircleCI',
    brandColor: '#000000',
    category: SourceCategoryId.CloudApps,
    type: IntegrationType.Credentials,
    credentialsSchema: {
      $schema: 'http://json-schema.org/draft-07/schema#',
      type: 'object',
      properties: {
        apiKey: {
          type: 'string',
          input: {
            type: 'password',
          },
        },
      },
      required: ['apiKey'],
    },
  },
  {
    id: SourceId.Clickup,
    title: 'ClickUp',
    subtitle: 'Task and project management in ClickUp',
    brandColor: '#000000',
    category: SourceCategoryId.CloudApps,
    type: IntegrationType.Credentials,
    credentialsSchema: {
      $schema: 'http://json-schema.org/draft-07/schema#',
      type: 'object',
      properties: {
        accessToken: {
          type: 'string',
          input: {
            type: 'password',
          },
        },
      },
      required: ['accessToken'],
    },
  },
  {
    id: SourceId.Clockify,
    title: 'Clockify',
    subtitle: 'Track time and manage projects with Clockify',
    brandColor: '#03A9F4',
    category: SourceCategoryId.CloudApps,
    type: IntegrationType.Credentials,
    credentialsSchema: {
      $schema: 'http://json-schema.org/draft-07/schema#',
      type: 'object',
      properties: {
        apiKey: {
          type: 'string',
          input: {
            type: 'password',
          },
        },
      },
      required: ['apiKey'],
    },
  },
  {
    id: SourceId.Cloudflare,
    title: 'Cloudflare',
    subtitle: 'Manage Cloudflare settings',
    brandColor: '#F48120',
    category: SourceCategoryId.CloudApps,
    type: IntegrationType.Credentials,
    credentialsSchema: {
      $schema: 'http://json-schema.org/draft-07/schema#',
      type: 'object',
      properties: {
        apiToken: {
          type: 'string',
          input: {
            type: 'password',
          },
        },
      },
      required: ['apiToken'],
    },
  },
  {
    id: SourceId.Cockpit,
    title: 'Cockpit',
    subtitle: 'Manage content in Cockpit CMS',
    brandColor: '#398B93',
    category: SourceCategoryId.CloudApps,
    type: IntegrationType.Credentials,
    credentialsSchema: {
      $schema: 'http://json-schema.org/draft-07/schema#',
      type: 'object',
      properties: {
        url: {
          type: 'string',
        },
        accessToken: {
          type: 'string',
          input: {
            type: 'password',
          },
        },
      },
      required: ['url', 'accessToken'],
    },
  },
  {
    id: SourceId.Coda,
    title: 'Coda',
    subtitle: 'Connect to your Coda docs',
    brandColor: '#4AB5F0',
    category: SourceCategoryId.CloudApps,
    type: IntegrationType.Credentials,
    credentialsSchema: {
      $schema: 'http://json-schema.org/draft-07/schema#',
      type: 'object',
      properties: {
        accessToken: {
          type: 'string',
          input: {
            type: 'password',
          },
        },
      },
      required: ['accessToken'],
    },
  },
  {
    id: SourceId.CoinGecko,
    title: 'CoinGecko',
    subtitle: 'Access cryptocurrency data from CoinGecko',
    brandColor: '#1e1e1e',
    category: SourceCategoryId.CloudApps,
    type: IntegrationType.Credentials,
    credentialsSchema: {
      $schema: 'http://json-schema.org/draft-07/schema#',
      type: 'object',
      properties: {
        apiKey: {
          type: 'string',
          input: {
            type: 'password',
          },
        },
      },
      required: ['apiKey'],
    },
  },
  {
    id: SourceId.ConvertKit,
    title: 'ConvertKit',
    subtitle: 'Automate your email marketing with ConvertKit',
    brandColor: '#FB6970',
    category: SourceCategoryId.CloudApps,
    type: IntegrationType.Credentials,
    credentialsSchema: {
      $schema: 'http://json-schema.org/draft-07/schema#',
      type: 'object',
      properties: {
        apiSecret: {
          type: 'string',
          input: {
            type: 'password',
          },
        },
      },
      required: ['apiSecret'],
    },
  },
  {
    id: SourceId.Copper,
    title: 'Copper',
    subtitle: 'Connect and manage your Copper CRM',
    brandColor: '#FF3465',
    category: SourceCategoryId.CloudApps,
    type: IntegrationType.Credentials,
    credentialsSchema: {
      $schema: 'http://json-schema.org/draft-07/schema#',
      type: 'object',
      properties: {
        apiKey: {
          type: 'string',
          input: {
            type: 'password',
          },
        },
        email: {
          type: 'string',
        },
      },
      required: ['apiKey', 'email'],
    },
  },
  {
    id: SourceId.Cortex,
    title: 'Cortex',
    subtitle: 'Apply Cortex analyzers and responders on entities',
    brandColor: '#141532',
    category: SourceCategoryId.AIML,
    type: IntegrationType.Credentials,
    credentialsSchema: {
      $schema: 'http://json-schema.org/draft-07/schema#',
      type: 'object',
      properties: {
        cortexApiKey: {
          type: 'string',
          input: {
            type: 'password',
          },
        },
        host: {
          type: 'string',
        },
      },
      required: ['cortexApiKey', 'host'],
    },
  },
  {
    id: SourceId.CrateDb,
    title: 'CrateDB',
    subtitle: 'Interact with CrateDB databases',
    brandColor: '#19C0EA',
    category: SourceCategoryId.DataWarehouse,
    type: IntegrationType.Credentials,
    credentialsSchema: {
      $schema: 'http://json-schema.org/draft-07/schema#',
      type: 'object',
      properties: {
        host: {
          type: 'string',
        },
        database: {
          type: 'string',
        },
        user: {
          type: 'string',
        },
        password: {
          type: 'string',
          input: {
            type: 'password',
          },
        },
        ssl: {
          type: 'string',
          enum: ['allow', 'disable', 'require', 'verify', 'verify-full'],
        },
        port: {
          type: 'number',
        },
      },
      required: ['host', 'database', 'user', 'password', 'ssl', 'port'],
    },
  },
  {
    id: SourceId.CrowdDev,
    title: 'crowd.dev',
    subtitle:
      'Integrate with crowd.dev to manage community data and activities',
    brandColor: '#4B8F8C',
    category: SourceCategoryId.CloudApps,
    type: IntegrationType.Credentials,
    credentialsSchema: {
      $schema: 'http://json-schema.org/draft-07/schema#',
      type: 'object',
      properties: {
        url: {
          type: 'string',
        },
        tenantId: {
          type: 'string',
        },
        token: {
          type: 'string',
          input: {
            type: 'password',
          },
        },
        allowUnauthorizedCerts: {
          type: 'boolean',
        },
      },
      required: ['url', 'tenantId', 'token'],
    },
  },
  {
    id: SourceId.CustomerIo,
    title: 'Customer.io',
    subtitle: 'Automate your email, push, SMS, and more with Customer.io',
    brandColor: '#2F326A',
    category: SourceCategoryId.CloudApps,
    type: IntegrationType.Credentials,
    credentialsSchema: {
      $schema: 'http://json-schema.org/draft-07/schema#',
      type: 'object',
      properties: {
        trackingApiKey: {
          type: 'string',
          input: {
            type: 'password',
          },
        },
        region: {
          type: 'string',
          enum: ['track-eu.customer.io', 'track.customer.io'],
          default: 'track.customer.io',
        },
        trackingSiteId: {
          type: 'string',
        },
        appApiKey: {
          type: 'string',
          input: {
            type: 'password',
          },
        },
      },
      required: ['trackingApiKey', 'region', 'trackingSiteId', 'appApiKey'],
    },
  },
  {
    id: SourceId.DeepL,
    title: 'DeepL',
    subtitle: 'Translate data using DeepL',
    brandColor: '#042B48',
    category: SourceCategoryId.AIML,
    type: IntegrationType.Credentials,
    credentialsSchema: {
      $schema: 'http://json-schema.org/draft-07/schema#',
      type: 'object',
      properties: {
        apiKey: {
          type: 'string',
          input: {
            type: 'password',
          },
        },
        apiPlan: {
          type: 'string',
          enum: ['pro', 'free'],
          default: 'pro',
        },
      },
      required: ['apiKey', 'apiPlan'],
    },
  },
  {
    id: SourceId.Demio,
    title: 'Demio',
    subtitle: 'Webinar platform for marketing',
    brandColor: '#FF6D00',
    category: SourceCategoryId.CloudApps,
    type: IntegrationType.Credentials,
    credentialsSchema: {
      $schema: 'http://json-schema.org/draft-07/schema#',
      type: 'object',
      properties: {
        apiKey: {
          type: 'string',
          input: {
            type: 'password',
          },
        },
        apiSecret: {
          type: 'string',
          input: {
            type: 'password',
          },
        },
      },
      required: ['apiKey', 'apiSecret'],
    },
  },
  {
    id: SourceId.Dhl,
    title: 'DHL',
    subtitle: 'Track shipments and manage logistics with DHL',
    brandColor: '#FFCC00',
    category: SourceCategoryId.CloudApps,
    type: IntegrationType.Credentials,
    credentialsSchema: {
      $schema: 'http://json-schema.org/draft-07/schema#',
      type: 'object',
      properties: {
        apiKey: {
          type: 'string',
          input: {
            type: 'password',
          },
        },
      },
      required: ['apiKey'],
    },
  },
  {
    id: SourceId.Discord,
    title: 'Discord',
    subtitle: "Interact with Discord's API to manage servers and messages",
    brandColor: '#5865F2',
    category: SourceCategoryId.CloudApps,
    type: IntegrationType.Credentials,
    credentialsSchema: {
      $schema: 'http://json-schema.org/draft-07/schema#',
      type: 'object',
      properties: {
        botToken: {
          type: 'string',
          input: {
            type: 'password',
          },
        },
      },
      required: ['botToken'],
    },
  },
  {
    id: SourceId.Discourse,
    title: 'Discourse',
    subtitle: 'Interact with Discourse forums',
    brandColor: '#000000',
    category: SourceCategoryId.CloudApps,
    type: IntegrationType.Credentials,
    credentialsSchema: {
      $schema: 'http://json-schema.org/draft-07/schema#',
      type: 'object',
      properties: {
        url: {
          type: 'string',
        },
        apiKey: {
          type: 'string',
          input: {
            type: 'password',
          },
        },
        username: {
          type: 'string',
        },
      },
      required: ['url', 'apiKey', 'username'],
    },
  },
  {
    id: SourceId.Disqus,
    title: 'Disqus',
    subtitle: 'Integrate with Disqus comment hosting service',
    brandColor: '#2E9FFF',
    category: SourceCategoryId.CloudApps,
    type: IntegrationType.Credentials,
    credentialsSchema: {
      $schema: 'http://json-schema.org/draft-07/schema#',
      type: 'object',
      properties: {
        accessToken: {
          type: 'string',
          input: {
            type: 'password',
          },
        },
      },
      required: ['accessToken'],
    },
  },
  {
    id: SourceId.Drift,
    title: 'Drift',
    subtitle: 'Converse and connect with your customers using Drift',
    brandColor: '#000000',
    category: SourceCategoryId.CloudApps,
    type: IntegrationType.OAuth2,
  },
  {
    id: SourceId.Dropbox,
    title: 'Dropbox',
    subtitle: 'Access and manage your Dropbox files and folders',
    brandColor: '#0061FF',
    category: SourceCategoryId.CloudApps,
    type: IntegrationType.OAuth2,
  },
  {
    id: SourceId.Dropcontact,
    title: 'Dropcontact',
    subtitle: 'Find B2B emails and enrich contacts',
    brandColor: '#ff3366',
    category: SourceCategoryId.CloudApps,
    type: IntegrationType.Credentials,
    credentialsSchema: {
      $schema: 'http://json-schema.org/draft-07/schema#',
      type: 'object',
      properties: {
        apiKey: {
          type: 'string',
          input: {
            type: 'password',
          },
        },
      },
      required: ['apiKey'],
    },
  },
  {
    id: SourceId.Erpnext,
    title: 'ERPNext',
    subtitle: 'Connect to your ERPNext instance',
    brandColor: '#F2C94C',
    category: SourceCategoryId.CloudApps,
    type: IntegrationType.Credentials,
    credentialsSchema: {
      $schema: 'http://json-schema.org/draft-07/schema#',
      type: 'object',
      properties: {
        apiKey: {
          type: 'string',
        },
        apiSecret: {
          type: 'string',
        },
        environment: {
          type: 'string',
          enum: ['cloudHosted', 'selfHosted'],
        },
        subdomain: {
          type: 'string',
        },
        domain: {
          type: 'string',
        },
        allowUnauthorizedCerts: {
          type: 'boolean',
        },
      },
      required: ['apiKey', 'apiSecret', 'environment'],
    },
  },
  {
    id: SourceId.Egoi,
    title: 'E-goi',
    subtitle: 'Automate your marketing with E-goi',
    brandColor: '#364656',
    category: SourceCategoryId.CloudApps,
    type: IntegrationType.Credentials,
    credentialsSchema: {
      $schema: 'http://json-schema.org/draft-07/schema#',
      type: 'object',
      properties: {
        apiKey: {
          type: 'string',
          input: {
            type: 'password',
          },
        },
      },
      required: ['apiKey'],
    },
  },
  {
    id: SourceId.Imap,
    title: 'IMAP Email',
    subtitle: 'Connect to your IMAP server to read emails',
    brandColor: '#d93025',
    category: SourceCategoryId.CloudApps,
    type: IntegrationType.Credentials,
    credentialsSchema: {
      $schema: 'http://json-schema.org/draft-07/schema#',
      type: 'object',
      properties: {
        host: {
          type: 'string',
        },
        port: {
          type: 'number',
        },
        user: {
          type: 'string',
        },
        password: {
          type: 'string',
          input: {
            type: 'password',
          },
        },
        ssl: {
          type: 'boolean',
        },
        ignoreTLS: {
          type: 'boolean',
        },
        mailbox: {
          type: 'string',
        },
      },
      required: ['host', 'port', 'user', 'password'],
    },
  },
  {
    id: SourceId.Smtp,
    title: 'SMTP',
    subtitle: 'Send emails using the SMTP protocol',
    brandColor: '#D44638',
    category: SourceCategoryId.CloudApps,
    type: IntegrationType.Credentials,
    credentialsSchema: {
      $schema: 'http://json-schema.org/draft-07/schema#',
      type: 'object',
      properties: {
        host: {
          type: 'string',
        },
        port: {
          type: 'integer',
        },
        secure: {
          type: 'boolean',
        },
        user: {
          type: 'string',
        },
        password: {
          type: 'string',
          input: {
            type: 'password',
          },
        },
      },
      required: ['host', 'port', 'user', 'password'],
    },
  },
  {
    id: SourceId.Emelia,
    title: 'Emelia',
    subtitle: 'Automate your email outreach with Emelia',
    brandColor: '#FFEED5',
    category: SourceCategoryId.CloudApps,
    type: IntegrationType.Credentials,
    credentialsSchema: {
      $schema: 'http://json-schema.org/draft-07/schema#',
      type: 'object',
      properties: {
        apiKey: {
          type: 'string',
          input: {
            type: 'password',
          },
        },
      },
      required: ['apiKey'],
    },
  },
  {
    id: SourceId.FacebookGraphApi,
    title: 'Facebook Graph API',
    subtitle: 'Interact with Facebook data through the Graph API',
    brandColor: '#0D88F0',
    category: SourceCategoryId.CloudApps,
    type: IntegrationType.Credentials,
    credentialsSchema: {
      $schema: 'http://json-schema.org/draft-07/schema#',
      type: 'object',
      properties: {
        accessToken: {
          type: 'string',
          input: {
            type: 'password',
          },
        },
      },
      required: ['accessToken'],
    },
  },
  {
    id: SourceId.FileMaker,
    title: 'FileMaker',
    subtitle: 'Connect and manage your FileMaker databases',
    brandColor: '#D9534F',
    category: SourceCategoryId.DataWarehouse,
    type: IntegrationType.Credentials,
    credentialsSchema: {
      $schema: 'http://json-schema.org/draft-07/schema#',
      type: 'object',
      properties: {
        host: {
          type: 'string',
        },
        db: {
          type: 'string',
        },
        login: {
          type: 'string',
        },
        password: {
          type: 'string',
          input: {
            type: 'password',
          },
        },
      },
      required: ['host', 'db', 'login', 'password'],
    },
  },
  {
    id: SourceId.Flow,
    title: 'Flow',
    subtitle: 'Manage your tasks and workflows with Flow',
    brandColor: '#001540',
    category: SourceCategoryId.CloudApps,
    type: IntegrationType.Credentials,
    credentialsSchema: {
      $schema: 'http://json-schema.org/draft-07/schema#',
      type: 'object',
      properties: {
        organizationId: {
          type: 'number',
        },
        accessToken: {
          type: 'string',
          input: {
            type: 'password',
          },
        },
      },
      required: ['organizationId', 'accessToken'],
    },
  },
  {
    id: SourceId.Freshdesk,
    title: 'Freshdesk',
    subtitle: 'Customer support software by Freshworks',
    brandColor: '#333333',
    category: SourceCategoryId.CloudApps,
    type: IntegrationType.Credentials,
    credentialsSchema: {
      $schema: 'http://json-schema.org/draft-07/schema#',
      type: 'object',
      properties: {
        apiKey: {
          type: 'string',
          input: {
            type: 'password',
          },
        },
        domain: {
          type: 'string',
        },
      },
      required: ['apiKey', 'domain'],
    },
  },
  {
    id: SourceId.Freshservice,
    title: 'Freshservice',
    subtitle: 'Integrate with Freshservice to manage support and ITSM',
    brandColor: '#78D5E3',
    category: SourceCategoryId.CloudApps,
    type: IntegrationType.Credentials,
    credentialsSchema: {
      $schema: 'http://json-schema.org/draft-07/schema#',
      type: 'object',
      properties: {
        apiKey: {
          type: 'string',
          input: {
            type: 'password',
          },
        },
        domain: {
          type: 'string',
        },
      },
      required: ['apiKey', 'domain'],
    },
  },
  {
    id: SourceId.FreshworksCrm,
    title: 'Freshworks CRM',
    subtitle:
      'Integrate with Freshworks CRM to manage your sales and customer data',
    brandColor: '#000000',
    category: SourceCategoryId.CloudApps,
    type: IntegrationType.Credentials,
    credentialsSchema: {
      $schema: 'http://json-schema.org/draft-07/schema#',
      type: 'object',
      properties: {
        apiKey: {
          type: 'string',
          input: {
            type: 'password',
          },
        },
        domain: {
          type: 'string',
        },
      },
      required: ['apiKey', 'domain'],
    },
  },
  {
    id: SourceId.Ftp,
    title: 'FTP/SFTP',
    subtitle: 'Transfer files using FTP or SFTP',
    brandColor: '#0082c9',
    category: SourceCategoryId.CloudApps,
    type: IntegrationType.Credentials,
    credentialsSchema: {
      $schema: 'http://json-schema.org/draft-07/schema#',
      type: 'object',
      properties: {
        host: {
          type: 'string',
        },
        port: {
          type: 'number',
        },
        username: {
          type: 'string',
        },
        password: {
          type: 'string',
          input: {
            type: 'password',
          },
        },
      },
      required: ['host', 'port', 'username', 'password'],
    },
  },
  {
    id: SourceId.Ghost,
    title: 'Ghost',
    subtitle: 'Consume Ghost API',
    brandColor: '#000000',
    category: SourceCategoryId.CloudApps,
    type: IntegrationType.Credentials,
    credentialsSchema: {
      $schema: 'http://json-schema.org/draft-07/schema#',
      type: 'object',
      properties: {
        url: {
          type: 'string',
        },
        apiKey: {
          type: 'string',
          input: {
            type: 'password',
          },
        },
      },
      required: ['url', 'apiKey'],
    },
  },
  {
    id: SourceId.GoToWebinar,
    title: 'GoToWebinar',
    subtitle: 'Automate your webinar workflows with GoToWebinar',
    brandColor: '#7C2DFB',
    category: SourceCategoryId.CloudApps,
    type: IntegrationType.OAuth2,
  },
  {
    id: SourceId.Gotify,
    title: 'Gotify',
    subtitle: 'Send and manage messages with Gotify',
    brandColor: '#00aa00',
    category: SourceCategoryId.CloudApps,
    type: IntegrationType.Credentials,
    credentialsSchema: {
      $schema: 'http://json-schema.org/draft-07/schema#',
      type: 'object',
      properties: {
        appApiToken: {
          type: 'string',
          description: '(Optional) Needed for message creation',
        },
        clientApiToken: {
          type: 'string',
          description:
            '(Optional) Needed for everything (delete, getAll) but message creation',
        },
        url: {
          type: 'string',
          description: 'The URL of the Gotify host',
        },
        ignoreSSLIssues: {
          type: 'boolean',
          default: false,
          description:
            'Whether to connect even if SSL certificate validation is not possible',
        },
      },
      required: ['url'],
    },
  },
  {
    id: SourceId.Grafana,
    title: 'Grafana',
    subtitle: 'Visualize and manage your Grafana dashboards',
    brandColor: '#F48120',
    category: SourceCategoryId.CloudApps,
    type: IntegrationType.Credentials,
    credentialsSchema: {
      $schema: 'http://json-schema.org/draft-07/schema#',
      type: 'object',
      properties: {
        apiKey: {
          type: 'string',
          input: {
            type: 'password',
          },
        },
        baseUrl: {
          type: 'string',
        },
      },
      required: ['apiKey', 'baseUrl'],
    },
  },
  {
    id: SourceId.Graphql,
    title: 'GraphQL API',
    subtitle: 'Execute GraphQL queries and mutations',
    brandColor: '#E535AB',
    category: SourceCategoryId.CloudApps,
    type: IntegrationType.Credentials,
    credentialsSchema: {
      $schema: 'http://json-schema.org/draft-07/schema#',
      type: 'object',
      properties: {
        endpoint: {
          type: 'string',
          title: 'Endpoint URL',
        },
        authentication: {
          type: 'string',
          enum: [
            'none',
            'basicAuth',
            'customAuth',
            'digestAuth',
            'headerAuth',
            'queryAuth',
            'oAuth1',
            'oAuth2',
          ],
          title: 'Authentication Type',
        },
        basicAuth: {
          type: 'object',
          title: 'Basic Auth Credentials',
          properties: {
            user: {
              type: 'string',
              title: 'Username',
            },
            password: {
              type: 'string',
              title: 'Password',
              input: {
                type: 'password',
              },
            },
          },
          required: ['user', 'password'],
        },
        customAuth: {
          type: 'object',
          title: 'Custom Auth Details',
          properties: {
            headers: {
              type: 'object',
              title: 'Headers',
              additionalProperties: {
                type: 'string',
              },
            },
            body: {
              type: 'object',
              title: 'Body',
              additionalProperties: {
                type: 'string',
              },
            },
            qs: {
              type: 'object',
              title: 'Query String',
              additionalProperties: {
                type: 'string',
              },
            },
          },
        },
        digestAuth: {
          type: 'object',
          title: 'Digest Auth Credentials',
          properties: {
            user: {
              type: 'string',
              title: 'Username',
            },
            password: {
              type: 'string',
              title: 'Password',
              input: {
                type: 'password',
              },
            },
          },
          required: ['user', 'password'],
        },
        headerAuth: {
          type: 'object',
          title: 'Header Auth Details',
          properties: {
            name: {
              type: 'string',
              title: 'Header Name',
            },
            value: {
              type: 'string',
              title: 'Header Value',
            },
          },
          required: ['name', 'value'],
        },
        queryAuth: {
          type: 'object',
          title: 'Query Auth Details',
          properties: {
            name: {
              type: 'string',
              title: 'Query Parameter Name',
            },
            value: {
              type: 'string',
              title: 'Query Parameter Value',
            },
          },
          required: ['name', 'value'],
        },
        oAuth1: {
          type: 'object',
          title: 'OAuth1 Details',
          properties: {
            consumerKey: {
              type: 'string',
              title: 'Consumer Key',
            },
            consumerSecret: {
              type: 'string',
              title: 'Consumer Secret',
              input: {
                type: 'password',
              },
            },
            token: {
              type: 'string',
              title: 'Token',
            },
            tokenSecret: {
              type: 'string',
              title: 'Token Secret',
              input: {
                type: 'password',
              },
            },
          },
          required: ['consumerKey', 'consumerSecret', 'token', 'tokenSecret'],
        },
        oAuth2: {
          type: 'object',
          title: 'OAuth2 Details',
          properties: {
            accessToken: {
              type: 'string',
              title: 'Access Token',
            },
          },
          required: ['accessToken'],
        },
      },
      required: ['endpoint', 'authentication'],
    },
  },
  {
    id: SourceId.Grist,
    title: 'Grist',
    subtitle: 'Connect and manage your Grist documents',
    brandColor: '#5A67D8',
    category: SourceCategoryId.CloudApps,
    type: IntegrationType.Credentials,
    credentialsSchema: {
      $schema: 'http://json-schema.org/draft-07/schema#',
      type: 'object',
      properties: {
        apiKey: {
          type: 'string',
          input: {
            type: 'password',
          },
        },
        planType: {
          type: 'string',
          enum: ['free', 'paid', 'selfHosted'],
        },
        customSubdomain: {
          type: 'string',
        },
        selfHostedUrl: {
          type: 'string',
        },
      },
      required: ['apiKey', 'planType'],
      dependencies: {
        planType: {
          oneOf: [
            {
              properties: {
                planType: {
                  enum: ['paid'],
                },
              },
              required: ['customSubdomain'],
            },
            {
              properties: {
                planType: {
                  enum: ['selfHosted'],
                },
              },
              required: ['selfHostedUrl'],
            },
          ],
        },
      },
    },
  },
  {
    id: SourceId.HackerNews,
    title: 'Hacker News',
    subtitle: 'Access and manage Hacker News data',
    brandColor: '#ff6600',
    category: SourceCategoryId.CloudApps,
    type: IntegrationType.Credentials,
    credentialsSchema: {
      $schema: 'http://json-schema.org/draft-07/schema#',
      type: 'object',
      properties: {
        apiKey: {
          type: 'string',
        },
      },
      required: ['apiKey'],
    },
  },
  {
    id: SourceId.HaloPSA,
    title: 'HaloPSA',
    subtitle: "Integrate with HaloPSA's ITSM platform",
    brandColor: '#0073AA',
    category: SourceCategoryId.CloudApps,
    type: IntegrationType.Credentials,
    credentialsSchema: {
      $schema: 'http://json-schema.org/draft-07/schema#',
      type: 'object',
      properties: {
        hostingType: {
          type: 'string',
          enum: ['onPremise', 'hostedHalo'],
          default: 'onPremise',
        },
        authUrl: {
          type: 'string',
        },
        resourceApiUrl: {
          type: 'string',
          description:
            "The Resource server is available at your 'Halo Web Application URL/api'",
        },
        client_id: {
          type: 'string',
          description: 'Must be your application client ID',
        },
        client_secret: {
          type: 'string',
          input: {
            type: 'password',
          },
          description: 'Must be your application client secret',
        },
        tenant: {
          type: 'string',
          description:
            'An additional tenant parameter for HaloPSA hosted solution',
        },
        scope: {
          type: 'string',
          default: 'admin edit:tickets edit:customers',
        },
      },
      required: [
        'authUrl',
        'resourceApiUrl',
        'client_id',
        'client_secret',
        'scope',
      ],
      additionalProperties: false,
    },
  },
  {
    id: SourceId.Harvest,
    title: 'Harvest',
    subtitle: 'Track time, manage invoices, and report expenses',
    brandColor: '#FA5D00',
    category: SourceCategoryId.CloudApps,
    type: IntegrationType.Credentials,
    credentialsSchema: {
      $schema: 'http://json-schema.org/draft-07/schema#',
      type: 'object',
      properties: {
        accessToken: {
          type: 'string',
          input: {
            type: 'password',
          },
        },
      },
      required: ['accessToken'],
    },
  },
  {
    id: SourceId.HelpScout,
    title: 'HelpScout',
    subtitle: 'Customer communication and support with HelpScout',
    brandColor: '#3197D6',
    category: SourceCategoryId.CloudApps,
    type: IntegrationType.OAuth2,
  },
  {
    id: SourceId.HighLevel,
    title: 'HighLevel',
    subtitle: 'Connect to HighLevel CRM',
    brandColor: '#000000',
    category: SourceCategoryId.CloudApps,
    type: IntegrationType.Credentials,
    credentialsSchema: {
      $schema: 'http://json-schema.org/draft-07/schema#',
      type: 'object',
      properties: {
        apiKey: {
          type: 'string',
          input: {
            type: 'password',
          },
        },
      },
      required: ['apiKey'],
    },
  },
  {
    id: SourceId.HomeAssistant,
    title: 'Home Assistant',
    subtitle:
      'Integrate with Home Assistant to control and monitor your smart home devices',
    brandColor: '#41BDF5',
    category: SourceCategoryId.CloudApps,
    type: IntegrationType.Credentials,
    credentialsSchema: {
      $schema: 'http://json-schema.org/draft-07/schema#',
      type: 'object',
      properties: {
        host: {
          type: 'string',
        },
        port: {
          type: 'number',
        },
        ssl: {
          type: 'boolean',
        },
        accessToken: {
          type: 'string',
          input: {
            type: 'password',
          },
        },
      },
      required: ['host', 'port', 'ssl', 'accessToken'],
    },
  },
  {
    id: SourceId.HumanticAi,
    title: 'Humantic AI',
    subtitle: 'Consume Humantic AI API for personality insights',
    brandColor: '#FFEEC7',
    category: SourceCategoryId.AIML,
    type: IntegrationType.Credentials,
    credentialsSchema: {
      $schema: 'http://json-schema.org/draft-07/schema#',
      type: 'object',
      properties: {
        apiKey: {
          type: 'string',
        },
      },
      required: ['apiKey'],
    },
  },
  {
    id: SourceId.Hunter,
    title: 'Hunter',
    subtitle: 'Find and verify professional email addresses',
    brandColor: '#3C4E5A',
    category: SourceCategoryId.CloudApps,
    type: IntegrationType.Credentials,
    credentialsSchema: {
      $schema: 'http://json-schema.org/draft-07/schema#',
      type: 'object',
      properties: {
        apiKey: {
          type: 'string',
          input: {
            type: 'password',
          },
        },
      },
      required: ['apiKey'],
    },
  },
  {
    id: SourceId.InvoiceNinja,
    title: 'Invoice Ninja',
    subtitle: 'Connect and manage your invoices with Invoice Ninja',
    brandColor: '#78C5D6',
    category: SourceCategoryId.CloudApps,
    type: IntegrationType.Credentials,
    credentialsSchema: {
      $schema: 'http://json-schema.org/draft-07/schema#',
      type: 'object',
      properties: {
        url: {
          type: 'string',
          title: 'URL',
          default: '',
          description:
            'The base URL for the Invoice Ninja instance. Default URL for v4 is https://app.invoiceninja.com, for v5 it is https://invoicing.co',
        },
        apiToken: {
          type: 'string',
          title: 'API Token',
          input: {
            type: 'password',
          },
          default: '',
        },
        secret: {
          type: 'string',
          title: 'Secret',
          input: {
            type: 'password',
          },
          default: '',
          description:
            'This is optional, enter only if you did set a secret in your app and only if you are using v5',
        },
      },
      required: ['url', 'apiToken'],
    },
  },
  {
    id: SourceId.Iterable,
    title: 'Iterable',
    subtitle: 'Automate your marketing with Iterable',
    brandColor: '#FFFFFF',
    category: SourceCategoryId.CloudApps,
    type: IntegrationType.Credentials,
    credentialsSchema: {
      $schema: 'http://json-schema.org/draft-07/schema#',
      type: 'object',
      properties: {
        apiKey: {
          type: 'string',
          input: {
            type: 'password',
          },
        },
      },
      required: ['apiKey'],
    },
  },
  {
    id: SourceId.Jenkins,
    title: 'Jenkins',
    subtitle: 'Automate and manage your Jenkins CI/CD pipelines',
    brandColor: '#D33833',
    category: SourceCategoryId.CloudApps,
    type: IntegrationType.Credentials,
    credentialsSchema: {
      $schema: 'http://json-schema.org/draft-07/schema#',
      type: 'object',
      properties: {
        username: {
          type: 'string',
        },
        apiKey: {
          type: 'string',
          input: {
            type: 'password',
          },
        },
        baseUrl: {
          type: 'string',
        },
      },
      required: ['username', 'apiKey', 'baseUrl'],
    },
  },
  {
    id: SourceId.Kafka,
    title: 'Kafka',
    subtitle: 'Connect and stream data with Apache Kafka',
    brandColor: '#000000',
    category: SourceCategoryId.DataWarehouse,
    type: IntegrationType.Credentials,
    credentialsSchema: {
      $schema: 'http://json-schema.org/draft-07/schema#',
      type: 'object',
      properties: {
        clientId: {
          type: 'string',
        },
        brokers: {
          type: 'string',
        },
        ssl: {
          type: 'boolean',
        },
        authentication: {
          type: 'boolean',
        },
        username: {
          type: 'string',
          input: {
            type: 'text',
          },
        },
        password: {
          type: 'string',
          input: {
            type: 'password',
          },
        },
        saslMechanism: {
          type: 'string',
          enum: ['plain', 'scram-sha-256', 'scram-sha-512'],
          default: 'plain',
        },
      },
      required: ['clientId', 'brokers'],
    },
  },
  {
    id: SourceId.Keap,
    title: 'Keap',
    subtitle: 'Automate your client lifecycle with Keap',
    brandColor: '#a37714',
    category: SourceCategoryId.CloudApps,
    type: IntegrationType.OAuth2,
  },
  {
    id: SourceId.Kitemaker,
    title: 'Kitemaker',
    subtitle:
      'Integrate with Kitemaker to manage work items and collaborate on projects',
    brandColor: '#0078D7',
    category: SourceCategoryId.CloudApps,
    type: IntegrationType.Credentials,
    credentialsSchema: {
      $schema: 'http://json-schema.org/draft-07/schema#',
      type: 'object',
      properties: {
        personalAccessToken: {
          type: 'string',
          input: {
            type: 'password',
          },
        },
      },
      required: ['personalAccessToken'],
    },
  },
  {
    id: SourceId.KoBoToolbox,
    title: 'KoBoToolbox',
    subtitle: 'Collect and manage data in humanitarian crises',
    brandColor: '#ff9f00',
    category: SourceCategoryId.CloudApps,
    type: IntegrationType.Credentials,
    credentialsSchema: {
      $schema: 'http://json-schema.org/draft-07/schema#',
      type: 'object',
      properties: {
        URL: {
          type: 'string',
        },
        token: {
          type: 'string',
          input: {
            type: 'password',
          },
        },
      },
      required: ['URL', 'token'],
    },
  },
  {
    id: SourceId.Ldap,
    title: 'LDAP',
    subtitle: 'Connect and interact with LDAP servers',
    brandColor: '#003366',
    category: SourceCategoryId.CloudApps,
    type: IntegrationType.Credentials,
    credentialsSchema: {
      $schema: 'http://json-schema.org/draft-07/schema#',
      type: 'object',
      properties: {
        hostname: {
          type: 'string',
        },
        port: {
          type: 'string',
        },
        bindDN: {
          type: 'string',
        },
        bindPassword: {
          type: 'string',
          input: {
            type: 'password',
          },
        },
        connectionSecurity: {
          type: 'string',
          enum: ['none', 'tls', 'startTls'],
        },
        allowUnauthorizedCerts: {
          type: 'boolean',
        },
        caCertificate: {
          type: 'string',
        },
        timeout: {
          type: 'number',
        },
      },
      required: [
        'hostname',
        'port',
        'bindDN',
        'bindPassword',
        'connectionSecurity',
      ],
    },
  },
  {
    id: SourceId.Lemlist,
    title: 'Lemlist',
    subtitle: 'Automate your outreach with Lemlist',
    brandColor: '#ff6a00',
    category: SourceCategoryId.CloudApps,
    type: IntegrationType.Credentials,
    credentialsSchema: {
      $schema: 'http://json-schema.org/draft-07/schema#',
      type: 'object',
      properties: {
        apiKey: {
          type: 'string',
          input: {
            type: 'password',
          },
        },
      },
      required: ['apiKey'],
    },
  },
  {
    id: SourceId.LineNotify,
    title: 'Line Notify',
    subtitle: 'Send notifications via Line',
    brandColor: '#00B900',
    category: SourceCategoryId.CloudApps,
    type: IntegrationType.OAuth2,
  },
  {
    id: SourceId.Linear,
    title: 'Linear',
    subtitle: 'Integrate with Linear to manage issues',
    brandColor: '#5E6AD2',
    category: SourceCategoryId.CloudApps,
    type: IntegrationType.Credentials,
    credentialsSchema: {
      $schema: 'http://json-schema.org/draft-07/schema#',
      type: 'object',
      properties: {
        apiKey: {
          type: 'string',
          input: {
            type: 'password',
          },
        },
      },
      required: ['apiKey'],
    },
  },
  {
    id: SourceId.LingvaNex,
    title: 'LingvaNex',
    subtitle: 'Translate text using LingvaNex API',
    brandColor: '#FF0000',
    category: SourceCategoryId.AIML,
    type: IntegrationType.Credentials,
    credentialsSchema: {
      $schema: 'http://json-schema.org/draft-07/schema#',
      type: 'object',
      properties: {
        apiKey: {
          type: 'string',
          input: {
            type: 'password',
          },
        },
      },
      required: ['apiKey'],
    },
  },
  {
    id: SourceId.Lonescale,
    title: 'LoneScale',
    subtitle: "Interact with LoneScale's list and item management",
    brandColor: '#00B0F0',
    category: SourceCategoryId.CloudApps,
    type: IntegrationType.Credentials,
    credentialsSchema: {
      $schema: 'http://json-schema.org/draft-07/schema#',
      type: 'object',
      properties: {
        apiKey: {
          type: 'string',
          input: {
            type: 'password',
          },
        },
      },
      required: ['apiKey'],
    },
  },
  {
    id: SourceId.Mqtt,
    title: 'MQTT',
    subtitle: 'Connect and publish messages to MQTT brokers',
    brandColor: '#660066',
    category: SourceCategoryId.CloudApps,
    type: IntegrationType.Credentials,
    credentialsSchema: {
      $schema: 'http://json-schema.org/draft-07/schema#',
      type: 'object',
      properties: {
        protocol: {
          type: 'string',
          default: 'mqtt',
        },
        host: {
          type: 'string',
        },
        port: {
          type: 'number',
          default: 1883,
        },
        clientId: {
          type: 'string',
        },
        username: {
          type: 'string',
        },
        password: {
          type: 'string',
          input: {
            type: 'password',
          },
        },
        clean: {
          type: 'boolean',
        },
        ssl: {
          type: 'boolean',
        },
        ca: {
          type: 'string',
        },
        cert: {
          type: 'string',
        },
        key: {
          type: 'string',
        },
        rejectUnauthorized: {
          type: 'boolean',
        },
      },
      required: ['host'],
    },
  },
  {
    id: SourceId.Magento,
    title: 'Magento 2',
    subtitle: 'Interact with your Magento 2 e-commerce platform',
    brandColor: '#F26322',
    category: SourceCategoryId.CloudApps,
    type: IntegrationType.Credentials,
    credentialsSchema: {
      $schema: 'http://json-schema.org/draft-07/schema#',
      type: 'object',
      properties: {
        host: {
          type: 'string',
        },
        accessToken: {
          type: 'string',
          input: {
            type: 'password',
          },
        },
      },
      required: ['host', 'accessToken'],
    },
  },
  {
    id: SourceId.Mailcheck,
    title: 'Mailcheck',
    subtitle: 'Verify email addresses with Mailcheck',
    brandColor: '#ff6f61',
    category: SourceCategoryId.CloudApps,
    type: IntegrationType.Credentials,
    credentialsSchema: {
      $schema: 'http://json-schema.org/draft-07/schema#',
      type: 'object',
      properties: {
        apiKey: {
          type: 'string',
          input: {
            type: 'password',
          },
        },
      },
      required: ['apiKey'],
    },
  },
  {
    id: SourceId.MailerLite,
    title: 'MailerLite',
    subtitle: 'Email marketing and automation platform',
    brandColor: '#09C269',
    category: SourceCategoryId.CloudApps,
    type: IntegrationType.Credentials,
    credentialsSchema: {
      $schema: 'http://json-schema.org/draft-07/schema#',
      type: 'object',
      properties: {
        apiKey: {
          type: 'string',
          input: {
            type: 'password',
          },
        },
      },
      required: ['apiKey'],
    },
  },
  {
    id: SourceId.Mailgun,
    title: 'Mailgun',
    subtitle: 'Send emails with Mailgun',
    brandColor: '#f06',
    category: SourceCategoryId.CloudApps,
    type: IntegrationType.Credentials,
    credentialsSchema: {
      $schema: 'http://json-schema.org/draft-07/schema#',
      type: 'object',
      properties: {
        apiDomain: {
          type: 'string',
          enum: ['api.eu.mailgun.net', 'api.mailgun.net'],
          default: 'api.mailgun.net',
        },
        emailDomain: {
          type: 'string',
        },
        apiKey: {
          type: 'string',
          input: {
            type: 'password',
          },
        },
      },
      required: ['apiDomain', 'emailDomain', 'apiKey'],
    },
  },
  {
    id: SourceId.Mailjet,
    title: 'Mailjet',
    subtitle: 'Send emails and SMS messages with Mailjet',
    brandColor: '#0A83EE',
    category: SourceCategoryId.CloudApps,
    type: IntegrationType.Credentials,
    credentialsSchema: {
      $schema: 'http://json-schema.org/draft-07/schema#',
      type: 'object',
      properties: {
        apiKey: {
          type: 'string',
        },
        secretKey: {
          type: 'string',
          input: {
            type: 'password',
          },
        },
        sandboxMode: {
          type: 'boolean',
        },
        token: {
          type: 'string',
          input: {
            type: 'password',
          },
        },
      },
      required: ['apiKey', 'secretKey', 'token'],
    },
  },
  {
    id: SourceId.Mandrill,
    title: 'Mandrill',
    subtitle: 'Send emails with Mandrill transactional email service',
    brandColor: '#4e98e2',
    category: SourceCategoryId.CloudApps,
    type: IntegrationType.Credentials,
    credentialsSchema: {
      $schema: 'http://json-schema.org/draft-07/schema#',
      type: 'object',
      properties: {
        apiKey: {
          type: 'string',
          input: {
            type: 'password',
          },
        },
      },
      required: ['apiKey'],
    },
  },
  {
    id: SourceId.Marketstack,
    title: 'Marketstack',
    subtitle: 'Access global stock market data',
    brandColor: '#4081EC',
    category: SourceCategoryId.DataWarehouse,
    type: IntegrationType.Credentials,
    credentialsSchema: {
      $schema: 'http://json-schema.org/draft-07/schema#',
      type: 'object',
      properties: {
        apiKey: {
          type: 'string',
        },
        useHttps: {
          type: 'boolean',
        },
      },
      required: ['apiKey'],
    },
  },
  {
    id: SourceId.Matrix,
    title: 'Matrix',
    subtitle: 'Interact with the Matrix ecosystem',
    brandColor: '#000000',
    category: SourceCategoryId.CloudApps,
    type: IntegrationType.Credentials,
    credentialsSchema: {
      $schema: 'http://json-schema.org/draft-07/schema#',
      type: 'object',
      properties: {
        accessToken: {
          type: 'string',
          input: {
            type: 'password',
          },
        },
        homeserverUrl: {
          type: 'string',
        },
      },
      required: ['accessToken', 'homeserverUrl'],
    },
  },
  {
    id: SourceId.Mattermost,
    title: 'Mattermost',
    subtitle: 'Integrate with Mattermost for team collaboration and messaging',
    brandColor: '#0058CC',
    category: SourceCategoryId.CloudApps,
    type: IntegrationType.Credentials,
    credentialsSchema: {
      $schema: 'http://json-schema.org/draft-07/schema#',
      type: 'object',
      properties: {
        accessToken: {
          type: 'string',
          input: {
            type: 'password',
          },
        },
        baseUrl: {
          type: 'string',
        },
        allowUnauthorizedCerts: {
          type: 'boolean',
        },
      },
      required: ['accessToken', 'baseUrl'],
    },
  },
  {
    id: SourceId.Mautic,
    title: 'Mautic',
    subtitle: 'Automate your marketing with Mautic',
    brandColor: '#4E5E9E',
    category: SourceCategoryId.CloudApps,
    type: IntegrationType.Credentials,
    credentialsSchema: {
      $schema: 'http://json-schema.org/draft-07/schema#',
      type: 'object',
      properties: {
        url: {
          type: 'string',
        },
        username: {
          type: 'string',
        },
        password: {
          type: 'string',
          input: {
            type: 'password',
          },
        },
      },
      required: ['url', 'username', 'password'],
    },
  },
  {
    id: SourceId.MessageBird,
    title: 'MessageBird',
    subtitle: 'Send SMS and manage communications with MessageBird',
    brandColor: '#FF6200',
    category: SourceCategoryId.CloudApps,
    type: IntegrationType.Credentials,
    credentialsSchema: {
      $schema: 'http://json-schema.org/draft-07/schema#',
      type: 'object',
      properties: {
        accessKey: {
          type: 'string',
          input: {
            type: 'password',
          },
        },
      },
      required: ['accessKey'],
    },
  },
  {
    id: SourceId.Metabase,
    title: 'Metabase',
    subtitle: 'Connect to your Metabase instance',
    brandColor: '#509EE3',
    category: SourceCategoryId.DataWarehouse,
    type: IntegrationType.Credentials,
    credentialsSchema: {
      $schema: 'http://json-schema.org/draft-07/schema#',
      type: 'object',
      properties: {
        url: {
          type: 'string',
        },
        username: {
          type: 'string',
        },
        password: {
          type: 'string',
          input: {
            type: 'password',
          },
        },
      },
      required: ['url', 'username', 'password'],
    },
  },
  {
    id: SourceId.Mindee,
    title: 'Mindee',
    subtitle: 'Extract data from documents with Mindee',
    brandColor: '#FD3246',
    category: SourceCategoryId.AIML,
    type: IntegrationType.Credentials,
    credentialsSchema: {
      $schema: 'http://json-schema.org/draft-07/schema#',
      type: 'object',
      properties: {
        apiKey: {
          type: 'string',
          input: {
            type: 'password',
          },
        },
      },
      required: ['apiKey'],
    },
  },
  {
    id: SourceId.Misp,
    title: 'MISP',
    subtitle: 'Connect to your MISP threat intelligence platform',
    brandColor: '#ff0000',
    category: SourceCategoryId.CloudApps,
    type: IntegrationType.Credentials,
    credentialsSchema: {
      $schema: 'http://json-schema.org/draft-07/schema#',
      type: 'object',
      properties: {
        apiKey: {
          type: 'string',
          input: {
            type: 'password',
          },
        },
        baseUrl: {
          type: 'string',
        },
        allowUnauthorizedCerts: {
          type: 'boolean',
        },
      },
      required: ['apiKey', 'baseUrl'],
    },
  },
  {
    id: SourceId.Mocean,
    title: 'Mocean',
    subtitle: 'Send SMS and voice messages via Mocean',
    brandColor: '#1a82e2',
    category: SourceCategoryId.CloudApps,
    type: IntegrationType.Credentials,
    credentialsSchema: {
      $schema: 'http://json-schema.org/draft-07/schema#',
      type: 'object',
      properties: {
        'mocean-api-key': {
          type: 'string',
        },
        'mocean-api-secret': {
          type: 'string',
          input: {
            type: 'password',
          },
        },
      },
      required: ['mocean-api-key', 'mocean-api-secret'],
    },
  },
  {
    id: SourceId.MondayCom,
    title: 'Monday.com',
    subtitle: 'Connect and manage your Monday.com boards',
    brandColor: '#FFFFFF',
    category: SourceCategoryId.CloudApps,
    type: IntegrationType.Credentials,
    credentialsSchema: {
      $schema: 'http://json-schema.org/draft-07/schema#',
      type: 'object',
      properties: {
        apiToken: {
          type: 'string',
          input: {
            type: 'password',
          },
        },
      },
      required: ['apiToken'],
    },
  },
  {
    id: SourceId.Mongodb,
    title: 'MongoDB',
    subtitle: 'Connect to your MongoDB database',
    brandColor: '#00684A',
    category: SourceCategoryId.DataWarehouse,
    type: IntegrationType.Credentials,
    credentialsSchema: {
      $schema: 'http://json-schema.org/draft-07/schema#',
      type: 'object',
      properties: {
        configurationType: {
          type: 'string',
          enum: ['connectionString', 'values'],
        },
        connectionString: {
          type: 'string',
        },
        host: {
          type: 'string',
        },
        database: {
          type: 'string',
        },
        user: {
          type: 'string',
        },
        password: {
          type: 'string',
          input: {
            type: 'password',
          },
        },
        port: {
          type: 'integer',
          default: 27017,
        },
      },
      required: ['configurationType', 'database'],
      dependencies: {
        configurationType: {
          oneOf: [
            {
              properties: {
                configurationType: {
                  enum: ['connectionString'],
                },
                connectionString: {
                  type: 'string',
                },
              },
              required: ['connectionString'],
            },
            {
              properties: {
                configurationType: {
                  enum: ['values'],
                },
                host: {
                  type: 'string',
                },
                user: {
                  type: 'string',
                },
                password: {
                  type: 'string',
                },
                port: {
                  type: 'integer',
                },
              },
              required: ['host', 'user', 'password', 'port'],
            },
          ],
        },
      },
    },
  },
  {
    id: SourceId.MonicaCrm,
    title: 'Monica CRM',
    subtitle: 'Connect to your Monica CRM to manage contacts and activities',
    brandColor: '#EBEBEB',
    category: SourceCategoryId.CloudApps,
    type: IntegrationType.Credentials,
    credentialsSchema: {
      $schema: 'http://json-schema.org/draft-07/schema#',
      type: 'object',
      properties: {
        environment: {
          type: 'string',
          enum: ['cloudHosted', 'selfHosted'],
        },
        domain: {
          type: 'string',
          format: 'uri',
        },
        apiToken: {
          type: 'string',
          input: {
            type: 'password',
          },
        },
      },
      required: ['environment', 'apiToken'],
      dependencies: {
        environment: {
          oneOf: [
            {
              properties: {
                environment: {
                  const: 'selfHosted',
                },
                domain: {
                  type: 'string',
                  format: 'uri',
                },
              },
              required: ['domain'],
            },
            {
              properties: {
                environment: {
                  const: 'cloudHosted',
                },
              },
            },
          ],
        },
      },
    },
  },
  {
    id: SourceId.Msg91,
    title: 'MSG91',
    subtitle: 'Send transactional SMS via MSG91',
    brandColor: '#f26c4f',
    category: SourceCategoryId.CloudApps,
    type: IntegrationType.Credentials,
    credentialsSchema: {
      $schema: 'http://json-schema.org/draft-07/schema#',
      type: 'object',
      properties: {
        authkey: {
          type: 'string',
          input: {
            type: 'password',
          },
        },
      },
      required: ['authkey'],
    },
  },
  {
    id: SourceId.Mysql,
    title: 'MySQL',
    subtitle: 'Connect and manage your MySQL databases',
    brandColor: '#00546B',
    category: SourceCategoryId.DataWarehouse,
    type: IntegrationType.Credentials,
    credentialsSchema: {
      $schema: 'http://json-schema.org/draft-07/schema#',
      type: 'object',
      properties: {
        host: {
          type: 'string',
        },
        database: {
          type: 'string',
        },
        user: {
          type: 'string',
        },
        password: {
          type: 'string',
          input: {
            type: 'password',
          },
        },
        port: {
          type: 'number',
        },
        connectTimeout: {
          type: 'number',
        },
        ssl: {
          type: 'boolean',
        },
        caCertificate: {
          type: 'string',
          input: {
            type: 'password',
          },
        },
        clientPrivateKey: {
          type: 'string',
          input: {
            type: 'password',
          },
        },
        clientCertificate: {
          type: 'string',
          input: {
            type: 'password',
          },
        },
        sshTunnel: {
          type: 'boolean',
        },
        sshAuthenticateWith: {
          type: 'string',
        },
        sshHost: {
          type: 'string',
        },
        sshPort: {
          type: 'number',
        },
        sshMysqlPort: {
          type: 'number',
        },
        sshUser: {
          type: 'string',
        },
        sshPassword: {
          type: 'string',
          input: {
            type: 'password',
          },
        },
        privateKey: {
          type: 'string',
          input: {
            type: 'password',
          },
        },
        passphrase: {
          type: 'string',
        },
      },
      required: ['host', 'database', 'user', 'password', 'port'],
    },
  },
  {
    id: SourceId.Nasa,
    title: 'NASA',
    subtitle: "Access data from NASA's various APIs",
    brandColor: '#0B3D91',
    category: SourceCategoryId.CloudApps,
    type: IntegrationType.Credentials,
    credentialsSchema: {
      $schema: 'http://json-schema.org/draft-07/schema#',
      type: 'object',
      properties: {
        api_key: {
          type: 'string',
          input: {
            type: 'password',
          },
        },
      },
      required: ['api_key'],
    },
  },
  {
    id: SourceId.Netlify,
    title: 'Netlify',
    subtitle: 'Deploy and manage your Netlify sites',
    brandColor: '#1CB2AF',
    category: SourceCategoryId.CloudApps,
    type: IntegrationType.Credentials,
    credentialsSchema: {
      $schema: 'http://json-schema.org/draft-07/schema#',
      type: 'object',
      properties: {
        accessToken: {
          type: 'string',
          input: {
            type: 'password',
          },
        },
      },
      required: ['accessToken'],
    },
  },
  {
    id: SourceId.NextCloud,
    title: 'NextCloud',
    subtitle: 'Access data on Nextcloud',
    brandColor: '#0082C9',
    category: SourceCategoryId.CloudApps,
    type: IntegrationType.Credentials,
    credentialsSchema: {
      $schema: 'http://json-schema.org/draft-07/schema#',
      type: 'object',
      properties: {
        webDavUrl: {
          type: 'string',
        },
        user: {
          type: 'string',
        },
        password: {
          type: 'string',
          input: {
            type: 'password',
          },
        },
      },
      required: ['webDavUrl', 'user', 'password'],
    },
  },
  {
    id: SourceId.Nocodb,
    title: 'NocoDB',
    subtitle: 'Work with your NocoDB projects',
    brandColor: '#409EFF',
    category: SourceCategoryId.DataWarehouse,
    type: IntegrationType.Credentials,
    credentialsSchema: {
      $schema: 'http://json-schema.org/draft-07/schema#',
      type: 'object',
      properties: {
        apiToken: {
          type: 'string',
          input: {
            type: 'password',
          },
        },
      },
      required: ['apiToken'],
    },
  },
  {
    id: SourceId.NpmRegistry,
    title: 'NPM Registry',
    subtitle: 'Interact with the NPM package registry',
    brandColor: '#CB3837',
    category: SourceCategoryId.CloudApps,
    type: IntegrationType.Credentials,
    credentialsSchema: {
      $schema: 'http://json-schema.org/draft-07/schema#',
      type: 'object',
      properties: {
        accessToken: {
          type: 'string',
          input: {
            type: 'password',
          },
        },
        registryUrl: {
          type: 'string',
        },
      },
      required: ['accessToken', 'registryUrl'],
    },
  },
  {
    id: SourceId.Odoo,
    title: 'Odoo',
    subtitle: 'Integrate with Odoo to manage your ERP services',
    brandColor: '#875A7B',
    category: SourceCategoryId.CloudApps,
    type: IntegrationType.Credentials,
    credentialsSchema: {
      $schema: 'http://json-schema.org/draft-07/schema#',
      type: 'object',
      properties: {
        url: {
          type: 'string',
        },
        username: {
          type: 'string',
        },
        password: {
          type: 'string',
          input: {
            type: 'password',
          },
        },
        db: {
          type: 'string',
        },
      },
      required: ['url', 'username', 'password'],
    },
  },
  {
    id: SourceId.OneSimpleApi,
    title: 'One Simple API',
    subtitle: 'A toolbox of no-code utilities',
    brandColor: '#4098ff',
    category: SourceCategoryId.CloudApps,
    type: IntegrationType.Credentials,
    credentialsSchema: {
      $schema: 'http://json-schema.org/draft-07/schema#',
      type: 'object',
      properties: {
        apiToken: {
          type: 'string',
          input: {
            type: 'password',
          },
        },
      },
      required: ['apiToken'],
    },
  },
  {
    id: SourceId.Onfleet,
    title: 'Onfleet',
    subtitle: 'Streamline your delivery operations with Onfleet',
    brandColor: '#FF6F00',
    category: SourceCategoryId.CloudApps,
    type: IntegrationType.Credentials,
    credentialsSchema: {
      $schema: 'http://json-schema.org/draft-07/schema#',
      type: 'object',
      properties: {
        apiKey: {
          type: 'string',
          input: {
            type: 'password',
          },
        },
      },
      required: ['apiKey'],
    },
  },
  {
    id: SourceId.Openthesaurus,
    title: 'OpenThesaurus',
    subtitle: 'Access synonyms for German words using OpenThesaurus',
    brandColor: '#0d6efd',
    category: SourceCategoryId.CloudApps,
    type: IntegrationType.Credentials,
    credentialsSchema: {
      $schema: 'http://json-schema.org/draft-07/schema#',
      type: 'object',
      properties: {
        apiKey: {
          type: 'string',
        },
      },
      required: ['apiKey'],
    },
  },
  {
    id: SourceId.Openweathermap,
    title: 'OpenWeatherMap',
    subtitle: 'Access current and forecast weather data',
    brandColor: '#ff7e00',
    category: SourceCategoryId.CloudApps,
    type: IntegrationType.Credentials,
    credentialsSchema: {
      $schema: 'http://json-schema.org/draft-07/schema#',
      type: 'object',
      properties: {
        accessToken: {
          type: 'string',
          input: {
            type: 'password',
          },
        },
      },
      required: ['accessToken'],
    },
  },
  {
    id: SourceId.Orbit,
    title: 'Orbit',
    subtitle: 'Integrate with Orbit to manage community activities and members',
    brandColor: '#5000ff',
    category: SourceCategoryId.CloudApps,
    type: IntegrationType.Credentials,
    credentialsSchema: {
      $schema: 'http://json-schema.org/draft-07/schema#',
      type: 'object',
      properties: {
        accessToken: {
          type: 'string',
          input: {
            type: 'password',
          },
        },
      },
      required: ['accessToken'],
    },
  },
  {
    id: SourceId.Oura,
    title: 'Oura',
    subtitle: 'Access Oura Ring data',
    brandColor: '#000000',
    category: SourceCategoryId.CloudApps,
    type: IntegrationType.Credentials,
    credentialsSchema: {
      $schema: 'http://json-schema.org/draft-07/schema#',
      type: 'object',
      properties: {
        accessToken: {
          type: 'string',
          input: {
            type: 'password',
          },
        },
      },
      required: ['accessToken'],
    },
  },
  {
    id: SourceId.Paddle,
    title: 'Paddle',
    subtitle:
      'Integrate with Paddle for payment processing and subscription management',
    brandColor: '#000000',
    category: SourceCategoryId.CloudApps,
    type: IntegrationType.Credentials,
    credentialsSchema: {
      $schema: 'http://json-schema.org/draft-07/schema#',
      type: 'object',
      properties: {
        vendorAuthCode: {
          type: 'string',
        },
        vendorId: {
          type: 'string',
        },
        sandbox: {
          type: 'boolean',
        },
      },
      required: ['vendorAuthCode', 'vendorId'],
    },
  },
  {
    id: SourceId.PagerDuty,
    title: 'PagerDuty',
    subtitle: 'Incident response platform for IT departments',
    brandColor: '#06AC38',
    category: SourceCategoryId.CloudApps,
    type: IntegrationType.Credentials,
    credentialsSchema: {
      $schema: 'http://json-schema.org/draft-07/schema#',
      type: 'object',
      properties: {
        apiToken: {
          type: 'string',
          input: {
            type: 'password',
          },
        },
      },
      required: ['apiToken'],
    },
  },
  {
    id: SourceId.Paypal,
    title: 'PayPal',
    subtitle: 'Process payments and manage transactions with PayPal',
    brandColor: '#CBEFFF',
    category: SourceCategoryId.CloudApps,
    type: IntegrationType.Credentials,
    credentialsSchema: {
      $schema: 'http://json-schema.org/draft-07/schema#',
      type: 'object',
      properties: {
        clientId: {
          type: 'string',
        },
        secret: {
          type: 'string',
          input: {
            type: 'password',
          },
        },
        env: {
          type: 'string',
          enum: ['live', 'sandbox'],
          default: 'live',
        },
      },
      required: ['clientId', 'secret', 'env'],
    },
  },
  {
    id: SourceId.Peekalink,
    title: 'Peekalink',
    subtitle: 'Preview and check availability of links',
    brandColor: '#0000ff',
    category: SourceCategoryId.CloudApps,
    type: IntegrationType.Credentials,
    credentialsSchema: {
      $schema: 'http://json-schema.org/draft-07/schema#',
      type: 'object',
      properties: {
        apiKey: {
          type: 'string',
          input: {
            type: 'password',
          },
        },
      },
      required: ['apiKey'],
    },
  },
  {
    id: SourceId.Phantombuster,
    title: 'Phantombuster',
    subtitle: 'Automate the web with Phantombuster',
    brandColor: '#8c52ff',
    category: SourceCategoryId.CloudApps,
    type: IntegrationType.Credentials,
    credentialsSchema: {
      $schema: 'http://json-schema.org/draft-07/schema#',
      type: 'object',
      properties: {
        apiKey: {
          type: 'string',
          input: {
            type: 'password',
          },
        },
      },
      required: ['apiKey'],
    },
  },
  {
    id: SourceId.PhilipsHue,
    title: 'Philips Hue',
    subtitle: 'Control and manage your Philips Hue smart lights',
    brandColor: '#000000',
    category: SourceCategoryId.CloudApps,
    type: IntegrationType.OAuth2,
  },
  {
    id: SourceId.Plivo,
    title: 'Plivo',
    subtitle: 'Send SMS/MMS messages or make phone calls',
    brandColor: '#f23f61',
    category: SourceCategoryId.CloudApps,
    type: IntegrationType.Credentials,
    credentialsSchema: {
      $schema: 'http://json-schema.org/draft-07/schema#',
      type: 'object',
      properties: {
        authId: {
          type: 'string',
        },
        authToken: {
          type: 'string',
          input: {
            type: 'password',
          },
        },
      },
      required: ['authId', 'authToken'],
    },
  },
  {
    id: SourceId.PostBin,
    title: 'PostBin',
    subtitle: 'Consume PostBin API',
    brandColor: '#5A45FF',
    category: SourceCategoryId.CloudApps,
    type: IntegrationType.Credentials,
    credentialsSchema: {
      $schema: 'http://json-schema.org/draft-07/schema#',
      type: 'object',
      properties: {
        apiKey: {
          type: 'string',
          input: {
            type: 'password',
          },
        },
      },
      required: ['apiKey'],
    },
  },
  {
    id: SourceId.Posthog,
    title: 'PostHog',
    subtitle: 'Analyze user behavior with PostHog analytics',
    brandColor: '#000000',
    category: SourceCategoryId.CloudApps,
    type: IntegrationType.Credentials,
    credentialsSchema: {
      $schema: 'http://json-schema.org/draft-07/schema#',
      type: 'object',
      properties: {
        url: {
          type: 'string',
        },
        apiKey: {
          type: 'string',
          input: {
            type: 'password',
          },
        },
      },
      required: ['url', 'apiKey'],
    },
  },
  {
    id: SourceId.Postgres,
    title: 'PostgreSQL',
    subtitle: 'Connect and manage your PostgreSQL databases',
    brandColor: '#114773',
    category: SourceCategoryId.DataWarehouse,
    type: IntegrationType.Credentials,
    credentialsSchema: {
      $schema: 'http://json-schema.org/draft-07/schema#',
      type: 'object',
      properties: {
        host: {
          type: 'string',
        },
        database: {
          type: 'string',
        },
        user: {
          type: 'string',
        },
        password: {
          type: 'string',
          input: {
            type: 'password',
          },
        },
        port: {
          type: 'number',
          default: 5432,
        },
        ssl: {
          type: 'boolean',
          enum: ['disable', 'allow', 'require'],
          default: 'disable',
        },
        allowUnauthorizedCerts: {
          type: 'boolean',
          default: false,
        },
      },
      required: ['host', 'database', 'user', 'password'],
    },
  },
  {
    id: SourceId.ProfitWell,
    title: 'ProfitWell',
    subtitle: 'Connect to ProfitWell for financial metrics and analytics',
    brandColor: '#4e5b6c',
    category: SourceCategoryId.CloudApps,
    type: IntegrationType.Credentials,
    credentialsSchema: {
      $schema: 'http://json-schema.org/draft-07/schema#',
      type: 'object',
      properties: {
        accessToken: {
          type: 'string',
          input: {
            type: 'password',
          },
        },
      },
      required: ['accessToken'],
    },
  },
  {
    id: SourceId.Pushbullet,
    title: 'Pushbullet',
    subtitle: 'Send notifications, links, files, and more to your devices',
    brandColor: '#67BF79',
    category: SourceCategoryId.CloudApps,
    type: IntegrationType.OAuth2,
  },
  {
    id: SourceId.Pushcut,
    title: 'Pushcut',
    subtitle: 'Send notifications to iOS devices with Pushcut',
    brandColor: '#000000',
    category: SourceCategoryId.CloudApps,
    type: IntegrationType.Credentials,
    credentialsSchema: {
      $schema: 'http://json-schema.org/draft-07/schema#',
      type: 'object',
      properties: {
        apiKey: {
          type: 'string',
          input: {
            type: 'password',
          },
        },
      },
      required: ['apiKey'],
    },
  },
  {
    id: SourceId.Pushover,
    title: 'Pushover',
    subtitle: 'Send notifications through Pushover',
    brandColor: '#249DF1',
    category: SourceCategoryId.CloudApps,
    type: IntegrationType.Credentials,
    credentialsSchema: {
      $schema: 'http://json-schema.org/draft-07/schema#',
      type: 'object',
      properties: {
        apiKey: {
          type: 'string',
          input: {
            type: 'password',
          },
        },
      },
      required: ['apiKey'],
    },
  },
  {
    id: SourceId.QuestDb,
    title: 'QuestDB',
    subtitle: 'Connect and manage your QuestDB instances',
    brandColor: '#5A6793',
    category: SourceCategoryId.DataWarehouse,
    type: IntegrationType.Credentials,
    credentialsSchema: {
      $schema: 'http://json-schema.org/draft-07/schema#',
      type: 'object',
      properties: {
        host: {
          type: 'string',
        },
        database: {
          type: 'string',
        },
        user: {
          type: 'string',
        },
        password: {
          type: 'string',
          input: {
            type: 'password',
          },
        },
        ssl: {
          type: 'string',
          enum: ['allow', 'disable', 'require', 'verify', 'verify-full'],
          default: 'disable',
        },
        port: {
          type: 'number',
        },
      },
      required: ['host', 'database', 'user', 'password', 'port'],
    },
  },
  {
    id: SourceId.Quickbase,
    title: 'Quick Base',
    subtitle: 'Integrate with Quick Base applications',
    brandColor: '#5F2B8C',
    category: SourceCategoryId.CloudApps,
    type: IntegrationType.Credentials,
    credentialsSchema: {
      $schema: 'http://json-schema.org/draft-07/schema#',
      type: 'object',
      properties: {
        hostname: {
          type: 'string',
        },
        userToken: {
          type: 'string',
          input: {
            type: 'password',
          },
        },
      },
      required: ['hostname', 'userToken'],
    },
  },
  {
    id: SourceId.Quickbooks,
    title: 'QuickBooks Online',
    subtitle: 'Integrate with QuickBooks Online accounting',
    brandColor: '#2CA01C',
    category: SourceCategoryId.CloudApps,
    type: IntegrationType.OAuth2,
  },
  {
    id: SourceId.Quickchart,
    title: 'QuickChart',
    subtitle: 'Generate charts and graphs with QuickChart',
    brandColor: '#FF6384',
    category: SourceCategoryId.CloudApps,
    type: IntegrationType.Credentials,
    credentialsSchema: {
      $schema: 'http://json-schema.org/draft-07/schema#',
      type: 'object',
      properties: {
        apiKey: {
          type: 'string',
          input: {
            type: 'password',
          },
        },
      },
      required: ['apiKey'],
    },
  },
  {
    id: SourceId.Rabbitmq,
    title: 'RabbitMQ',
    subtitle: 'Connect to your RabbitMQ message broker',
    brandColor: '#FF6600',
    category: SourceCategoryId.CloudApps,
    type: IntegrationType.Credentials,
    credentialsSchema: {
      $schema: 'http://json-schema.org/draft-07/schema#',
      type: 'object',
      properties: {
        hostname: {
          type: 'string',
        },
        port: {
          type: 'number',
        },
        username: {
          type: 'string',
        },
        password: {
          type: 'string',
          input: {
            type: 'password',
          },
        },
        vhost: {
          type: 'string',
        },
        ssl: {
          type: 'boolean',
        },
        passwordless: {
          type: 'boolean',
        },
        ca: {
          type: 'string',
          input: {
            type: 'password',
          },
        },
        cert: {
          type: 'string',
          input: {
            type: 'password',
          },
        },
        key: {
          type: 'string',
          input: {
            type: 'password',
          },
        },
        passphrase: {
          type: 'string',
          input: {
            type: 'password',
          },
        },
      },
      required: ['hostname', 'port', 'username', 'password', 'vhost'],
    },
  },
  {
    id: SourceId.Raindrop,
    title: 'Raindrop',
    subtitle: 'Organize your bookmarks with Raindrop',
    brandColor: '#5B9AEF',
    category: SourceCategoryId.CloudApps,
    type: IntegrationType.OAuth2,
  },
  {
    id: SourceId.Reddit,
    title: 'Reddit',
    subtitle: 'Interact with the Reddit API',
    brandColor: '#FF4500',
    category: SourceCategoryId.CloudApps,
    type: IntegrationType.OAuth2,
  },
  {
    id: SourceId.Redis,
    title: 'Redis',
    subtitle: 'Connect to your Redis data store',
    brandColor: '#5B0708',
    category: SourceCategoryId.DataWarehouse,
    type: IntegrationType.Credentials,
    credentialsSchema: {
      $schema: 'http://json-schema.org/draft-07/schema#',
      type: 'object',
      properties: {
        host: {
          type: 'string',
        },
        port: {
          type: 'number',
        },
        password: {
          type: 'string',
          input: {
            type: 'password',
          },
        },
        database: {
          type: 'number',
        },
      },
      required: ['host', 'port', 'database'],
    },
  },
  {
    id: SourceId.Rocketchat,
    title: 'Rocket.Chat',
    subtitle: 'Connect and interact with Rocket.Chat',
    brandColor: '#F5455C',
    category: SourceCategoryId.CloudApps,
    type: IntegrationType.Credentials,
    credentialsSchema: {
      $schema: 'http://json-schema.org/draft-07/schema#',
      type: 'object',
      properties: {
        userId: {
          type: 'string',
        },
        authKey: {
          type: 'string',
          input: {
            type: 'password',
          },
        },
        domain: {
          type: 'string',
        },
      },
      required: ['userId', 'authKey', 'domain'],
    },
  },
  {
    id: SourceId.RssFeed,
    title: 'RSS Feed',
    subtitle: 'Read data from RSS Feeds',
    brandColor: '#ff6600',
    category: SourceCategoryId.CloudApps,
    type: IntegrationType.Credentials,
    credentialsSchema: {
      $schema: 'http://json-schema.org/draft-07/schema#',
      type: 'object',
      properties: {
        url: {
          type: 'string',
        },
      },
      required: ['url'],
    },
  },
  {
    id: SourceId.Rundeck,
    title: 'Rundeck',
    subtitle: 'Connect and manage your Rundeck automation jobs',
    brandColor: '#1E50A2',
    category: SourceCategoryId.CloudApps,
    type: IntegrationType.Credentials,
    credentialsSchema: {
      $schema: 'http://json-schema.org/draft-07/schema#',
      type: 'object',
      properties: {
        url: {
          type: 'string',
        },
        token: {
          type: 'string',
          input: {
            type: 'password',
          },
        },
      },
      required: ['url', 'token'],
    },
  },
  {
    id: SourceId.S3,
    title: 'S3',
    subtitle: 'Connect to any S3-compatible storage service',
    brandColor: '#FFDBD7',
    category: SourceCategoryId.CloudApps,
    type: IntegrationType.Credentials,
    credentialsSchema: {
      $schema: 'http://json-schema.org/draft-07/schema#',
      type: 'object',
      properties: {
        endpoint: {
          type: 'string',
        },
        region: {
          type: 'string',
        },
        accessKeyId: {
          type: 'string',
        },
        secretAccessKey: {
          type: 'string',
          input: {
            type: 'password',
          },
        },
        forcePathStyle: {
          type: 'boolean',
        },
      },
      required: ['endpoint', 'region', 'accessKeyId', 'secretAccessKey'],
    },
  },
  {
    id: SourceId.Salesforce,
    title: 'Salesforce',
    subtitle: 'Integrate with Salesforce to manage CRM data',
    brandColor: '#00A1E0',
    category: SourceCategoryId.CloudApps,
    type: IntegrationType.Credentials,
    credentialsSchema: {
      $schema: 'http://json-schema.org/draft-07/schema#',
      type: 'object',
      properties: {
        environment: {
          type: 'string',
          enum: ['production', 'sandbox'],
          default: 'production',
        },
        clientId: {
          type: 'string',
          title: 'Client ID',
          description: 'Consumer Key from Salesforce Connected App',
        },
        username: {
          type: 'string',
          title: 'Username',
        },
        privateKey: {
          type: 'string',
          title: 'Private Key',
          description:
            'Use the multiline editor. Make sure it is in standard PEM key format: -----BEGIN PRIVATE KEY----- KEY DATA GOES HERE -----END PRIVATE KEY-----',
        },
      },
      required: ['environment', 'clientId', 'username', 'privateKey'],
    },
  },
  {
    id: SourceId.Salesmate,
    title: 'Salesmate',
    subtitle: 'Connect to your Salesmate CRM',
    brandColor: '#1F62FF',
    category: SourceCategoryId.CloudApps,
    type: IntegrationType.Credentials,
    credentialsSchema: {
      $schema: 'http://json-schema.org/draft-07/schema#',
      type: 'object',
      properties: {
        sessionToken: {
          type: 'string',
          input: {
            type: 'password',
          },
        },
        url: {
          type: 'string',
        },
      },
      required: ['sessionToken', 'url'],
    },
  },
  {
    id: SourceId.SeaTable,
    title: 'SeaTable',
    subtitle: 'Connect and manage your SeaTable bases',
    brandColor: '#223344',
    category: SourceCategoryId.CloudApps,
    type: IntegrationType.Credentials,
    credentialsSchema: {
      $schema: 'http://json-schema.org/draft-07/schema#',
      type: 'object',
      properties: {
        environment: {
          type: 'string',
          enum: ['cloudHosted', 'selfHosted'],
        },
        domain: {
          type: 'string',
          format: 'uri',
        },
        token: {
          type: 'string',
          input: {
            type: 'password',
          },
        },
        timezone: {
          type: 'string',
        },
      },
      required: ['environment', 'token'],
      dependencies: {
        environment: {
          oneOf: [
            {
              properties: {
                environment: {
                  const: 'selfHosted',
                },
                domain: {
                  type: 'string',
                  format: 'uri',
                },
              },
              required: ['domain'],
            },
            {
              properties: {
                environment: {
                  const: 'cloudHosted',
                },
              },
            },
          ],
        },
      },
    },
  },
  {
    id: SourceId.SecurityScorecard,
    title: 'SecurityScorecard',
    subtitle: 'Monitor and manage company security ratings',
    brandColor: '#6641F3',
    category: SourceCategoryId.CloudApps,
    type: IntegrationType.Credentials,
    credentialsSchema: {
      $schema: 'http://json-schema.org/draft-07/schema#',
      type: 'object',
      properties: {
        apiKey: {
          type: 'string',
          input: {
            type: 'password',
          },
        },
      },
      required: ['apiKey'],
    },
  },
  {
    id: SourceId.Sendgrid,
    title: 'SendGrid',
    subtitle: 'Send emails with SendGrid',
    brandColor: '#263746',
    category: SourceCategoryId.CloudApps,
    type: IntegrationType.Credentials,
    credentialsSchema: {
      $schema: 'http://json-schema.org/draft-07/schema#',
      type: 'object',
      properties: {
        apiKey: {
          type: 'string',
          input: {
            type: 'password',
          },
        },
      },
      required: ['apiKey'],
    },
  },
  {
    id: SourceId.Sendy,
    title: 'Sendy',
    subtitle: 'Connect to your Sendy email marketing application',
    brandColor: '#1A1A1A',
    category: SourceCategoryId.CloudApps,
    type: IntegrationType.Credentials,
    credentialsSchema: {
      $schema: 'http://json-schema.org/draft-07/schema#',
      type: 'object',
      properties: {
        url: {
          type: 'string',
        },
        apiKey: {
          type: 'string',
          input: {
            type: 'password',
          },
        },
      },
      required: ['url', 'apiKey'],
    },
  },
  {
    id: SourceId.SentryIo,
    title: 'Sentry.io',
    subtitle: 'Monitor and fix crashes in real time with Sentry.io',
    brandColor: '#4E3D93',
    category: SourceCategoryId.CloudApps,
    type: IntegrationType.Credentials,
    credentialsSchema: {
      $schema: 'http://json-schema.org/draft-07/schema#',
      type: 'object',
      properties: {
        token: {
          type: 'string',
          input: {
            type: 'password',
          },
        },
        url: {
          type: 'string',
          input: {
            type: 'text',
          },
        },
      },
      required: ['token'],
    },
  },
  {
    id: SourceId.ServiceNow,
    title: 'ServiceNow',
    subtitle: 'Integrate with ServiceNow to manage incidents, users, and more',
    brandColor: '#293F41',
    category: SourceCategoryId.CloudApps,
    type: IntegrationType.Credentials,
    credentialsSchema: {
      $schema: 'http://json-schema.org/draft-07/schema#',
      type: 'object',
      properties: {
        user: {
          type: 'string',
        },
        password: {
          type: 'string',
          input: {
            type: 'password',
          },
        },
        subdomain: {
          type: 'string',
        },
      },
      required: ['user', 'password', 'subdomain'],
    },
  },
  {
    id: SourceId.Shopify,
    title: 'Shopify',
    subtitle:
      'E-commerce platform for online stores and retail point-of-sale systems',
    brandColor: '#95BF47',
    category: SourceCategoryId.CloudApps,
    type: IntegrationType.Credentials,
    credentialsSchema: {
      $schema: 'http://json-schema.org/draft-07/schema#',
      type: 'object',
      properties: {
        shopSubdomain: {
          type: 'string',
          description: 'Only the subdomain without .myshopify.com',
        },
        apiKey: {
          type: 'string',
          input: {
            type: 'password',
          },
        },
        password: {
          type: 'string',
          input: {
            type: 'password',
          },
        },
        sharedSecret: {
          type: 'string',
          input: {
            type: 'password',
          },
        },
        accessToken: {
          type: 'string',
          input: {
            type: 'password',
          },
        },
        appSecretKey: {
          type: 'string',
          input: {
            type: 'password',
          },
          description:
            'Secret key needed to verify the webhook when using Shopify Trigger node',
        },
      },
      required: [
        'shopSubdomain',
        'apiKey',
        'password',
        'accessToken',
        'appSecretKey',
      ],
    },
  },
  {
    id: SourceId.Signl4,
    title: 'SIGNL4',
    subtitle: 'Alert management and mobile dispatching for SIGNL4 teams',
    brandColor: '#d6336c',
    category: SourceCategoryId.CloudApps,
    type: IntegrationType.Credentials,
    credentialsSchema: {
      $schema: 'http://json-schema.org/draft-07/schema#',
      type: 'object',
      properties: {
        teamSecret: {
          type: 'string',
          input: {
            type: 'password',
          },
        },
      },
      required: ['teamSecret'],
    },
  },
  {
    id: SourceId.Sms77,
    title: 'Sms77',
    subtitle: 'Send SMS and make voice calls with Sms77',
    brandColor: '#22cc88',
    category: SourceCategoryId.CloudApps,
    type: IntegrationType.Credentials,
    credentialsSchema: {
      $schema: 'http://json-schema.org/draft-07/schema#',
      type: 'object',
      properties: {
        apiKey: {
          type: 'string',
          input: {
            type: 'password',
          },
        },
      },
      required: ['apiKey'],
    },
  },
  {
    id: SourceId.Splunk,
    title: 'Splunk',
    subtitle: 'Connect and manage your Splunk instances',
    brandColor: '#000000',
    category: SourceCategoryId.DataWarehouse,
    type: IntegrationType.Credentials,
    credentialsSchema: {
      $schema: 'http://json-schema.org/draft-07/schema#',
      type: 'object',
      properties: {
        authToken: {
          type: 'string',
          input: {
            type: 'password',
          },
        },
        baseUrl: {
          type: 'string',
        },
        allowUnauthorizedCerts: {
          type: 'boolean',
        },
      },
      required: ['authToken', 'baseUrl'],
    },
  },
  {
    id: SourceId.Spontit,
    title: 'Spontit',
    subtitle: 'Send push notifications with Spontit',
    brandColor: '#FFD700',
    category: SourceCategoryId.CloudApps,
    type: IntegrationType.Credentials,
    credentialsSchema: {
      $schema: 'http://json-schema.org/draft-07/schema#',
      type: 'object',
      properties: {
        apiKey: {
          type: 'string',
          input: {
            type: 'password',
          },
        },
        username: {
          type: 'string',
        },
      },
      required: ['apiKey', 'username'],
    },
  },
  {
    id: SourceId.Spotify,
    title: 'Spotify',
    subtitle: 'Access and manage Spotify music data',
    brandColor: '#1DB954',
    category: SourceCategoryId.CloudApps,
    type: IntegrationType.OAuth2,
  },
  {
    id: SourceId.Ssh,
    title: 'SSH',
    subtitle: 'Execute commands and manage files over SSH',
    brandColor: '#6c7a89',
    category: SourceCategoryId.CloudApps,
    type: IntegrationType.Credentials,
    credentialsSchema: {
      $schema: 'http://json-schema.org/draft-07/schema#',
      type: 'object',
      properties: {
        host: {
          type: 'string',
        },
        port: {
          type: 'number',
        },
        username: {
          type: 'string',
        },
        password: {
          type: 'string',
          input: {
            type: 'password',
          },
        },
        privateKey: {
          type: 'string',
          input: {
            type: 'password',
            multiline: true,
          },
        },
        passphrase: {
          type: 'string',
          input: {
            type: 'password',
          },
        },
      },
      required: ['host', 'port', 'username'],
    },
  },
  {
    id: SourceId.Stackby,
    title: 'Stackby',
    subtitle: 'Connect and manage your Stackby workspaces',
    brandColor: '#5BC0DE',
    category: SourceCategoryId.CloudApps,
    type: IntegrationType.Credentials,
    credentialsSchema: {
      $schema: 'http://json-schema.org/draft-07/schema#',
      type: 'object',
      properties: {
        apiKey: {
          type: 'string',
          input: {
            type: 'password',
          },
        },
      },
      required: ['apiKey'],
    },
  },
  {
    id: SourceId.Storyblok,
    title: 'Storyblok',
    subtitle: 'Content Management with Storyblok',
    brandColor: '#0AB3AF',
    category: SourceCategoryId.CloudApps,
    type: IntegrationType.Credentials,
    credentialsSchema: {
      $schema: 'http://json-schema.org/draft-07/schema#',
      type: 'object',
      properties: {
        apiKey: {
          type: 'string',
          input: {
            type: 'password',
          },
        },
        accessToken: {
          type: 'string',
          input: {
            type: 'password',
          },
        },
      },
      required: ['apiKey', 'accessToken'],
    },
  },
  {
    id: SourceId.Strapi,
    title: 'Strapi',
    subtitle: 'Interact with your Strapi headless CMS',
    brandColor: '#4945FF',
    category: SourceCategoryId.CloudApps,
    type: IntegrationType.Credentials,
    credentialsSchema: {
      $schema: 'http://json-schema.org/draft-07/schema#',
      type: 'object',
      properties: {
        email: {
          type: 'string',
        },
        password: {
          type: 'string',
          input: {
            type: 'password',
          },
        },
        url: {
          type: 'string',
        },
        apiVersion: {
          type: 'string',
          enum: ['v3', 'v4'],
          default: 'v3',
        },
      },
      required: ['email', 'password', 'url', 'apiVersion'],
    },
  },
  {
    id: SourceId.Strava,
    title: 'Strava',
    subtitle: 'Track your fitness activity with Strava',
    brandColor: '#fc4c02',
    category: SourceCategoryId.CloudApps,
    type: IntegrationType.OAuth2,
  },
  {
    id: SourceId.Stripe,
    title: 'Stripe',
    subtitle: 'Process payments with Stripe',
    brandColor: '#635BFF',
    category: SourceCategoryId.CloudApps,
    type: IntegrationType.Credentials,
    credentialsSchema: {
      $schema: 'http://json-schema.org/draft-07/schema#',
      type: 'object',
      properties: {
        secretKey: {
          type: 'string',
          input: {
            type: 'password',
          },
        },
      },
      required: ['secretKey'],
    },
  },
  {
    id: SourceId.Supabase,
    title: 'Supabase',
    subtitle: 'Connect to your Supabase projects',
    brandColor: '#000000',
    category: SourceCategoryId.DataWarehouse,
    type: IntegrationType.Credentials,
    credentialsSchema: {
      $schema: 'http://json-schema.org/draft-07/schema#',
      type: 'object',
      properties: {
        host: {
          type: 'string',
        },
        serviceRole: {
          type: 'string',
          input: {
            type: 'password',
          },
        },
      },
      required: ['host', 'serviceRole'],
    },
  },
  {
    id: SourceId.Syncromsp,
    title: 'SyncroMSP',
    subtitle:
      'Integrate with SyncroMSP for managed service provider operations',
    brandColor: '#7C4DFF',
    category: SourceCategoryId.CloudApps,
    type: IntegrationType.Credentials,
    credentialsSchema: {
      $schema: 'http://json-schema.org/draft-07/schema#',
      type: 'object',
      properties: {
        apiKey: {
          type: 'string',
          input: {
            type: 'password',
          },
        },
        subdomain: {
          type: 'string',
        },
      },
      required: ['apiKey', 'subdomain'],
    },
  },
  {
    id: SourceId.Taiga,
    title: 'Taiga',
    subtitle: 'Agile project management platform',
    brandColor: '#D2F6D7',
    category: SourceCategoryId.CloudApps,
    type: IntegrationType.Credentials,
    credentialsSchema: {
      $schema: 'http://json-schema.org/draft-07/schema#',
      type: 'object',
      properties: {
        username: {
          type: 'string',
        },
        password: {
          type: 'string',
          input: {
            type: 'password',
          },
        },
        environment: {
          type: 'string',
          enum: ['cloud', 'selfHosted'],
          default: 'cloud',
        },
        url: {
          type: 'string',
          format: 'uri',
          input: {
            placeholder: 'https://taiga.yourdomain.com',
          },
        },
      },
      required: ['username', 'password', 'environment'],
      dependencies: {
        environment: {
          oneOf: [
            {
              properties: {
                environment: {
                  const: 'cloud',
                },
              },
            },
            {
              properties: {
                environment: {
                  const: 'selfHosted',
                },
                url: {
                  type: 'string',
                },
              },
              required: ['url'],
            },
          ],
        },
      },
    },
  },
  {
    id: SourceId.Tapfiliate,
    title: 'Tapfiliate',
    subtitle: 'Affiliate tracking software for your business',
    brandColor: '#4099ff',
    category: SourceCategoryId.CloudApps,
    type: IntegrationType.Credentials,
    credentialsSchema: {
      $schema: 'http://json-schema.org/draft-07/schema#',
      type: 'object',
      properties: {
        apiKey: {
          type: 'string',
          input: {
            type: 'password',
          },
        },
      },
      required: ['apiKey'],
    },
  },
  {
    id: SourceId.Telegram,
    title: 'Telegram',
    subtitle: 'Send messages and files over Telegram',
    brandColor: '#34AADF',
    category: SourceCategoryId.CloudApps,
    type: IntegrationType.Credentials,
    credentialsSchema: {
      $schema: 'http://json-schema.org/draft-07/schema#',
      type: 'object',
      properties: {
        accessToken: {
          type: 'string',
          input: {
            type: 'password',
          },
        },
      },
      required: ['accessToken'],
    },
  },
  {
    id: SourceId.TheHive,
    title: 'TheHive',
    subtitle: 'Connect to your TheHive instance for security incident response',
    brandColor: '#003B5C',
    category: SourceCategoryId.CloudApps,
    type: IntegrationType.Credentials,
    credentialsSchema: {
      $schema: 'http://json-schema.org/draft-07/schema#',
      type: 'object',
      properties: {
        ApiKey: {
          type: 'string',
          input: {
            type: 'password',
          },
        },
        url: {
          type: 'string',
        },
        apiVersion: {
          type: 'string',
          enum: ['v1', ''],
          default: 'v1',
          description: 'The version of the API to be used',
        },
        allowUnauthorizedCerts: {
          type: 'boolean',
          default: false,
          description:
            'Whether to connect even if SSL certificate validation is not possible',
        },
      },
      required: ['ApiKey', 'url', 'apiVersion'],
    },
  },
  {
    id: SourceId.TheHiveProject,
    title: 'The Hive Project',
    subtitle: 'Connect to your The Hive Project instance',
    brandColor: '#003B5C',
    category: SourceCategoryId.CloudApps,
    type: IntegrationType.Credentials,
    credentialsSchema: {
      $schema: 'http://json-schema.org/draft-07/schema#',
      type: 'object',
      properties: {
        ApiKey: {
          type: 'string',
          input: {
            type: 'password',
          },
        },
        url: {
          type: 'string',
        },
        allowUnauthorizedCerts: {
          type: 'boolean',
        },
      },
      required: ['ApiKey', 'url'],
    },
  },
  {
    id: SourceId.TimescaleDb,
    title: 'TimescaleDB',
    subtitle: 'Add and update data in TimescaleDB',
    brandColor: '#FDB515',
    category: SourceCategoryId.DataWarehouse,
    type: IntegrationType.Credentials,
    credentialsSchema: {
      $schema: 'http://json-schema.org/draft-07/schema#',
      type: 'object',
      properties: {
        host: {
          type: 'string',
        },
        database: {
          type: 'string',
        },
        user: {
          type: 'string',
        },
        password: {
          type: 'string',
          input: {
            type: 'password',
          },
        },
        ssl: {
          type: 'string',
          enum: ['disable', 'allow', 'require', 'verify', 'verify-full'],
          default: 'disable',
        },
        port: {
          type: 'number',
        },
      },
      required: ['host', 'database', 'user', 'password', 'port'],
    },
  },
  {
    id: SourceId.Todoist,
    title: 'Todoist',
    subtitle: 'Organize tasks and projects with Todoist',
    brandColor: '#E44332',
    category: SourceCategoryId.CloudApps,
    type: IntegrationType.Credentials,
    credentialsSchema: {
      $schema: 'http://json-schema.org/draft-07/schema#',
      type: 'object',
      properties: {
        apiKey: {
          type: 'string',
          input: {
            type: 'password',
          },
        },
      },
      required: ['apiKey'],
    },
  },
  {
    id: SourceId.TravisCi,
    title: 'Travis CI',
    subtitle: 'Interact with Travis CI to manage builds',
    brandColor: '#000000',
    category: SourceCategoryId.CloudApps,
    type: IntegrationType.Credentials,
    credentialsSchema: {
      $schema: 'http://json-schema.org/draft-07/schema#',
      type: 'object',
      properties: {
        apiToken: {
          type: 'string',
          input: {
            type: 'password',
          },
        },
      },
      required: ['apiToken'],
    },
  },
  {
    id: SourceId.Trello,
    title: 'Trello',
    subtitle: 'Organize and prioritize your projects in a fun, flexible way',
    brandColor: 'rgba(38, 132, 255, 1)',
    category: SourceCategoryId.CloudApps,
    type: IntegrationType.Credentials,
    credentialsSchema: {
      $schema: 'http://json-schema.org/draft-07/schema#',
      type: 'object',
      properties: {
        apiKey: {
          type: 'string',
        },
        apiToken: {
          type: 'string',
          input: {
            type: 'password',
          },
        },
      },
      required: ['apiKey', 'apiToken'],
    },
  },
  {
    id: SourceId.Twake,
    title: 'Twake',
    subtitle: 'Connect to Twake for collaboration and messaging',
    brandColor: '#675FFF',
    category: SourceCategoryId.CloudApps,
    type: IntegrationType.Credentials,
    credentialsSchema: {
      $schema: 'http://json-schema.org/draft-07/schema#',
      type: 'object',
      properties: {
        workspaceKey: {
          type: 'string',
          input: {
            type: 'password',
          },
        },
      },
      required: ['workspaceKey'],
    },
  },
  {
    id: SourceId.Twilio,
    title: 'Twilio',
    subtitle: 'Send SMS, WhatsApp messages, and make phone calls',
    brandColor: '#F12E45',
    category: SourceCategoryId.CloudApps,
    type: IntegrationType.Credentials,
    credentialsSchema: {
      $schema: 'http://json-schema.org/draft-07/schema#',
      type: 'object',
      properties: {
        authType: {
          type: 'string',
          enum: ['authToken', 'apiKey'],
          default: 'authToken',
        },
        accountSid: {
          type: 'string',
        },
        authToken: {
          type: 'string',
          input: {
            type: 'password',
          },
        },
        apiKeySid: {
          type: 'string',
          input: {
            type: 'password',
          },
        },
        apiKeySecret: {
          type: 'string',
          input: {
            type: 'password',
          },
        },
      },
      required: ['authType', 'accountSid'],
      dependencies: {
        authType: {
          oneOf: [
            {
              properties: {
                authType: {
                  const: 'authToken',
                },
                authToken: {
                  type: 'string',
                },
              },
              required: ['authToken'],
            },
            {
              properties: {
                authType: {
                  const: 'apiKey',
                },
                apiKeySid: {
                  type: 'string',
                },
                apiKeySecret: {
                  type: 'string',
                },
              },
              required: ['apiKeySid', 'apiKeySecret'],
            },
          ],
        },
      },
    },
  },
  {
    id: SourceId.Twist,
    title: 'Twist',
    subtitle: 'Communicate with your team on Twist',
    brandColor: '#008AA6',
    category: SourceCategoryId.CloudApps,
    type: IntegrationType.OAuth2,
  },
  {
    id: SourceId.Twitter,
    title: 'Twitter',
    subtitle: 'Interact with the Twitter API',
    brandColor: '#000000',
    category: SourceCategoryId.CloudApps,
    type: IntegrationType.OAuth2,
  },
  {
    id: SourceId.Uproc,
    title: 'uProc',
    subtitle: 'Data processing and enrichment with uProc',
    brandColor: '#FF6A00',
    category: SourceCategoryId.CloudApps,
    type: IntegrationType.Credentials,
    credentialsSchema: {
      $schema: 'http://json-schema.org/draft-07/schema#',
      type: 'object',
      properties: {
        email: {
          type: 'string',
        },
        apiKey: {
          type: 'string',
          input: {
            type: 'password',
          },
        },
      },
      required: ['email', 'apiKey'],
    },
  },
  {
    id: SourceId.UnleashedSoftware,
    title: 'Unleashed Software',
    subtitle: 'Integrate with Unleashed Software inventory management',
    brandColor: '#FF851B',
    category: SourceCategoryId.CloudApps,
    type: IntegrationType.Credentials,
    credentialsSchema: {
      $schema: 'http://json-schema.org/draft-07/schema#',
      type: 'object',
      properties: {
        apiId: {
          type: 'string',
        },
        apiKey: {
          type: 'string',
          input: {
            type: 'password',
          },
        },
      },
      required: ['apiId', 'apiKey'],
    },
  },
  {
    id: SourceId.Uplead,
    title: 'Uplead',
    subtitle: 'Access Uplead for company and person data enrichment',
    brandColor: '#ff6a00',
    category: SourceCategoryId.CloudApps,
    type: IntegrationType.Credentials,
    credentialsSchema: {
      $schema: 'http://json-schema.org/draft-07/schema#',
      type: 'object',
      properties: {
        apiKey: {
          type: 'string',
          input: {
            type: 'password',
          },
        },
      },
      required: ['apiKey'],
    },
  },
  {
    id: SourceId.Uptimerobot,
    title: 'UptimeRobot',
    subtitle: 'Monitor your websites and services uptime',
    brandColor: '#F05A28',
    category: SourceCategoryId.CloudApps,
    type: IntegrationType.Credentials,
    credentialsSchema: {
      $schema: 'http://json-schema.org/draft-07/schema#',
      type: 'object',
      properties: {
        apiKey: {
          type: 'string',
          input: {
            type: 'password',
          },
        },
      },
      required: ['apiKey'],
    },
  },
  {
    id: SourceId.Urlscanio,
    title: 'urlscan.io',
    subtitle: 'Monitor and scan websites with urlscan.io',
    brandColor: '#E35946',
    category: SourceCategoryId.CloudApps,
    type: IntegrationType.Credentials,
    credentialsSchema: {
      $schema: 'http://json-schema.org/draft-07/schema#',
      type: 'object',
      properties: {
        apiKey: {
          type: 'string',
          input: {
            type: 'password',
          },
        },
      },
      required: ['apiKey'],
    },
  },
  {
    id: SourceId.Vero,
    title: 'Vero',
    subtitle: 'Consume Vero API to manage users and track events',
    brandColor: '#D4F0FF',
    category: SourceCategoryId.CloudApps,
    type: IntegrationType.Credentials,
    credentialsSchema: {
      $schema: 'http://json-schema.org/draft-07/schema#',
      type: 'object',
      properties: {
        authToken: {
          type: 'string',
          input: {
            type: 'password',
          },
        },
      },
      required: ['authToken'],
    },
  },
  {
    id: SourceId.Vonage,
    title: 'Vonage',
    subtitle: 'Send SMS with Vonage API',
    brandColor: '#ffffff',
    category: SourceCategoryId.CloudApps,
    type: IntegrationType.Credentials,
    credentialsSchema: {
      $schema: 'http://json-schema.org/draft-07/schema#',
      type: 'object',
      properties: {
        apiKey: {
          type: 'string',
        },
        apiSecret: {
          type: 'string',
          input: {
            type: 'password',
          },
        },
      },
      required: ['apiKey', 'apiSecret'],
    },
  },
  {
    id: SourceId.Webflow,
    title: 'Webflow',
    subtitle: 'Design, build, and launch responsive websites visually',
    brandColor: '#4353ff',
    category: SourceCategoryId.CloudApps,
    type: IntegrationType.Credentials,
    credentialsSchema: {
      $schema: 'http://json-schema.org/draft-07/schema#',
      type: 'object',
      properties: {
        accessToken: {
          type: 'string',
          input: {
            type: 'password',
          },
        },
      },
      required: ['accessToken'],
    },
  },
  {
    id: SourceId.Wekan,
    title: 'Wekan',
    subtitle: 'Collaborate with your team using Wekan boards',
    brandColor: '#00C875',
    category: SourceCategoryId.CloudApps,
    type: IntegrationType.Credentials,
    credentialsSchema: {
      $schema: 'http://json-schema.org/draft-07/schema#',
      type: 'object',
      properties: {
        username: {
          type: 'string',
        },
        password: {
          type: 'string',
          input: {
            type: 'password',
          },
        },
        url: {
          type: 'string',
        },
      },
      required: ['username', 'password', 'url'],
    },
  },
  {
    id: SourceId.WhatsAppBusinessCloud,
    title: 'WhatsApp Business Cloud',
    subtitle: 'Send messages and media over WhatsApp Business Cloud API',
    brandColor: '#27D045',
    category: SourceCategoryId.CloudApps,
    type: IntegrationType.Credentials,
    credentialsSchema: {
      $schema: 'http://json-schema.org/draft-07/schema#',
      type: 'object',
      properties: {
        accessToken: {
          type: 'string',
          input: {
            type: 'password',
          },
        },
        businessAccountId: {
          type: 'string',
        },
      },
      required: ['accessToken', 'businessAccountId'],
    },
  },
  {
    id: SourceId.Wise,
    title: 'Wise',
    subtitle: 'Execute international money transfers with Wise',
    brandColor: '#9FE870',
    category: SourceCategoryId.CloudApps,
    type: IntegrationType.Credentials,
    credentialsSchema: {
      $schema: 'http://json-schema.org/draft-07/schema#',
      type: 'object',
      properties: {
        apiToken: {
          type: 'string',
          input: {
            type: 'password',
          },
        },
        environment: {
          type: 'string',
          enum: ['live', 'test'],
          default: 'live',
        },
        privateKey: {
          type: 'string',
          input: {
            type: 'password',
          },
        },
      },
      required: ['apiToken', 'environment'],
    },
  },
  {
    id: SourceId.WooCommerce,
    title: 'WooCommerce',
    subtitle: 'Connect to your WooCommerce store',
    brandColor: '#7F54B3',
    category: SourceCategoryId.CloudApps,
    type: IntegrationType.Credentials,
    credentialsSchema: {
      $schema: 'http://json-schema.org/draft-07/schema#',
      type: 'object',
      properties: {
        consumerKey: {
          type: 'string',
        },
        consumerSecret: {
          type: 'string',
        },
        url: {
          type: 'string',
        },
        includeCredentialsInQuery: {
          type: 'boolean',
        },
      },
      required: ['consumerKey', 'consumerSecret', 'url'],
    },
  },
  {
    id: SourceId.Wordpress,
    title: 'Wordpress',
    subtitle: "Interact with your Wordpress site's API",
    brandColor: '#21759B',
    category: SourceCategoryId.CloudApps,
    type: IntegrationType.Credentials,
    credentialsSchema: {
      $schema: 'http://json-schema.org/draft-07/schema#',
      type: 'object',
      properties: {
        username: {
          type: 'string',
        },
        password: {
          type: 'string',
          input: {
            type: 'password',
          },
        },
        url: {
          type: 'string',
        },
        allowUnauthorizedCerts: {
          type: 'boolean',
        },
      },
      required: ['username', 'password', 'url'],
    },
  },
  {
    id: SourceId.Yourls,
    title: 'Yourls',
    subtitle: 'Shorten and expand URLs using Yourls',
    brandColor: '#ff6700',
    category: SourceCategoryId.CloudApps,
    type: IntegrationType.Credentials,
    credentialsSchema: {
      $schema: 'http://json-schema.org/draft-07/schema#',
      type: 'object',
      properties: {
        signature: {
          type: 'string',
        },
        url: {
          type: 'string',
        },
      },
      required: ['signature', 'url'],
    },
  },
  {
    id: SourceId.Zammad,
    title: 'Zammad',
    subtitle: 'Connect to your Zammad helpdesk platform',
    brandColor: '#ff4600',
    category: SourceCategoryId.CloudApps,
    type: IntegrationType.Credentials,
    credentialsSchema: {
      $schema: 'http://json-schema.org/draft-07/schema#',
      type: 'object',
      properties: {
        baseUrl: {
          type: 'string',
        },
        authentication: {
          type: 'string',
          enum: ['basicAuth', 'tokenAuth'],
        },
        username: {
          type: 'string',
        },
        password: {
          type: 'string',
          input: {
            type: 'password',
          },
        },
        accessToken: {
          type: 'string',
          input: {
            type: 'password',
          },
        },
        allowUnauthorizedCerts: {
          type: 'boolean',
        },
      },
      required: ['baseUrl', 'authentication'],
      dependencies: {
        authentication: {
          oneOf: [
            {
              properties: {
                authentication: {
                  const: 'basicAuth',
                },
                username: {
                  type: 'string',
                },
                password: {
                  type: 'string',
                  input: {
                    type: 'password',
                  },
                },
              },
              required: ['username', 'password'],
            },
            {
              properties: {
                authentication: {
                  const: 'tokenAuth',
                },
                accessToken: {
                  type: 'string',
                  input: {
                    type: 'password',
                  },
                },
              },
              required: ['accessToken'],
            },
          ],
        },
      },
    },
  },
  {
    id: SourceId.Zendesk,
    title: 'Zendesk',
    subtitle: 'Customer service and engagement platform',
    brandColor: '#045662',
    category: SourceCategoryId.CloudApps,
    type: IntegrationType.Credentials,
    credentialsSchema: {
      $schema: 'http://json-schema.org/draft-07/schema#',
      type: 'object',
      properties: {
        subdomain: {
          type: 'string',
          description: 'The subdomain of your Zendesk work environment',
        },
        email: {
          type: 'string',
          description: 'Your Zendesk account email',
        },
        apiToken: {
          type: 'string',
          description: 'Your Zendesk API token',
        },
      },
      required: ['subdomain', 'email', 'apiToken'],
    },
  },
  {
    id: SourceId.ZohoCrm,
    title: 'Zoho CRM',
    subtitle: 'Integrate with Zoho CRM to manage your customer relationships',
    brandColor: '#005CE3',
    category: SourceCategoryId.CloudApps,
    type: IntegrationType.OAuth2,
  },
  {
    id: SourceId.Zulip,
    title: 'Zulip',
    subtitle: 'Connect to your Zulip chat server',
    brandColor: '#5096FF',
    category: SourceCategoryId.CloudApps,
    type: IntegrationType.Credentials,
    credentialsSchema: {
      $schema: 'http://json-schema.org/draft-07/schema#',
      type: 'object',
      properties: {
        url: {
          type: 'string',
        },
        email: {
          type: 'string',
        },
        apiKey: {
          type: 'string',
          input: {
            type: 'password',
          },
        },
      },
      required: ['url', 'email', 'apiKey'],
    },
  }
];