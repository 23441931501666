import React from 'react';
import SvgIcon, { SvgIconProps } from '../SvgIcon';

export const SplunkIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props} style={{ fill: 'transparent' }} viewBox="0 0 15 17">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 0V3.21429L12.8571 7.5L0 13.0757V16.0714L15 9.64286V5.35714L0 0Z"
      fill="currentColor"
    />
  </SvgIcon>
);
