import React from 'react';
import SvgIcon, { SvgIconProps } from '../SvgIcon';

export const PipedriveIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props} style={{ fill: 'transparent' }} viewBox="-5 -4 24 24">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.6747 1.52139C6.22943 0.900198 7.37935 0 9.36598 0C12.7805 0 15.0764 2.7019 15.0764 6.72383C15.0764 10.6826 12.6578 13.4464 9.20015 13.4464C7.55165 13.4464 6.4957 12.7409 5.94358 12.2303C5.9475 12.3514 5.9501 12.4869 5.9501 12.6317V18H2.41288V3.69688C2.41288 3.48891 2.34631 3.42316 2.14008 3.42316H0.923584V0.290854H3.89172C5.25832 0.290854 5.60813 0.985742 5.6747 1.52139ZM8.69505 3.15725C7.33367 3.15725 5.87832 4.11276 5.87832 6.79621C5.87832 8.50053 6.7437 10.3391 8.64804 10.3391C10.0603 10.3391 11.4883 9.23757 11.4883 6.77126C11.4883 4.60892 10.3658 3.15725 8.69505 3.15725Z"
      fill="white"
    />
  </SvgIcon>
);
