import MainSidebar from "@/components/custom/sidebar";
import { Button } from "@/components/custom/button";
import { PlusIcon } from "@/components/custom/icons";

export default function DemoProofPage() {
  return (
    <main>
      <MainSidebar
        pageTitle="Proof"
        shouldHideActionButton
        toolbar={
          <Button color="dark" className="gap-2 flex items-center">
            <img src={PlusIcon} className="w-4 h-4" />
            Add proof
          </Button>
        }
      >
        <div className="text-cell px-8 py-5">
        </div>
      </MainSidebar>
    </main>
  )
}
