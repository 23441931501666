import { Avatar } from "../cataylst/avatar";

interface IAvatarGroup {
  srcs: string[];
};

export default function AvatarGroup({ srcs }: IAvatarGroup) {
  const getMarginLeft = (index: number) => {
    if (!index || srcs.length <= 1)
      return 0;
    return -4;
  };
  return (
    <div className="flex">
      {srcs.map((src, index) => (
        <div style={{ marginLeft: `${getMarginLeft(index)}px` }} className="flex items-center">
          <Avatar key={index} square={false} src={src} outline={false} className="size-4" />
        </div>
      ))}
    </div>
  )
}