import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";

export default function useAuthRedirect() {
  const { isAuthenticated, isLoading, loginWithRedirect, getAccessTokenSilently } = useAuth0();
  const [accessToken, setAccessToken] = useState('');

  useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      loginWithRedirect({ authorizationParams: { prompt: 'login' } });
    }
    if (isAuthenticated) {
      fetchAccessToken();
    }
  }, [isAuthenticated, isLoading]);

  const fetchAccessToken = async () => {
    const token = await getAccessTokenSilently();
    setAccessToken(token);
  }

  return {
    isAuthenticated,
    isLoading,
    accessToken
  };
}