import React from 'react';
import SvgIcon, { SvgIconProps } from '../SvgIcon';

export const SupabaseIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props} style={{ fill: 'transparent' }} viewBox="0 0 22 22">
    <g clipPath="url(#clip0_supabase)">
      <path
        d="M12.7722 21.4615C12.2212 22.1555 11.1039 21.7753 11.0906 20.8892L10.8965 7.92957H19.6105C21.1889 7.92957 22.0692 9.75258 21.0877 10.9887L12.7722 21.4615Z"
        fill="url(#paint0_supabase)"
      />
      <path
        d="M12.7722 21.4615C12.2212 22.1555 11.1039 21.7753 11.0906 20.8892L10.8965 7.92957H19.6105C21.1889 7.92957 22.0692 9.75258 21.0877 10.9887L12.7722 21.4615Z"
        fill="url(#paint1_supabase)"
        fillOpacity="0.2"
      />
      <path
        d="M9.22805 0.608402C9.77908 -0.0856049 10.8964 0.29466 10.9097 1.18073L10.9947 14.1403H2.38975C0.811357 14.1403 -0.0689429 12.3173 0.91255 11.0812L9.22805 0.608402Z"
        fill="#3ECF8E"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_supabase"
        x1="232.109"
        y1="295.277"
        x2="731.782"
        y2="504.839"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#249361" />
        <stop offset="1" stopColor="#3ECF8E" />
      </linearGradient>
      <linearGradient
        id="paint1_supabase"
        x1="32.0103"
        y1="-174.845"
        x2="352.777"
        y2="428.981"
        gradientUnits="userSpaceOnUse"
      >
        <stop />
        <stop offset="1" stopOpacity="0" />
      </linearGradient>
      <clipPath id="clip0_supabase">
        <rect
          width="21"
          height="21.5742"
          fill="white"
          transform="translate(0.5 0.25)"
        />
      </clipPath>
    </defs>
  </SvgIcon>
);
