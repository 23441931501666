import { Avatar } from "@/components/cataylst/avatar";
import { Badge } from "@/components/cataylst/badge";
import { Checkbox } from "@/components/cataylst/checkbox";
import { Input, InputGroup } from "@/components/cataylst/input";
import { Link } from "@/components/cataylst/link";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/cataylst/table";
import { Button } from "@/components/custom/button";
import { AIStarIcon, KanbanIcon, SearchIcon } from "@/components/custom/icons";
import { MenuIcon } from "@/components/custom/menu-icon";
import MainSidebar from "@/components/custom/sidebar";
import { formatDateTime } from "@/utils/format";
import { AdjustmentsHorizontalIcon, ChevronDownIcon, EllipsisHorizontalIcon, ListBulletIcon } from "@heroicons/react/16/solid";
import { useState } from "react";
import MetaIcon from '@/assets/demo/meta.png';
import SarahIcon from '@/assets/demo/sarah.png';
import { ProgressBar } from "@/components/custom/progressbar";
import { Dropdown, DropdownButton, DropdownItem, DropdownMenu } from "@/components/cataylst/dropdown";
import { DocumentPlusIcon } from "@heroicons/react/24/outline";
import { useContactList } from "@/hooks/useContactList";
import { Pagination, PaginationGap, PaginationList, PaginationNext, PaginationPage, PaginationPrevious } from "@/components/cataylst/pagination";
import Loading from "@/components/custom/loading";

const mockCustomers = [
  {
    id: '0',
    name: 'Sarah Hughes',
    avatar: SarahIcon,
    role: 'Developer',
    company: {
      name: 'Meta',
      logo: MetaIcon
    },
    tags: ['react', 'llm'],
    lastInteractionDate: new Date(),
    engagementScore: 50,
    salesRep: {
      avatar: SarahIcon,
      name: 'Sarah Hughes'
    }
  }
];

export default function DemoAudiencePage() {
  const [searchKeyword, setSearchKeyword] = useState("");
  const { data: contacts, isLoading } = useContactList();

  const onSearchCustomers = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchKeyword(event.target.value);
  };

  const filterCustomers = () => {
    return contacts;
    // const customers: any = [];
    // for (let i = 0; i < 10; i++) {
    //   for (let j = 0; j < mockCustomers.length; j++) {
    //     const customer = {...mockCustomers[j], id: i * 100 + j};
    //     customers.push(customer);
    //   }
    // }
    // return customers.filter(customer => customer.name.toLowerCase().includes(keyword.toLowerCase()));
  };

  return (
    <main>
      <MainSidebar>
        <div className="text-cell px-8 py-5">
          <div className="mb-5 flex justify-between">
            <div className="w-[250px]">
              <InputGroup>
                <MenuIcon src={AIStarIcon} data-slot="icon" className="!size-4" />
                <Input placeholder="Ask Bloom AI..." className="h-9 pl-9 pr-2 !rounded-full" />
              </InputGroup>
            </div>
            <div className="flex gap-2">
              <div className="w-[250px]">
                <InputGroup>
                  <MenuIcon src={SearchIcon} data-slot="icon" className="!size-4" />
                  <Input placeholder="Search contacts" className="h-9 pl-9 pr-2 !rounded-full" onChange={onSearchCustomers} />
                </InputGroup>
              </div>
              <Button color="white">
                <AdjustmentsHorizontalIcon color="black" height={16} />
                Filter
              </Button>
            </div>
            <div className="flex gap-2">
              <Button color="white">
                <ListBulletIcon color="black" height={16} />
                List
              </Button>
              <Button color="white" disabled={true}>
                <MenuIcon className="!size-4" src={KanbanIcon} data-slot="icon" />
                Kanban
              </Button>
              <Dropdown>
                <DropdownButton outline>
                  <EllipsisHorizontalIcon height={16} />
                </DropdownButton>
                <DropdownMenu className="min-w-48">
                  <DropdownItem>Update contact information</DropdownItem>
                  <DropdownItem>Assign next best actions</DropdownItem>
                  <DropdownItem>Export</DropdownItem>
                  <DropdownItem>AI analysis</DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </div>
          </div>
          <Table grid dense>
            <TableHead>
              <TableRow>
                <TableHeader>
                  <div className="flex gap-4 items-center">
                    <Checkbox />
                    <span>Name</span>
                  </div>
                </TableHeader>
                <TableHeader>Role</TableHeader>
                <TableHeader>Company</TableHeader>
                <TableHeader>Tags</TableHeader>
                <TableHeader>Last interaction</TableHeader>
                <TableHeader>Engagement score</TableHeader>
                <TableHeader>Sales rep</TableHeader>
                <TableHeader>Next action</TableHeader>
                <TableHeader>Suggested completion</TableHeader>
                <TableHeader>Action</TableHeader>
              </TableRow>
            </TableHead>
            <TableBody>
              {filterCustomers().map((customer) => (
                <TableRow key={customer.id}>
                  <TableCell>
                    <div className="flex gap-4 items-center">
                      <Checkbox />
                      <Link className="flex gap-2 items-center" href="/customers/contacts/sarah/tasks-notes">
                        <Avatar square={false} src={customer.avatar} outline={false} className="size-4" />
                        <div className="underline">{customer.name}</div>
                      </Link>
                    </div>
                  </TableCell>
                  <TableCell>
                    {customer.role}
                  </TableCell>
                  <TableCell>
                    <Link className="flex gap-2 items-center" href="#">
                      <Avatar square={false} src={customer.company.logo} outline={false} className="size-4" />
                      <div className="underline">{customer.company.name}</div>
                    </Link>
                  </TableCell>
                  <TableCell>
                    <div className="flex flex-wrap gap-2">
                      {customer.tags.map(tag => (
                        <Badge color="blue" key={tag}>{tag}</Badge>
                      ))}
                    </div>
                  </TableCell>
                  <TableCell>
                    {formatDateTime(customer.lastInteractionDate)}
                  </TableCell>
                  <TableCell>
                    <ProgressBar value={50}>{customer.engagementScore}%</ProgressBar>
                  </TableCell>
                  <TableCell>
                    <div className="flex gap-2 items-center">
                      <Avatar square={false} src={customer.salesRep.avatar} outline={false} className="size-4" />
                      <div>{customer.salesRep.name}</div>
                      <ChevronDownIcon className="w-4 h-4" />
                    </div>
                  </TableCell>
                  <TableCell>
                    <Link className="flex gap-1 items-center" href="#">
                      <DocumentPlusIcon width={16} height={16} />
                      <div className="underline">Email</div>
                    </Link>
                  </TableCell>
                  <TableCell>
                    {formatDateTime(new Date())}
                  </TableCell>
                  <TableCell>
                    <Dropdown>
                      <DropdownButton outline>
                        <EllipsisHorizontalIcon height={16} />
                      </DropdownButton>
                      <DropdownMenu className="min-w-48">
                        <DropdownItem className="underline">Edit Details</DropdownItem>
                        <DropdownItem className="underline">Add Note</DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Pagination className="mt-6">
            <PaginationPrevious href="?page=2" />
            <PaginationList>
              <PaginationPage href="?page=1">1</PaginationPage>
              <PaginationPage href="?page=2">2</PaginationPage>
              <PaginationPage href="?page=3" current>
                3
              </PaginationPage>
              <PaginationPage href="?page=4">4</PaginationPage>
              <PaginationGap />
              <PaginationPage href="?page=65">65</PaginationPage>
              <PaginationPage href="?page=66">66</PaginationPage>
            </PaginationList>
            <PaginationNext href="?page=4" />
          </Pagination>
          {isLoading && (
            <div className="w-full h-full mt-4">
              <Loading />
            </div>
          )}
        </div>
      </MainSidebar>
    </main>
  )
}