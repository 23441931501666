import MainSidebar from "@/components/custom/sidebar";
import { Button } from "@/components/custom/button";
import { Textarea } from "@/components/cataylst/textarea";
import { PlusIcon, SearchIcon } from "@/components/custom/icons";
import { Input, InputGroup } from "@/components/cataylst/input";
import { MenuIcon } from "@/components/custom/menu-icon";
import { ReactNode, useState } from "react";
import { useNavigate } from "react-router-dom";

export interface IPersona {
  id: number;
  title: string;
  description: string;
  icon: ReactNode;
  primaryResponsibilities: string[];
  painPoints: string[];
  concerns: string[];
  objectives: string[];
}

interface IPersonaItemProps {
  persona: IPersona;
  onClick?: Function;
  borderTop?: boolean;
}

function PersonaItem({ persona: { title, description, icon }, borderTop, onClick }: IPersonaItemProps) {
  return (
    <div className={`flex items-center justify-between pr-2 py-4 font-firstext ${borderTop && 'border-t border-solid border-t-gray-200'} border-b border-b-gray-200 cursor-pointer`} onClick={() => ((onClick || (() => {})))()}>
      <div className="flex gap-3 items-center w-[90%]">
        <div className="flex-1">
          {icon}
        </div>
        <div className="flex flex-col gap-1 flex-1 max-w-full">
          <div className="font-semibold text-sm leading-[17.5px]">
            {title}
          </div>
          <div className="text-sm leading-[21px] opacity-60 pr-10 truncate">
            {description}
          </div>
        </div>
      </div>
      <div>
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M5.96967 3.46967C6.26256 3.17678 6.73744 3.17678 7.03033 3.46967L11.0303 7.46967C11.3232 7.76256 11.3232 8.23744 11.0303 8.53033L7.03033 12.5303C6.73744 12.8232 6.26256 12.8232 5.96967 12.5303C5.67678 12.2374 5.67678 11.7626 5.96967 11.4697L9.43934 8L5.96967 4.53033C5.67678 4.23744 5.67678 3.76256 5.96967 3.46967Z" fill="black" fill-opacity="0.4"/>
        </svg>
      </div>
    </div>
  )
}

export const mockPersonas: IPersona[] = [
  {
    id: 1,
    title: 'Chief Revenue Officer',
    description: `The Chief Revenue Officer (CRO) is a senior executive responsible for driving sustainable revenue growth across all customer-facing functions of an organization. They oversee the integration and alignment of sales, marketing, and customer success strategies to maximize revenue potential and market share. The CRO's performance is typically measured by metrics such as revenue growth, customer acquisition costs, and overall business profitability.`,
    icon: <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16Z" fill="#106EF6"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M16 10.75C13.1005 10.75 10.75 13.1005 10.75 16C10.75 17.3498 11.2594 18.5806 12.0964 19.5107C12.7023 18.7279 13.6455 18.25 14.6667 18.25H17.3333C18.3545 18.25 19.2976 18.7279 19.9036 19.5107C20.7406 18.5806 21.25 17.3498 21.25 16C21.25 13.1005 18.8995 10.75 16 10.75ZM18.7503 20.4728C18.4259 20.0253 17.902 19.75 17.3333 19.75H14.6667C14.0979 19.75 13.5741 20.0253 13.2497 20.4728C14.0495 20.9657 14.9916 21.25 16 21.25C17.0084 21.25 17.9504 20.9657 18.7503 20.4728ZM9.25 16C9.25 12.2721 12.2721 9.25 16 9.25C19.7279 9.25 22.75 12.2721 22.75 16C22.75 19.7279 19.7279 22.75 16 22.75C12.2721 22.75 9.25 19.7279 9.25 16ZM16 13.4167C15.3096 13.4167 14.75 13.9763 14.75 14.6667C14.75 15.357 15.3096 15.9167 16 15.9167C16.6904 15.9167 17.25 15.357 17.25 14.6667C17.25 13.9763 16.6904 13.4167 16 13.4167ZM13.25 14.6667C13.25 13.1479 14.4812 11.9167 16 11.9167C17.5188 11.9167 18.75 13.1479 18.75 14.6667C18.75 16.1855 17.5188 17.4167 16 17.4167C14.4812 17.4167 13.25 16.1855 13.25 14.6667Z" fill="white"/>
    </svg>,
    primaryResponsibilities: [
      `Analyze market trends, competitor activities, and customer feedback to identify new revenue opportunities and optimize existing channels.`,
      'Orchestrate seamless collaboration between sales, marketing, and customer success teams to create a unified revenue generation ecosystem.',
      'Analyze market trends, competitor activities, and customer feedback to identify new revenue opportunities and optimize existing channels.'
    ],
    painPoints: [
      `Fragmented and siloed data across various revenue-generating departments, hindering a unified view of the customer and revenue performance.`,
      `Difficulty in accurately attributing revenue to specific marketing and sales activities, leading to suboptimal resource allocation.`,
      `Challenges in maintaining sales team productivity and motivation in increasingly complex, multi-channel selling environments.`,
      `Struggle to keep pace with rapidly changing market dynamics and customer expectations while ensuring consistent revenue growth.`
    ],
    concerns: [
      `Ensuring consistent revenue growth in increasingly competitive and dynamic market environments.`,
      `Adapting revenue strategies to rapidly evolving customer behaviors and preferences in the digital age.`,
      `Optimizing the efficiency and effectiveness of revenue operations to maximize profitability and return on investment.`,
      `Maintaining a balance between short-term revenue targets and long-term sustainable growth initiatives.`
    ],
    objectives: [
      `Implement data-driven decision-making processes across all revenue functions to enhance forecasting accuracy and strategic planning.`,
      `Foster a culture of innovation within revenue teams to develop new products, services, or market approaches that drive competitive advantage.`,
      `Streamline the customer journey across all touchpoints to improve conversion rates and customer lifetime value.`,
      `Develop and nurture strategic partnerships and alliances that expand market reach and create new revenue streams.`
    ]
  },
  {
    id: 2,
    title: `Vice President of Sales`,
    description: `The Vice President of Sales is a high-level executive responsible for driving revenue growth and managing the overall sales strategy of an organization. This role oversees the entire sales department, aligning sales objectives with broader business goals and ensuring consistent performance across all sales channels. The success of a VP of Sales is primarily measured by their ability to meet or exceed revenue targets, expand market share, and develop a high-performing sales team.`,
    icon: <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16Z" fill="#00B453"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M16 10.75C13.1005 10.75 10.75 13.1005 10.75 16C10.75 17.3498 11.2594 18.5806 12.0964 19.5107C12.7023 18.7279 13.6455 18.25 14.6667 18.25H17.3333C18.3545 18.25 19.2976 18.7279 19.9036 19.5107C20.7406 18.5806 21.25 17.3498 21.25 16C21.25 13.1005 18.8995 10.75 16 10.75ZM18.7503 20.4728C18.4259 20.0253 17.902 19.75 17.3333 19.75H14.6667C14.0979 19.75 13.5741 20.0253 13.2497 20.4728C14.0495 20.9657 14.9916 21.25 16 21.25C17.0084 21.25 17.9504 20.9657 18.7503 20.4728ZM9.25 16C9.25 12.2721 12.2721 9.25 16 9.25C19.7279 9.25 22.75 12.2721 22.75 16C22.75 19.7279 19.7279 22.75 16 22.75C12.2721 22.75 9.25 19.7279 9.25 16ZM16 13.4167C15.3096 13.4167 14.75 13.9763 14.75 14.6667C14.75 15.357 15.3096 15.9167 16 15.9167C16.6904 15.9167 17.25 15.357 17.25 14.6667C17.25 13.9763 16.6904 13.4167 16 13.4167ZM13.25 14.6667C13.25 13.1479 14.4812 11.9167 16 11.9167C17.5188 11.9167 18.75 13.1479 18.75 14.6667C18.75 16.1855 17.5188 17.4167 16 17.4167C14.4812 17.4167 13.25 16.1855 13.25 14.6667Z" fill="white"/>
    </svg>,
    concerns: [],
    objectives: [],
    painPoints: [],
    primaryResponsibilities: []
  },
  {
    id: 3,
    title: `Chief Marketing Officer`,
    description: `The Chief Marketing Officer (CMO) is a senior executive responsible for developing and executing comprehensive marketing strategies to drive brand awareness, customer acquisition, and revenue growth. They oversee all marketing functions, including digital marketing, brand management, product marketing, and customer experience, while aligning marketing initiatives with overall business objectives. The CMO's performance is measured by key metrics such as market share, customer engagement, marketing ROI, and contribution to revenue growth.`,
    icon: <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16Z" fill="#7A3DFA"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M16 10.75C13.1005 10.75 10.75 13.1005 10.75 16C10.75 17.3498 11.2594 18.5806 12.0964 19.5107C12.7023 18.7279 13.6455 18.25 14.6667 18.25H17.3333C18.3545 18.25 19.2976 18.7279 19.9036 19.5107C20.7406 18.5806 21.25 17.3498 21.25 16C21.25 13.1005 18.8995 10.75 16 10.75ZM18.7503 20.4728C18.4259 20.0253 17.902 19.75 17.3333 19.75H14.6667C14.0979 19.75 13.5741 20.0253 13.2497 20.4728C14.0495 20.9657 14.9916 21.25 16 21.25C17.0084 21.25 17.9504 20.9657 18.7503 20.4728ZM9.25 16C9.25 12.2721 12.2721 9.25 16 9.25C19.7279 9.25 22.75 12.2721 22.75 16C22.75 19.7279 19.7279 22.75 16 22.75C12.2721 22.75 9.25 19.7279 9.25 16ZM16 13.4167C15.3096 13.4167 14.75 13.9763 14.75 14.6667C14.75 15.357 15.3096 15.9167 16 15.9167C16.6904 15.9167 17.25 15.357 17.25 14.6667C17.25 13.9763 16.6904 13.4167 16 13.4167ZM13.25 14.6667C13.25 13.1479 14.4812 11.9167 16 11.9167C17.5188 11.9167 18.75 13.1479 18.75 14.6667C18.75 16.1855 17.5188 17.4167 16 17.4167C14.4812 17.4167 13.25 16.1855 13.25 14.6667Z" fill="white"/>
    </svg>,
    concerns: [],
    objectives: [],
    painPoints: [],
    primaryResponsibilities: []
  },
]

export default function DemoPersonasPage() {
  const [searchKeyword, setSearchKeyword] = useState('');
  const navigate = useNavigate();
  const onSearchCompanies = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchKeyword(event.target.value);
  };

  const onAddPersona = () => {
    navigate('/settings/personas/new');
  };
  const onEditPersona = (persona: IPersona) => {
    navigate(`/settings/personas/${persona.id}`);
  };

  return (
    <main>
      <MainSidebar
        pageTitle="Personas"
        shouldHideActionButton
        toolbar={
          <Button color="dark" className="gap-2 flex items-center">
            <img src={PlusIcon} className="w-4 h-4" />
            Add persona
          </Button>
        }
      >
        <div className="text-cell px-8 py-5">
          <div className="flex flex-col gap-2 align-center">
            <div className="w-[250px]">
              <InputGroup>
                <MenuIcon
                  src={SearchIcon}
                  data-slot="icon"
                  className="!size-4"
                />
                <Input
                  placeholder="Search personas"
                  className="h-9 pl-9 pr-2 !rounded-full border-none !bg-[#F2F2F2]"
                  onChange={onSearchCompanies}
                  value={searchKeyword}
                  color=''
                />
              </InputGroup>
            </div>
          </div>
          <div className="mt-5">
            {mockPersonas.map((persona, index) => (
              <PersonaItem persona={persona} key={persona.id} borderTop={index === 0} onClick={() => onEditPersona(persona)} />
            ))}
          </div>
          <div
            className="w-full border mt-5 border-[#00000022] text-[#00000099] border-dashed rounded-lg px-4 h-[42px] flex gap-1.5 items-center text-sm cursor-pointer"
            onClick={onAddPersona}
          >
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M8.75003 3.33331C8.75003 2.9191 8.41424 2.58331 8.00003 2.58331C7.58582 2.58331 7.25003 2.9191 7.25003 3.33331V7.25H3.33337C2.91916 7.25 2.58337 7.58579 2.58337 8C2.58337 8.41421 2.91916 8.75 3.33337 8.75H7.25003V12.6666C7.25003 13.0809 7.58582 13.4166 8.00003 13.4166C8.41424 13.4166 8.75003 13.0809 8.75003 12.6666V8.75H12.6667C13.0809 8.75 13.4167 8.41421 13.4167 8C13.4167 7.58579 13.0809 7.25 12.6667 7.25H8.75003V3.33331Z"
                fill="black"
                fill-opacity="0.6"
              />
            </svg>
            <span>Add persona</span>
          </div>
        </div>
      </MainSidebar>
    </main>
  )
}
