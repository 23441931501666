import React from 'react';
import SvgIcon, { SvgIconProps } from '../SvgIcon';

export const ChargebeeIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon
    {...props}
    style={{ ...props.style, fill: 'transparent' }}
    viewBox="0 0 18 19"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.96556 9.19591L18.0003 6.29857V0H11.7602L5.96556 9.19591ZM5.96556 9.19591L11.7602 18.3903H18.0003V12.0917L5.96556 9.19591ZM0 9.08494C0 9.83776 0.0914792 10.5696 0.26244 11.2699L5.96215 9.19514L5.96242 9.19557L2.12245 15.1594C2.1223 15.1592 2.12216 15.159 2.12201 15.1588V15.157H2.12051C2.12101 15.1576 2.12151 15.1582 2.12201 15.1588V15.16L2.12245 15.1594C3.51553 16.8257 5.49129 17.9781 7.73374 18.2958L5.96414 9.19291L5.96335 9.19412L5.96301 9.194L7.73374 0.0899738C5.49025 0.407901 3.5137 1.56114 2.12051 3.22876L5.96092 9.19324L0.215951 7.10089C0.0764827 7.73824 0 8.40259 0 9.08194V9.08494ZM5.96287 9.19487L5.96284 9.19489L5.96282 9.19495L5.96287 9.19487Z"
      fill="currentColor"
    />
  </SvgIcon>
);
