import { Avatar } from "@/components/cataylst/avatar";
import { Badge } from "@/components/cataylst/badge";
import { Checkbox } from "@/components/cataylst/checkbox";
import { Input, InputGroup } from "@/components/cataylst/input";
import { Link } from "@/components/cataylst/link";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/cataylst/table";
import { Button } from "@/components/custom/button";
import {
  AIStarIcon,
  CalendarIcon,
  PlusIcon,
  SearchIcon,
} from "@/components/custom/icons";
import { MenuIcon } from "@/components/custom/menu-icon";
import MainSidebar from "@/components/custom/sidebar";
import { useEffect, useState } from "react";
import MetaIcon from "@/assets/demo/meta.png";
import { ProgressBar } from "@/components/custom/progressbar";
import { TooltipClaim } from "@/components/custom/tooltip-claim";
import {
  Pagination,
  PaginationGap,
  PaginationList,
  PaginationNext,
  PaginationPage,
  PaginationPrevious,
} from "@/components/cataylst/pagination";
import Loading from "@/components/custom/loading";
import {
  AdobeIcon,
  AmplitudeIcon,
  AppFolioIcon,
  AsanaIcon,
  AtlassianIcon,
  AvePointIcon,
  AvidXchangeIcon,
  BigcommerceIcon,
  BillIcon,
  BlackLineIcon,
  BlockIcon,
  BoxIcon,
  BrazeIcon,
  C3Icon,
  ClearwaterAnalyticsIcon,
  CloudflareIcon,
  ConfluentIcon,
  CrowdstrikeIcon,
  DatadogIcon,
  DigitalOceanIcon,
  DocuSignIcon,
  DropboxIcon,
  DynatraceIcon,
  ElasticIcon,
  FastlyIcon,
  Five9Icon,
  FreshworksIcon,
  GitlabIcon,
  HubSpotIcon,
  JFrogIcon,
  KlaviyoIcon,
  MondayIcon,
  MongoDBIcon,
  nCinoIcon,
  OktaIcon,
  PagerDutyIcon,
  PaloAltoNetworksIcon,
  PaycomIcon,
  PaylocityIcon,
  PayPalIcon,
  ProcoreIcon,
  Q2Icon,
  QualysIcon,
  RingcentralIcon,
  SalesforceIcon,
  SamsaraIcon,
  SentinelOneIcon,
  ServiceNowIcon,
  ShopifyIcon,
  SmartsheetIcon,
  SnowflakeIcon,
  SprinklrIcon,
  SproutSocialIcon,
  TenableIcon,
  ToastIcon,
  TwilioIcon,
  UiPathIcon,
  VeevaSystemsIcon,
  WixIcon,
  WorkdayIcon,
  WorkivaIcon,
  ZoomIcon,
  ZoominfoIcon,
  ZscalerIcon,
  ZuoraIcon,
} from "@/components/custom/company-icons";
import { useParams, useSearchParams } from "react-router-dom";
import { getBusinesses } from "@/services/contact";
import { useBusinessList } from "@/hooks/useBusinessList";

const mockCompanies = [
  {
    id: "1",
    name: "Adobe",
    logo: AdobeIcon,
    location: "San Jose, CA, USA",
    industry: "Software",
    tags: ["Event"],
    salesStage: {
      label: "Prospect",
      description: "Prospect",
    },
    customerJourneyStage: {
      label: "Awareness",
      description: "Awareness",
    },
    dealProgress: 55,
    nextAction: {
      icon: CalendarIcon,
      label: "Research the company",
    },
  },

  {
    id: "2",
    name: "Amplitude",
    logo: AmplitudeIcon,
    location: "San Francisco, CA, USA",
    industry: "Analytics",
    tags: ["Partner"],
    salesStage: {
      label: "Won",
      description: "Won",
    },
    customerJourneyStage: {
      label: "Purchase",
      description: "Purchase",
    },
    dealProgress: 55,
    nextAction: {
      icon: CalendarIcon,
      label: "Onboard the customer",
    },
  },

  {
    id: "3",
    name: "AppFolio",
    logo: AppFolioIcon,
    location: "Santa Barbara, CA, USA",
    industry: "Real Estate Software",
    tags: ["Partner"],
    salesStage: {
      label: "Propose",
      description: "Propose",
    },
    customerJourneyStage: {
      label: "Evaluation",
      description: "Evaluation",
    },
    dealProgress: 55,
    nextAction: {
      icon: CalendarIcon,
      label: "Send proposal",
    },
  },

  {
    id: "4",
    name: "Asana",
    logo: AsanaIcon,
    location: "San Francisco, CA, USA",
    industry: "Productivity Software",
    tags: ["Inbound"],
    salesStage: {
      label: "Negotiate",
      description: "Negotiate",
    },
    customerJourneyStage: {
      label: "Decision",
      description: "Decision",
    },
    dealProgress: 55,
    nextAction: {
      icon: CalendarIcon,
      label: "Negotiate terms",
    },
  },

  {
    id: "5",
    name: "Atlassian",
    logo: AtlassianIcon,
    location: "Sydney, Australia",
    industry: "Software",
    tags: ["Outbound"],
    salesStage: {
      label: "Negotiate",
      description: "Negotiate",
    },
    customerJourneyStage: {
      label: "Decision",
      description: "Decision",
    },
    dealProgress: 55,
    nextAction: {
      icon: CalendarIcon,
      label: "Negotiate terms",
    },
  },

  {
    id: "6",
    name: "AvePoint",
    logo: AvePointIcon,
    location: "Jersey City, NJ, USA",
    industry: "Cloud Software",
    tags: ["Outbound"],
    salesStage: {
      label: "Implement",
      description: "Implement",
    },
    customerJourneyStage: {
      label: "Implementation",
      description: "Implementation",
    },
    dealProgress: 55,
    nextAction: {
      icon: CalendarIcon,
      label: "Begin implementation",
    },
  },

  {
    id: "7",
    name: "AvidXchange",
    logo: AvidXchangeIcon,
    location: "Charlotte, NC, USA",
    industry: "Financial Software",
    tags: ["Content Marketing"],
    salesStage: {
      label: "Won",
      description: "Won",
    },
    customerJourneyStage: {
      label: "Purchase",
      description: "Purchase",
    },
    dealProgress: 55,
    nextAction: {
      icon: CalendarIcon,
      label: "Onboard the customer",
    },
  },

  {
    id: "8",
    name: "Bigcommerce",
    logo: BigcommerceIcon,
    location: "Austin, TX, USA",
    industry: "E-commerce",
    tags: ["Referral"],
    salesStage: {
      label: "Success",
      description: "Success",
    },
    customerJourneyStage: {
      label: "Retention",
      description: "Retention",
    },
    dealProgress: 55,
    nextAction: {
      icon: CalendarIcon,
      label: "Measure customer success",
    },
  },

  {
    id: "9",
    name: "Bill.com",
    logo: BillIcon,
    location: "San Jose, CA, USA",
    industry: "Financial Software",
    tags: ["Referral"],
    salesStage: {
      label: "Review",
      description: "Review",
    },
    customerJourneyStage: {
      label: "Decision",
      description: "Decision",
    },
    dealProgress: 55,
    nextAction: {
      icon: CalendarIcon,
      label: "Send final contract",
    },
  },

  {
    id: "10",
    name: "BlackLine",
    logo: BlackLineIcon,
    location: "Los Angeles, CA, USA",
    industry: "Financial Software",
    tags: ["Referral"],
    salesStage: {
      label: "Review",
      description: "Review",
    },
    customerJourneyStage: {
      label: "Decision",
      description: "Decision",
    },
    dealProgress: 55,
    nextAction: {
      icon: CalendarIcon,
      label: "Send final contract",
    },
  },

  {
    id: "11",
    name: "Block",
    logo: BlockIcon,
    location: "San Francisco, CA, USA",
    industry: "Financial Technology",
    tags: ["Outbound"],
    salesStage: {
      label: "Prospect",
      description: "Prospect",
    },
    customerJourneyStage: {
      label: "Awareness",
      description: "Awareness",
    },
    dealProgress: 55,
    nextAction: {
      icon: CalendarIcon,
      label: "Research the company",
    },
  },

  {
    id: "12",
    name: "Box",
    logo: BoxIcon,
    location: "Redwood City, CA, USA",
    industry: "Cloud Storage",
    tags: ["Event"],
    salesStage: {
      label: "Prospect",
      description: "Prospect",
    },
    customerJourneyStage: {
      label: "Awareness",
      description: "Awareness",
    },
    dealProgress: 55,
    nextAction: {
      icon: CalendarIcon,
      label: "Research the company",
    },
  },

  {
    id: "13",
    name: "Braze",
    logo: BrazeIcon,
    location: "New York, NY, USA",
    industry: "Marketing Technology",
    tags: ["Partner"],
    salesStage: {
      label: "Success",
      description: "Success",
    },
    customerJourneyStage: {
      label: "Retention",
      description: "Retention",
    },
    dealProgress: 55,
    nextAction: {
      icon: CalendarIcon,
      label: "Measure customer success",
    },
  },

  {
    id: "14",
    name: "C3.ai",
    logo: C3Icon,
    location: "Redwood City, CA, USA",
    industry: "AI Software",
    tags: ["Outbound"],
    salesStage: {
      label: "Verbal",
      description: "Verbal",
    },
    customerJourneyStage: {
      label: "Decision",
      description: "Decision",
    },
    dealProgress: 55,
    nextAction: {
      icon: CalendarIcon,
      label: "Await signature",
    },
  },

  {
    id: "15",
    name: "Clearwater Analytics",
    logo: ClearwaterAnalyticsIcon,
    location: "Boise, ID, USA",
    industry: "Financial Software",
    tags: ["Content Marketing"],
    salesStage: {
      label: "Review",
      description: "Review",
    },
    customerJourneyStage: {
      label: "Decision",
      description: "Decision",
    },
    dealProgress: 55,
    nextAction: {
      icon: CalendarIcon,
      label: "Send final contract",
    },
  },

  {
    id: "16",
    name: "Cloudflare",
    logo: CloudflareIcon,
    location: "San Francisco, CA, USA",
    industry: "Cloud Computing",
    tags: ["Content Marketing"],
    salesStage: {
      label: "Implement",
      description: "Implement",
    },
    customerJourneyStage: {
      label: "Implementation",
      description: "Implementation",
    },
    dealProgress: 55,
    nextAction: {
      icon: CalendarIcon,
      label: "Begin implementation",
    },
  },

  {
    id: "17",
    name: "Confluent",
    logo: ConfluentIcon,
    location: "Mountain View, CA, USA",
    industry: "Data Streaming",
    tags: ["Event"],
    salesStage: {
      label: "Lost",
      description: "Lost",
    },
    customerJourneyStage: {
      label: "Post-purchase",
      description: "Post-purchase",
    },
    dealProgress: 55,
    nextAction: {
      icon: CalendarIcon,
      label: "Conduct post-mortem",
    },
  },

  {
    id: "18",
    name: "Crowdstrike",
    logo: CrowdstrikeIcon,
    location: "Sunnyvale, CA, USA",
    industry: "Cybersecurity",
    tags: ["Content Marketing"],
    salesStage: {
      label: "Verbal",
      description: "Verbal",
    },
    customerJourneyStage: {
      label: "Decision",
      description: "Decision",
    },
    dealProgress: 55,
    nextAction: {
      icon: CalendarIcon,
      label: "Await signature",
    },
  },

  {
    id: "19",
    name: "Datadog",
    logo: DatadogIcon,
    location: "New York, NY, USA",
    industry: "Cloud Monitoring",
    tags: ["Event"],
    salesStage: {
      label: "Prospect",
      description: "Prospect",
    },
    customerJourneyStage: {
      label: "Awareness",
      description: "Awareness",
    },
    dealProgress: 55,
    nextAction: {
      icon: CalendarIcon,
      label: "Research the company",
    },
  },

  {
    id: "20",
    name: "DigitalOcean",
    logo: DigitalOceanIcon,
    location: "New York, NY, USA",
    industry: "Cloud Infrastructure",
    tags: ["Partner"],
    salesStage: {
      label: "Negotiate",
      description: "Negotiate",
    },
    customerJourneyStage: {
      label: "Decision",
      description: "Decision",
    },
    dealProgress: 55,
    nextAction: {
      icon: CalendarIcon,
      label: "Negotiate terms",
    },
  },

  {
    id: "21",
    name: "DocuSign",
    logo: DocuSignIcon,
    location: "San Francisco, CA, USA",
    industry: "E-signature Software",
    tags: ["Partner"],
    salesStage: {
      label: "Prospect",
      description: "Prospect",
    },
    customerJourneyStage: {
      label: "Awareness",
      description: "Awareness",
    },
    dealProgress: 55,
    nextAction: {
      icon: CalendarIcon,
      label: "Research the company",
    },
  },

  {
    id: "22",
    name: "Dropbox",
    logo: DropboxIcon,
    location: "San Francisco, CA, USA",
    industry: "Cloud Storage",
    tags: ["Referral"],
    salesStage: {
      label: "Negotiate",
      description: "Negotiate",
    },
    customerJourneyStage: {
      label: "Decision",
      description: "Decision",
    },
    dealProgress: 55,
    nextAction: {
      icon: CalendarIcon,
      label: "Negotiate terms",
    },
  },

  {
    id: "23",
    name: "Dynatrace",
    logo: DynatraceIcon,
    location: "Waltham, MA, USA",
    industry: "Cloud Monitoring",
    tags: ["Referral"],
    salesStage: {
      label: "Develop",
      description: "Develop",
    },
    customerJourneyStage: {
      label: "Evaluation",
      description: "Evaluation",
    },
    dealProgress: 55,
    nextAction: {
      icon: CalendarIcon,
      label: "Customize solution",
    },
  },

  {
    id: "24",
    name: "Elastic",
    logo: ElasticIcon,
    location: "Mountain View, CA, USA",
    industry: "Search Technology",
    tags: ["Event"],
    salesStage: {
      label: "Discover",
      description: "Discover",
    },
    customerJourneyStage: {
      label: "Consideration",
      description: "Consideration",
    },
    dealProgress: 55,
    nextAction: {
      icon: CalendarIcon,
      label: "Send product demo",
    },
  },

  {
    id: "25",
    name: "Fastly",
    logo: FastlyIcon,
    location: "San Francisco, CA, USA",
    industry: "Content Delivery Network",
    tags: ["Event"],
    salesStage: {
      label: "Develop",
      description: "Develop",
    },
    customerJourneyStage: {
      label: "Evaluation",
      description: "Evaluation",
    },
    dealProgress: 55,
    nextAction: {
      icon: CalendarIcon,
      label: "Customize solution",
    },
  },

  {
    id: "26",
    name: "Five9",
    logo: Five9Icon,
    location: "San Ramon, CA, USA",
    industry: "Cloud Contact Center",
    tags: ["Referral"],
    salesStage: {
      label: "Verbal",
      description: "Verbal",
    },
    customerJourneyStage: {
      label: "Decision",
      description: "Decision",
    },
    dealProgress: 55,
    nextAction: {
      icon: CalendarIcon,
      label: "Await signature",
    },
  },

  {
    id: "27",
    name: "Freshworks",
    logo: FreshworksIcon,
    location: "San Mateo, CA, USA",
    industry: "Customer Support Software",
    tags: ["Event"],
    salesStage: {
      label: "Negotiate",
      description: "Negotiate",
    },
    customerJourneyStage: {
      label: "Decision",
      description: "Decision",
    },
    dealProgress: 55,
    nextAction: {
      icon: CalendarIcon,
      label: "Negotiate terms",
    },
  },

  {
    id: "28",
    name: "Gitlab",
    logo: GitlabIcon,
    location: "San Francisco, CA, USA",
    industry: "DevOps",
    tags: ["Inbound"],
    salesStage: {
      label: "Implement",
      description: "Implement",
    },
    customerJourneyStage: {
      label: "Implementation",
      description: "Implementation",
    },
    dealProgress: 55,
    nextAction: {
      icon: CalendarIcon,
      label: "Begin implementation",
    },
  },

  {
    id: "29",
    name: "HubSpot",
    logo: HubSpotIcon,
    location: "Cambridge, MA, USA",
    industry: "Marketing Software",
    tags: ["Outbound"],
    salesStage: {
      label: "Review",
      description: "Review",
    },
    customerJourneyStage: {
      label: "Decision",
      description: "Decision",
    },
    dealProgress: 55,
    nextAction: {
      icon: CalendarIcon,
      label: "Send final contract",
    },
  },

  {
    id: "30",
    name: "JFrog",
    logo: JFrogIcon,
    location: "Sunnyvale, CA, USA",
    industry: "DevOps",
    tags: ["Partner"],
    salesStage: {
      label: "Verbal",
      description: "Verbal",
    },
    customerJourneyStage: {
      label: "Decision",
      description: "Decision",
    },
    dealProgress: 55,
    nextAction: {
      icon: CalendarIcon,
      label: "Await signature",
    },
  },

  {
    id: "31",
    name: "Klaviyo",
    logo: KlaviyoIcon,
    location: "Boston, MA, USA",
    industry: "Marketing Automation",
    tags: ["Outbound"],
    salesStage: {
      label: "Review",
      description: "Review",
    },
    customerJourneyStage: {
      label: "Decision",
      description: "Decision",
    },
    dealProgress: 55,
    nextAction: {
      icon: CalendarIcon,
      label: "Send final contract",
    },
  },

  {
    id: "32",
    name: "Monday.com",
    logo: MondayIcon,
    location: "Tel Aviv, Israel",
    industry: "Productivity Software",
    tags: ["Referral"],
    salesStage: {
      label: "Review",
      description: "Review",
    },
    customerJourneyStage: {
      label: "Decision",
      description: "Decision",
    },
    dealProgress: 55,
    nextAction: {
      icon: CalendarIcon,
      label: "Send final contract",
    },
  },

  {
    id: "33",
    name: "MongoDB",
    logo: MongoDBIcon,
    location: "New York, NY, USA",
    industry: "Database Technology",
    tags: ["Partner"],
    salesStage: {
      label: "Negotiate",
      description: "Negotiate",
    },
    customerJourneyStage: {
      label: "Decision",
      description: "Decision",
    },
    dealProgress: 55,
    nextAction: {
      icon: CalendarIcon,
      label: "Negotiate terms",
    },
  },

  {
    id: "34",
    name: "nCino",
    logo: nCinoIcon,
    location: "Wilmington, NC, USA",
    industry: "Financial Software",
    tags: ["Content Marketing"],
    salesStage: {
      label: "Won",
      description: "Won",
    },
    customerJourneyStage: {
      label: "Purchase",
      description: "Purchase",
    },
    dealProgress: 55,
    nextAction: {
      icon: CalendarIcon,
      label: "Onboard the customer",
    },
  },

  {
    id: "35",
    name: "Okta",
    logo: OktaIcon,
    location: "San Francisco, CA, USA",
    industry: "Identity Management",
    tags: ["Event"],
    salesStage: {
      label: "Discover",
      description: "Discover",
    },
    customerJourneyStage: {
      label: "Consideration",
      description: "Consideration",
    },
    dealProgress: 55,
    nextAction: {
      icon: CalendarIcon,
      label: "Send product demo",
    },
  },

  {
    id: "36",
    name: "PagerDuty",
    logo: PagerDutyIcon,
    location: "San Francisco, CA, USA",
    industry: "Incident Management",
    tags: ["Referral"],
    salesStage: {
      label: "Verbal",
      description: "Verbal",
    },
    customerJourneyStage: {
      label: "Decision",
      description: "Decision",
    },
    dealProgress: 55,
    nextAction: {
      icon: CalendarIcon,
      label: "Await signature",
    },
  },

  {
    id: "37",
    name: "Palo Alto Networks",
    logo: PaloAltoNetworksIcon,
    location: "Santa Clara, CA, USA",
    industry: "Cybersecurity",
    tags: ["Content Marketing"],
    salesStage: {
      label: "Develop",
      description: "Develop",
    },
    customerJourneyStage: {
      label: "Evaluation",
      description: "Evaluation",
    },
    dealProgress: 55,
    nextAction: {
      icon: CalendarIcon,
      label: "Customize solution",
    },
  },

  {
    id: "38",
    name: "Paycom",
    logo: PaycomIcon,
    location: "Oklahoma City, OK, USA",
    industry: "Payroll Software",
    tags: ["Content Marketing"],
    salesStage: {
      label: "Qualify",
      description: "Qualify",
    },
    customerJourneyStage: {
      label: "Consideration",
      description: "Consideration",
    },
    dealProgress: 55,
    nextAction: {
      icon: CalendarIcon,
      label: "Conduct discovery call",
    },
  },

  {
    id: "39",
    name: "Paylocity",
    logo: PaylocityIcon,
    location: "Schaumburg, IL, USA",
    industry: "Payroll Software",
    tags: ["Content Marketing"],
    salesStage: {
      label: "Develop",
      description: "Develop",
    },
    customerJourneyStage: {
      label: "Evaluation",
      description: "Evaluation",
    },
    dealProgress: 55,
    nextAction: {
      icon: CalendarIcon,
      label: "Customize solution",
    },
  },

  {
    id: "40",
    name: "PayPal",
    logo: PayPalIcon,
    location: "San Jose, CA, USA",
    industry: "Financial Technology",
    tags: ["Referral"],
    salesStage: {
      label: "Success",
      description: "Success",
    },
    customerJourneyStage: {
      label: "Retention",
      description: "Retention",
    },
    dealProgress: 55,
    nextAction: {
      icon: CalendarIcon,
      label: "Measure customer success",
    },
  },

  {
    id: "41",
    name: "Procore",
    logo: ProcoreIcon,
    location: "Carpinteria, CA, USA",
    industry: "Construction Software",
    tags: ["Content Marketing"],
    salesStage: {
      label: "Negotiate",
      description: "Negotiate",
    },
    customerJourneyStage: {
      label: "Decision",
      description: "Decision",
    },
    dealProgress: 55,
    nextAction: {
      icon: CalendarIcon,
      label: "Negotiate terms",
    },
  },

  {
    id: "42",
    name: "Q2",
    logo: Q2Icon,
    location: "Austin, TX, USA",
    industry: "Financial Software",
    tags: ["Referral"],
    salesStage: {
      label: "Prospect",
      description: "Prospect",
    },
    customerJourneyStage: {
      label: "Awareness",
      description: "Awareness",
    },
    dealProgress: 55,
    nextAction: {
      icon: CalendarIcon,
      label: "Research the company",
    },
  },

  {
    id: "43",
    name: "Qualys",
    logo: QualysIcon,
    location: "Foster City, CA, USA",
    industry: "Cybersecurity",
    tags: ["Event"],
    salesStage: {
      label: "Negotiate",
      description: "Negotiate",
    },
    customerJourneyStage: {
      label: "Decision",
      description: "Decision",
    },
    dealProgress: 55,
    nextAction: {
      icon: CalendarIcon,
      label: "Negotiate terms",
    },
  },

  {
    id: "44",
    name: "Ringcentral",
    logo: RingcentralIcon,
    location: "Belmont, CA, USA",
    industry: "Cloud Communications",
    tags: ["Event"],
    salesStage: {
      label: "Discover",
      description: "Discover",
    },
    customerJourneyStage: {
      label: "Consideration",
      description: "Consideration",
    },
    dealProgress: 55,
    nextAction: {
      icon: CalendarIcon,
      label: "Send product demo",
    },
  },

  {
    id: "45",
    name: "Salesforce",
    logo: SalesforceIcon,
    location: "San Francisco, CA, USA",
    industry: "Customer Relationship Management (CRM)",
    tags: ["Content Marketing"],
    salesStage: {
      label: "Negotiate",
      description: "Negotiate",
    },
    customerJourneyStage: {
      label: "Decision",
      description: "Decision",
    },
    dealProgress: 55,
    nextAction: {
      icon: CalendarIcon,
      label: "Negotiate terms",
    },
  },

  {
    id: "46",
    name: "Samsara",
    logo: SamsaraIcon,
    location: "San Francisco, CA, USA",
    industry: "IoT Solutions",
    tags: ["Inbound"],
    salesStage: {
      label: "Implement",
      description: "Implement",
    },
    customerJourneyStage: {
      label: "Implementation",
      description: "Implementation",
    },
    dealProgress: 55,
    nextAction: {
      icon: CalendarIcon,
      label: "Begin implementation",
    },
  },

  {
    id: "47",
    name: "SentinelOne",
    logo: SentinelOneIcon,
    location: "Mountain View, CA, USA",
    industry: "Cybersecurity",
    tags: ["Partner"],
    salesStage: {
      label: "Implement",
      description: "Implement",
    },
    customerJourneyStage: {
      label: "Implementation",
      description: "Implementation",
    },
    dealProgress: 55,
    nextAction: {
      icon: CalendarIcon,
      label: "Begin implementation",
    },
  },

  {
    id: "48",
    name: "ServiceNow",
    logo: ServiceNowIcon,
    location: "Santa Clara, CA, USA",
    industry: "IT Services",
    tags: ["Partner"],
    salesStage: {
      label: "Prospect",
      description: "Prospect",
    },
    customerJourneyStage: {
      label: "Awareness",
      description: "Awareness",
    },
    dealProgress: 55,
    nextAction: {
      icon: CalendarIcon,
      label: "Research the company",
    },
  },

  {
    id: "49",
    name: "Shopify",
    logo: ShopifyIcon,
    location: "Ottawa, Canada",
    industry: "E-commerce",
    tags: ["Referral"],
    salesStage: {
      label: "Review",
      description: "Review",
    },
    customerJourneyStage: {
      label: "Decision",
      description: "Decision",
    },
    dealProgress: 55,
    nextAction: {
      icon: CalendarIcon,
      label: "Send final contract",
    },
  },

  {
    id: "50",
    name: "Smartsheet",
    logo: SmartsheetIcon,
    location: "Bellevue, WA, USA",
    industry: "Project Management Software",
    tags: ["Inbound"],
    salesStage: {
      label: "Lost",
      description: "Lost",
    },
    customerJourneyStage: {
      label: "Post-purchase",
      description: "Post-purchase",
    },
    dealProgress: 55,
    nextAction: {
      icon: CalendarIcon,
      label: "Conduct post-mortem",
    },
  },

  {
    id: "51",
    name: "Snowflake",
    logo: SnowflakeIcon,
    location: "Bozeman, MT, USA",
    industry: "Cloud Data Warehousing",
    tags: ["Outbound"],
    salesStage: {
      label: "Review",
      description: "Review",
    },
    customerJourneyStage: {
      label: "Decision",
      description: "Decision",
    },
    dealProgress: 55,
    nextAction: {
      icon: CalendarIcon,
      label: "Send final contract",
    },
  },

  {
    id: "52",
    name: "Sprinklr",
    logo: SprinklrIcon,
    location: "New York, NY, USA",
    industry: "Customer Experience Software",
    tags: ["Referral"],
    salesStage: {
      label: "Prospect",
      description: "Prospect",
    },
    customerJourneyStage: {
      label: "Awareness",
      description: "Awareness",
    },
    dealProgress: 55,
    nextAction: {
      icon: CalendarIcon,
      label: "Research the company",
    },
  },

  {
    id: "53",
    name: "Sprout Social",
    logo: SproutSocialIcon,
    location: "Chicago, IL, USA",
    industry: "Social Media Software",
    tags: ["Content Marketing"],
    salesStage: {
      label: "Won",
      description: "Won",
    },
    customerJourneyStage: {
      label: "Purchase",
      description: "Purchase",
    },
    dealProgress: 55,
    nextAction: {
      icon: CalendarIcon,
      label: "Onboard the customer",
    },
  },

  {
    id: "54",
    name: "Tenable",
    logo: TenableIcon,
    location: "Columbia, MD, USA",
    industry: "Cybersecurity",
    tags: ["Partner"],
    salesStage: {
      label: "Verbal",
      description: "Verbal",
    },
    customerJourneyStage: {
      label: "Decision",
      description: "Decision",
    },
    dealProgress: 55,
    nextAction: {
      icon: CalendarIcon,
      label: "Await signature",
    },
  },

  {
    id: "55",
    name: "Toast",
    logo: ToastIcon,
    location: "Boston, MA, USA",
    industry: "Restaurant Software",
    tags: ["Outbound"],
    salesStage: {
      label: "Discover",
      description: "Discover",
    },
    customerJourneyStage: {
      label: "Consideration",
      description: "Consideration",
    },
    dealProgress: 55,
    nextAction: {
      icon: CalendarIcon,
      label: "Send product demo",
    },
  },

  {
    id: "56",
    name: "Twilio",
    logo: TwilioIcon,
    location: "San Francisco, CA, USA",
    industry: "Cloud Communications",
    tags: ["Content Marketing"],
    salesStage: {
      label: "Won",
      description: "Won",
    },
    customerJourneyStage: {
      label: "Purchase",
      description: "Purchase",
    },
    dealProgress: 55,
    nextAction: {
      icon: CalendarIcon,
      label: "Onboard the customer",
    },
  },

  {
    id: "57",
    name: "UiPath",
    logo: UiPathIcon,
    location: "New York, NY, USA",
    industry: "Robotic Process Automation",
    tags: ["Outbound"],
    salesStage: {
      label: "Qualify",
      description: "Qualify",
    },
    customerJourneyStage: {
      label: "Consideration",
      description: "Consideration",
    },
    dealProgress: 55,
    nextAction: {
      icon: CalendarIcon,
      label: "Conduct discovery call",
    },
  },

  {
    id: "58",
    name: "Veeva Systems",
    logo: VeevaSystemsIcon,
    location: "Pleasanton, CA, USA",
    industry: "Cloud Software (Life Sciences)",
    tags: ["Outbound"],
    salesStage: {
      label: "Discover",
      description: "Discover",
    },
    customerJourneyStage: {
      label: "Consideration",
      description: "Consideration",
    },
    dealProgress: 55,
    nextAction: {
      icon: CalendarIcon,
      label: "Send product demo",
    },
  },

  {
    id: "59",
    name: "Wix",
    logo: WixIcon,
    location: "Tel Aviv, Israel",
    industry: "Website Development",
    tags: ["Content Marketing"],
    salesStage: {
      label: "Propose",
      description: "Propose",
    },
    customerJourneyStage: {
      label: "Evaluation",
      description: "Evaluation",
    },
    dealProgress: 55,
    nextAction: {
      icon: CalendarIcon,
      label: "Send proposal",
    },
  },

  {
    id: "60",
    name: "Workday",
    logo: WorkdayIcon,
    location: "Pleasanton, CA, USA",
    industry: "Human Capital Management",
    tags: ["Referral"],
    salesStage: {
      label: "Develop",
      description: "Develop",
    },
    customerJourneyStage: {
      label: "Evaluation",
      description: "Evaluation",
    },
    dealProgress: 55,
    nextAction: {
      icon: CalendarIcon,
      label: "Customize solution",
    },
  },

  {
    id: "61",
    name: "Workiva",
    logo: WorkivaIcon,
    location: "Ames, IA, USA",
    industry: "Financial Reporting Software",
    tags: ["Partner"],
    salesStage: {
      label: "Qualify",
      description: "Qualify",
    },
    customerJourneyStage: {
      label: "Consideration",
      description: "Consideration",
    },
    dealProgress: 55,
    nextAction: {
      icon: CalendarIcon,
      label: "Conduct discovery call",
    },
  },

  {
    id: "62",
    name: "Zoom",
    logo: ZoomIcon,
    location: "San Jose, CA, USA",
    industry: "Video Communications",
    tags: ["Outbound"],
    salesStage: {
      label: "Propose",
      description: "Propose",
    },
    customerJourneyStage: {
      label: "Evaluation",
      description: "Evaluation",
    },
    dealProgress: 55,
    nextAction: {
      icon: CalendarIcon,
      label: "Send proposal",
    },
  },

  {
    id: "63",
    name: "Zoominfo",
    logo: ZoominfoIcon,
    location: "Vancouver, WA, USA",
    industry: "B2B Database",
    tags: ["Referral"],
    salesStage: {
      label: "Prospect",
      description: "Prospect",
    },
    customerJourneyStage: {
      label: "Awareness",
      description: "Awareness",
    },
    dealProgress: 55,
    nextAction: {
      icon: CalendarIcon,
      label: "Research the company",
    },
  },

  {
    id: "64",
    name: "Zscaler",
    logo: ZscalerIcon,
    location: "San Jose, CA, USA",
    industry: "Cybersecurity",
    tags: ["Content Marketing"],
    salesStage: {
      label: "Won",
      description: "Won",
    },
    customerJourneyStage: {
      label: "Purchase",
      description: "Purchase",
    },
    dealProgress: 55,
    nextAction: {
      icon: CalendarIcon,
      label: "Onboard the customer",
    },
  },

  {
    id: "65",
    name: "Zuora",
    logo: ZuoraIcon,
    location: "Redwood City, CA, USA",
    industry: "Subscription Management",
    tags: ["Event"],
    salesStage: {
      label: "Qualify",
      description: "Qualify",
    },
    customerJourneyStage: {
      label: "Consideration",
      description: "Consideration",
    },
    dealProgress: 55,
    nextAction: {
      icon: CalendarIcon,
      label: "Conduct discovery call",
    },
  },
];

const PAGE_SIZE = 14;

const getTagColor = (tag: string) => {
  const colors = {
    Event: "customPurpleLight",
    Partner: "yellow",
    Inbound: "customGreenLight",
    Outbound: "customBlueLight",
    "Content Marketing": "customRedLight",
    Referral: "amber",
  };
  return colors[tag] || "customBlueLight";
};

const getSalesStageColor = (salesStage: string) => {
  const colors = {
    Prospect: "customPurpleMain",
    Won: "customYellowMain",
    Propose: "customFuchsiaMain",
    Negotiate: "customBlueMain",
    Implement: "customRedMain",
    Success: "customGreenMain",
  };
  return colors[salesStage] || "customBlueMain";
};

const getCustomerJourneyColor = (customerJourney: string) => {
  const colors = {
    Awareness: "customPurpleLight",
    Purchase: "yellow",
    Evaluation: "customGreenLight",
    Decision: "customBlueLight",
    Implementation: "customRedLight",
    Retention: "amber",
  };
  return colors[customerJourney] || "customBlueLight";
};

export default function DemoCompaniesPage() {
  const [searchParams] = useSearchParams();
  const keyword = searchParams.get("keyword") || "";
  const pageNum = Number(searchParams.get("page")) || 1;
  const [searchKeyword, setSearchKeyword] = useState(keyword || "");
  const { data: businessListData, isLoading } = useBusinessList({ cursor: (pageNum-1)*PAGE_SIZE, limit: PAGE_SIZE });
  const [businesses, setBusinesses] = useState<any[]>([]);
  
  useEffect(() => {
    const fetchBusinesses = async () => {
      const newBusinesses: any[] = [];
      for (const business of (businessListData?.items || [])) {
        newBusinesses.push({
          id: business.businessId,
          name: business.businessName,
          logo: ZuoraIcon,
          location: business.headquarterName,
          industry: business.industry,
          tags: ["Event"],
          salesStage: {
            label: "Qualify",
            description: "Qualify",
          },
          customerJourneyStage: {
            label: "Consideration",
            description: "Consideration",
          },
          dealProgress: 55,
          nextAction: {
            icon: CalendarIcon,
            label: "Conduct discovery call",
          }
        });
      }
      setBusinesses(newBusinesses);
    };
    fetchBusinesses();
  }, [businessListData]);

  const onSearchCompanies = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchKeyword(event.target.value);
  };

  const totalRows = isLoading ? -1 : businessListData.totalRows;

  const renderPagination = () => {
    const totalPages = Math.ceil(totalRows / PAGE_SIZE);
    const prevPage = pageNum > 1 ? pageNum - 1 : 1;
    const nextPage = pageNum < totalPages ? pageNum + 1 : totalPages;
  
    const getPageNumbers = () => {
      const pageNumbers: any[] = [];
      const showPagesCount = 5; // Number of pages to show
      
      if (totalPages <= showPagesCount) {
        // If total pages are less than showPagesCount, show all pages
        for (let i = 1; i <= totalPages; i++) {
          pageNumbers.push(i);
        }
      } else {
        // Always show first page
        pageNumbers.push(1);
        
        let startPage = Math.max(2, pageNum - 1);
        let endPage = Math.min(totalPages - 1, pageNum + 1);
        
        // Adjust startPage and endPage to always show showPagesCount-2 pages
        if (startPage <= 2) {
          endPage = showPagesCount - 1;
        } else if (endPage >= totalPages - 1) {
          startPage = totalPages - (showPagesCount - 2);
        }
        
        // Add ellipsis after first page
        if (startPage > 2) {
          pageNumbers.push('...');
        }
        
        // Add middle pages
        for (let i = startPage; i <= endPage; i++) {
          pageNumbers.push(i);
        }
        
        // Add ellipsis before last page
        if (endPage < totalPages - 1) {
          pageNumbers.push('...');
        }
        
        // Always show last page
        pageNumbers.push(totalPages);
      }
      
      return pageNumbers;
    };
  
    return (
      <Pagination className="mt-6">
        <PaginationPrevious
          href={`?page=${prevPage}&keyword=${searchKeyword}`}
        />
        <PaginationList>
          {getPageNumbers().map((pageNumber, index) => (
            pageNumber === '...' ? (
              <span key={`ellipsis-${index}`} className="px-2">...</span>
            ) : (
              <PaginationPage
                key={pageNumber}
                href={`?page=${pageNumber}&keyword=${searchKeyword}`}
                current={pageNumber === pageNum}
              >
                {pageNumber}
              </PaginationPage>
            )
          ))}
        </PaginationList>
        <PaginationNext href={`?page=${nextPage}&keyword=${searchKeyword}`} />
      </Pagination>
    );
  };

  return (
    <main>
      <MainSidebar
        toolbar={
          <Button color="dark" className="gap-2 flex items-center">
            <img src={PlusIcon} className="w-4 h-4" />
            Add company
          </Button>
        }
      >
        <div className="text-cell px-8 py-5">
          <div className="mb-5 flex justify-between gap-4 pt-3">
            <div className="flex flex-col gap-2 align-center">
              <div className="w-[250px]">
                <InputGroup>
                  <MenuIcon
                    src={SearchIcon}
                    data-slot="icon"
                    className="!size-4"
                  />
                  <Input
                    placeholder="Search companies"
                    className="h-9 pl-9 pr-2 !rounded-full border-none !bg-[#F2F2F2]"
                    onChange={onSearchCompanies}
                    value={searchKeyword}
                    color=''
                  />
                </InputGroup>
              </div>
            </div>
            <div className="flex gap-4 items-center">
              <div className="flex gap-2 items-center tracking-widest min-w-fit">
                <img src={AIStarIcon} className="w-4 h-4" />
                <span className="font-medium min-w-fit">AI FILTERS</span>
              </div>
              <div className="flex flex-wrap gap-2 items-center">
                <Button color="white" size="sm">
                  Industry: <span className="text-gray-500">Technology</span>
                </Button>
                <Button color="white" size="sm">
                  Location: <span className="text-gray-500">US, AU, CA, IL</span>
                </Button>
                <Button color="white" size="sm">
                  SEC Keywords: <span className="text-gray-500">AI, Artificial Intelligence, Machine Learning,
                  Automation</span>
                </Button>
              </div>
            </div>
          </div>
          <div className="max-w-full">
            <Table
              grid
              dense={true}
              fixedFirstColumn={true}
              fixedHeader={true}
              fixedLastColumn={true}
            >
              <TableHead>
                <TableRow>
                  <TableHeader className="bg-white">
                    <div className="flex gap-4 items-center">
                      <Checkbox />
                      <span>Company</span>
                    </div>
                  </TableHeader>
                  <TableHeader>Location</TableHeader>
                  <TableHeader>Industry</TableHeader>
                  <TableHeader>Tags</TableHeader>
                  <TableHeader>Sales Stage</TableHeader>
                  <TableHeader>Customer Journey</TableHeader>
                  <TableHeader>Deal Progress</TableHeader>
                  <TableHeader>Next Action</TableHeader>
                </TableRow>
              </TableHead>
              <TableBody>
                {businesses.map((company) => (
                  <TableRow key={company.id}>
                    <TableCell className="bg-white !border-r">
                      <div className="flex gap-4 items-center">
                        <Checkbox />
                        <Link
                          className="flex gap-2 items-center"
                          href={`/customers/companies/${company.id}/account-plan`}
                        >
                          <img src={company.logo} className="size-4" />
                          <div className="underline">{company.name}</div>
                        </Link>
                      </div>
                    </TableCell>
                    <TableCell>{company.location}</TableCell>
                    <TableCell>{company.industry}</TableCell>
                    <TableCell>
                      <div className="flex gap-2">
                        {company.tags.map((tag) => (
                          <Badge color={getTagColor(tag)} key={tag}>
                            {tag}
                          </Badge>
                        ))}
                      </div>
                    </TableCell>
                    <TableCell>
                      <div className="flex gap-2 items-center">
                        <Badge
                          color={getSalesStageColor(company.salesStage.label)}
                        >
                          {company.salesStage.label || "N/A"}
                        </Badge>
                        <TooltipClaim id={`salesStage-tooltip-${company.id}`}>
                          {company.salesStage.description}
                        </TooltipClaim>
                      </div>
                    </TableCell>
                    <TableCell>
                      <div className="flex gap-2 items-center">
                        <Badge
                          color={getCustomerJourneyColor(
                            company.customerJourneyStage.label
                          )}
                        >
                          {company.customerJourneyStage.label}
                        </Badge>
                        <TooltipClaim id={`journey-tooltip-${company.id}`}>
                          {company.customerJourneyStage.description}
                        </TooltipClaim>
                      </div>
                    </TableCell>
                    <TableCell>
                      <ProgressBar value={company.dealProgress}>
                        {company.dealProgress}%
                      </ProgressBar>
                    </TableCell>
                    <TableCell>
                      <a className="flex gap-2 items-center" href="#">
                        <img
                          src={company.nextAction.icon}
                          className="w-4 h-4"
                        />
                        <div className="underline">
                          {company.nextAction.label}
                        </div>
                      </a>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            {renderPagination()}
            {isLoading && (
              <div className="w-full h-full mt-4">
                <Loading />
              </div>
            )}
          </div>
        </div>
      </MainSidebar>
    </main>
  );
}
