import React from 'react';
import SvgIcon, { SvgIconProps } from '../SvgIcon';

export const AWSPersonalizeIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props} style={{ fill: 'transparent' }} viewBox="5 5 30 30">
    <g fill="none" fillRule="evenodd">
      <path
        d="M0 0h40v40H0z"
        fill="url(#Arch_Amazon-Personalize_32_svg__a)"
      ></path>
      <path
        d="M22 17.446c0 .833-.673 1.51-1.5 1.51s-1.5-.677-1.5-1.51.673-1.51 1.5-1.51 1.5.677 1.5 1.51zm1.691 5.59h-6.393c.135-.99.711-3.068 3.173-3.068 2.506 0 3.086 2.08 3.22 3.069zm-5.696-5.59c0 .72.304 1.366.787 1.826-1.957.81-2.53 2.995-2.53 4.27 0 .28.225.507.502.507h7.48a.505.505 0 00.503-.505c0-.086-.01-2.126-1.337-3.467a3.636 3.636 0 00-1.198-.793 2.516 2.516 0 00-1.702-4.36 2.516 2.516 0 00-2.505 2.522zm14.594 2.915l-.03-.018a.496.496 0 01-.205.17.494.494 0 01-.548-.11l-1.877-1.89.71-.716.944.95c-.482-4.104-3.219-7.701-7.112-9.188l.357-.946c4.198 1.603 7.155 5.458 7.727 9.87l.588-.964.857.53-1.41 2.312zM16.566 30.53l-.358.946c-4.186-1.6-7.138-5.439-7.72-9.838l-.631 1.035-.857-.53 1.41-2.312.025.016a.486.486 0 01.25-.27.5.5 0 01.548.11l1.878 1.89-.711.716-.945-.95c.483 4.103 3.22 7.699 7.111 9.187zm14.372-6.507l.94.36a12.228 12.228 0 01-10.172 7.819l1.11 1.066-.692.732-1.95-1.871.011-.013c-.05-.048-.102-.094-.13-.16a.51.51 0 01.11-.552l1.877-1.89.711.716-.943.95a11.224 11.224 0 009.128-7.157zm-20.836-7.956l-.941-.36c1.647-4.362 5.677-7.396 10.24-7.828l-1.195-1.146L18.9 6l1.95 1.872-.042.045c.076.053.14.125.177.216a.511.511 0 01-.109.552l-1.877 1.89-.711-.716.943-.95a11.222 11.222 0 00-9.128 7.158zM27.487 20c0 3.878-3.134 7.033-6.987 7.033s-6.987-3.155-6.987-7.033c0-3.879 3.134-7.034 6.987-7.034s6.987 3.155 6.987 7.034zm-14.98 0c0 4.435 3.586 8.045 7.993 8.045s7.993-3.61 7.993-8.045c0-4.436-3.586-8.046-7.993-8.046s-7.993 3.61-7.993 8.046z"
        fill="#FFF"
      ></path>
    </g>
  </SvgIcon>
);
