import { JSONSchema, JSONSchemaType } from '@makeropsinc/workflow-types';
import { useCallback, useContext } from 'react';
import { context } from '../form-from-schema';
import { followRefs } from '../json-schemas';
import { Property } from '.';

interface Props {
  path: string;
  propName: string;
  property: JSONSchema;
  required?: boolean;
  value: Record<string, JSONSchemaType>;
  onChange: (propName: string, value: JSONSchemaType) => void;
  level: number;
  maxLevel: number;
  noSelects: boolean;
}
export const ObjectDetails: React.FC<Props> = ({
  path,
  propName,
  property,
  value,
  onChange,
  level,
  maxLevel,
  noSelects,
}) => {
  const ctx = useContext(context);
  const prop = followRefs(property, ctx.schema);

  const handlePropertyChanged = useCallback(
    (key: string, propValue: JSONSchemaType) =>
      onChange(propName, {
        ...value,
        [key]: propValue,
      }),
    [propName, value, onChange]
  );
  const properties = prop.properties as Record<string, JSONSchema>;
  const required = prop.required as string[];

  if (prop.type !== 'object') return null;

  return (
    <>
      {Object.keys(prop.properties ?? {}).map((key) => (
        <Property
          path={`${path}${key}/`}
          key={key}
          propName={key}
          property={properties?.[key] as JSONSchema}
          required={required?.includes(key)}
          value={value?.[key]}
          onChange={handlePropertyChanged}
          level={level + 1}
          maxLevel={maxLevel}
          noSelects={noSelects}
        />
      ))}
    </>
  );
};
