import React from 'react';
import SvgIcon, { SvgIconProps } from '../SvgIcon';

export const ClockifyIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon
    {...props}
    style={{ ...props.style, fill: 'transparent' }}
    viewBox="0 0 20 20"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.9005 6.87428L17.4925 2.27936L18.8069 3.59365L14.2149 8.18859L12.9005 6.87428ZM11.0398 11.5699C10.1411 11.5699 9.41309 10.8387 9.41309 9.9357C9.41309 9.03367 10.1411 8.30148 11.0398 8.30148C11.9385 8.30148 12.6665 9.03367 12.6665 9.9357C12.6665 10.8387 11.9385 11.5699 11.0398 11.5699ZM18.8283 16.316L17.5139 17.6312L12.9219 13.0362L14.2364 11.721L18.8283 16.316Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.087 17.0029C12.0126 17.0029 12.8945 16.8182 13.7027 16.4898L15.9455 18.7352C14.5072 19.5382 12.8516 20 11.087 20C5.56832 20 1.09375 15.5226 1.09375 10.0005C1.09375 4.4774 5.56832 0 11.087 0C12.8339 0 14.4746 0.45054 15.9027 1.23876L13.6971 3.44666C12.8898 3.12019 12.0108 2.93549 11.087 2.93549C7.22205 2.93549 4.08891 6.0846 4.08891 9.96969C4.08891 13.8538 7.22205 17.0029 11.087 17.0029Z"
      fill="currentColor"
    />
  </SvgIcon>
);
