import { JSONSchema, JSONSchemaType, Maybe } from '@makeropsinc/workflow-types';
import { useState, useCallback, useEffect } from 'react';
import { FormFromSchema } from './form-from-schema';
import { DetailsForm } from './form-details';

interface Props {
  readonlyKey: boolean;
  showCredentialsForm: boolean;
  credentialsSchema: JSONSchema;
  credentials: JSONSchemaType;
  setCredentials: (arg0: JSONSchemaType) => void;
  details: Record<string, string>;
  setDetails: (
    arg0:
      | Record<string, string>
      | ((arg0: Record<string, string>) => Record<string, string>)
  ) => void;
  onValidationUpdated: (arg0: () => Promise<boolean>) => void;
}

export const Form: React.FC<Props> = ({
  readonlyKey,
  showCredentialsForm,
  credentialsSchema,
  onValidationUpdated,
  credentials,
  setCredentials,
  details,
  setDetails,
}) => {
  const [validateDetails, setValidateDetails] = useState(
    undefined as Maybe<() => () => Promise<boolean>>
  );
  const [validateCredentials, setValidateCredentials] = useState(
    undefined as Maybe<() => () => boolean>
  );

  const validate = useCallback(async (): Promise<boolean> => {
    if (!validateDetails) return false;
    if (showCredentialsForm && !validateCredentials) return false;

    const validCredentials =
      !showCredentialsForm || !!(validateCredentials && validateCredentials());
    const validDetails = await validateDetails();

    return validCredentials && validDetails;
  }, [showCredentialsForm, validateCredentials, validateDetails]);

  useEffect(() => {
    if (!onValidationUpdated) return;

    onValidationUpdated(validate);
  }, [validate, onValidationUpdated]);

  return (
    <>
      <DetailsForm
        readonlyKey={readonlyKey}
        details={details}
        setDetails={setDetails}
        onValidationUpdated={(v) => setValidateDetails(() => v)}
      />
      {showCredentialsForm && (
        <FormFromSchema
          schema={credentialsSchema as JSONSchema}
          onChange={setCredentials}
          hideJSONEditor
          maxLevel={1}
          title="Credentials"
          value={credentials}
          validation={(v) => setValidateCredentials(() => v)}
        />
      )}
    </>
  );
};
