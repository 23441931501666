import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/cataylst/table";
import { AIStarIcon, DaveIcon, LogoIcon, PlusIcon, SarahIcon } from "@/components/custom/icons";
import MainSidebar from "@/components/custom/sidebar";
import { useParams } from "react-router-dom";
import { Dropdown, DropdownButton, DropdownItem, DropdownMenu } from "@/components/cataylst/dropdown";
import { ChevronDownIcon, EllipsisHorizontalIcon } from "@heroicons/react/16/solid";
import { formatDateTime } from "@/utils/format";
import { Avatar } from "@/components/cataylst/avatar";
import { Button } from "@/components/custom/button";
import { Checkbox } from "@/components/cataylst/checkbox";
import DemoCompanyProfileAside from "./DemoCompanyProfileAside";

const mockCompanyNames = {
  zoom: 'Zoom'
};

const mockTasks = [
  {
    id: 0,
    type: 'Suggest',
    name: 'Draft security demo script',
    dueDate: new Date(),
    assignedTo: {
      name: 'David Wallace',
      avatar: DaveIcon
    }
  },
  {
    id: 1,
    type: 'Checkbox',
    name: 'Record security demo',
    dueDate: new Date(),
    assignedTo: {
      name: 'David Wallace',
      avatar: DaveIcon
    }
  },
  {
    id: 2,
    type: 'Checkbox',
    name: 'Meeting with CFO to discuss pricing concerns',
    dueDate: new Date(),
    assignedTo: {
      name: 'David Wallace',
      avatar: DaveIcon
    }
  },
  {
    id: 3,
    type: 'Checkbox',
    name: 'Sync with Brian on POC',
    dueDate: new Date(),
    assignedTo: {
      name: 'David Wallace',
      avatar: DaveIcon
    }
  },
];

interface INote {
  id: number;
  author: {
    name: string;
    avatar: string;
  };
  createdAt: Date;
  title: string;
  description: string;
};

const mockNotes: INote[] = [
  {
    id: 0,
    author: {
      name: 'Bloom AI',
      avatar: LogoIcon
    },
    createdAt: new Date(),
    title: 'Client engagement pattern',
    description: `Zoom has accessed our support team 12 times in the last quarter, primarily regarding data integration issues. AI suggests a potential need for the Premium Support Package, which includes 24/7 support and dedicated account management.
Suggested next step: Proposal presentation during the next scheduled review meeting.`
  },
  {
    id: 1,
    author: {
      name: 'David Wallace',
      avatar: DaveIcon
    },
    createdAt: new Date(),
    title: 'Follow-up required',
    description: `Spoke with Andrew Bosworth (CTO of Zoom) about their interest in expanding data security capabilities. He mentioned a board meeting next month where they’ll discuss budget allocation for this. Need to follow up in early September to check if additional resources are needed for the proposal.`
  },
  {
    id: 2,
    author: {
      name: 'Bloom AI',
      avatar: LogoIcon
    },
    createdAt: new Date(),
    title: 'Cross-sell potential identified',
    description: `AI analysis has identified a cross-sell opportunity for Zoom. The company has recently shown increased activity in their cloud storage solution, particularly in security settings. Recommending the introduction of our Advanced Security Features package, which aligns with their recent behavior patterns. Estimated probability of successful upsell: 85%.`
  },
  {
    id: 3,
    author: {
      name: 'Amanda Guthrie',
      avatar: SarahIcon
    },
    createdAt: new Date(),
    title: 'Customer Feedback',
    description: `Zoom provided positive feedback on the recent software update but mentioned some concerns about the complexity of setting up new features.
Action Item: Arrange a training session for their IT team to ensure they can fully leverage the new functionalities.`
  },
];

interface INoteSectionProps {
  note: INote;
};

function NoteSection({ note }: INoteSectionProps) {
  return (
    <div className="border-t border-solid border-t-gray-200">
      <div className="mt-5 flex justify-between items-center">
        <div className="flex gap-1 flex-col">
          <div className="flex items-center gap-2">
            <Avatar square={false} src={note.author.avatar} outline={false} className="size-4" />
            <span className="text-sm leading-[17.5px] font-medium">{note.author.name}</span>
          </div>
          <div className="opacity-60 text-xs">
            {formatDateTime(note.createdAt)}
          </div>
        </div>
        <div>
          <EllipsisHorizontalIcon height={16} />
        </div>
      </div>
      <div className="mt-5">
        <span className="text-sm leading-[17.5px] font-semibold">{note.title}</span>
      </div>
      <div className="mt-2">
        <div className="text-sm font-normal">{note.description}</div>
      </div>
    </div>
  )
}

export default function DemoCompanyDetailTasksPage() {
  const { companyId } = useParams();

  return (
    <main>
      <MainSidebar pageTitle={mockCompanyNames[companyId!]}>
        <div className="flex h-full gap-0.5 bg-zinc-100">
          <div className="text-cell px-8 py-5 flex-1 bg-white">
            <div className="flex mb-5 justify-between">
              <div className="flex gap-5 items-center">
                <div className="text-xl leading-5 font-p22 font-medium">Tasks</div>
              </div>
              <div className="flex gap-3">
                <Button color="white" className="gap-2 flex items-center">
                  <img src={PlusIcon} className="w-4 h-4 invert" />
                  Add task
                </Button>
              </div>
            </div>
            <Table grid dense={true} fixedHeader={true}>
              <TableHead>
                <TableRow>
                  <TableHeader>Task</TableHeader>
                  <TableHeader>Due</TableHeader>
                  <TableHeader>Assigned to</TableHeader>
                </TableRow>
              </TableHead>
              <TableBody>
                {mockTasks.map((task) => (
                  <TableRow key={task.id}>
                    <TableCell>
                      <div className="flex gap-4 items-center">
                        {task.type === 'Checkbox' ? <Checkbox /> : 
                          <img src={AIStarIcon} className="w-4 h-4" />
                        }
                        {task.type === 'Suggest' && (
                          <div>
                            <span className="font-semibold">Suggested:</span>
                            {" "}
                            <span className="opacity-60">{task.name}</span>
                          </div>
                        )}
                        {task.type === 'Checkbox' && (
                          <div>{task.name}</div>
                        )}
                      </div>
                    </TableCell>
                    <TableCell>
                      {formatDateTime(task.dueDate)}
                    </TableCell>
                    <TableCell>
                      <div className="flex gap-2 items-center">
                        <Avatar square={false} src={task.assignedTo.avatar} outline={false} className="size-4" />
                        <Dropdown>
                          <DropdownButton outline className="!border-none hover:!bg-transparent !outline-none !p-0">
                            <div className="flex gap-2 items-center">
                              <span className="font-normal">{task.assignedTo.name}</span>
                              <ChevronDownIcon className="w-4 h-4" />
                            </div>
                          </DropdownButton>
                          <DropdownMenu className="min-w-48">
                            <DropdownItem>Thomas</DropdownItem>
                            <DropdownItem>Jerry</DropdownItem>
                            <DropdownItem>Peter</DropdownItem>
                          </DropdownMenu>
                        </Dropdown>
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <div className="flex mb-5 justify-between mt-8">
              <div className="flex gap-5 items-center">
                <div className="text-xl leading-5 font-p22 font-medium">Notes</div>
              </div>
              <div className="flex gap-3">
                <Button color="white" className="gap-2 flex items-center">
                  <img src={PlusIcon} className="w-4 h-4 invert" />
                  Add note
                </Button>
              </div>
            </div>
            <div className="flex flex-col gap-5">
              {mockNotes.map(note => (
                <NoteSection key={note.id} note={note} />
              ))}
            </div>
          </div>
          <div className="min-w-[456px] bg-white">
            <DemoCompanyProfileAside />
          </div>
        </div>
      </MainSidebar>
    </main>
  )
}