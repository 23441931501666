import React from 'react';
import SvgIcon, { SvgIconProps } from '../SvgIcon';

export const CopperIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon
    {...props}
    style={{ ...props.style, fill: 'transparent' }}
    viewBox="0 0 18 19"
  >
    <path
      d="M9.10896 18.7627C7.77966 18.7627 6.55932 18.5448 5.44794 18.0872C4.33656 17.6295 3.37772 16.9976 2.57143 16.1913C1.76513 15.385 1.13317 14.4262 0.675545 13.3148C0.217917 12.2034 0 10.9831 0 9.65376C0 8.30267 0.261501 7.03875 0.762712 5.86199C1.26392 4.68524 1.98305 3.66102 2.87651 2.78935C3.76998 1.91768 4.83777 1.24214 6.03632 0.740925C7.23487 0.239715 8.54237 0 9.93705 0C10.9831 0 11.9637 0.152542 12.8571 0.435835C13.7724 0.74092 14.5569 1.15497 15.2324 1.67797C15.908 2.20097 16.431 2.83293 16.8232 3.55206C17.2155 4.27119 17.4116 5.0557 17.4116 5.86199C17.4116 6.77725 17.1283 7.51816 16.5617 8.12833C15.9952 8.7385 15.2978 9.04358 14.4697 9.04358C13.6199 9.04358 12.9007 8.73851 12.3341 8.15013C11.7676 7.56175 11.4843 6.79903 11.4843 5.90557C11.4843 5.64407 11.5061 5.40436 11.5714 5.16465C11.6368 4.92494 11.724 4.57628 11.8329 4.11865C11.9419 3.70461 12.0291 3.42131 12.0726 3.20339C12.1162 3.00727 12.1162 2.78935 12.1162 2.54964C12.1162 1.93947 11.9201 1.41647 11.5061 1.02422C11.1138 0.631964 10.6126 0.435835 10.0024 0.435835C9.45763 0.435835 8.97821 0.610175 8.52058 0.958843C8.06295 1.30751 7.6707 1.78693 7.32203 2.37531C6.97337 2.96369 6.71186 3.68281 6.51574 4.5109C6.31961 5.33899 6.21065 6.21066 6.21065 7.1477C6.21065 9.32688 6.75545 11.0484 7.84504 12.3124C8.93463 13.5981 10.3947 14.23 12.247 14.23C13.293 14.23 14.23 14.0339 15.0799 13.6416C15.9298 13.2494 16.7579 12.6392 17.5642 11.8111L18 12.0291C16.0169 16.4964 13.0315 18.7627 9.10896 18.7627Z"
      fill="currentColor"
    />
  </SvgIcon>
);
