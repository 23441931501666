import React from 'react';
import SvgIcon, { SvgIconProps } from '../SvgIcon';

export const IterableIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props} style={{ fill: 'transparent' }} viewBox="0 0 22 23">
    <g opacity="0.25">
      <path
        d="M17.0994 8.9979L9.08008 17.0172L13.1198 21.057L21.1391 13.0377L17.0994 8.9979Z"
        fill="#59C1A7"
      />
    </g>
    <g opacity="0.25">
      <path
        d="M13.073 0.916177L9.0332 4.95594L17.0523 12.9751L21.0921 8.93532L13.073 0.916177Z"
        fill="#36C3F2"
      />
    </g>
    <g opacity="0.15">
      <path
        d="M4.92949 9.00722L0.889648 13.0471L8.90887 21.0663L12.9487 17.0264L4.92949 9.00722Z"
        fill="#EF3D55"
      />
    </g>
    <g opacity="0.15">
      <path
        d="M9.09727 0.725893L1.07812 8.74504L5.11789 12.7848L13.137 4.76566L9.09727 0.725893Z"
        fill="#6A266D"
      />
    </g>
    <path
      d="M11 0C12.5894 0 13.8779 1.28838 13.8779 2.87789C13.8779 4.46729 12.5895 5.75579 11 5.75579C9.41056 5.75579 8.12207 4.4674 8.12207 2.87789C8.12218 1.28838 9.41056 0 11 0Z"
      fill="#6A266D"
    />
    <path
      d="M19.122 8.15398C20.7114 8.15398 21.9999 9.44236 21.9999 11.0319C21.9999 12.6216 20.7115 13.91 19.122 13.91C17.5326 13.91 16.2441 12.6216 16.2441 11.0319C16.2443 9.44236 17.5326 8.15398 19.122 8.15398Z"
      fill="#36C3F2"
    />
    <path
      d="M11 16.2761C12.5894 16.2761 13.8779 17.5644 13.8779 19.1539C13.8779 20.7434 12.5895 22.0321 11 22.0321C9.41056 22.0321 8.12207 20.7434 8.12207 19.1539C8.12218 17.5645 9.41056 16.2761 11 16.2761Z"
      fill="#59C1A7"
    />
    <path
      d="M2.87789 8.12196C4.4673 8.12196 5.75579 9.41034 5.75579 10.9999C5.75579 12.5896 4.46741 13.8777 2.87789 13.8777C1.28849 13.8777 0 12.5896 0 10.9999C0 9.41045 1.28849 8.12196 2.87789 8.12196Z"
      fill="#EF3D55"
    />
  </SvgIcon>
);
