import { FC, PropsWithChildren } from "react"

type SVGProps = React.SVGProps<SVGSVGElement>; 

export interface SvgIconProps extends SVGProps {
};

const SvgIcon: React.FC<SvgIconProps> = ({ children, ...props }) => {
  if (props.style) {
    props.style.width = '24px';
    props.style.height = '24px';
  }
  return <svg {...props}>{children}</svg>
};

export default SvgIcon;