import React from 'react';
import SvgIcon, { SvgIconProps } from '../SvgIcon';

export const RedditIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props} style={{ fill: 'transparent' }} viewBox="0 0 24 21">
    <g clip-path="url(#clip0_reddit)">
      <path
        d="M20.4991 13.971C22.4327 13.971 24.0002 12.4035 24.0002 10.4699C24.0002 8.53632 22.4327 6.96883 20.4991 6.96883C18.5655 6.96883 16.998 8.53632 16.998 10.4699C16.998 12.4035 18.5655 13.971 20.4991 13.971Z"
        fill="url(#paint0_reddit)"
      />
      <path
        d="M3.50108 13.971C5.43467 13.971 7.00215 12.4035 7.00215 10.4699C7.00215 8.53632 5.43467 6.96883 3.50108 6.96883C1.56748 6.96883 0 8.53632 0 10.4699C0 12.4035 1.56748 13.971 3.50108 13.971Z"
        fill="url(#paint1_reddit)"
      />
      <path
        d="M12.0088 20.9742C17.529 20.9742 22.004 17.618 22.004 13.4779C22.004 9.33777 17.529 5.98154 12.0088 5.98154C6.48865 5.98154 2.01367 9.33777 2.01367 13.4779C2.01367 17.618 6.48865 20.9742 12.0088 20.9742Z"
        fill="url(#paint2_reddit)"
      />
      <path
        d="M9.05317 12.7488C8.99487 14.0187 8.15194 14.4796 7.1702 14.4796C6.18845 14.4796 5.44048 13.8294 5.49934 12.5594C5.55765 11.2895 6.40057 10.4488 7.38232 10.4488C8.36406 10.4488 9.11203 11.4783 9.05317 12.7488Z"
        fill="#842123"
      />
      <path
        d="M18.517 12.5594C18.5753 13.8294 17.8273 14.4796 16.8461 14.4796C15.8649 14.4796 15.022 14.0187 14.9632 12.7488C14.9048 11.4789 15.6528 10.4488 16.634 10.4488C17.6152 10.4488 18.4581 11.289 18.517 12.5594Z"
        fill="#842123"
      />
      <path
        d="M9.05422 12.8599C8.99925 14.0487 8.21074 14.4796 7.2923 14.4796C6.37386 14.4796 5.67364 13.8338 5.72861 12.6455C5.78359 11.4566 6.57209 10.6793 7.49053 10.6793C8.40898 10.6793 9.10919 11.671 9.05422 12.8599Z"
        fill="url(#paint3_reddit)"
      />
      <path
        d="M14.963 12.8599C15.018 14.0487 15.8065 14.4796 16.7249 14.4796C17.6434 14.4796 18.3436 13.8338 18.2886 12.6455C18.2336 11.4566 17.4451 10.6793 16.5267 10.6793C15.6082 10.6793 14.908 11.671 14.963 12.8599Z"
        fill="url(#paint4_reddit)"
      />
      <path
        d="M8.06253 12.1985C8.2772 12.1985 8.45123 12.0086 8.45123 11.7743C8.45123 11.54 8.2772 11.35 8.06253 11.35C7.84786 11.35 7.67383 11.54 7.67383 11.7743C7.67383 12.0086 7.84786 12.1985 8.06253 12.1985Z"
        fill="#FFC49C"
      />
      <path
        d="M17.3047 12.1985C17.5194 12.1985 17.6934 12.0086 17.6934 11.7743C17.6934 11.54 17.5194 11.35 17.3047 11.35C17.09 11.35 16.916 11.54 16.916 11.7743C16.916 12.0086 17.09 12.1985 17.3047 12.1985Z"
        fill="#FFC49C"
      />
      <path
        d="M12.0085 15.327C10.7691 15.327 9.58131 15.387 8.48296 15.4958C8.29527 15.5147 8.17644 15.7057 8.24918 15.8767C8.86444 17.3188 10.316 18.3316 12.0085 18.3316C13.701 18.3316 15.1525 17.3188 15.7677 15.8767C15.8405 15.7057 15.7217 15.5147 15.534 15.4958C14.4356 15.3864 13.2479 15.327 12.0085 15.327Z"
        fill="#BBCFDA"
      />
      <path
        d="M12.0085 15.6024C10.773 15.6024 9.58915 15.6629 8.49413 15.774C8.30699 15.7929 8.18816 15.9872 8.26091 16.161C8.8745 17.6259 10.321 18.6548 12.0085 18.6548C13.696 18.6548 15.1426 17.6259 15.7562 16.161C15.8289 15.9878 15.7101 15.7934 15.5229 15.774C14.4279 15.6629 13.244 15.6024 12.0085 15.6024Z"
        fill="white"
      />
      <path
        d="M12.0089 15.4591C10.7929 15.4591 9.62733 15.5191 8.54952 15.628C8.36517 15.6468 8.24856 15.8379 8.32019 16.0089C8.92378 17.451 10.3481 18.4638 12.0095 18.4638C13.6709 18.4638 15.0947 17.451 15.6988 16.0089C15.7705 15.8379 15.6538 15.6468 15.4695 15.628C14.3917 15.5186 13.2261 15.4591 12.0095 15.4591H12.0089Z"
        fill="url(#paint5_reddit)"
      />
      <path
        d="M17.483 4.96203C18.8532 4.96203 19.964 3.85124 19.964 2.48102C19.964 1.11079 18.8532 0 17.483 0C16.1127 0 15.002 1.11079 15.002 2.48102C15.002 3.85124 16.1127 4.96203 17.483 4.96203Z"
        fill="url(#paint6_reddit)"
      />
      <path
        d="M11.9726 6.29749C11.6756 6.29749 11.4346 6.17311 11.4346 5.98154C11.4346 3.75873 13.2426 1.95072 15.4654 1.95072C15.7625 1.95072 16.0035 2.19171 16.0035 2.48879C16.0035 2.78587 15.7625 3.02686 15.4654 3.02686C13.8362 3.02686 12.5102 4.35233 12.5102 5.98209C12.5102 6.17367 12.2692 6.29805 11.9721 6.29805L11.9726 6.29749Z"
        fill="url(#paint7_reddit)"
      />
      <path
        d="M8.40058 13.4479C8.40058 13.9077 7.91138 14.1142 7.30722 14.1142C6.70307 14.1142 6.21387 13.9077 6.21387 13.4479C6.21387 12.9881 6.70307 12.615 7.30722 12.615C7.91138 12.615 8.40058 12.9881 8.40058 13.4479Z"
        fill="#FF6101"
      />
      <path
        d="M17.8029 13.4479C17.8029 13.9077 17.3137 14.1142 16.7096 14.1142C16.1054 14.1142 15.6162 13.9077 15.6162 13.4479C15.6162 12.9881 16.1054 12.615 16.7096 12.615C17.3137 12.615 17.8029 12.9881 17.8029 13.4479Z"
        fill="#FF6101"
      />
    </g>
    <defs>
      <radialGradient
        id="paint0_reddit"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(20.573 8.38555) scale(7.07711 6.15709)"
      >
        <stop stop-color="#FEFFFF" />
        <stop offset="0.4" stop-color="#FEFFFF" />
        <stop offset="0.51" stop-color="#F9FCFC" />
        <stop offset="0.62" stop-color="#EDF3F5" />
        <stop offset="0.7" stop-color="#DEE9EC" />
        <stop offset="0.72" stop-color="#D8E4E8" />
        <stop offset="0.76" stop-color="#CCD8DF" />
        <stop offset="0.8" stop-color="#C8D5DD" />
        <stop offset="0.83" stop-color="#CCD6DE" />
        <stop offset="0.85" stop-color="#D8DBE2" />
        <stop offset="0.88" stop-color="#EDE3E9" />
        <stop offset="0.9" stop-color="#FFEBEF" />
      </radialGradient>
      <radialGradient
        id="paint1_reddit"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(3.57493 1.08641) scale(7.07711 6.15709)"
      >
        <stop stop-color="#FEFFFF" />
        <stop offset="0.4" stop-color="#FEFFFF" />
        <stop offset="0.51" stop-color="#F9FCFC" />
        <stop offset="0.62" stop-color="#EDF3F5" />
        <stop offset="0.7" stop-color="#DEE9EC" />
        <stop offset="0.72" stop-color="#D8E4E8" />
        <stop offset="0.76" stop-color="#CCD8DF" />
        <stop offset="0.8" stop-color="#C8D5DD" />
        <stop offset="0.83" stop-color="#CCD6DE" />
        <stop offset="0.85" stop-color="#D8DBE2" />
        <stop offset="0.88" stop-color="#EDE3E9" />
        <stop offset="0.9" stop-color="#FFEBEF" />
      </radialGradient>
      <radialGradient
        id="paint2_reddit"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(12.2243 7.49114) scale(21.3474 14.9432)"
      >
        <stop stop-color="#FEFFFF" />
        <stop offset="0.4" stop-color="#FEFFFF" />
        <stop offset="0.51" stop-color="#F9FCFC" />
        <stop offset="0.62" stop-color="#EDF3F5" />
        <stop offset="0.7" stop-color="#DEE9EC" />
        <stop offset="0.72" stop-color="#D8E4E8" />
        <stop offset="0.76" stop-color="#CCD8DF" />
        <stop offset="0.8" stop-color="#C8D5DD" />
        <stop offset="0.83" stop-color="#CCD6DE" />
        <stop offset="0.85" stop-color="#D8DBE2" />
        <stop offset="0.88" stop-color="#EDE3E9" />
        <stop offset="0.9" stop-color="#FFEBEF" />
      </radialGradient>
      <radialGradient
        id="paint3_reddit"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(7.47998 13.3858) scale(1.78358 2.60402)"
      >
        <stop stop-color="#FF6600" />
        <stop offset="0.5" stop-color="#FF4500" />
        <stop offset="0.7" stop-color="#FC4301" />
        <stop offset="0.82" stop-color="#F43F07" />
        <stop offset="0.92" stop-color="#E53812" />
        <stop offset="1" stop-color="#D4301F" />
      </radialGradient>
      <radialGradient
        id="paint4_reddit"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(16.5372 13.3858) rotate(180) scale(1.78358 2.60402)"
      >
        <stop stop-color="#FF6600" />
        <stop offset="0.5" stop-color="#FF4500" />
        <stop offset="0.7" stop-color="#FC4301" />
        <stop offset="0.82" stop-color="#F43F07" />
        <stop offset="0.92" stop-color="#E53812" />
        <stop offset="1" stop-color="#D4301F" />
      </radialGradient>
      <radialGradient
        id="paint5_reddit"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(11.9906 18.8693) scale(6.28916 4.15085)"
      >
        <stop stop-color="#172E35" />
        <stop offset="0.29" stop-color="#0E1C21" />
        <stop offset="0.73" stop-color="#030708" />
        <stop offset="1" />
      </radialGradient>
      <radialGradient
        id="paint6_reddit"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(17.5368 0.192162) scale(5.52065 5.41024)"
      >
        <stop stop-color="#FEFFFF" />
        <stop offset="0.4" stop-color="#FEFFFF" />
        <stop offset="0.51" stop-color="#F9FCFC" />
        <stop offset="0.62" stop-color="#EDF3F5" />
        <stop offset="0.7" stop-color="#DEE9EC" />
        <stop offset="0.72" stop-color="#D8E4E8" />
        <stop offset="0.76" stop-color="#CCD8DF" />
        <stop offset="0.8" stop-color="#C8D5DD" />
        <stop offset="0.83" stop-color="#CCD6DE" />
        <stop offset="0.85" stop-color="#D8DBE2" />
        <stop offset="0.88" stop-color="#EDE3E9" />
        <stop offset="0.9" stop-color="#FFEBEF" />
      </radialGradient>
      <radialGradient
        id="paint7_reddit"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(15.2355 5.76497) scale(4.52502 4.52502)"
      >
        <stop offset="0.48" stop-color="#7A9299" />
        <stop offset="0.67" stop-color="#172E35" />
        <stop offset="0.75" />
        <stop offset="0.82" stop-color="#172E35" />
      </radialGradient>
      <clipPath id="clip0_reddit">
        <rect width="24" height="20.9742" fill="white" />
      </clipPath>
    </defs>
  </SvgIcon>
);
