import React from 'react';
import SvgIcon, { SvgIconProps } from '../SvgIcon';

export const DeepLIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon
    {...props}
    style={{ ...props.style, fill: 'transparent' }}
    viewBox="0 0 18 22"
  >
    <path
      d="M12.3566 11.1767C11.547 11.1767 10.8911 10.5208 10.8911 9.71126C10.8911 9.62587 10.8983 9.54222 10.9128 9.46089L7.4082 7.44287C7.15319 7.65822 6.82351 7.78818 6.46373 7.78818C5.65416 7.78818 4.99798 7.13201 4.99798 6.32272C4.99798 5.51343 5.65416 4.85726 6.46373 4.85726C7.27331 4.85726 7.92949 5.51343 7.92949 6.32272C7.92949 6.41592 7.91994 6.5071 7.90315 6.59567L11.395 8.60616C11.6523 8.38184 11.9887 8.2458 12.3566 8.2458C13.1656 8.2458 13.8217 8.90197 13.8217 9.71126C13.8217 10.5208 13.1656 11.1767 12.3566 11.1767ZM7.91154 13.123C7.91154 13.9322 7.25566 14.5884 6.44608 14.5884C5.63708 14.5884 4.98091 13.9322 4.98091 13.123C4.98091 12.3134 5.63708 11.6572 6.44608 11.6572C6.80991 11.6572 7.14219 11.7901 7.39835 12.0095L9.95329 10.5411C10.0624 10.8563 10.232 11.1434 10.4477 11.3889L7.8881 12.8601C7.90315 12.9455 7.91154 13.0332 7.91154 13.123ZM16.7234 4.17938L9.76457 0.202973C9.29075 -0.0676578 8.70896 -0.0676578 8.23514 0.202973L1.27687 4.17938C0.796392 4.45377 0.5 4.96493 0.5 5.51777V13.6066C0.5 14.1571 0.793787 14.6663 1.27079 14.9415L13.2501 21.8561L13.2521 16.9448L16.7283 14.9415C17.2059 14.6663 17.5 14.1571 17.5 13.606V5.51777C17.5 4.96493 17.2036 4.45377 16.7234 4.17938Z"
      fill="currentColor"
    />
  </SvgIcon>
);
