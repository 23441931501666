import React from 'react';
import SvgIcon, { SvgIconProps } from '../SvgIcon';

export const BannerbearIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon
    {...props}
    style={{ ...props.style, fill: 'transparent' }}
    viewBox="0 0 19 24"
  >
    <path
      d="M9.36904 23C5.17099 23 1.75586 19.5717 1.75586 15.3577C1.75586 11.194 5.051 1 9.36904 1C13.6873 1 16.9827 11.194 16.9827 15.3577C16.9827 19.5717 13.5673 23 9.36904 23ZM9.34315 1.46588C5.45645 1.46588 2.22199 11.5343 2.22199 15.3331C2.22199 19.2751 5.41669 22.4824 9.34315 22.4824C13.2699 22.4824 16.4648 19.2751 16.4648 15.3331C16.4648 11.5345 13.2301 1.46588 9.34315 1.46588Z"
      fill="black"
      stroke="black"
      strokeWidth="0.704"
    />
    <path
      d="M1.27104 3.48284C1.14048 3.55942 1.04601 3.69564 1.01264 3.85592C0.979685 4.01641 1.0118 4.17857 1.10169 4.30139C1.20305 4.44033 1.37427 4.52361 1.55947 4.52361C1.70129 4.52361 1.8331 4.47444 1.93091 4.38488C2.0429 4.28277 2.10672 4.13002 2.1059 3.96598C2.10505 3.80214 2.03978 3.65002 1.92632 3.54896C1.75343 3.39475 1.47146 3.36629 1.27104 3.48284Z"
      fill="black"
      stroke="black"
      strokeWidth="0.645333"
    />
    <path
      d="M17.4177 3.48285C17.2177 3.36609 16.9353 3.39476 16.7624 3.54876C16.6493 3.64983 16.5841 3.80194 16.583 3.96557C16.582 4.12962 16.6458 4.28237 16.7582 4.38469C16.856 4.47424 16.9878 4.52341 17.1299 4.52341C17.3146 4.52341 17.4859 4.44034 17.5874 4.3012C17.6771 4.17879 17.7092 4.01621 17.6761 3.85572C17.6429 3.69523 17.5486 3.55943 17.4177 3.48285Z"
      fill="black"
      stroke="black"
      strokeWidth="0.645333"
    />
    <path
      d="M12.0973 14.4196C11.9801 14.4196 11.8852 14.5148 11.8852 14.6322C11.8852 15.2763 11.3632 15.8002 10.7213 15.8002C10.0794 15.8002 9.55671 15.2763 9.55671 14.6322V12.1546C11.4652 12.0152 12.9787 10.0379 12.9787 9.16345C12.9787 8.2568 11.352 7.52235 9.34461 7.52235C7.33809 7.52235 5.71094 8.2568 5.71094 9.16345C5.71094 10.0379 7.22485 12.0152 9.13272 12.1546V14.6322C9.13272 15.2763 8.6107 15.8002 7.96856 15.8002C7.32683 15.8002 6.80461 15.2763 6.80461 14.6322C6.80461 14.5148 6.70992 14.4196 6.59271 14.4196C6.4755 14.4196 6.38061 14.5148 6.38061 14.6322C6.38061 15.5108 7.09304 16.2254 7.96835 16.2254C8.55668 16.2254 9.07015 15.9021 9.34461 15.424C9.61886 15.9021 10.1325 16.2254 10.7211 16.2254C11.5966 16.2254 12.309 15.5108 12.309 14.6322C12.3094 14.5148 12.2145 14.4196 12.0973 14.4196Z"
      fill="black"
      stroke="black"
      strokeWidth="0.586667"
    />
  </SvgIcon>
);
