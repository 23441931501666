import { fetcher } from '@/services/api'
import { getPageParamString, IPageParams } from '@/services/contact';
import useSWR from 'swr'

export function useBusinessList(pageParams?: IPageParams) {
  const { data, error } = useSWR(`/agents/orgs/x-functions/businesses?${getPageParamString(pageParams)}`, fetcher);

  return {
    data,
    isLoading: !error && !data,
    isError: !!error
  }
};
