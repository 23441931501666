import { useRef, useCallback, useState, useEffect } from 'react';
import CodeMirror from 'codemirror';
import { validateJSON } from './json-schemas';
import { JSONSchema, JSONSchemaType, Maybe } from '@makeropsinc/workflow-types';

interface Props {
  value: JSONSchemaType;
  schema: JSONSchema;
  onChange?: (arg0: JSONSchemaType) => void;
}

const createErrorMarker = (error: string) => {
  const marker = document.createElement('div');
  marker.classList.add('error-marker');
  marker.innerHTML = '&nbsp;';

  const err = document.createElement('div');
  err.innerHTML = error;
  err.classList.add('error-message');
  marker.appendChild(err);

  return marker;
};

const makeProcessor = () => {
  let marks: CodeMirror.TextMarker[] = [];

  return (
    editor: CodeMirror.EditorFromTextArea,
    schema: JSONSchema,
    onChange: (arg0: string) => void
  ) => {
    const value = editor.getValue();
    const errors = validateJSON(value, schema, true);
    editor.clearGutter('errors');
    marks.forEach((m) => m.clear());
    let newMarks: any[] = [];
    newMarks = errors.map((error) => {
      editor.setGutterMarker(
        error.from.line,
        'errors',
        createErrorMarker(error.message)
      );
      return editor.markText(error.from, error.to, {
        className: 'CodeMirror-highlighted',
      });
    });
    marks = newMarks;

    return onChange(value);
  };
};

export const JSONEditor: React.FC<Props> = ({ value, onChange, schema }) => {
  const [editor, setEditor] = useState(
    undefined as Maybe<CodeMirror.EditorFromTextArea>
  );
  const [editorValue, setEditorTextValue] = useState(
    value ? JSON.stringify(value, null, 2) : ''
  );
  const domRef = useRef();

  const processChange = makeProcessor();

  // @ts-ignore
  const setRef = useCallback((elem) => {
    if (!elem) return undefined;

    domRef.current = elem;
    const e = CodeMirror.fromTextArea(
      domRef.current as unknown as HTMLTextAreaElement,
      {
        mode: 'application/json',
        gutters: ['errors'],
        tabSize: 2,
        indentUnit: 2,
        indentWithTabs: false,
        lineNumbers: true,
        extraKeys: {
          Tab: (cm) => {
            cm.replaceSelection('  ', 'end');
          },
        },
        viewportMargin: Infinity,
      }
    );
    setEditor(e);

    return () => {
      e.toTextArea();
      setEditor(undefined);
    };
  }, []);

  const setEditorValue = useCallback(
    (val?: string) => {
      if (!editor) return;

      const cursor = editor.getCursor();
      const scrollInfo = editor.getScrollInfo();

      let newVal = val || editorValue;
      if (typeof newVal !== 'string')
        newVal = JSON.stringify(newVal || {}, null, 2);

      const oldVal =
        editorValue && editorValue !== 'string'
          ? JSON.stringify(editorValue)
          : editorValue;

      if (newVal !== oldVal) {
        editor.setValue(newVal);
        editor.setCursor(cursor);
        editor.scrollTo(scrollInfo.left, scrollInfo.top);
      }
    },
    [editor, editorValue]
  );

  useEffect(() => {
    if (editor) {
      editor.setOption('readOnly', !onChange);
    }
  }, [editor, onChange]);

  useEffect(() => {
    if (!editor) return undefined;

    const handler = onChange
      ? () => processChange(editor, schema, setEditorTextValue)
      : undefined;

    if (handler) editor.on('change', handler);

    return () => {
      if (handler) editor.off('change', handler);
    };
  }, [editor, processChange, onChange, schema]);

  useEffect(() => {
    if (editor) {
      setEditorValue();
    }
  }, [editor, setEditorValue]);

  useEffect(() => {
    if (!onChange) return;
    if (!editorValue) return;

    let newVal = {};
    let isJSON = true;
    try {
      newVal = JSON.parse(editorValue);
    } catch {
      isJSON = false;
    }

    if (isJSON) onChange(newVal);
  }, [editorValue, onChange]);

  return (
    <div>
      <textarea ref={setRef} />
    </div>
  );
};
