import { ButtonHTMLAttributes, FC, PropsWithChildren } from "react";
import { Link } from "react-router-dom";
import { ArrowRightUpIcon } from "./icons";

interface IButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  url?: string;
  className?: string;
  size?: "sm" | "lg";
  color?: "dark" | "white";
  withArrow?: boolean;
}

export const Button: FC<PropsWithChildren<IButtonProps>> = ({
  children,
  url,
  className,
  color,
  size = "lg",
  withArrow = false,
  ...props
}) => {
  const button = (
    <button
      className={`${className} flex items-center text-button whitespace-nowrap justify-center ${
        color === "dark"
          ? "text-white bg-black border-black"
          : "text-black bg-white border-gray-300"
      } ${!withArrow && 'rounded-full'} ${withArrow && 'px-1.5 rounded-lg'} ${size === 'lg' && 'h-9 pl-[16px] pr-[20px] py-[10px] text-sm gap-2'} ${size === 'sm' && 'h-6 px-3 py-1 text-xs gap-1.5'} font-firstext outline-none focus:outline-none z-[100] border ${
        props.disabled ? "opacity-60" : ""
      }
    `}
      {...props}
    >
      {withArrow && (
        <img src={ArrowRightUpIcon} className="size-4" />
      )}
      {children}
    </button>
  );

  if (url) {
    return <Link to={url}>{button}</Link>;
  }

  return <>{button}</>;
};
