import { Dialog, DialogPanel, DialogTitle } from "@headlessui/react";
import { IconButton } from "./icon-button";
import { CloseLeftIcon, CloseRightIcon, XMarkIcon } from "./icons";
import { useState } from "react";
import { sourceConfigs, SourceIcon, SourceId } from "./source-icon";
import { Form } from "./form/form";
import { DetailsForm } from "./form/form-details";
import { JSONSchema, JSONSchemaType } from "@makeropsinc/workflow-types";

interface ISourceDrawerProps {
  isDrawerOpen: boolean;
  setDrawerOpen: (open: boolean) => void;
  sourceId: SourceId;
};

const emptyDetails = { key: '', description: '' } as Record<string, string>;

export default function SourceDrawer({ isDrawerOpen, setDrawerOpen, sourceId }: ISourceDrawerProps) {
  const [drawerSize, setDrawerSize] = useState('sm');
  const [credentials, setCredentials] = useState<JSONSchemaType>(null);
  const [details, setDetails] = useState<Record<string, string>>(
    emptyDetails as Record<
      string,
      string
    >
  );
  
  const onResizeDrawer = () => {
    setDrawerSize(drawerSize => drawerSize === 'sm' ? 'lg' : 'sm');
  };

  const getSourceConfig = () => {
    return sourceConfigs.find(config => config.id === sourceId)!;
  };

  const sourceConfig = getSourceConfig();

  console.log({ sourceConfig });

  return (
    <Dialog open={isDrawerOpen} onClose={setDrawerOpen} className="relative z-10">
      <div className="fixed inset-0" />
      <div className="fixed inset-0 overflow-hidden">
        <div className="absolute inset-0 overflow-hidden">
          <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
            <DialogPanel
              transition
              className={`pointer-events-auto w-screen ${drawerSize === 'sm' ? 'max-w-md' : 'max-w-6xl'} transform transition duration-500 ease-in-out data-[closed]:translate-x-full sm:duration-700`}
            >
              <div className="flex h-full flex-col overflow-y-auto bg-white pt-6 shadow-xl">
                <div className="px-6 pb-[16px] border-b border-bloom-grey-line">
                  <div className="flex items-center justify-between">
                    <DialogTitle className="text-title flex items-center gap-2">
                      <div className="size-6">
                        <SourceIcon id={sourceId} />
                      </div>
                      Manage {getSourceConfig().title} accounts
                    </DialogTitle>
                    <div className="flex gap-3">
                      <IconButton src={drawerSize === 'sm' ? CloseLeftIcon : CloseRightIcon} onClick={onResizeDrawer} />
                      <IconButton src={XMarkIcon} onClick={() => setDrawerOpen(false)} />
                    </div>
                  </div>
                </div>
                <div className="mt-4 px-6">
                  {sourceConfig.type === 'credentials' && (
                    <Form
                      readonlyKey={false}
                      showCredentialsForm={true}
                      credentialsSchema={sourceConfig.credentialsSchema as JSONSchema}
                      credentials={credentials}
                      setCredentials={setCredentials}
                      details={details}
                      setDetails={setDetails}
                      onValidationUpdated={() => {}}
                    />
                  )}
                  {sourceConfig.type === 'oauth2' && (
                    <DetailsForm
                      readonlyKey={false}
                      details={details}
                      setDetails={setDetails}
                      onValidationUpdated={() => {}}
                    />
                  )}
                </div>
              </div>
            </DialogPanel>
          </div>
        </div>
      </div>
    </Dialog>
  )
}
