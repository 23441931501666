import { Dialog, DialogPanel, DialogTitle } from "@headlessui/react";
import { IconButton } from "./icon-button";
import { ArchivedCheckIcon, ArchivedIcon, CheckIcon, CircleCheckIcon, DaveIcon, DocIcon, InboxBoldIcon, SalesforceIcon, SarahIcon, SortDownIcon, UnreadIcon, XMarkIcon } from "./icons";
import { useEffect, useState } from "react";
import { ChevronDownIcon } from "@heroicons/react/16/solid";
import { Dropdown, DropdownButton, DropdownItem, DropdownMenu } from "../cataylst/dropdown";
import Notification from "./notification";
import { toast } from "react-toastify";
import { ZoomIcon } from "./company-icons";

interface INotificationDrawerProps {
  isDrawerOpen: boolean;
  setDrawerOpen: (open: boolean) => void;
};

export default function NotificationDrawer({ isDrawerOpen, setDrawerOpen }: INotificationDrawerProps) {
  const [drawerSize, setDrawerSize] = useState('sm');
  const onNotificationMenu = () => {
    document.getElementById("notificationMenuButton")?.click();
  };

  const getToastNotification = () => {
    return (
      <Notification
        logoType="user"
        logoPath={ZoomIcon}
        profileIcon={DaveIcon}
        title="New task"
        color="primary"
        action={{
          title: 'View task'
        }}
        content={
          <div>
            James assigned a new task <span className="underline">Update proposal terms</span> to you for <span>Zoom</span>.
          </div>
        }
        insideToast
      />
    );
  }

  // useEffect(() => {
  //   toast(getToastNotification(), {
  //     hideProgressBar: true
  //   });
  // }, []);

  const getSidebarWidth = () => {
    return document.getElementById('sidebar')?.clientWidth || 64;
  };

  return (
    <Dialog open={isDrawerOpen} onClose={setDrawerOpen} className="relative z-10">
      <div className="fixed inset-0" />
      <div className="fixed inset-0 overflow-hidden">
        <div className="absolute inset-0 overflow-hidden">
          <div className="pointer-events-none fixed inset-y-0 flex max-w-full" style={{ left: `${getSidebarWidth()}px` }}>
            <DialogPanel
              transition
              className={`pointer-events-auto w-screen ${drawerSize === 'sm' ? 'max-w-md' : 'max-w-6xl'} transform transition duration-100`}
            >
              <div className="flex h-full flex-col overflow-y-auto bg-white pt-6 shadow-xl">
                <div className="px-6 pb-[16px] border-b border-bloom-grey-line">
                  <div className="flex items-center justify-between">
                    <DialogTitle className="text-title">Notifications</DialogTitle>
                    <div className="flex gap-3">
                      <div className="relative">
                        <IconButton src={SortDownIcon} onClick={onNotificationMenu} />
                        <Dropdown>
                          <DropdownButton outline className="!border-none hover:!bg-transparent !outline-none !p-0 !absolute opacity-0" id="notificationMenuButton">
                            <div className="flex gap-2 items-center">
                              <span className="font-normal"></span>
                              <ChevronDownIcon className="w-4 h-4" />
                            </div>
                          </DropdownButton>
                          <DropdownMenu anchor={{ gap: 8 }} className="min-w-32 p-4">
                            <div className="text-xs text-[#00000099] mb-2">Show</div>
                            <DropdownItem className="!px-0 mb-1">
                              <div className="flex gap-2 items-center relative w-full">
                                <img src={InboxBoldIcon} className="size-4" />
                                <span className="text-sm leading-[17.5px] font-firstext text-black font-medium">Read & unread</span>
                                <img src={CheckIcon} className="size-4 absolute right-[-32px]" />
                              </div>
                            </DropdownItem>
                            <DropdownItem className="!px-0 mb-1">
                              <div className="flex gap-2 items-center">
                                <img src={UnreadIcon} className="size-4" />
                                <span className="text-sm leading-[17.5px] font-firstext text-black">Unread</span>
                              </div>
                            </DropdownItem>
                            <DropdownItem className="!px-0 mb-1">
                              <div className="flex gap-2 items-center">
                                <img src={ArchivedIcon} className="size-4" />
                                <span className="text-sm leading-[17.5px] font-firstext text-black">Archived</span>
                              </div>
                            </DropdownItem>
                            <div className="text-xs text-[#00000099] mb-2">Actions</div>
                            <DropdownItem className="!px-0 mb-1">
                              <div className="flex gap-2 items-center">
                                <img src={CircleCheckIcon} className="size-4" />
                                <span className="text-sm leading-[17.5px] font-firstext text-black">Mark all as read</span>
                              </div>
                            </DropdownItem>
                            <DropdownItem className="!px-0 mb-1">
                              <div className="flex gap-2 items-center">
                                <img src={ArchivedIcon} className="size-4" />
                                <span className="text-sm leading-[17.5px] font-firstext text-black">Archive all</span>
                              </div>
                            </DropdownItem>
                            <DropdownItem className="!px-0 mb-1">
                              <div className="flex gap-2 items-center">
                                <img src={ArchivedCheckIcon} className="size-4" />
                                <span className="text-sm leading-[17.5px] font-firstext text-black">Archive read</span>
                              </div>
                            </DropdownItem>
                          </DropdownMenu>
                        </Dropdown>
                      </div>
                      <IconButton src={XMarkIcon} onClick={() => setDrawerOpen(false)} />
                    </div>
                  </div>
                </div>
                <div className="p-6">
                  <div className="text-xs font-medium font-firstext mb-1 tracking-wider">
                    THIS WEEK
                  </div>
                  <Notification
                    logoType="user"
                    logoPath={SarahIcon}
                    date={new Date()}
                    action={{
                      title: 'View engagement history'
                    }}
                    content={
                      <div>
                        <span className="underline">Sarah Hughes</span> from <span className="underline">Zoom</span> just viewed the <span className="underline">product pricing page</span>.
                      </div>
                    }
                    isUnread
                  />
                  <Notification
                    logoType="user"
                    logoPath={ZoomIcon}
                    date={new Date()}
                    title="Customer milestone"
                    color="success"
                    action={{
                      title: 'Schedule a check-in'
                    }}
                    content={
                      <div>
                        <span className="underline">Zoom</span> has successfully completed the onboarding process.
                      </div>
                    }
                    borderTop
                    isUnread
                  />
                  <Notification
                    logoType="user"
                    logoPath={ZoomIcon}
                    profileIcon={DaveIcon}
                    date={new Date()}
                    title="New task"
                    color="primary"
                    action={{
                      title: 'View task'
                    }}
                    content={
                      <div>
                        James assigned a new task <span className="underline">Update proposal terms</span> to you for <span>Zoom</span>.
                      </div>
                    }
                    borderTop
                  />
                  <div className="text-xs font-medium font-firstext mb-1 tracking-wider">
                    LAST WEEK
                  </div>
                  <Notification
                    logoType="icon"
                    logoPath={DocIcon}
                    date={new Date()}
                    action={{
                      title: 'View meeting summary'
                    }}
                    content={
                      <div>
                        A transcript and summary is available for your recent <span className="underline">demo meeting</span> with <span className="underline">Zoom</span>.
                      </div>
                    }
                    iconBackgroundColor="primary"
                    borderTop
                  />
                  <Notification
                    logoType="icon"
                    logoPath={DocIcon}
                    date={new Date()}
                    action={{
                      title: 'View document'
                    }}
                    isUnread
                    content={
                      <div>
                        <span className="underline">Zoom-Sales-Proposal</span> for <span className="underline">Zoom</span> has been updated by Amanda Guthrie.
                      </div>
                    }
                    iconBackgroundColor="black"
                    borderTop
                    borderBottom
                  />
                  <Notification
                    logoType="user"
                    logoPath={SalesforceIcon}
                    date={new Date()}
                    title="Integration alert"
                    color="danger"
                    action={{
                      title: 'Alert admin'
                    }}
                    content={
                      <div>
                        Integration with Saleforce has failed. Sync of new leads has been delayed.
                      </div>
                    }
                    borderTop
                  />
                  <Notification
                    logoType="icon"
                    logoPath={DocIcon}
                    date={new Date()}
                    action={{
                      title: 'View meeting summary'
                    }}
                    title="AI insight"
                    color="purple"
                    content={
                      <div>
                        A transcript and summary is available for your recent <span className="underline">demo meeting</span> with <span className="underline">Zoom</span>.
                      </div>
                    }
                    iconBackgroundColor="primary"
                    borderTop
                  />
                  <Notification
                    logoType="user"
                    logoPath={SalesforceIcon}
                    date={new Date()}
                    title="Integration alert"
                    color="danger"
                    action={{
                      title: 'Alert admin'
                    }}
                    content={
                      <div>
                        Integration with Saleforce has failed. Sync of new leads has been delayed.
                      </div>
                    }
                    borderTop
                  />
                </div>
              </div>
            </DialogPanel>
          </div>
        </div>
      </div>
    </Dialog>
  )
}
