import React from 'react';
import SvgIcon, { SvgIconProps } from '../SvgIcon';

export const GoogleCloudIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props} style={{ fill: 'transparent' }} viewBox="0 0 20 16.9">
    <path
      d="M12.7275 4.44625L13.3681 4.45719L15.1087 2.71656L15.1931 1.97906C13.8125 0.749063 11.9897 0 9.9978 0C6.39155 0 3.3478 2.45375 2.44686 5.7775C2.63686 5.645 3.04311 5.74438 3.04311 5.74438L6.52124 5.1725C6.52124 5.1725 6.69999 4.87656 6.79061 4.89125C7.19731 4.44443 7.69288 4.08751 8.24554 3.84335C8.79821 3.5992 9.39579 3.4732 9.99999 3.47344C11.0312 3.47563 11.9812 3.84 12.7275 4.44281V4.44625Z"
      fill="#EA4335"
    />
    <path
      d="M17.5531 5.78438C17.1491 4.29375 16.3162 2.9775 15.1922 1.97906L12.7275 4.44375C13.715 5.24063 14.3484 6.46 14.3484 7.825V8.26C15.5475 8.26 16.5216 9.23625 16.5216 10.4331C16.5216 11.6322 15.5453 12.6063 14.3484 12.6063H10.0022L9.56718 13.0438V15.6519L10.0022 16.0847H14.3484C15.8466 16.0826 17.2829 15.4866 18.3423 14.4272C19.4017 13.3679 19.9978 11.9316 20 10.4334C19.9978 8.51 19.0312 6.80844 17.5531 5.78469V5.78438Z"
      fill="#4285F4"
    />
    <path
      d="M5.65155 16.0875H9.9953V12.6063H5.65155C5.34294 12.6063 5.03792 12.54 4.75717 12.4119L4.12999 12.6041L2.38936 14.3447L2.23686 14.9322C3.21776 15.6805 4.41748 16.0856 5.65124 16.085L5.65155 16.0875Z"
      fill="#34A853"
    />
    <path
      d="M5.65156 4.78438C4.15336 4.78644 2.71711 5.3825 1.6577 6.44185C0.598281 7.50121 0.0021495 8.93743 0 10.4356C0 12.2709 0.879062 13.9028 2.23938 14.9366L4.75938 12.4166C4.37794 12.2446 4.05422 11.9662 3.82702 11.6148C3.59982 11.2635 3.47879 10.854 3.47844 10.4356C3.47844 9.23657 4.45469 8.2625 5.65156 8.2625C6.53281 8.2625 7.29031 8.79375 7.63281 9.54375L10.1528 7.02375C9.11938 5.66344 7.48719 4.78438 5.65188 4.78438H5.65156Z"
      fill="#FBBC05"
    />
  </SvgIcon>
);
