import React from 'react';
import SvgIcon, { SvgIconProps } from '../SvgIcon';

export const ServiceNowIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props} style={{ fill: 'transparent' }} viewBox="0 0 20 18">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 0C4.60195 0 0 4.39944 0 9.90224C0 12.8003 1.20111 15.405 3.10055 17.3045C3.79888 18.0028 4.90224 18.0028 5.69833 17.4022C6.80169 16.5014 8.29609 16.0056 10 16.0056C11.6969 16.0056 13.1006 16.5084 14.3017 17.4022C15.1048 18.0028 16.2011 17.905 16.8994 17.1997C18.7989 15.398 20 12.8003 20 9.90224C19.9022 4.49721 15.5028 0 10 0ZM9.90224 15C7.0042 15 4.90223 12.8003 4.90223 10C4.90223 7.19972 6.89945 5 9.90224 5C12.905 5 14.9022 7.29748 14.9022 10C14.9022 12.7025 12.8981 15 9.90224 15Z"
      fill="#82B6A1"
    />
  </SvgIcon>
);
