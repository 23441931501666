import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/cataylst/table";
import { AIStarIcon, AlertRed32Icon, ArrowRightIcon, CalendarIcon, DaveIcon, DocIcon, EmailIcon, EyePurple32Icon, ForwardGreen32Icon, PenIcon, PhoneIcon, SarahIcon, SecurityYellow32Icon, ThunderColoredIcon, VideoBlue32Icon, WhatsAppIcon } from "@/components/custom/icons";
import MainSidebar from "@/components/custom/sidebar";
import { useParams } from "react-router-dom";
import { formatDateTime } from "@/utils/format";
import { Badge } from "@/components/cataylst/badge";
import { Button } from "@/components/custom/button";
import DemoContactProfileAside from "./DemoContactProfileAside";
import AvatarGroup from "@/components/custom/avatar-group";

const mockContactNames = {
  sarah: 'Sarah Hughes'
};

interface IAction {
  id: number;
  icon: string;
  title: string;
  description: string;
  dueDate?: Date;
  button: {
    icon: string;
    title: string;
  };
};

const mockEvents = [
  {
    id: 0,
    name: 'Voice call',
    icon: PhoneIcon,
    date: new Date(),
    contacts: [
      {
        name: 'David',
        avatar: DaveIcon
      },
      {
        name: 'Sarah',
        avatar: SarahIcon
      },
    ],
    summary: 'Sarah asked about seat-based pricing in relation to distributed teams'
  },
  {
    id: 1,
    name: 'Email',
    icon: EmailIcon,
    date: new Date(),
    contacts: [
      {
        name: 'Sarah Hughes',
        avatar: SarahIcon
      },
    ],
    summary: "We're expanding and sometimes struggle with consistency and efficiency across the team. I'm looking for a solution that not only offers better visibility..."
  },
  {
    id: 2,
    name: 'WhatsApp',
    icon: WhatsAppIcon,
    date: new Date(),
    contacts: [
      {
        name: 'Sarah Hughes',
        avatar: SarahIcon
      },
    ],
    summary: "I’ll send you an email where it’s easier to describe"
  }
];

const mockNextActions: IAction[] = [
  {
    id: 0,
    icon: VideoBlue32Icon,
    title: 'Security demo',
    description: `Schedule a product demo focusing on Apple's new data security features, as indicated to be a current priority for Meta.`,
    dueDate: new Date(),
    button: {
      icon: PenIcon,
      title: 'Draft demo script'
    }
  }
];

const mockInsights: IAction[] = [
  {
    id: 1,
    icon: SecurityYellow32Icon,
    title: 'Content recommendation - personalized security report',
    description: `Prepare a report on AI and data security to address Sarah’s recent concerns from your last call.`,
    button: {
      icon: DocIcon,
      title: 'Preview report'
    }
  },
  {
    id: 2,
    icon: ForwardGreen32Icon,
    title: 'Upsell opportunity - subscription tier upgrade',
    description: `Meta nearing their usage limits on the current subscription tier. They will soon require the capacity and features available in the Premium Plan.`,
    button: {
      icon: PenIcon,
      title: 'Draft proposal'
    }
  },
  {
    id: 3,
    icon: AlertRed32Icon,
    title: 'Risk alert - timeline dragging',
    description: `While Sarah is very engaged and responds quickly, the negotiations are lagging due to unaddressed concerns. Send a status report to ease her concerns.`,
    button: {
      icon: PenIcon,
      title: 'Draft email'
    }
  },
];

const mockProgressList = [
  {
    id: 0,
    salesStage: 'Negotiation',
    customerJourney: 'Decision',
    notes: 'Proposal sent by James Charlesworth at 3:45pm yesterday'
  }
];

interface IActionSectionProps {
  action: IAction;
  borderTop?: boolean;
};

function ActionSection({ action, borderTop }: IActionSectionProps) {
  return (
    <div className={`flex gap-3 font-firstext py-4 ${borderTop && 'border-t border-solid border-t-gray-200'}`}>
      <img src={action.icon} className="w-8 h-8" />
      <div className="flex flex-col gap-1 flex-1">
        <div className="font-semibold text-sm leading-[17.5px]">
          {action.title}
        </div>
        <div className="text-sm leading-[21px] opacity-60 pr-10">
          {action.description}
        </div>
        <div className="font-medium text-sm leading-[17.5px]">
          {action.dueDate && (
            <div>
              Due: {formatDateTime(action.dueDate)}
            </div>
          )}
        </div>
      </div>
      <Button color="white" className="gap-2 flex items-center">
        <img src={action.button.icon} className="w-4 h-4" />
        {action.button.title}
      </Button>
    </div>
  )
}

export default function DemoContactDetailInsightsPage() {
  const { contactId } = useParams();

  return (
    <main>
      <MainSidebar pageTitle={mockContactNames[contactId!]} pageIcon={SarahIcon}>
        <div className="flex h-full gap-0.5 bg-zinc-100">
          <div className="flex flex-1 flex-col gap-1">
            <div className="rounded pt-7 pb-8 px-8 bg-white">
              <div className="font-medium text-xs font-firstext">
                <div className="flex gap-2 items-center tracking-widest">
                  <img src={ThunderColoredIcon} className="w-4 h-4" />
                  NEXT ACTION
                </div>
                <div className="mt-2">
                  {mockNextActions.map(action => (
                    <ActionSection key={action.id} action={action} />
                  ))}
                </div>
              </div>
            </div>
            <div className="rounded pt-7 pb-8 px-8 bg-white">
              <div className="font-medium text-xs font-firstext">
                <div className="flex gap-2 items-center tracking-widest">
                  <img src={AIStarIcon} className="w-4 h-4" />
                  AI INSIGHTS
                </div>
                <div className="mt-2">
                  {mockInsights.map((action, index) => (
                    <ActionSection key={action.id} action={action} borderTop={index > 0} />
                  ))}
                </div>
              </div>
            </div>
            <div className="rounded pt-7 pb-8 px-8 bg-white">
              <div className="font-medium text-xs tracking-widest font-firstext">
                DEAL PROGRESS
              </div>
              <div className="mt-4">
                <Table grid dense={true} fixedHeader={true}>
                  <TableHead>
                    <TableRow>
                      <TableHeader>Sales stage</TableHeader>
                      <TableHeader>Customer journey</TableHeader>
                      <TableHeader>Notes</TableHeader>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {mockProgressList.map((progress) => (
                      <TableRow key={progress.id}>
                        <TableCell>
                          <Badge color="customPurpleMain">{progress.salesStage}</Badge>
                        </TableCell>
                        <TableCell>
                          <Badge color="customPurpleLight">{progress.customerJourney}</Badge>
                        </TableCell>
                        <TableCell>
                          <div className="text-ellipsis overflow-hidden max-w-[400px]">
                            {progress.notes}
                          </div>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </div>
            </div>
            <div className="rounded pt-7 pb-8 px-8 bg-white flex-1">
              <div className="font-medium text-xs tracking-widest font-firstext">
                LATEST ACTIVITY
              </div>
              <div className="mt-4">
                <Table grid dense={true} fixedHeader={true}>
                  <TableHead>
                    <TableRow>
                      <TableHeader>Event</TableHeader>
                      <TableHeader sortable sort="desc">Date</TableHeader>
                      <TableHeader>Contact</TableHeader>
                      <TableHeader>Summary</TableHeader>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {mockEvents.map((event) => (
                      <TableRow key={event.id}>
                        <TableCell>
                          <div className="flex gap-2 items-center">
                            <img src={event.icon} className="w-4 h-4" />
                            <div>{event.name}</div>
                          </div>
                        </TableCell>
                        <TableCell>
                          {formatDateTime(event.date)}
                        </TableCell>
                        <TableCell>
                          <div className="flex gap-2 items-center">
                            <AvatarGroup srcs={event.contacts.map(contact => contact.avatar)} />
                            <div>{event.contacts.map(contact => contact.name).join(" & ")}</div>
                          </div>
                        </TableCell>
                        <TableCell className="text-ellipsis overflow-hidden max-w-[180px]">
                          {event.summary}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
                <div className="mt-5">
                  <Button color="white" className="gap-2 flex items-center">
                    All activity
                    <img src={ArrowRightIcon} className="w-4 h-4" />
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div className="min-w-[456px] bg-white">
            <DemoContactProfileAside />
          </div>
        </div>
      </MainSidebar>
    </main>
  )
}