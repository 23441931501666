import React from 'react';
import SvgIcon, { SvgIconProps } from '../SvgIcon';

export const NetlifyIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props} style={{ fill: 'transparent' }} viewBox="0 0 27 22">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.4116 21.8827V16.3444L12.5289 16.2271H13.6835L13.8007 16.3444V21.8827L13.6835 22H12.5289L12.4116 21.8827ZM12.4116 5.65559V0.11726L12.5289 0H13.6835L13.8007 0.11726V5.65559L13.6835 5.77286H12.5289L12.4116 5.65559ZM7.39647 17.8868H7.23411L6.4223 17.066V16.9036L8.32554 15.0004H9.19147L9.30873 15.1177V15.9836L7.39647 17.8868ZM7.39647 4.11316H7.23411L6.4223 4.93399V5.09635L8.32554 6.99959H9.19147L9.30873 6.88233V6.0164L7.39647 4.11316ZM0.117261 10.31H7.96474L8.082 10.4272V11.5818L7.96474 11.6991H0.117261L0 11.5818V10.4272L0.117261 10.31ZM14.3151 13.918L14.1979 13.8008V10.4543C14.1979 9.87701 13.9724 9.42601 13.2778 9.41699C12.917 9.40797 12.5111 9.41699 12.0691 9.43503L12.006 9.49817V13.8008L11.8887 13.918H10.4996L10.3824 13.8008V8.19927L10.4996 8.08201L13.6206 8.05495C15.1811 8.05495 15.8125 9.12834 15.8125 10.337V13.8008L15.6952 13.918H14.3151ZM18.1563 10.31H26.0038L26.121 10.4272V11.5818L26.0038 11.6991H18.1563L18.039 11.5818V10.4272L18.1563 10.31Z"
      fill="currentColor"
    />
  </SvgIcon>
);
