import React from 'react';
import SvgIcon, { SvgIconProps } from '../SvgIcon';

export const PaypalIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props} style={{ fill: 'transparent' }} viewBox="0 0 17 20">
    <path
      d="M4.81553 19.322L5.16215 17.1203L4.39004 17.1024H0.703125L3.26534 0.856279C3.27297 0.80664 3.29818 0.761394 3.33638 0.728791C3.37458 0.696187 3.42323 0.678396 3.47345 0.67866H9.6901C11.7539 0.67866 13.1782 1.10813 13.9218 1.95579C14.2704 2.35345 14.4924 2.76899 14.5998 3.2263C14.7125 3.70613 14.7145 4.27942 14.6044 4.97862L14.5965 5.02966V5.47768L14.9451 5.67518C15.2112 5.81001 15.4504 5.9924 15.6509 6.21334C15.9492 6.55333 16.142 6.98545 16.2236 7.49776C16.3077 8.02465 16.2799 8.65162 16.142 9.36143C15.983 10.1779 15.7258 10.8891 15.3785 11.471C15.0721 11.9923 14.6601 12.4438 14.169 12.7965C13.7077 13.1239 13.1596 13.3724 12.54 13.5315C11.9395 13.6879 11.2549 13.7668 10.504 13.7668H10.0202C9.6742 13.7668 9.33818 13.8914 9.0744 14.1147C8.811 14.3405 8.63626 14.6523 8.58131 14.9949L8.54486 15.193L7.93247 19.0735L7.90464 19.216C7.89735 19.261 7.88475 19.2836 7.8662 19.2988C7.84822 19.3135 7.82579 19.3217 7.80257 19.322H4.81553Z"
      fill="#253B80"
    />
    <path
      d="M15.2754 5.08135C15.2568 5.19999 15.2356 5.32127 15.2118 5.44587C14.3919 9.65503 11.5872 11.1091 8.00496 11.1091H6.18106C5.74298 11.1091 5.37382 11.4272 5.30556 11.8594L4.37173 17.7818L4.10729 19.4605C4.09676 19.5271 4.10078 19.5952 4.11908 19.6601C4.13738 19.725 4.16953 19.7851 4.2133 19.8364C4.25708 19.8877 4.31145 19.9288 4.37266 19.9571C4.43388 19.9853 4.50049 20 4.56791 20H7.80282C8.1859 20 8.51131 19.7216 8.57162 19.3439L8.60343 19.1795L9.21251 15.3143L9.25161 15.1022C9.31126 14.7231 9.63733 14.4448 10.0204 14.4448H10.5042C13.6384 14.4448 16.0919 13.1723 16.809 9.49001C17.1086 7.95175 16.9535 6.66733 16.1608 5.76399C15.9096 5.48455 15.6096 5.25322 15.2754 5.08135Z"
      fill="#179BD7"
    />
    <path
      d="M14.4179 4.73937C14.1561 4.66364 13.8898 4.60454 13.6206 4.56241C13.0886 4.48066 12.551 4.44143 12.0128 4.44511H7.14017C6.95474 4.44496 6.77537 4.51116 6.63449 4.63174C6.49361 4.75232 6.40052 4.91933 6.37204 5.10256L5.33549 11.6678L5.30566 11.8594C5.33796 11.6504 5.44398 11.4598 5.60457 11.3222C5.76515 11.1846 5.96969 11.109 6.18117 11.1091H8.00507C11.5873 11.1091 14.3921 9.65437 15.2119 5.44587C15.2364 5.32127 15.257 5.19999 15.2755 5.08135C15.0592 4.96791 14.8337 4.8728 14.6015 4.79703C14.5406 4.77683 14.4794 4.75761 14.4179 4.73937Z"
      fill="#222D65"
    />
    <path
      d="M6.37168 5.10256C6.39992 4.91927 6.49296 4.75219 6.63391 4.63168C6.77486 4.51116 6.95437 4.44519 7.13981 4.44577H12.0124C12.5897 4.44577 13.1285 4.48355 13.6202 4.56308C13.953 4.61538 14.2812 4.6936 14.6018 4.79703C14.8437 4.87722 15.0684 4.972 15.2758 5.08135C15.5197 3.52586 15.2738 2.46678 14.4328 1.50777C13.5056 0.452 11.8321 0 9.69076 0H3.47411C3.03669 0 2.66356 0.318123 2.59596 0.750903L0.00656772 17.1641C-0.00549322 17.2403 -0.000904441 17.3182 0.0200184 17.3924C0.0409412 17.4667 0.0777016 17.5355 0.127771 17.5942C0.17784 17.6529 0.24003 17.7001 0.310062 17.7325C0.380095 17.7648 0.456308 17.7816 0.533459 17.7818H4.37148L5.33513 11.6678L6.37168 5.10256Z"
      fill="#253B80"
    />
  </SvgIcon>
);
