import React from 'react';
import SvgIcon, { SvgIconProps } from '../SvgIcon';

export const ChevronUpIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props} style={{ ...props.style, fill: 'transparent' }}>
    <path
      d="M7.46843 13.4803C7.17622 13.7739 7.17734 14.2488 7.47091 14.541C7.76449 14.8332 8.23937 14.8321 8.53157 14.5385L7.46843 13.4803ZM15.4684 14.5385C15.7606 14.8321 16.2355 14.8332 16.5291 14.541C16.8227 14.2488 16.8238 13.7739 16.5316 13.4803L15.4684 14.5385ZM12 9.9906L12.5316 9.46151C12.3908 9.3201 12.1995 9.2406 12 9.2406C11.8005 9.2406 11.6092 9.3201 11.4684 9.46151L12 9.9906ZM8.53157 14.5385L12.5316 10.5197L11.4684 9.46151L7.46843 13.4803L8.53157 14.5385ZM11.4684 10.5197L15.4684 14.5385L16.5316 13.4803L12.5316 9.46151L11.4684 10.5197Z"
      fill="currentColor"
    />
  </SvgIcon>
);
