import React from 'react';
import SvgIcon, { SvgIconProps } from '../SvgIcon';

export const SlackIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props} style={{ fill: 'transparent' }} viewBox="7.5 7.5 24 24">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20 0H0V20V40H20H40V20V0H20Z"
      fill="#692070"
    />
    <path
      d="M14.202 22.6384C14.202 23.7948 13.2573 24.7394 12.101 24.7394C10.9446 24.7394 10 23.7948 10 22.6384C10 21.4821 10.9446 20.5375 12.101 20.5375H14.202V22.6384Z"
      fill="#E01E5A"
    />
    <path
      d="M15.2606 22.6384C15.2606 21.4821 16.2052 20.5374 17.3616 20.5374C18.5179 20.5374 19.4625 21.4821 19.4625 22.6384V27.899C19.4625 29.0554 18.5179 30 17.3616 30C16.2052 30 15.2606 29.0554 15.2606 27.899V22.6384Z"
      fill="#E01E5A"
    />
    <path
      d="M17.3616 14.202C16.2052 14.202 15.2606 13.2573 15.2606 12.101C15.2606 10.9446 16.2052 10 17.3616 10C18.5179 10 19.4625 10.9446 19.4625 12.101V14.202H17.3616Z"
      fill="#36C5F0"
    />
    <path
      d="M17.3616 15.2606C18.5179 15.2606 19.4625 16.2052 19.4625 17.3616C19.4625 18.5179 18.5179 19.4625 17.3616 19.4625H12.101C10.9446 19.4625 10 18.5179 10 17.3616C10 16.2052 10.9446 15.2606 12.101 15.2606H17.3616Z"
      fill="#36C5F0"
    />
    <path
      d="M25.798 17.3616C25.798 16.2052 26.7426 15.2606 27.899 15.2606C29.0554 15.2606 30 16.2052 30 17.3616C30 18.5179 29.0554 19.4625 27.899 19.4625H25.798V17.3616Z"
      fill="#2EB67D"
    />
    <path
      d="M24.7394 17.3616C24.7394 18.5179 23.7948 19.4625 22.6384 19.4625C21.4821 19.4625 20.5375 18.5179 20.5375 17.3616V12.101C20.5375 10.9446 21.4821 10 22.6384 10C23.7948 10 24.7394 10.9446 24.7394 12.101V17.3616Z"
      fill="#2EB67D"
    />
    <path
      d="M22.6384 25.798C23.7948 25.798 24.7394 26.7427 24.7394 27.899C24.7394 29.0554 23.7948 30 22.6384 30C21.4821 30 20.5374 29.0554 20.5374 27.899V25.798H22.6384Z"
      fill="#ECB22E"
    />
    <path
      d="M22.6384 24.7394C21.4821 24.7394 20.5375 23.7948 20.5375 22.6384C20.5375 21.4821 21.4821 20.5375 22.6384 20.5375H27.899C29.0554 20.5375 30 21.4821 30 22.6384C30 23.7948 29.0554 24.7394 27.899 24.7394H22.6384Z"
      fill="#ECB22E"
    />
  </SvgIcon>
);
