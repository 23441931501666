import React from 'react';
import SvgIcon, { SvgIconProps } from '../SvgIcon';

export const ZohoCrmIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props} style={{ fill: 'transparent' }} viewBox="0 0 24 16">
    <path
      d="M16.439 15.589C14.437 15.589 12.5509 14.8114 11.1281 13.405L6.97528 9.25226C6.49548 8.76419 6.22249 8.12721 6.23076 7.44059C6.23076 6.75398 6.50375 6.117 6.99183 5.6372C7.98452 4.65277 9.59765 4.66105 10.5903 5.64547L14.3957 9.4508C14.8424 9.89751 15.5704 9.89751 16.0171 9.45907C16.2322 9.24399 16.3562 8.95445 16.3562 8.64837C16.3562 8.34229 16.2404 8.05275 16.0171 7.8294L11.8643 3.67662C10.7062 2.52675 9.16748 1.89805 7.52954 1.90632C5.86677 1.91459 4.31155 2.56811 3.15341 3.75108C1.99526 4.93404 1.37483 6.5058 1.40792 8.17684C1.4741 11.4941 4.22883 14.1992 7.54608 14.1992C8.1417 14.1992 8.72904 14.1165 9.29157 13.951C9.66383 13.8435 10.0526 14.0503 10.1602 14.4225C10.2677 14.7948 10.0609 15.1836 9.68865 15.2994C8.99376 15.5062 8.27406 15.6055 7.54608 15.6055C3.46776 15.6055 0.0843259 12.2883 0.00160133 8.20993C-0.039761 6.15836 0.721305 4.23088 2.15244 2.77493C3.5753 1.31897 5.48624 0.516545 7.53781 0.508273C9.53974 0.5 11.4341 1.27761 12.8653 2.6922L17.018 6.84497C17.5061 7.33305 17.7708 7.97003 17.7708 8.65664C17.7708 9.34326 17.4978 9.98851 17.0098 10.4683C16.0171 11.4527 14.3957 11.4527 13.403 10.46L9.59765 6.65471C9.15094 6.208 8.43123 6.208 7.98452 6.64644C7.76944 6.86152 7.64535 7.15106 7.64535 7.45714C7.64535 7.76322 7.76117 8.05275 7.98452 8.26784L12.1373 12.4206C13.2954 13.5622 14.8258 14.1909 16.4555 14.1909C19.839 14.1909 22.5937 11.4362 22.5937 8.05275C22.5937 6.41481 21.9567 4.86786 20.7986 3.70971C19.6404 2.5433 18.0935 1.90632 16.4555 1.90632C15.9344 1.90632 15.4132 1.9725 14.9168 2.10486C14.8424 2.1214 14.7679 2.14622 14.7018 2.16276C14.3295 2.27031 13.9407 2.06349 13.8249 1.69123C13.7173 1.31897 13.9241 0.930168 14.2964 0.814353C14.3874 0.789536 14.4701 0.764719 14.5611 0.739901C15.1733 0.582725 15.8103 0.5 16.4555 0.5C18.474 0.5 20.3684 1.28588 21.7913 2.71702C23.2141 4.14815 24 6.04255 24 8.06103C23.9752 12.2138 20.5917 15.589 16.439 15.589Z"
      fill="currentColor"
    />
  </SvgIcon>
);
