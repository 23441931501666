import { ButtonHTMLAttributes, FC, PropsWithChildren } from "react"
import { MenuIcon } from "./menu-icon";

interface IIconButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  src: string;
};

export const IconButton: FC<PropsWithChildren<IIconButtonProps>> = ({ children, className, src, ...props })  => {
  return (
    <button className={`${className} cursor-pointer bg-none border-none`} {...props}>
      <MenuIcon src={src} />
    </button>
  )
}