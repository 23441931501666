import React from 'react';
import SvgIcon, { SvgIconProps } from '../SvgIcon';

export const CircleCIIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon
    {...props}
    style={{ ...props.style, fill: 'transparent' }}
    viewBox="0 0 20 21"
  >
    <path
      d="M7.43738 10.1349C7.43738 8.8054 8.51638 7.7264 9.84586 7.7264C11.1753 7.7264 12.2543 8.8054 12.2543 10.1349C12.2543 11.4644 11.1753 12.5434 9.84586 12.5434C8.51638 12.5626 7.43738 11.4644 7.43738 10.1349ZM9.84586 0C5.10597 0 1.1368 3.23699 0.0192678 7.63006C0.0192678 7.66859 0 7.68786 0 7.7264C0 7.99615 0.211946 8.20809 0.481696 8.20809H4.56647C4.75915 8.20809 4.93256 8.09249 5.00963 7.91908C5.85742 6.08863 7.68786 4.81696 9.84586 4.81696C12.7746 4.81696 15.1638 7.20617 15.1638 10.1349C15.1638 13.0636 12.7746 15.4528 9.84586 15.4528C7.70713 15.4528 5.85742 14.1811 5.00963 12.3507C4.93256 12.1773 4.75915 12.0617 4.56647 12.0617H0.481696C0.211946 12.0617 0 12.2736 0 12.5434C0 12.5819 0 12.6012 0.0192678 12.6397C1.1368 17.0328 5.10597 20.2697 9.84586 20.2697C15.4528 20.2697 20 15.7225 20 10.1156C20 4.52794 15.4528 0 9.84586 0Z"
      fill="currentColor"
    />
  </SvgIcon>
);
