import React from 'react';
import SvgIcon, { SvgIconProps } from '../SvgIcon';

export const GoogleDocumentAIIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props} style={{ fill: 'transparent' }} viewBox="-2 -2 35 35">
    <path
      d="M16 2v2.997H7.005v22.006H16V30H6.002a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2H16zm3 19l6.002.003c.512 0 .935.387.993.884l.007.117v2.265A2 2 0 1 1 24 24.26v-1.27h-5V21zm-3-1v3h-5v-3h5zm11-9.333a2 2 0 0 1 1.002 3.73v1.6a1 1 0 0 1-1 1L19 17.002v-1.998h7V14.4a2 2 0 0 1 1-3.733zM16 14v3h-5v-3h5zm7-11a2 2 0 0 1 1.002 3.73V10a1 1 0 0 1-1 1H19V9.003h3v-2.27A2 2 0 0 1 23 3zm-7 5v3h-5V8h5z"
      fill="#3367d6"
    ></path>
  </SvgIcon>
);
