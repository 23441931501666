import React from 'react';
import SvgIcon, { SvgIconProps } from '../SvgIcon';

export const ActiveCampaignIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon
    {...props}
    style={{ ...props.style, fill: 'transparent' }}
    viewBox="0 0 16 23"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.3013 18.6498L12.8294 10.9941L1.3013 3.29375C0.811639 2.98211 0.5 2.40361 0.5 1.78033V0L14.4763 9.08001C15.1439 9.52507 15.5 10.2374 15.5 10.9941C15.5 11.7506 15.0993 12.4629 14.4763 12.908L0.5 22.0769V20.163C0.5 19.5844 0.767222 19.0059 1.3013 18.6498ZM7.93328 11.6172C7.31019 12.0177 6.50888 12.0177 5.88579 11.6172L4.28336 10.5934L0.5 8.05626V6.58744C0.5 6.00894 1.16769 5.69731 1.61276 6.00894L8.91244 10.9495L7.93328 11.6172Z"
      fill="currentColor"
    />
  </SvgIcon>
);
