import React from 'react';
import SvgIcon, { SvgIconProps } from '../SvgIcon';

export const GoogleAdsIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props} style={{ fill: 'transparent' }} viewBox="7 7 26 26">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20 0H0V20V40H20H40V20V0H20Z"
      fill="white"
    />
    <path
      d="M16.806 12.301C16.9993 11.7935 17.2651 11.3264 17.6597 10.9478C19.2384 9.40936 21.8481 9.79598 22.9194 11.7291C23.7249 13.195 24.5786 14.6287 25.4083 16.0786C26.7937 18.4869 28.1952 20.8952 29.5644 23.3116C30.7162 25.3333 29.4678 27.8866 27.1803 28.2329C25.7788 28.4424 24.4659 27.798 23.741 26.5415C22.5247 24.4231 21.3004 22.3048 20.0842 20.1945C20.06 20.1461 20.0278 20.1059 19.9956 20.0656C19.8667 19.9609 19.8103 19.8078 19.7298 19.6709C19.1901 18.7205 18.6343 17.7781 18.0947 16.8357C17.7483 16.2235 17.3859 15.6195 17.0395 15.0073C16.7254 14.4596 16.5804 13.8636 16.5965 13.2353C16.6207 12.9131 16.661 12.5909 16.806 12.301Z"
      fill="#3C8BD9"
    />
    <path
      d="M16.806 12.301C16.7335 12.5909 16.669 12.8809 16.6529 13.187C16.6288 13.8636 16.7979 14.4918 17.1362 15.0798C18.0222 16.6021 18.9082 18.1325 19.7862 19.6629C19.8667 19.7998 19.9311 19.9367 20.0117 20.0656C19.5284 20.9033 19.0451 21.7329 18.5538 22.5706C17.8772 23.7385 17.2006 24.9145 16.516 26.0824C16.4838 26.0824 16.4757 26.0663 16.4677 26.0421C16.4596 25.9777 16.4838 25.9213 16.4999 25.8568C16.8301 24.6487 16.5563 23.5774 15.7266 22.6592C15.2192 22.1034 14.5748 21.7893 13.8338 21.6846C12.8673 21.5476 12.0135 21.7973 11.2483 22.4014C11.1114 22.5061 11.0228 22.6592 10.8617 22.7397C10.8294 22.7397 10.8133 22.7236 10.8053 22.6994C11.1919 22.0309 11.5705 21.3624 11.9571 20.6939C13.5519 17.9231 15.1467 15.1523 16.7496 12.3896C16.7657 12.3573 16.7898 12.3332 16.806 12.301Z"
      fill="#FABC04"
    />
    <path
      d="M10.8375 22.7236C10.9905 22.5867 11.1355 22.4417 11.2966 22.3128C13.2539 20.7663 16.1938 21.8859 16.6207 24.3345C16.7254 24.9225 16.669 25.4863 16.4918 26.0502C16.4838 26.0985 16.4757 26.1388 16.4596 26.1871C16.3871 26.316 16.3227 26.4529 16.2421 26.5818C15.5253 27.7658 14.4701 28.3538 13.0847 28.2652C11.498 28.1524 10.2495 26.9603 10.032 25.3816C9.92733 24.6164 10.0804 23.8996 10.475 23.2391C10.5556 23.0941 10.6522 22.9652 10.7408 22.8203C10.7811 22.788 10.765 22.7236 10.8375 22.7236Z"
      fill="#34A852"
    />
    <path
      d="M10.8375 22.7236C10.8053 22.7558 10.8053 22.8122 10.7489 22.8203C10.7408 22.7639 10.7731 22.7317 10.8053 22.6914L10.8375 22.7236Z"
      fill="#FABC04"
    />
    <path
      d="M16.4596 26.1871C16.4274 26.1307 16.4596 26.0904 16.4918 26.0502C16.4999 26.0582 16.516 26.0743 16.524 26.0824L16.4596 26.1871Z"
      fill="#E1C025"
    />
  </SvgIcon>
);
