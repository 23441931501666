import { Avatar } from "@/components/cataylst/avatar";
import { Badge } from "@/components/cataylst/badge";
import { Checkbox } from "@/components/cataylst/checkbox";
import { Input, InputGroup } from "@/components/cataylst/input";
import { Link } from "@/components/cataylst/link";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/cataylst/table";
import { Button } from "@/components/custom/button";
import { AIStarIcon, KanbanIcon, SearchIcon } from "@/components/custom/icons";
import { MenuIcon } from "@/components/custom/menu-icon";
import MainSidebar from "@/components/custom/sidebar";
import { formatDate } from "@/utils/format";
import { AdjustmentsHorizontalIcon, ChevronDownIcon, EllipsisHorizontalIcon, ListBulletIcon } from "@heroicons/react/16/solid";
import { useState } from "react";
import { ProgressBar } from "@/components/custom/progressbar";
import { Dropdown, DropdownButton, DropdownItem, DropdownMenu } from "@/components/cataylst/dropdown";

const mockSegments = [
  {
    id: '0',
    name: 'Test Segment',
    numberOfContacts: 10,
    attributes: ['tech startups with over 500 people in the bay area'],
    linkedActions: ['Reply to customer', 'Delay 10 seconds']
  },
  {
    id: '1',
    name: 'Second Segment',
    numberOfContacts: 15,
    attributes: ['tech startups with over 200 people in the bay area'],
    linkedActions: ['Reply to customer', 'Delay 10 seconds', 'Automate email processing', 'Connect with Hubspot', 'Integrate Slack']
  }
];

export default function DemoSegmentsPage() {
  const [searchKeyword, setSearchKeyword] = useState("");

  const onSearchSegments = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchKeyword(event.target.value);
  };

  const filterSegments = (keyword: string) => {
    const segments: any = [];
    for (let i = 0; i < 10; i++) {
      for (let j = 0; j < mockSegments.length; j++) {
        const segment = {...mockSegments[j], id: i * 100 + j};
        segments.push(segment);
      }
    }
    return segments.filter(segment => segment.name.toLowerCase().includes(keyword.toLowerCase()));
  };

  return (
    <main>
      <MainSidebar>
        <div className="text-cell px-8 py-5">
          <div className="mb-5 flex justify-between">
            <div className="w-[250px]">
              <InputGroup>
                <MenuIcon src={AIStarIcon} data-slot="icon" className="!size-4" />
                <Input placeholder="Ask Bloom AI..." className="h-9 pl-9 pr-2 !rounded-full" />
              </InputGroup>
            </div>
            <div className="flex gap-2">
              <div className="w-[250px]">
                <InputGroup>
                  <MenuIcon src={SearchIcon} data-slot="icon" className="!size-4" />
                  <Input placeholder="Search segments" className="h-9 pl-9 pr-2 !rounded-full" onChange={onSearchSegments} />
                </InputGroup>
              </div>
              <Button color="white">
                <AdjustmentsHorizontalIcon color="black" height={16} />
                Filter
              </Button>
            </div>
            <div className="flex gap-2">
              <Button color="white">
                <ListBulletIcon color="black" height={16} />
                List
              </Button>
              <Button color="white" disabled={true}>
                <MenuIcon className="!size-4" src={KanbanIcon} data-slot="icon" />
                Kanban
              </Button>
            </div>
          </div>
          <Table grid dense fixedFirstColumn fixedHeader>
            <TableHead>
              <TableRow>
                <TableHeader>
                  <div className="flex gap-4 items-center">
                    <Checkbox />
                    <span>Name</span>
                  </div>
                </TableHeader>
                <TableHeader className="max-w-24">Number of contacts</TableHeader>
                <TableHeader>Attributes</TableHeader>
                <TableHeader>Linked actions</TableHeader>
              </TableRow>
            </TableHead>
            <TableBody>
              {filterSegments(searchKeyword).map((segment) => (
                <TableRow key={segment.id}>
                  <TableCell>
                    <div className="flex gap-4 items-center">
                      <Checkbox />
                      <div>{segment.name}</div>
                    </div>
                  </TableCell>
                  <TableCell>
                    {segment.numberOfContacts}
                  </TableCell>
                  <TableCell>
                    <div className="flex flex-wrap gap-2">
                      {segment.attributes.map(attr => (
                        <Badge color="blue" key={attr}>{attr}</Badge>
                      ))}
                    </div>
                  </TableCell>
                  <TableCell>
                    {segment.linkedActions.join(", ").length < 100 ? (
                      <div>
                        {segment.linkedActions.join(", ")}
                      </div>
                    ) : (
                      <Dropdown>
                        <DropdownButton outline>
                          <EllipsisHorizontalIcon height={16} />
                        </DropdownButton>
                        <DropdownMenu className="min-w-48">
                          {segment.linkedActions.map(action => (
                            <DropdownItem className="underline">{action}</DropdownItem>  
                          ))}
                        </DropdownMenu>
                      </Dropdown>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </MainSidebar>
    </main>
  )
}