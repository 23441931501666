import { Environment } from '@/utils/constants';
import { Auth0Provider } from '@auth0/auth0-react';

interface Props {
  children: React.ReactNode;
}

export const AuthProvider: React.FC<Props> = ({ children }) => {
  const redirectUri = window.location.origin;

  return (
    <Auth0Provider
      domain={Environment.AUTH0_DOMAIN}
      clientId={Environment.AUTH0_CLIENT_ID}
      authorizationParams={{
        redirect_uri: redirectUri,
        organization: 'org_wmjxTvla8C9nroh3',
        audience: 'https://api.makerops.com',
      }}
    >
      {children}
    </Auth0Provider>
  );
};
