import React from 'react';
import SvgIcon, { SvgIconProps } from '../SvgIcon';

export const PostgreSQLIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props} style={{ fill: 'transparent' }} viewBox="0 0 22 23">
    <g clipPath="url(#clip0_postgresql)">
      <path
        d="M16.4603 16.5116C16.6045 15.3097 16.5613 15.1334 17.4565 15.3281L17.6838 15.3481C18.3722 15.3794 19.2726 15.2373 19.8017 14.9916C20.9405 14.4632 21.6157 13.5809 20.4928 13.8127C17.9314 14.3412 17.7553 13.4738 17.7553 13.4738C20.46 9.46072 21.5907 4.36644 20.6147 3.11947C17.9528 -0.281822 13.3452 1.32657 13.2681 1.36833L13.2435 1.37286C12.7374 1.26785 12.171 1.20511 11.5348 1.19483C10.3756 1.17578 9.49609 1.4987 8.82891 2.00475C8.82891 2.00475 0.609053 -1.38172 0.991456 6.26359C1.07278 7.88996 3.32269 18.5701 6.00613 15.3442C6.98692 14.1646 7.93475 13.1672 7.93475 13.1672C8.40541 13.4799 8.9689 13.6394 9.5599 13.5821L9.60558 13.5431C9.59127 13.6896 9.59758 13.8329 9.62386 14.0024C8.93269 14.7748 9.13579 14.9105 7.75371 15.1949C6.35543 15.4831 7.17692 15.9962 7.71312 16.1303C8.36334 16.2929 9.86754 16.5232 10.8842 15.1004L10.8437 15.2627C11.1149 15.4797 11.0965 16.822 11.135 17.7811C11.1735 18.7402 11.2377 19.6353 11.4332 20.163C11.6287 20.6905 11.8594 22.0498 13.6758 21.6605C15.1938 21.3352 16.3545 20.867 16.4603 16.5116Z"
        fill="black"
        stroke="black"
        strokeWidth="1.90439"
      />
      <path
        d="M20.4933 13.8126C17.9317 14.341 17.7556 13.4737 17.7556 13.4737C20.4603 9.46036 21.5909 4.36598 20.6152 3.11916C17.9533 -0.281927 13.3454 1.32657 13.2685 1.36833L13.2438 1.37276C12.7377 1.2677 12.1713 1.20511 11.5347 1.19472C10.3756 1.17573 9.49633 1.4986 8.82915 2.00455C8.82915 2.00455 0.609148 -1.38177 0.991449 6.26348C1.07278 7.88995 3.32263 18.5701 6.00618 15.3442C6.98701 14.1646 7.93469 13.1672 7.93469 13.1672C8.4054 13.4799 8.96889 13.6394 9.55963 13.5821L9.60552 13.5431C9.59126 13.6896 9.59778 13.8329 9.6239 14.0024C8.93258 14.7748 9.13573 14.9104 7.75375 15.1949C6.35537 15.4831 7.17686 15.9962 7.71321 16.1303C8.36343 16.2929 9.86774 16.5232 10.8842 15.1004L10.8436 15.2627C11.1145 15.4796 11.3047 16.6739 11.2728 17.7565C11.241 18.8391 11.2197 19.5824 11.4331 20.1629C11.6465 20.7435 11.8592 22.0498 13.676 21.6605C15.194 21.3352 15.9807 20.4921 16.0901 19.0859C16.1678 18.0862 16.3435 18.234 16.3546 17.3402L16.4956 16.917C16.6581 15.5618 16.5214 15.1246 17.4567 15.328L17.6839 15.348C18.3723 15.3793 19.2732 15.2372 19.802 14.9915C20.9406 14.4631 21.6159 13.5808 20.4932 13.8126H20.4933Z"
        fill="#336791"
      />
      <path
        d="M10.9942 14.5895C10.9237 17.1111 11.0119 19.6503 11.2587 20.2674C11.5056 20.8846 12.0339 22.0849 13.8508 21.6957C15.3687 21.3702 15.921 20.7405 16.1606 19.3505C16.3371 18.3278 16.6774 15.4875 16.7211 14.9056"
        stroke="white"
        strokeWidth="0.634797"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.8161 1.94822C8.8161 1.94822 0.59049 -1.4137 0.972893 6.23155C1.05422 7.85802 3.30423 18.5385 5.98772 15.3125C6.96835 14.1327 7.85523 13.2074 7.85523 13.2074"
        stroke="white"
        strokeWidth="0.634797"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.259 1.33462C12.9743 1.42389 17.8346 -0.442138 20.5968 3.08733C21.5725 4.33421 20.4418 9.42858 17.7372 13.4421"
        stroke="white"
        strokeWidth="0.634797"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.7371 13.4421C17.7371 13.4421 17.9134 14.3096 20.4751 13.7809C21.5975 13.549 20.922 14.4314 19.7837 14.96C18.8495 15.3936 16.7549 15.5048 16.7207 14.9056C16.6327 13.3598 17.8231 13.8294 17.7371 13.4421ZM17.7371 13.4421C17.6595 13.0932 17.1272 12.7508 16.775 11.8971C16.4676 11.1519 12.5585 5.43716 17.8591 6.28589C18.0532 6.24571 16.4766 1.24412 11.5161 1.16279C6.55666 1.08146 6.71937 7.26178 6.71937 7.26178"
        stroke="white"
        strokeWidth="0.634797"
        strokeLinecap="round"
        strokeLinejoin="bevel"
      />
      <path
        d="M9.60619 13.9707C8.91477 14.7431 9.11812 14.8787 7.73604 15.1632C6.33766 15.4515 7.15925 15.9645 7.69545 16.0985C8.34567 16.2612 9.84997 16.4915 10.8664 15.0683C11.1759 14.635 10.8646 13.9436 10.4394 13.7673C10.234 13.6822 9.95926 13.5756 9.60619 13.9707Z"
        stroke="white"
        strokeWidth="0.634797"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.55973 13.9572C9.49006 13.5031 9.70894 12.9627 9.94351 12.3305C10.296 11.382 11.1094 10.4333 10.4587 7.42439C9.97376 5.18217 6.72097 6.95776 6.71893 7.26178C6.71694 7.56571 6.86606 8.8028 6.66459 10.2434C6.40171 12.1232 7.86074 13.7131 9.54084 13.5504"
        stroke="white"
        strokeWidth="0.634797"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.7859 7.2162C8.77123 7.32004 8.976 7.59713 9.24301 7.63415C9.50955 7.67133 9.7377 7.45479 9.75217 7.35105C9.76663 7.24722 9.56211 7.13284 9.295 7.09566C9.02831 7.05843 8.80006 7.11262 8.78595 7.2162H8.7859Z"
        fill="white"
        stroke="white"
        strokeWidth="0.211597"
      />
      <path
        d="M16.9048 7.0045C16.9193 7.10834 16.7148 7.38543 16.4477 7.42245C16.181 7.45963 15.9528 7.24309 15.9382 7.13936C15.9241 7.03552 16.1287 6.92114 16.3955 6.88396C16.6623 6.84679 16.8904 6.90092 16.9048 7.00456V7.0045Z"
        fill="white"
        stroke="white"
        strokeWidth="0.105799"
      />
      <path
        d="M17.8593 6.28589C17.9033 7.1004 17.6839 7.65518 17.6562 8.52225C17.6153 9.78256 18.2571 11.2251 17.29 12.6694"
        stroke="white"
        strokeWidth="0.634797"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_postgresql">
        <rect width="22.0036" height="22.6815" fill="white" />
      </clipPath>
    </defs>
  </SvgIcon>
);
