import React from 'react';
import SvgIcon, { SvgIconProps } from '../SvgIcon';

export const LinkedInIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props} style={{ fill: 'transparent' }} viewBox="-3 -3 24 24">
    <path
      d="M15.3369 15.337H12.67V11.1592C12.67 10.163 12.652 8.88034 11.2826 8.88034C9.89358 8.88034 9.68089 9.96554 9.68089 11.086V15.3367H7.01397V6.74756H9.5742V7.9215H9.60873C9.865 7.48339 10.2353 7.12299 10.6802 6.87869C11.1251 6.63438 11.6279 6.51532 12.1351 6.53418C14.8383 6.53418 15.3369 8.31236 15.3369 10.6254V15.337ZM4.00453 5.57327C3.69837 5.57334 3.39907 5.48262 3.14448 5.31258C2.88989 5.14253 2.69144 4.90081 2.57423 4.61798C2.45702 4.33515 2.42632 4.02391 2.486 3.72363C2.54569 3.42335 2.69308 3.14751 2.90954 2.931C3.12601 2.71449 3.40181 2.56704 3.70208 2.50728C4.00235 2.44753 4.31359 2.47816 4.59645 2.59531C4.87931 2.71245 5.12107 2.91085 5.29117 3.1654C5.46127 3.41996 5.55206 3.71924 5.55206 4.02539C5.55206 4.43586 5.38903 4.82951 5.09882 5.11979C4.80861 5.41006 4.41499 5.57318 4.00453 5.57327ZM5.33799 15.3367H2.6683V6.74756H5.33833L5.33799 15.3367ZM16.6652 0.00118521H1.32794C1.1554 -0.000912021 0.984148 0.031043 0.82398 0.0952208C0.663812 0.159399 0.517875 0.254539 0.394526 0.375194C0.271177 0.495849 0.172839 0.639649 0.10514 0.798361C0.0374415 0.957073 0.00171273 1.12758 0 1.30012V16.6995C0.00409812 17.0478 0.146293 17.3803 0.395349 17.6238C0.644405 17.8674 0.979953 18.0021 1.32828 17.9984H16.6652C17.0144 18.0031 17.3512 17.8689 17.6015 17.6253C17.8519 17.3818 17.9952 17.0487 18 16.6995V1.29736C17.995 0.948329 17.8516 0.61558 17.6012 0.372309C17.3509 0.129038 17.0142 -0.00483117 16.6652 0.000149421"
      fill="#000"
    />
  </SvgIcon>
);
