import React from 'react';
import SvgIcon, { SvgIconProps } from '../SvgIcon';

export const SendGridIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props} style={{ fill: 'transparent' }} viewBox="0 0 20 20">
    <path d="M0 6.64043H6.67903V13.3195H0V6.64043Z" fill="white" />
    <path d="M0 6.64043H6.67903V13.3195H0V6.64043Z" fill="#99E1F4" />
    <path d="M6.67871 13.3194H13.3197V19.9604H6.67871V13.3194Z" fill="white" />
    <path
      d="M6.67871 13.3194H13.3197V19.9604H6.67871V13.3194Z"
      fill="#99E1F4"
    />
    <path
      d="M0 19.9611H6.67903V19.9997H0V19.9611ZM0 13.3194H6.67903V19.9611H0V13.3194Z"
      fill="#1A82E2"
    />
    <path
      d="M6.67871 0H13.3197V6.64103H6.67871V0ZM13.3204 6.68036H19.9994V13.3214H13.3204V6.68036Z"
      fill="#00B3E3"
    />
    <path
      d="M13.3207 13.3195V6.64043H6.67969V13.3195H13.3207Z"
      fill="#009DD9"
    />
    <path d="M13.3213 0H20.0003V6.64103H13.3213V0Z" fill="#1A82E2" />
    <path d="M13.3213 6.6404H20.0003V6.67906H13.3213V6.6404Z" fill="#1A82E2" />
  </SvgIcon>
);
