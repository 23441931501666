import React from "react";
import SvgIcon, { SvgIconProps } from "../SvgIcon";

export const MSCopilotIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon
    {...props}
    style={{ fill: "transparent" }}
    width="32"
    height="32"
    viewBox="0 0 32 32"
  >
    <path
      d="M0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16Z"
      fill="white"
    />
    <path
      d="M0.363636 16C0.363636 7.36427 7.36427 0.363636 16 0.363636C24.6357 0.363636 31.6364 7.36427 31.6364 16C31.6364 24.6357 24.6357 31.6364 16 31.6364C7.36427 31.6364 0.363636 24.6357 0.363636 16Z"
      stroke="black"
      stroke-opacity="0.1"
      stroke-width="0.727273"
    />
    <path
      d="M19.6883 9.93639C19.4778 9.21961 18.8201 8.72729 18.073 8.72729H17.5815C16.769 8.72729 16.0723 9.3077 15.9257 10.1069L15.084 14.6935L15.2929 13.9789C15.5027 13.2611 16.1609 12.7677 16.9087 12.7677H19.7647L20.9624 13.2343L22.117 12.7677H21.78C21.0329 12.7677 20.3753 12.2754 20.1647 11.5586L19.6883 9.93639Z"
      fill="url(#paint0_radial_1205_4651)"
    />
    <path
      d="M12.4839 22.0568C12.6921 22.777 13.3515 23.2728 14.1012 23.2728H15.1449C16.0579 23.2728 16.8044 22.5451 16.8279 21.6325L16.9416 17.2099L16.7038 18.0219C16.4937 18.7394 15.8357 19.2324 15.0881 19.2324H12.2084L11.1818 18.6754L10.0703 19.2324H10.4017C11.1514 19.2324 11.8108 19.7281 12.0189 20.4484L12.4839 22.0568Z"
      fill="url(#paint1_radial_1205_4651)"
    />
    <path
      d="M17.9997 8.72729H12.1673C10.5008 8.72729 9.50099 10.9297 8.83442 13.1321C8.04471 15.7413 7.01136 19.231 10.0009 19.231H12.5193C13.2719 19.231 13.9326 18.7334 14.1395 18.0098C14.5773 16.4779 15.3447 13.8057 15.9474 11.7714C16.2537 10.7376 16.5088 9.84974 16.9004 9.29685C17.12 8.98688 17.4858 8.72729 17.9997 8.72729Z"
      fill="url(#paint2_linear_1205_4651)"
    />
    <path
      d="M17.9997 8.72729H12.1673C10.5008 8.72729 9.50099 10.9297 8.83442 13.1321C8.04471 15.7413 7.01136 19.231 10.0009 19.231H12.5193C13.2719 19.231 13.9326 18.7334 14.1395 18.0098C14.5773 16.4779 15.3447 13.8057 15.9474 11.7714C16.2537 10.7376 16.5088 9.84974 16.9004 9.29685C17.12 8.98688 17.4858 8.72729 17.9997 8.72729Z"
      fill="url(#paint3_linear_1205_4651)"
    />
    <path
      d="M13.999 23.2727H19.8315C21.4979 23.2727 22.4978 21.07 23.1644 18.8672C23.9541 16.2575 24.9874 12.7672 21.9979 12.7672H19.4795C18.7269 12.7672 18.0662 13.2649 17.8594 13.9885C17.4215 15.5206 16.6542 18.1935 16.0514 20.2281C15.7451 21.2621 15.4899 22.1501 15.0984 22.7031C14.8788 23.0131 14.513 23.2727 13.999 23.2727Z"
      fill="url(#paint4_radial_1205_4651)"
    />
    <path
      d="M13.999 23.2727H19.8315C21.4979 23.2727 22.4978 21.07 23.1644 18.8672C23.9541 16.2575 24.9874 12.7672 21.9979 12.7672H19.4795C18.7269 12.7672 18.0662 13.2649 17.8594 13.9885C17.4215 15.5206 16.6542 18.1935 16.0514 20.2281C15.7451 21.2621 15.4899 22.1501 15.0984 22.7031C14.8788 23.0131 14.513 23.2727 13.999 23.2727Z"
      fill="url(#paint5_linear_1205_4651)"
    />
    <defs>
      <radialGradient
        id="paint0_radial_1205_4651"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(21.0929 14.7325) rotate(-129.304) scale(6.29211 5.91658)"
      >
        <stop offset="0.0955758" stop-color="#00AEFF" />
        <stop offset="0.773185" stop-color="#2253CE" />
        <stop offset="1" stop-color="#0736C4" />
      </radialGradient>
      <radialGradient
        id="paint1_radial_1205_4651"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(11.3169 19.2063) rotate(51.84) scale(5.81498 5.64069)"
      >
        <stop stop-color="#FFB657" />
        <stop offset="0.633728" stop-color="#FF5F3D" />
        <stop offset="0.923392" stop-color="#C02B3C" />
      </radialGradient>
      <linearGradient
        id="paint2_linear_1205_4651"
        x1="11.8182"
        y1="10"
        x2="12.6503"
        y2="19.6273"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.156162" stop-color="#0D91E1" />
        <stop offset="0.487484" stop-color="#52B471" />
        <stop offset="0.652394" stop-color="#98BD42" />
        <stop offset="0.937361" stop-color="#FFC800" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_1205_4651"
        x1="12.5455"
        y1="8.72729"
        x2="12.9999"
        y2="19.231"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#3DCBFF" />
        <stop offset="0.246674" stop-color="#0588F7" stop-opacity="0" />
      </linearGradient>
      <radialGradient
        id="paint4_radial_1205_4651"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(22.2975 11.7387) rotate(109.274) scale(13.959 16.7224)"
      >
        <stop offset="0.0661714" stop-color="#8C48FF" />
        <stop offset="0.5" stop-color="#F2598A" />
        <stop offset="0.895833" stop-color="#FFB152" />
      </radialGradient>
      <linearGradient
        id="paint5_linear_1205_4651"
        x1="22.7583"
        y1="12.1258"
        x2="22.7523"
        y2="14.9872"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.0581535" stop-color="#F8ADFA" />
        <stop offset="0.708063" stop-color="#A86EDD" stop-opacity="0" />
      </linearGradient>
    </defs>
  </SvgIcon>
);
