import React from "react";
import SvgIcon, { SvgIconProps } from "../SvgIcon";

export const MistralIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon
    {...props}
    style={{ fill: "transparent" }}
    width="32"
    height="32"
    viewBox="0 0 32 32"
  >
    <path
      d="M0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16Z"
      fill="white"
    />
    <path
      d="M0.363636 16C0.363636 7.36427 7.36427 0.363636 16 0.363636C24.6357 0.363636 31.6364 7.36427 31.6364 16C31.6364 24.6357 24.6357 31.6364 16 31.6364C7.36427 31.6364 0.363636 24.6357 0.363636 16Z"
      stroke="black"
      stroke-opacity="0.1"
      stroke-width="0.727273"
    />
    <g clip-path="url(#clip0_1205_4649)">
      <path d="M21.8038 9.81805H19.334V12.2879H21.8038V9.81805Z" fill="black" />
      <path
        d="M23.0392 9.81805H20.5693V12.2879H23.0392V9.81805Z"
        fill="#F7D046"
      />
      <path
        d="M11.9249 9.81805H9.45508V12.2879H11.9249V9.81805Z"
        fill="black"
      />
      <path
        d="M11.9249 12.2878H9.45508V14.7577H11.9249V12.2878Z"
        fill="black"
      />
      <path
        d="M11.9249 14.7577H9.45508V17.2275H11.9249V14.7577Z"
        fill="black"
      />
      <path
        d="M11.9249 17.2276H9.45508V19.6974H11.9249V17.2276Z"
        fill="black"
      />
      <path
        d="M11.9249 19.6974H9.45508V22.1672H11.9249V19.6974Z"
        fill="black"
      />
      <path
        d="M13.1603 9.81805H10.6904V12.2879H13.1603V9.81805Z"
        fill="#F7D046"
      />
      <path
        d="M23.0392 12.2878H20.5693V14.7577H23.0392V12.2878Z"
        fill="#F2A73B"
      />
      <path
        d="M13.1603 12.2878H10.6904V14.7577H13.1603V12.2878Z"
        fill="#F2A73B"
      />
      <path
        d="M19.3351 12.2878H16.8652V14.7577H19.3351V12.2878Z"
        fill="black"
      />
      <path
        d="M20.5694 12.2878H18.0996V14.7577H20.5694V12.2878Z"
        fill="#F2A73B"
      />
      <path
        d="M15.629 12.2878H13.1592V14.7577H15.629V12.2878Z"
        fill="#F2A73B"
      />
      <path
        d="M18.0997 14.7577H15.6299V17.2275H18.0997V14.7577Z"
        fill="#EE792F"
      />
      <path
        d="M20.5694 14.7577H18.0996V17.2275H20.5694V14.7577Z"
        fill="#EE792F"
      />
      <path
        d="M15.629 14.7577H13.1592V17.2275H15.629V14.7577Z"
        fill="#EE792F"
      />
      <path
        d="M16.8644 17.2276H14.3945V19.6974H16.8644V17.2276Z"
        fill="black"
      />
      <path
        d="M18.0997 17.2276H15.6299V19.6974H18.0997V17.2276Z"
        fill="#EB5829"
      />
      <path
        d="M23.0392 14.7577H20.5693V17.2275H23.0392V14.7577Z"
        fill="#EE792F"
      />
      <path
        d="M13.1603 14.7577H10.6904V17.2275H13.1603V14.7577Z"
        fill="#EE792F"
      />
      <path d="M21.8038 17.2276H19.334V19.6974H21.8038V17.2276Z" fill="black" />
      <path
        d="M23.0392 17.2276H20.5693V19.6974H23.0392V17.2276Z"
        fill="#EB5829"
      />
      <path d="M21.8038 19.6974H19.334V22.1672H21.8038V19.6974Z" fill="black" />
      <path
        d="M13.1603 17.2276H10.6904V19.6974H13.1603V17.2276Z"
        fill="#EB5829"
      />
      <path
        d="M23.0392 19.6974H20.5693V22.1672H23.0392V19.6974Z"
        fill="#EA3326"
      />
      <path
        d="M13.1603 19.6974H10.6904V22.1672H13.1603V19.6974Z"
        fill="#EA3326"
      />
    </g>
    <defs>
      <clipPath id="clip0_1205_4649">
        <rect
          width="13.5841"
          height="12.3636"
          fill="white"
          transform="translate(9.4541 9.81818)"
        />
      </clipPath>
    </defs>
  </SvgIcon>
);
