import React from 'react';
import SvgIcon, { SvgIconProps } from '../SvgIcon';

export const StoryblokIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props} style={{ fill: 'transparent' }} viewBox="0 0 18 22">
    <path
      d="M17.1924 0.5L17.1728 0.519603C17.6746 0.519603 17.9824 0.909671 18 1.38599V1.42911V17.6415C18 18.1413 17.7079 18.449 17.2375 18.4667H17.1924H6.29794L3.3146 21.6892V18.4471H0.911467C0.429272 18.4471 0.0235217 18.155 0 17.6846V17.6415V1.40951C0 0.927311 0.392029 0.523521 0.889905 0.501959H0.933028H17.1924V0.5ZM11.6433 3.8048H3.3146V15.3697H10.6495C11.2081 15.3697 11.7471 15.2658 12.245 15.1423C12.7096 15.0462 13.1192 14.8424 13.4917 14.613L13.5701 14.564C13.917 14.3563 14.2091 14.0681 14.4188 13.7251L14.46 13.6565H14.4384C14.6442 13.286 14.7501 12.8509 14.7501 12.3353C14.7501 11.5297 14.5639 10.8888 14.1895 10.3928C13.7975 9.91849 13.278 9.58723 12.6155 9.40297C13.1408 9.17952 13.5779 8.78749 13.8582 8.28765C14.1287 7.79173 14.2738 7.27622 14.2738 6.73914C14.2836 6.00996 14.0327 5.30039 13.5701 4.73587C13.3427 4.46733 13.0526 4.23996 12.7409 4.0753C12.4312 3.90281 12.1039 3.81852 11.7256 3.80872L11.6433 3.8048ZM10.4515 10.2674C10.6906 10.2674 10.871 10.385 11.0297 10.581C11.1787 10.7849 11.2551 11.0319 11.2493 11.2847C11.2493 11.5768 11.1493 11.812 10.9905 11.9688C10.8239 12.1139 10.622 12.2079 10.4025 12.2217L10.3515 12.2236H6.2999V10.2713H10.4515V10.2674ZM10.1124 6.36083C10.3025 6.36279 10.4868 6.43139 10.6318 6.55684C10.771 6.69405 10.8514 6.90967 10.8514 7.20173C10.8514 7.51536 10.7808 7.76037 10.6201 7.88386L10.5907 7.90542C10.4436 8.01323 10.2614 8.10536 10.0771 8.11908L10.032 8.12104H6.2999V6.36279H10.1124V6.36083Z"
      fill="currentColor"
    />
  </SvgIcon>
);
