import { JSONSchema, JSONSchemaType, Maybe } from '@makeropsinc/workflow-types';
import {
  ValidationError,
  ValidationResults,
} from '@makeropsinc/workflow-validator';
import Ajv from 'ajv';
import jsonMap from 'json-source-map';
import jsonpointer from 'jsonpointer';

export const jsonPointerToPath = (pointer: string) =>
  (pointer || '').replace(/\//g, '.').replace(/~0/g, '~').replace(/~1/g, '/');

export const getPropertyFromSchema = (
  schema: Maybe<JSONSchema>,
  propertyName: string,
  typeName: string = ''
): Maybe<JSONSchema> => {
  if (!schema) return undefined;

  let type = schema;
  if ('$ref' in schema) {
    const rootTypeName = schema.$ref?.split('/')[2] || '';
    type = schema.definitions?.[typeName || rootTypeName] as JSONSchema;
  }

  if (!type.properties) return undefined;

  const property = type.properties[propertyName];
  if (property?.type) {
    return property;
  }

  // Has a ref?
  const refName = (property?.$ref || '').split('/')[2];
  if (refName) {
    return getPropertyFromSchema(schema, propertyName, refName);
  }

  // Fallback to the property we found
  return property;
};

export interface TypeDefinition {
  name: string;
  type: string;
  required: boolean;
  enum?: string[];
  input?: { [key: string]: string };
  description?: string;
  default?: JSONSchemaType;
  properties?: TypeDefinition[];
}

const getTypeDefinition = (
  property: JSONSchema,
  name: string,
  requiredFields: string[],
  schema: JSONSchema
): TypeDefinition => {
  if (property.type) {
    return {
      name,
      type: property.type,
      required: (requiredFields || []).includes(name),
      enum: property.enum as string[],
      input: property.input,
      description: property.description,
      default: property.default,
      properties: property.properties
        ? Object.keys(property.properties).map((k) =>
            getTypeDefinition(
              property.properties?.[k] as JSONSchema,
              k,
              property.required || [],
              schema
            )
          )
        : undefined,
    };
  }

  const refName = (property.$ref || '').split('/')[2];
  if (!refName) {
    return {
      name,
      type: 'any',
      required: (requiredFields || []).includes(name),
      enum: property.enum as string[],
      input: property.input,
      description: property.description,
      default: property.default,
      properties: property.properties
        ? Object.keys(property.properties).map((k) =>
            getTypeDefinition(
              property.properties?.[k] as JSONSchema,
              k,
              requiredFields,
              schema
            )
          )
        : undefined,
    };
  }
  return getTypeDefinition(
    schema.definitions?.[refName] as JSONSchema,
    name,
    property.required || [],
    schema
  );
};

export const getSchemaTypeDefinitions = (
  schema: JSONSchema,
  typeName = ''
): TypeDefinition[] => {
  if (!schema) return [];

  let type = schema;
  if ('$ref' in schema) {
    const rootTypeName = schema.$ref?.split('/')[2] || '';
    type = schema.definitions?.[typeName || rootTypeName] as JSONSchema;
  }
  return Object.keys(type.properties || {}).map((p) =>
    getTypeDefinition(
      type.properties?.[p] as JSONSchema,
      p,
      type.required || [],
      schema
    )
  );
};

export const getSchemaTypeDescription = (schema: JSONSchema, typeName = '') =>
  getSchemaTypeDefinitions(schema, typeName)
    .map((p) => `${p.name}: ${p.type}${p.required ? ' (required)' : ''}`)
    .join('\n');

const ajv = new Ajv({
  allErrors: true,
  strict: false,
});

const tryParse = (str: string) => {
  let json;
  try {
    json = JSON.parse(str);
  } catch (err) {
    json = undefined;
  }

  return json;
};

export interface JSONPos {
  line: number;
  ch: number;
}

export interface JSONValidationError {
  from: JSONPos;
  to: JSONPos;
  message: string;
  dataPath: string;
}

const instrinsicFunctions = [
  'String.format',
  'JSON.parse',
  'JSON.stringify',
  'Array.from',
];

export const isJSONPathOrIntrinsicFunction = (
  value?: string,
  quoted?: boolean
) => {
  const jsonRoot = quoted ? '"$"' : '$';
  const isPath = value?.startsWith && value?.startsWith(jsonRoot);

  const isInstrinsic = instrinsicFunctions.some(
    (fn) => value?.startsWith && value.startsWith(`${quoted ? '"' : ''}${fn}(`)
  );

  return isPath || isInstrinsic;
};

export const validateJSON = (
  string: string,
  schema: JSONSchema,
  ignoreJsonPaths: boolean = false
): JSONValidationError[] => {
  const jsonValue = tryParse(string);
  if (!jsonValue) {
    return [
      {
        from: { line: 0, ch: 0 },
        to: { line: 0, ch: 0 },
        message: 'Invalid JSON',
        dataPath: '/',
      },
    ];
  }

  const map = jsonMap.parse(string);
  if (schema && !ajv.validate(schema, jsonValue)) {
    const allErrors =
      ajv.errors?.map((error) => {
        const pointer = map.pointers[error.instancePath];
        if (ignoreJsonPaths) {
          const value =
            (string.split('\n')[pointer.value.line] || '').substring(
              pointer.value.column
            ) || '';
          if (isJSONPathOrIntrinsicFunction(value.trim(), true)) {
            return undefined;
          }
        }

        const from = { line: pointer.value.line, ch: pointer.value.column };
        const to = { line: pointer.valueEnd.line, ch: pointer.valueEnd.column };
        let message = error.message || '';
        const s = followRefs(
          jsonpointer.get(schema, cleanupSchemaPath(error.schemaPath)),
          schema
        );
        if (error.params.missingProperty) {
          message = `Required field "${error.params.missingProperty}"`;
        }
        if (s?.errorMessage) message = s.errorMessage;

        return { from, to, message, dataPath: error.instancePath };
      }) || [];
    return allErrors.filter((e) => !!e) as JSONValidationError[];
  }

  return [];
};

export const validateObject = (
  obj: {},
  schema: JSONSchema,
  ignoreJsonPaths: boolean = false
): ValidationResults => {
  let errors: ValidationError[] = [];
  if (!ajv.validate(schema, obj)) {
    errors =
      (ajv.errors
        ?.map((e) => {
          let dataPath = e.instancePath ?? '';
          if (
            e.params?.missingProperty &&
            !dataPath.endsWith(e.params.missingProperty)
          ) {
            dataPath = `${dataPath}/${e.params.missingProperty}`;
          }
          const instanceVal = jsonpointer.get(obj, dataPath);
          if (
            ignoreJsonPaths &&
            isJSONPathOrIntrinsicFunction(instanceVal, false)
          )
            return undefined;

          let message = e.message || '';
          const s = jsonpointer.get(schema, cleanupSchemaPath(e.schemaPath));
          if (e.params.missingProperty) message = 'Required field';
          if (s?.errorMessage) message = s.errorMessage;

          return {
            code: 'VALIDATION_ERROR',
            schemaPath: e.schemaPath,
            dataPath,
            message,
          } as ValidationError;
        })
        .filter((v) => !!v) as ValidationError[]) ?? [];
  }

  return {
    isValid: errors.length === 0,
    errors,
  };
};

export const getNewObjectWithDefaults = (schema: JSONSchema) => {
  const defaults = getSchemaTypeDefinitions(schema).filter(
    (f) => 'default' in f
  );
  if (defaults.length) {
    return defaults.reduce(
      (acc, curr) => ({
        ...acc,
        [curr.name]: curr.default,
      }),
      {}
    );
  }

  return {};
};

export const cleanupRef = (path: string) =>
  (path || '')
    .replace('#', '')
    .replace(/^\/$/, '')
    .replace(/\/$/, '')
    .replace(/\/\//g, '/');

export const cleanupSchemaPath = (path: string) =>
  cleanupRef((path || '').replace(/required/g, '').replace(/pattern/g, ''));

export const followRefs = (
  property: Maybe<JSONSchema>,
  schema: JSONSchema
): JSONSchema => {
  if (property?.$ref) {
    return jsonpointer.get(schema, cleanupRef(property.$ref)) ?? property;
  }

  return property ?? schema;
};

export const createValueFromSchemaType = (
  property: JSONSchema,
  schema: JSONSchema
): JSONSchemaType => {
  const prop = followRefs(property, schema);
  if (!prop?.type) return null;
  if (prop.default !== undefined) return prop.default;

  switch (prop.type) {
    case 'object':
      return Object.keys(prop.properties || {}).reduce((acc, curr) => {
        const p = prop.properties?.[curr];
        if (p?.default) {
          return {
            ...acc,
            [curr]: createValueFromSchemaType(p as JSONSchema, schema),
          };
        }

        return acc;
      }, {});
    default:
      return null;
  }
};
