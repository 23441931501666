import { Avatar } from "@/components/cataylst/avatar";
import { Badge } from "@/components/cataylst/badge";
import { Input, InputGroup } from "@/components/cataylst/input";
import { Link } from "@/components/cataylst/link";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/cataylst/table";
import { Button } from "@/components/custom/button";
import { SarahIcon, SearchIcon } from "@/components/custom/icons";
import Loading from "@/components/custom/loading";
import { MenuIcon } from "@/components/custom/menu-icon";
import MainSidebar from "@/components/custom/sidebar";
import { useBeefreeTemplates } from "@/hooks/useBeefreeTemplates";
import { getRelativeDate } from "@/utils/format";
import { useNavigate, useParams } from "react-router-dom";

export default function ContentPage() {
  const { data: templates, isLoading } = useBeefreeTemplates();
  const { templateType } = useParams();
  const navigate = useNavigate();

  const onNewTemplate = () => {
    navigate("/content/create/new");
  };
  console.log({ templates });

  const filterTemplates = () => {
    if (!templateType) return templates;
    const typeMap = {
      emails: "email",
      "landing-pages": "page",
      "text-messages": "pureText",
      popups: "popup",
    };
    return templates.filter((item) => item.type === typeMap[templateType]);
  };

  return (
    <main>
      <MainSidebar
        toolbar={
          <Button color="dark" onClick={onNewTemplate}>
            New Template
          </Button>
        }
      >
        <div className="text-cell px-8 py-5">
          <div className="mb-5 flex justify-between">
            <div className="flex gap-2">
              <div className="w-[250px]">
                <InputGroup>
                  <MenuIcon
                    src={SearchIcon}
                    data-slot="icon"
                    className="!size-4"
                  />
                  <Input
                    placeholder="Search templates"
                    className="h-9 pl-9 pr-2 !rounded-full"
                  />
                </InputGroup>
              </div>
            </div>
          </div>
          <Table grid>
            <TableHead>
              <TableRow>
                <TableHeader>Template</TableHeader>
                <TableHeader>Type</TableHeader>
                <TableHeader>Status</TableHeader>
                <TableHeader>Creator</TableHeader>
                <TableHeader>Last Modified</TableHeader>
              </TableRow>
            </TableHead>
            <TableBody>
              {filterTemplates().map((template) => (
                <TableRow key={template.templateId}>
                  <TableCell className="font-semibold">
                    <Link href={`/content/email/${template.templateId}`}>
                      {template.templateId}
                    </Link>
                  </TableCell>
                  <TableCell>{template.type}</TableCell>
                  <TableCell>
                    <Badge color="blue">Active</Badge>
                  </TableCell>
                  <TableCell>
                    <div className="flex gap-2 items-center">
                      <Avatar
                        square={false}
                        src={SarahIcon}
                        className="size-4"
                      />
                      <span>Brooklyn Simmons</span>
                    </div>
                  </TableCell>
                  <TableCell>
                    {getRelativeDate(new Date(template.lastUpdate))}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          {isLoading && (
            <div className="w-full h-full mt-4">
              <Loading />
            </div>
          )}
        </div>
      </MainSidebar>
    </main>
  );
}
