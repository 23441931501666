import React, { ReactNode, useState, useCallback } from 'react';
import { ChevronUpIcon, InfoIcon } from '@/assets/app-icons';

interface Props {
  content: ReactNode | string;
  required?: boolean;
  infoText?: ReactNode | string;
  actions?: ReactNode;
  children?: ReactNode | ReactNode[];
}

export const StaticLabel: React.FC<Props> = ({
  content: title,
  required,
  infoText,
  children,
}) => {
  const [infoExpanded, setInfoExpanded] = useState(false);

  const toggleInfoText = useCallback(
    () => setInfoExpanded((value) => !value),
    []
  );

  return (
    <div>
      <div className='flex gap-1'>
        {typeof title === 'string' && (
          <div>{title}</div>
        )}
        {typeof title !== 'string' && title}
        {required && (
          <div className='text-red-600'>
            *
          </div>
        )}
        {infoText !== undefined && (
          <button role="button" onClick={toggleInfoText}>
            {infoExpanded && <ChevronUpIcon fontSize="small" />}
            {!infoExpanded && <InfoIcon fontSize="small" />}
          </button>
        )}
      </div>
      {infoExpanded && infoText !== undefined && (
        <div>
          {typeof infoText === 'string' && (
            <div>
              {infoText}
            </div>
          )}
          {typeof infoText !== 'string' && infoText}
        </div>
      )}
      {children}
    </div>
  );
};
