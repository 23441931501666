import { Dialog, DialogPanel, DialogTitle } from "@headlessui/react";
import { IconButton } from "./icon-button";
import { CameraIcon, CloseLeftIcon, CloseRightIcon, DocIcon, LogoIcon, PlusIcon, SarahIcon, TableIcon, ThunderIcon, VoiceIcon, XMarkIcon } from "./icons";
import { Input, InputGroup } from "../cataylst/input";
import { MenuIcon } from "./menu-icon";
import { useCallback, useEffect, useState } from "react";
import { useConversationStore } from "@/services/conversation";
import { useWebsocketStore } from "@/services/websocket";
import Sockette from "sockette";
import Messages from "../core/conversation/messages";
import { Environment } from "@/utils/constants";
import { Button } from "./button";
import AvatarGroup from "./avatar-group";
import { formatDate } from "@/utils/format";
import { ChevronRightIcon } from "@heroicons/react/16/solid";
import AgentInput from "./agent-input";
import { apiClient } from "@/services/api";

interface IMainDrawerProps {
  isDrawerOpen: boolean;
  setDrawerOpen: (open: boolean) => void;
};

interface IDiscussion {
  id: number;
  title: string;
  description: string;
  date: Date;
  users: Array<{ icon: string, name: string; }>;
};

const mockDiscussions: IDiscussion[] = [
  {
    id: 0,
    title: 'Meta company contacts',
    description: 'Show me all the contacts who work at Meta that I haven’t contacted yet?',
    date: new Date(),
    users: [
      {
        icon: LogoIcon,
        name: 'Bloom AI',
      }
    ]
  },
  {
    id: 1,
    title: 'Q4 revenue forecast',
    description: 'What is our projected revenue for Q4?',
    date: new Date(),
    users: [
      {
        icon: LogoIcon,
        name: 'Bloom AI',
      },
      {
        icon: SarahIcon,
        name: 'Arlene',
      },
    ]
  },
  {
    id: 2,
    title: 'Customer churn risk',
    description: 'Which customers are at risk of churning and why?',
    date: new Date(),
    users: [
      {
        icon: LogoIcon,
        name: 'Bloom AI'
      }
    ]
  },
];

interface IDiscussionSectionProps {
  discussion: IDiscussion;
};

function DiscussionSection({ discussion }: IDiscussionSectionProps) {
  return (
    <div className="border-t border-solid border-t-gray-200 flex justify-between items-center">
      <div>
        <div className="mt-4">
          <span className="text-sm leading-[17.5px] font-semibold">{discussion.title}</span>
        </div>
        <div>
          <div className="text-sm font-normal">{discussion.description}</div>
        </div>
        <div className="flex justify-between items-center mt-1 mb-4">
          <div className="flex gap-1 flex-col">
            <div className="flex items-center gap-2">
              <AvatarGroup srcs={discussion.users.map(user => user.icon)} />
              <span className="text-xs leading-[18px] font-normal text-[#00000099]">
                {discussion.users.map(user => user.name).join(" & ")}
                -
                {formatDate(discussion.date)}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div>
        <ChevronRightIcon height={16} />
      </div>
    </div>
  )
}

interface ITopic {
  id: number;
  icon: string;
  description: string;
};

const mockTopics = [
  {
    id: 0,
    icon: ThunderIcon,
    description: 'Suggest actions to advanced stalled deals'
  },
  {
    id: 1,
    icon: DocIcon,
    description: 'Generate personalised customer content'
  },
  {
    id: 2,
    icon: TableIcon,
    description: 'Generate multi-scenario revenue forecasts'
  },
  {
    id: 3,
    icon: CameraIcon,
    description: 'Optimize sales stages and processes'
  }
];

interface ITopicSectionProps {
  topic: ITopic;
};

const TopicSection = ({ topic }: ITopicSectionProps) => {
  return (
    <div className="h-[110px] bg-[#F2F2F299] p-4">
      <img src={topic.icon} className="size-4" />
      <div className="mt-3 text-sm">
        {topic.description}
      </div>
    </div>
  )
};

export default function MainDrawer({ isDrawerOpen, setDrawerOpen }: IMainDrawerProps) {
  const [drawerSize, setDrawerSize] = useState('sm');
  const { connect, disconnect, connected, subscribe, unsubscribe, socket } =
    useWebsocketStore();
  const {
    conversation,
    messages,
    suggestions,
    cursors,
    fetchMessages,
    sendMessage,
    updateTitle,
    clearSuggestions,
    processWSMessage,
  } = useConversationStore();
  const [conversationId, setConversationId] = useState('');
  const [isNewDiscussion, setNewDiscussion] = useState(false);

  const [newMessage, setNewMessage] = useState('');

  const handleTitleUpdate = useCallback(
    async (title: string) => {
      await updateTitle(title);
    },
    [updateTitle]
  );

  const handleMessageUpdate = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (!(window as any).freezeAgentInput)
        setNewMessage(e.target.value);
    },
    []
  );

  const sendMessageHandler = useCallback(async () => {
    if (!newMessage) return;
    if (cursors.newMessage.sending) return;

    await sendMessage(newMessage, socket as Sockette);
    (window as any).freezeAgentInput = true;
    setNewMessage('');
    clearSuggestions();
    setTimeout(() => {
      (window as any).freezeAgentInput = false;
    }, 1000);
  }, [cursors.newMessage.sending, newMessage, sendMessage, socket]);

  // Handler for the keypress event in the input. 'enter' should send the message
  const handleKeyPressEnter = useCallback(
    () => {
      sendMessageHandler();
    },
    [sendMessageHandler]
  );

  useEffect(() => {
    const getConversations = async () => {
      const { data } = await apiClient.get('/agents/orgs/x-functions/conversations');
      if (data.items[0].agentId === Environment.AGENT_ID)
        setConversationId(data.items[0].conversationId);
    };
    getConversations();
  }, []);

  useEffect(() => {
    if (!conversationId) return;

    fetchMessages(conversationId);
  }, [conversationId, fetchMessages]);

  useEffect(() => {
    if (connected) {
      subscribe(processWSMessage);
    }
    return () => {
      unsubscribe(processWSMessage);
    };
  }, [connected, processWSMessage, subscribe, unsubscribe]);

  useEffect(() => {
    connect();
    return () => {
      disconnect();
    };
  }, [connect, disconnect]);

  useEffect(() => {
    if (conversationId) {
      fetchMessages(conversationId);
    }
  }, [connected, fetchMessages, conversationId]);
  
  const onResizeDrawer = () => {
    setDrawerSize(drawerSize => drawerSize === 'sm' ? 'lg' : 'sm');
  };

  const onNewDiscussion = () => {
    setNewDiscussion(true);
  };

  const onInputSuggestion = (suggestion: string) => {
    setNewMessage(suggestion);
  };

  const onNewConversation = async () => {
    await apiClient.post('/agents/orgs/x-functions/conversations', {
      agentId: Environment.AGENT_ID
    });
    location.reload();
  };

  console.log({ suggestions, newMessage });

  return (
    <Dialog open={isDrawerOpen} onClose={setDrawerOpen} className="relative z-10">
      <div className="fixed inset-0" />
      <div className="fixed inset-0 overflow-hidden">
        <div className="absolute inset-0 overflow-hidden">
          <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
            <DialogPanel
              transition
              className={`pointer-events-auto w-screen ${drawerSize === 'sm' ? 'max-w-md' : 'max-w-6xl'} transform transition duration-500 ease-in-out data-[closed]:translate-x-full sm:duration-700`}
            >
              <div className="flex h-full flex-col overflow-y-auto bg-white shadow-2xl">
                <div className="px-6 min-h-[64px] border-b border-bloom-grey-line">
                  <div className="flex items-center justify-between h-full">
                    <DialogTitle className="text-title">Discussions</DialogTitle>
                    <Button color="dark" className="flex gap-2 items-center" onClick={onNewConversation}>
                      <img src={PlusIcon} className="w-4 h-4" />
                      New Conversation
                    </Button>
                    <div className="flex gap-3">
                      <IconButton src={drawerSize === 'sm' ? CloseLeftIcon : CloseRightIcon} onClick={onResizeDrawer} />
                      <IconButton src={XMarkIcon} onClick={() => setDrawerOpen(false)} />
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    flexGrow: 1,
                    overflow: 'hidden',
                    overflowY: 'auto',
                    padding: 16,
                    maxWidth: 'lg',
                    margin: '0 auto',
                  }}
                >
                  <Messages
                    messages={messages}
                    assistantName={conversation?.agentName ?? ''}
                  />
                  {!newMessage && suggestions?.length > 0 && (
                    <div className="flex flex-col gap-2 ml-10">
                      {[...suggestions].sort().map(suggestion => (
                        <Button onClick={() => onInputSuggestion(suggestion)} key={suggestion} size="sm" className="w-fit bg-[#106EF638]" withArrow>
                          {suggestion}
                        </Button>
                      ))}
                    </div>
                  )}
                </div>
                {/*!isNewDiscussion && (
                  <div className="px-6 py-5">
                    <Button color="dark" className="gap-2 flex items-center" onClick={onNewDiscussion}>
                      <img src={PlusIcon} className="w-4 h-4" />
                      New discussion
                    </Button>
                    <div className="mt-5">
                      {mockDiscussions.map(discussion => (
                        <DiscussionSection discussion={discussion} key={discussion.id} />
                      ))}
                    </div>
                  </div>
                )*/}
                {/*isNewDiscussion && (
                  <div className="flex items-center h-full px-6">
                    <div className="flex-1">
                      <div className="flex items-center">
                        <div className="flex gap-3 items-center text-sm">
                          <img src={LogoIcon} className="size-4" />
                          <div>
                            Hi James. What can I help you with today?
                          </div>
                        </div>
                      </div>
                      <div className="mt-5 flex gap-3 flex-wrap">
                        {mockTopics.map(topic => (
                          <div className="w-[calc(50%-10px)]">
                            <TopicSection key={topic.id} topic={topic} />
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                )*/}
                <div className="relative flex-1 flex flex-col-reverse">
                  <AgentInput
                    size="sm"
                    type="empty"
                    message={newMessage}
                    onChange={handleMessageUpdate}
                    onConfirm={handleKeyPressEnter}
                    withSuffixIcon={false}
                  />
                </div>
              </div>
            </DialogPanel>
          </div>
        </div>
      </div>
    </Dialog>
  )
}
