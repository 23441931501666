import { fetcher } from '@/services/api'
import { getPageParamString, IPageParams } from '@/services/contact';
import useSWR from 'swr'

export function useBusinessEmployees(businessId: number, pageParams?: IPageParams) {
  const { data, error } = useSWR(`/agents/orgs/x-functions/businesses/${businessId}/employees?${getPageParamString(pageParams)}`, fetcher);

  return {
    data,
    isLoading: !error && !data,
    isError: !!error
  }
};
