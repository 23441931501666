import React from 'react';
import SvgIcon, { SvgIconProps } from '../SvgIcon';

export const MauticIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props} style={{ fill: 'transparent' }} viewBox="0 0 22 22">
    <path
      d="M11 21.9999C4.94443 21.9999 0 17.0741 0 11C0 4.92594 4.94443 0 11 0C12.463 0 13.8889 0.277815 15.2222 0.85181C15.5926 1.01849 15.7778 1.44442 15.6296 1.83331C15.463 2.20365 15.0371 2.38888 14.6482 2.24069C13.4815 1.75922 12.2594 1.51851 11.0001 1.51851C5.75932 1.51851 1.50009 5.77775 1.50009 11.0185C1.50009 16.2592 5.75926 20.5185 11 20.5185C16.2407 20.5185 20.5 16.2592 20.5 11.0185C20.5 9.88887 20.3148 8.79623 19.9259 7.75925C19.7778 7.37036 19.9815 6.94442 20.3703 6.7963C20.7592 6.64818 21.1851 6.85184 21.3333 7.24073C21.7777 8.44444 22 9.72219 22 11.0185C21.9999 17.0555 17.0741 21.9999 11 21.9999Z"
      fill="white"
    />
    <path
      d="M15.8149 9.87038L14.1113 11.6481L15.0557 15.6296H17.2038L15.8149 9.87038Z"
      fill="#FDB933"
    />
    <path
      d="M15.1105 4.61108L15.703 5.2037L10.9993 10.1852L6.9808 6.0555L4.66602 15.6296H6.81419L8.09193 10.3148L10.9993 13.4815L17.2215 6.70372L17.8141 7.31482L18.4067 3.96293L15.1105 4.61108Z"
      fill="#FDB933"
    />
  </SvgIcon>
);
