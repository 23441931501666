import MainSidebar from "@/components/custom/sidebar";
import { Button } from "@/components/custom/button";
import { Textarea } from "@/components/cataylst/textarea";
import { PlusIcon, SearchIcon } from "@/components/custom/icons";
import { Input, InputGroup } from "@/components/cataylst/input";
import { MenuIcon } from "@/components/custom/menu-icon";
import { ReactNode, useState } from "react";
import { useNavigate } from "react-router-dom";

export interface IUseCase {
  id: number;
  title: string;
  description: string;
  icon: ReactNode;
  challenges: string[];
  desiredOutcomes: string[];
}

interface IUseCaseItemProps {
  usecase: IUseCase;
  onClick?: Function;
  borderTop?: boolean;
}

function UseCaseItem({ usecase: { title, description, icon }, borderTop, onClick }: IUseCaseItemProps) {
  return (
    <div className={`flex items-center justify-between pr-2 py-4 font-firstext ${borderTop && 'border-t border-solid border-t-gray-200'} border-b border-b-gray-200 cursor-pointer`} onClick={() => ((onClick || (() => {})))()}>
      <div className="flex gap-3 items-center w-[90%]">
        <div className="flex-grow-[32px]">
          {icon}
        </div>
        <div className="flex flex-col gap-1 flex-1 max-w-full">
          <div className="font-semibold text-sm leading-[17.5px]">
            {title}
          </div>
          <div className="text-sm leading-[21px] opacity-60 pr-10 truncate">
            {description}
          </div>
        </div>
      </div>
      <div>
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M5.96967 3.46967C6.26256 3.17678 6.73744 3.17678 7.03033 3.46967L11.0303 7.46967C11.3232 7.76256 11.3232 8.23744 11.0303 8.53033L7.03033 12.5303C6.73744 12.8232 6.26256 12.8232 5.96967 12.5303C5.67678 12.2374 5.67678 11.7626 5.96967 11.4697L9.43934 8L5.96967 4.53033C5.67678 4.23744 5.67678 3.76256 5.96967 3.46967Z" fill="black" fill-opacity="0.4"/>
        </svg>
      </div>
    </div>
  )
}

export const mockUseCases: IUseCase[] = [
  {
    id: 1,
    title: 'AI-driven revenue optimization',
    description: `This approach leverages advanced AI algorithms to continuously analyze market dynamics, customer behaviors, and internal performance metrics. It enables real-time adjustments to pricing, product offerings, and engagement strategies, ensuring maximum revenue capture and sustainable growth.`,
    icon: <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16Z" fill="#7A3DFA"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M16.75 10C16.75 9.58579 16.4142 9.25 16 9.25C15.5858 9.25 15.25 9.58579 15.25 10V12C15.25 12.4142 15.5858 12.75 16 12.75C16.4142 12.75 16.75 12.4142 16.75 12V10ZM10 15.25C9.58579 15.25 9.25 15.5858 9.25 16C9.25 16.4142 9.58579 16.75 10 16.75H12C12.4142 16.75 12.75 16.4142 12.75 16C12.75 15.5858 12.4142 15.25 12 15.25H10ZM11.203 11.203C11.4959 10.9101 11.9708 10.9101 12.2637 11.203L13.7303 12.6697C14.0232 12.9626 14.0232 13.4374 13.7303 13.7303C13.4374 14.0232 12.9626 14.0232 12.6697 13.7303L11.203 12.2637C10.9101 11.9708 10.9101 11.4959 11.203 11.203ZM20.797 12.2637C21.0899 11.9708 21.0899 11.4959 20.797 11.203C20.5041 10.9101 20.0292 10.9101 19.7363 11.203L18.2697 12.6697C17.9768 12.9626 17.9768 13.4374 18.2697 13.7303C18.5626 14.0232 19.0374 14.0232 19.3303 13.7303L20.797 12.2637ZM13.7303 18.2697C14.0232 18.5626 14.0232 19.0374 13.7303 19.3303L12.2637 20.797C11.9708 21.0899 11.4959 21.0899 11.203 20.797C10.9101 20.5041 10.9101 20.0292 11.203 19.7363L12.6697 18.2697C12.9626 17.9768 13.4374 17.9768 13.7303 18.2697ZM16.2372 15.2885C15.9677 15.1986 15.6706 15.2688 15.4697 15.4697C15.2688 15.6705 15.1987 15.9677 15.2885 16.2372L17.2885 22.2372C17.3844 22.5249 17.6443 22.7266 17.9469 22.7481C18.2494 22.7696 18.5352 22.6067 18.6708 22.3354L19.8924 19.8923L22.3354 18.6708C22.6068 18.5351 22.7696 18.2494 22.7481 17.9468C22.7266 17.6442 22.525 17.3844 22.2372 17.2885L16.2372 15.2885ZM18.139 20.0451L17.1859 17.1858L20.0451 18.1389L18.9979 18.6625C18.8528 18.7351 18.7351 18.8528 18.6625 18.9979L18.139 20.0451Z" fill="white"/>
    </svg>
    ,
    challenges: [
      `A company struggles with rapid market shifts, causing misalignment between pricing strategies and customer willingness-to-pay, resulting in lost revenue and market share.`,
      `Sales teams are overwhelmed by the complexity of product configurations and pricing variables, leading to suboptimal deal structures and missed upsell opportunities.`,
      `Marketing campaigns suffer from poor timing and irrelevant messaging, failing to capitalize on fleeting market trends and customer intent signals.`
    ],
    desiredOutcomes: [
      `Achieve dynamic pricing equilibrium that maximizes revenue while maintaining customer satisfaction and market competitiveness.`,
      `Empower sales teams with AI-driven insights to craft tailored product bundles and pricing structures that optimize deal value and close rates.`,
      `Create hyper-personalized, timely marketing campaigns that resonate with individual customer needs and market conditions, significantly boosting conversion rates and customer lifetime value.`
    ]
  },
  {
    id: 2,
    title: 'Predictive sales insights',
    description: `Leverage advanced AI algorithms to analyze historical and current sales data, market conditions, and customer behaviors. This enables accurate sales forecasting, identification of high-potential opportunities, and proactive strategy adjustments to maximize revenue growth.`,
    icon: <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16Z" fill="#106EF6"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M16.75 10C16.75 9.58579 16.4142 9.25 16 9.25C15.5858 9.25 15.25 9.58579 15.25 10V12C15.25 12.4142 15.5858 12.75 16 12.75C16.4142 12.75 16.75 12.4142 16.75 12V10ZM10 15.25C9.58579 15.25 9.25 15.5858 9.25 16C9.25 16.4142 9.58579 16.75 10 16.75H12C12.4142 16.75 12.75 16.4142 12.75 16C12.75 15.5858 12.4142 15.25 12 15.25H10ZM11.203 11.203C11.4959 10.9101 11.9708 10.9101 12.2637 11.203L13.7303 12.6697C14.0232 12.9626 14.0232 13.4374 13.7303 13.7303C13.4374 14.0232 12.9626 14.0232 12.6697 13.7303L11.203 12.2637C10.9101 11.9708 10.9101 11.4959 11.203 11.203ZM20.797 12.2637C21.0899 11.9708 21.0899 11.4959 20.797 11.203C20.5041 10.9101 20.0292 10.9101 19.7363 11.203L18.2697 12.6697C17.9768 12.9626 17.9768 13.4374 18.2697 13.7303C18.5626 14.0232 19.0374 14.0232 19.3303 13.7303L20.797 12.2637ZM13.7303 18.2697C14.0232 18.5626 14.0232 19.0374 13.7303 19.3303L12.2637 20.797C11.9708 21.0899 11.4959 21.0899 11.203 20.797C10.9101 20.5041 10.9101 20.0292 11.203 19.7363L12.6697 18.2697C12.9626 17.9768 13.4374 17.9768 13.7303 18.2697ZM16.2372 15.2885C15.9677 15.1986 15.6706 15.2688 15.4697 15.4697C15.2688 15.6705 15.1987 15.9677 15.2885 16.2372L17.2885 22.2372C17.3844 22.5249 17.6443 22.7266 17.9469 22.7481C18.2494 22.7696 18.5352 22.6067 18.6708 22.3354L19.8924 19.8923L22.3354 18.6708C22.6068 18.5351 22.7696 18.2494 22.7481 17.9468C22.7266 17.6442 22.525 17.3844 22.2372 17.2885L16.2372 15.2885ZM18.139 20.0451L17.1859 17.1858L20.0451 18.1389L18.9979 18.6625C18.8528 18.7351 18.7351 18.8528 18.6625 18.9979L18.139 20.0451Z" fill="white"/>
    </svg>    
    ,
    challenges: [],
    desiredOutcomes: []
  },
  {
    id: 3,
    title: 'Real-time customer personalization',
    description: `This approach enables businesses to dynamically tailor interactions based on individual customer preferences, behaviors, and real-time market conditions. By leveraging AI-driven insights and automated workflows, companies can create highly relevant and engaging experiences that adapt instantly to each customer's unique journey.`,
    icon: <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16Z" fill="#EE2E5C"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M16.75 10C16.75 9.58579 16.4142 9.25 16 9.25C15.5858 9.25 15.25 9.58579 15.25 10V12C15.25 12.4142 15.5858 12.75 16 12.75C16.4142 12.75 16.75 12.4142 16.75 12V10ZM10 15.25C9.58579 15.25 9.25 15.5858 9.25 16C9.25 16.4142 9.58579 16.75 10 16.75H12C12.4142 16.75 12.75 16.4142 12.75 16C12.75 15.5858 12.4142 15.25 12 15.25H10ZM11.203 11.203C11.4959 10.9101 11.9708 10.9101 12.2637 11.203L13.7303 12.6697C14.0232 12.9626 14.0232 13.4374 13.7303 13.7303C13.4374 14.0232 12.9626 14.0232 12.6697 13.7303L11.203 12.2637C10.9101 11.9708 10.9101 11.4959 11.203 11.203ZM20.797 12.2637C21.0899 11.9708 21.0899 11.4959 20.797 11.203C20.5041 10.9101 20.0292 10.9101 19.7363 11.203L18.2697 12.6697C17.9768 12.9626 17.9768 13.4374 18.2697 13.7303C18.5626 14.0232 19.0374 14.0232 19.3303 13.7303L20.797 12.2637ZM13.7303 18.2697C14.0232 18.5626 14.0232 19.0374 13.7303 19.3303L12.2637 20.797C11.9708 21.0899 11.4959 21.0899 11.203 20.797C10.9101 20.5041 10.9101 20.0292 11.203 19.7363L12.6697 18.2697C12.9626 17.9768 13.4374 17.9768 13.7303 18.2697ZM16.2372 15.2885C15.9677 15.1986 15.6706 15.2688 15.4697 15.4697C15.2688 15.6705 15.1987 15.9677 15.2885 16.2372L17.2885 22.2372C17.3844 22.5249 17.6443 22.7266 17.9469 22.7481C18.2494 22.7696 18.5352 22.6067 18.6708 22.3354L19.8924 19.8923L22.3354 18.6708C22.6068 18.5351 22.7696 18.2494 22.7481 17.9468C22.7266 17.6442 22.525 17.3844 22.2372 17.2885L16.2372 15.2885ZM18.139 20.0451L17.1859 17.1858L20.0451 18.1389L18.9979 18.6625C18.8528 18.7351 18.7351 18.8528 18.6625 18.9979L18.139 20.0451Z" fill="white"/>
    </svg>        
    ,
    challenges: [],
    desiredOutcomes: []
  },
  {
    id: 4,
    title: 'Automated marketing integration',
    description: `This approach revolutionizes marketing operations by integrating fragmented systems into a unified, intelligent platform. It enables real-time, data-driven decision making and personalized customer interactions across all touchpoints, dramatically improving campaign effectiveness and operational efficiency.`,
    icon: <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16Z" fill="#F49200"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M16.75 10C16.75 9.58579 16.4142 9.25 16 9.25C15.5858 9.25 15.25 9.58579 15.25 10V12C15.25 12.4142 15.5858 12.75 16 12.75C16.4142 12.75 16.75 12.4142 16.75 12V10ZM10 15.25C9.58579 15.25 9.25 15.5858 9.25 16C9.25 16.4142 9.58579 16.75 10 16.75H12C12.4142 16.75 12.75 16.4142 12.75 16C12.75 15.5858 12.4142 15.25 12 15.25H10ZM11.203 11.203C11.4959 10.9101 11.9708 10.9101 12.2637 11.203L13.7303 12.6697C14.0232 12.9626 14.0232 13.4374 13.7303 13.7303C13.4374 14.0232 12.9626 14.0232 12.6697 13.7303L11.203 12.2637C10.9101 11.9708 10.9101 11.4959 11.203 11.203ZM20.797 12.2637C21.0899 11.9708 21.0899 11.4959 20.797 11.203C20.5041 10.9101 20.0292 10.9101 19.7363 11.203L18.2697 12.6697C17.9768 12.9626 17.9768 13.4374 18.2697 13.7303C18.5626 14.0232 19.0374 14.0232 19.3303 13.7303L20.797 12.2637ZM13.7303 18.2697C14.0232 18.5626 14.0232 19.0374 13.7303 19.3303L12.2637 20.797C11.9708 21.0899 11.4959 21.0899 11.203 20.797C10.9101 20.5041 10.9101 20.0292 11.203 19.7363L12.6697 18.2697C12.9626 17.9768 13.4374 17.9768 13.7303 18.2697ZM16.2372 15.2885C15.9677 15.1986 15.6706 15.2688 15.4697 15.4697C15.2688 15.6705 15.1987 15.9677 15.2885 16.2372L17.2885 22.2372C17.3844 22.5249 17.6443 22.7266 17.9469 22.7481C18.2494 22.7696 18.5352 22.6067 18.6708 22.3354L19.8924 19.8923L22.3354 18.6708C22.6068 18.5351 22.7696 18.2494 22.7481 17.9468C22.7266 17.6442 22.525 17.3844 22.2372 17.2885L16.2372 15.2885ZM18.139 20.0451L17.1859 17.1858L20.0451 18.1389L18.9979 18.6625C18.8528 18.7351 18.7351 18.8528 18.6625 18.9979L18.139 20.0451Z" fill="white"/>
    </svg>    
    ,
    challenges: [],
    desiredOutcomes: []
  },
]

export default function DemoUseCasesPage() {
  const [searchKeyword, setSearchKeyword] = useState('');
  const navigate = useNavigate();
  const onSearchCompanies = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchKeyword(event.target.value);
  };

  const onAddUseCase = () => {
    navigate('/settings/use-cases/new');
  };
  const onEditUseCase = (usecase: IUseCase) => {
    navigate(`/settings/use-cases/${usecase.id}`);
  };

  return (
    <main>
      <MainSidebar
        pageTitle="Use cases"
        shouldHideActionButton
        toolbar={
          <Button color="dark" className="gap-2 flex items-center">
            <img src={PlusIcon} className="w-4 h-4" />
            Add use case
          </Button>
        }
      >
        <div className="text-cell px-8 py-5">
          <div className="flex flex-col gap-2 align-center">
            <div className="w-[250px]">
              <InputGroup>
                <MenuIcon
                  src={SearchIcon}
                  data-slot="icon"
                  className="!size-4"
                />
                <Input
                  placeholder="Search usecases"
                  className="h-9 pl-9 pr-2 !rounded-full border-none !bg-[#F2F2F2]"
                  onChange={onSearchCompanies}
                  value={searchKeyword}
                  color=''
                />
              </InputGroup>
            </div>
          </div>
          <div className="mt-5">
            {mockUseCases.map((usecase, index) => (
              <UseCaseItem usecase={usecase} key={usecase.id} borderTop={index === 0} onClick={() => onEditUseCase(usecase)} />
            ))}
          </div>
          <div
            className="w-full border mt-5 border-[#00000022] text-[#00000099] border-dashed rounded-lg px-4 h-[42px] flex gap-1.5 items-center text-sm cursor-pointer"
            onClick={onAddUseCase}
          >
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M8.75003 3.33331C8.75003 2.9191 8.41424 2.58331 8.00003 2.58331C7.58582 2.58331 7.25003 2.9191 7.25003 3.33331V7.25H3.33337C2.91916 7.25 2.58337 7.58579 2.58337 8C2.58337 8.41421 2.91916 8.75 3.33337 8.75H7.25003V12.6666C7.25003 13.0809 7.58582 13.4166 8.00003 13.4166C8.41424 13.4166 8.75003 13.0809 8.75003 12.6666V8.75H12.6667C13.0809 8.75 13.4167 8.41421 13.4167 8C13.4167 7.58579 13.0809 7.25 12.6667 7.25H8.75003V3.33331Z"
                fill="black"
                fill-opacity="0.6"
              />
            </svg>
            <span>Add usecase</span>
          </div>
        </div>
      </MainSidebar>
    </main>
  )
}
