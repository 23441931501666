import React from 'react';
import SvgIcon, { SvgIconProps } from '../SvgIcon';

export const LinearIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props} style={{ fill: 'transparent' }} viewBox="0 0 20 20">
    <g clip-path="url(#clip0_linear)">
      <path
        d="M16.5204 17.6C16.7204 17.44 16.8804 17.28 17.0404 17.08C20.9604 13.16 20.9604 6.84 17.0404 2.92C13.1204 -1 6.80039 -1 2.88039 2.92C2.72039 3.12 2.56039 3.28 2.40039 3.48L16.5204 17.6Z"
        fill="white"
      />
      <path
        d="M15.3198 18.48L1.5198 4.68C1.2398 5.12 0.999805 5.56 0.799805 6.04L13.9598 19.2C14.4398 19 14.8798 18.76 15.3198 18.48Z"
        fill="white"
      />
      <path
        d="M12.44 19.72L0.28 7.56C0.12 8.16 0.04 8.76 0 9.36L10.64 20C11.24 19.96 11.84 19.88 12.44 19.72Z"
        fill="white"
      />
      <path
        d="M8.44008 19.92L0.0800781 11.56C0.400078 13.6 1.32008 15.52 2.88008 17.12C4.48008 18.64 6.44008 19.6 8.44008 19.92Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_linear">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </SvgIcon>
);
