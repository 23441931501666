import React from "react";
import SvgIcon, { SvgIconProps } from "../SvgIcon";

export const LlamaIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon
    {...props}
    style={{ fill: "transparent" }}
    width="32"
    height="32"
    viewBox="0 0 32 32"
  >
    <path
      d="M0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16Z"
      fill="black"
    />
    <g clip-path="url(#clip0_1205_4650)">
      <path
        d="M9.7282 17.7016C9.7282 18.3136 9.86229 18.7816 10.0376 19.0653C10.131 19.2269 10.2655 19.3608 10.4274 19.4536C10.5893 19.5463 10.7729 19.5945 10.9595 19.5933C11.4102 19.5933 11.8225 19.4821 12.6171 18.3826C13.2536 17.5018 14.0036 16.2683 14.5088 15.4893L15.3635 14.1762C15.9572 13.2642 16.6432 12.2505 17.4322 11.5611C18.0748 11.0024 18.7687 10.6909 19.467 10.6909C20.6393 10.6909 21.756 11.3702 22.6107 12.6444C23.546 14.0399 24.0017 15.7975 24.0017 17.6114C24.0017 18.6897 23.7891 19.4821 23.4275 20.108C23.0786 20.7134 22.3953 21.3182 21.2497 21.3182V19.5933C22.2306 19.5933 22.4738 18.692 22.4738 17.6604C22.4738 16.1904 22.131 14.559 21.376 13.3933C20.8402 12.5665 20.1458 12.0613 19.3818 12.0613C18.5556 12.0613 17.8907 12.6845 17.1434 13.7956C16.7461 14.3859 16.3383 15.1054 15.8804 15.9172L15.3763 16.8074C14.3636 18.6029 14.1071 19.0119 13.6008 19.6868C12.715 20.8731 11.9572 21.3182 10.9595 21.3182C9.77605 21.3182 9.02768 20.8058 8.56698 20.0335C8.18584 19.4042 8 18.5785 8 17.6376L9.7282 17.7016Z"
        fill="#0081FB"
      />
      <path
        d="M9.36328 12.7662C10.1556 11.5449 11.299 10.6908 12.6105 10.6908C13.37 10.6908 14.125 10.9134 14.9134 11.5593C15.7759 12.2632 16.6939 13.4222 17.8418 15.3323L18.253 16.0178C19.2456 17.6715 19.8109 18.5216 20.1409 18.9234C20.566 19.4386 20.8642 19.5911 21.2503 19.5911C22.2313 19.5911 22.4744 18.6897 22.4744 17.6581L23.999 17.6103C23.999 18.6886 23.7864 19.4809 23.4248 20.1068C23.0792 20.7133 22.396 21.3181 21.2503 21.3181C20.5381 21.3181 19.9072 21.1635 19.2094 20.5052C18.6731 20 18.046 19.1025 17.5636 18.2957L16.1286 15.8988C15.4086 14.6958 14.7482 13.7989 14.3659 13.3949C13.9542 12.9559 13.4262 12.4285 12.5826 12.4285C11.8999 12.4285 11.3202 12.9075 10.835 13.6403L9.36328 12.7662Z"
        fill="url(#paint0_linear_1205_4650)"
      />
      <path
        d="M12.582 12.4285C11.8993 12.4285 11.3195 12.9076 10.8343 13.6404C10.1483 14.6758 9.7282 16.2182 9.7282 17.7016C9.7282 18.3136 9.86229 18.7816 10.0376 19.0653L8.5642 20.0335C8.18584 19.4042 8 18.5785 8 17.6376C8 15.9266 8.46961 14.1434 9.36264 12.7662C10.155 11.5449 11.2984 10.6909 12.6098 10.6909L12.582 12.4285Z"
        fill="url(#paint1_linear_1205_4650)"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_1205_4650"
        x1="11.4693"
        y1="15.729"
        x2="22.4861"
        y2="16.2855"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#0064E1" />
        <stop offset="0.4" stop-color="#0064E1" />
        <stop offset="0.83" stop-color="#0073EE" />
        <stop offset="1" stop-color="#0082FB" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_1205_4650"
        x1="10.3046"
        y1="18.4249"
        x2="10.3046"
        y2="14.3631"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#0082FB" />
        <stop offset="1" stop-color="#0064E0" />
      </linearGradient>
      <clipPath id="clip0_1205_4650">
        <rect
          width="16"
          height="10.6273"
          fill="white"
          transform="translate(8 10.6909)"
        />
      </clipPath>
    </defs>
  </SvgIcon>
);
