import React from 'react';
import SvgIcon, { SvgIconProps } from '../SvgIcon';

export const MQTTIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props} style={{ fill: 'transparent' }} viewBox="0 0 16 16">
    <path
      d="M0.0473138 9.06851H0V15.1878C0 15.6294 0.36274 15.9921 0.804337 15.9921H7.10498C7.04978 12.1676 3.91129 9.06851 0.0473138 9.06851ZM0.0473138 4.02169H0V6.62396C5.19665 6.64761 9.42336 10.827 9.47856 15.9921H12.1755C12.1281 9.38393 6.71069 4.02169 0.0473138 4.02169ZM16 15.1957V9.66782C14.1705 5.18877 10.4879 1.64811 5.90636 3.53474e-06H0.804337C0.36274 3.53474e-06 0 0.362744 0 0.804341V1.58502C7.99606 1.60868 14.5017 8.05126 14.549 16H15.1957C15.6451 15.9921 16 15.6373 16 15.1957ZM13.8945 2.23164C14.6358 2.9729 15.3928 3.91129 16 4.73928V0.796455C16.0003 0.691776 15.9799 0.588068 15.94 0.491297C15.9001 0.394526 15.8414 0.306602 15.7674 0.232582C15.6934 0.158563 15.6055 0.0999085 15.5087 0.0599937C15.4119 0.0200788 15.3082 -0.000308787 15.2035 3.53474e-06H11.1424C12.0887 0.654513 13.0665 1.40365 13.8945 2.23164Z"
      fill="currentColor"
    />
  </SvgIcon>
);
