import { Avatar } from "@/components/cataylst/avatar";
import { Badge } from "@/components/cataylst/badge";
import { Dropdown, DropdownButton, DropdownItem, DropdownMenu } from "@/components/cataylst/dropdown";
import { Input, InputGroup } from "@/components/cataylst/input";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/cataylst/table";
import { CircularButton } from "@/components/custom/circular-button";
import { DaveIcon, ExternalLinkIcon, LinkedInIcon, MetaIcon, SarahIcon, SearchIcon, TwitterIcon } from "@/components/custom/icons";
import { MenuIcon } from "@/components/custom/menu-icon";
import { ProgressBar } from "@/components/custom/progressbar";
import { ChevronDownIcon, PlusIcon } from "@heroicons/react/16/solid";

const tags = [
  {
    name: 'Economic Buyer',
    color: 'customGreenLight'
  },
  {
    name: 'Ideal Persona',
    color: 'customPurpleLight'
  },
  {
    name: 'PowerUser',
    color: 'customRedLight'
  },
];

const mockUsers = [
  {
    id: 0,
    name: 'David Wallace',
    avatar: DaveIcon,
    role: 'Account owner'
  },
  {
    id: 1,
    name: 'Tim Jenkins',
    avatar: SarahIcon,
    role: 'Support'
  },
  {
    id: 2,
    name: 'Amanda Guthrie',
    avatar: DaveIcon,
    role: 'Support'
  },
];

export default function DemoContactProfileAside() {
  return (
    <div className="px-8 py-5 font-firstext">
      <div>
        <InputGroup>
          <MenuIcon src={SearchIcon} data-slot="icon" className="!size-4" />
          <Input placeholder="Search events" className="h-9 !rounded-full" />
        </InputGroup>
      </div>
      <div className="mt-8 flex flex-col gap-4 text-sm leading-[17.5px]">
        <div className="flex items-center">
          <div className="min-w-[114px] text-gray-600">
            Name
          </div>
          <div className="flex gap-1 items-center">
            <Avatar square={false} outline={false} className="size-4" src={SarahIcon} />
            Sarah Hughes
          </div>
        </div>
        <div className="flex items-center">
          <div className="min-w-[114px] text-gray-600">
            Company
          </div>
          <div className="flex gap-1 items-center">
            <img src={MetaIcon} className="w-4 h-4" />
            Meta
          </div>
        </div>
        <div className="flex items-center">
          <div className="min-w-[114px] text-gray-600">
            Role
          </div>
          <div>
            CRO
          </div>
        </div>
        <div className="flex items-center">
          <div className="min-w-[114px] text-gray-600">
            Location
          </div>
          <div>
            Menlo Park, CA, USA
          </div>
        </div>
        <div className="flex items-center">
          <div className="min-w-[114px] text-gray-600">
            Phone
          </div>
          <div>
            +1 123 456 7890
          </div>
        </div>
        <div className="flex items-center">
          <div className="min-w-[114px] text-gray-600">
            Email
          </div>
          <div>
            sarah.hughes@meta.com
          </div>
        </div>
        <div className="flex items-center">
          <div className="min-w-[114px] text-gray-600">
            LinkedIn
          </div>
          <div className="flex gap-1 items-center">
            <img src={LinkedInIcon} className="w-4 h-4" />
            Sarah Hughes
          </div>
        </div>
        <div className="flex items-center">
          <div className="min-w-[114px] text-gray-600">
            Twitter (X)
          </div>
          <div className="flex gap-1 items-center">
            <img src={TwitterIcon} className="w-4 h-4" />
            Sarah Hughes
          </div>
        </div>
      </div>
      <div className="mt-8 flex flex-wrap gap-1">
        {tags.map(tag => (
          <Badge color={tag.color as any} key={tag.name} className="h-6">
            {tag.name}
          </Badge>
        ))}
        <CircularButton className="!w-6 !h-6">
          <PlusIcon height={16} />
        </CircularButton>
      </div>
      <div className="mt-8 border-t border-solid border-t-gray-200 pt-7">
        <div className="text-xs tracking-wider font-medium">
          SALES STAGE
        </div>
        <div className="mt-4">
          <Badge color="customPurpleMain" className="h-6">
            Negotiation
          </Badge>
        </div>
        <div className="mt-6 flex flex-col gap-4 text-sm leading-[17.5px]">
          <div className="flex items-center">
            <div className="min-w-[114px] text-gray-600">
              Close date
            </div>
            <div>
              19 Sept 2024 <span className="text-gray-600">(20 days)</span>
            </div>
          </div>
          <div className="flex items-center">
            <div className="min-w-[114px] text-gray-600">
              Revenue
            </div>
            <div>
              $0
            </div>
          </div>
          <div className="flex items-center">
            <div className="min-w-[114px] text-gray-600">
              Projected
            </div>
            <div>
              $3.5M ACV
            </div>
          </div>
        </div>
        <div className="mt-6 flex gap-3 items-center">
          <div>
            <ProgressBar type="circle" value={82}>
              82
            </ProgressBar>
          </div>
          <div className="flex flex-col gap-1">
            <div className="font-semibold text-sm leading-[17.5px]">
              Highly Engaged
            </div>
            <div className="text-xs font-normal text-gray-600 max-w-[255px]">
              Responsive and deeply engaged, particularly during product demo discussions.
            </div>
          </div>
        </div>
        <div className="mt-8 border-t border-solid border-t-gray-200 pt-7">
          <div className="flex justify-between items-center">
            <div className="text-xs tracking-wider font-medium">
              SALES REP
            </div>
            <Dropdown>
              <DropdownButton outline className="!border-none hover:!bg-transparent !outline-none !p-0">
                <div className="flex gap-2 items-center">
                  <span className="font-normal text-sm leading-[17.5px]">Reassign</span>
                  <ChevronDownIcon className="w-4 h-4" />
                </div>
              </DropdownButton>
              <DropdownMenu className="min-w-48">
                <DropdownItem>Thomas</DropdownItem>
                <DropdownItem>Jerry</DropdownItem>
                <DropdownItem>Peter</DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </div>
          <div className="mt-4">
            <div className="flex gap-4 p-4 bg-gray-100">
              <Avatar square={false} src={DaveIcon} outline={false} className="size-10" />
              <div className="flex flex-col gap-1">
                <div className="font-semibold text-sm leading-[17.5px]">
                  David Wallace
                </div>
                <div className="text-xs font-normal">
                  david@bloom-ars.com
                </div>
                <div className="text-xs font-normal">
                  012 345 6789
                </div>
              </div>
            </div>
          </div>
          <div className="mt-8">
            <div className="text-xs tracking-wider font-medium">
              TEAM INVOLVEMENT
            </div>
            <div className="mt-4">
              <Table grid={false} dense={true} fixedHeader={true}>
                <TableHead>
                  <TableRow>
                    <TableHeader>Team member</TableHeader>
                    <TableHeader>Role</TableHeader>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {mockUsers.map((user) => (
                    <TableRow key={user.id}>
                      <TableCell>
                        <div className="flex gap-2 items-center">
                          <Avatar square={false} src={user.avatar} outline={false} className="size-4" />
                          {user.name}
                        </div>
                      </TableCell>
                      <TableCell>
                        {user.role}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
};