import React from 'react';
import SvgIcon, { SvgIconProps } from '../SvgIcon';

export const ClearbitIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props} style={{ fill: 'transparent' }} viewBox="5.5 5.5 28 28">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20 0H0V20V40H20H40V20V0H20Z"
      fill="#499AFF"
    />
    <path
      d="M11 15C11 12.7909 12.7909 11 15 11H20V29H15C12.7909 29 11 27.2091 11 25V15Z"
      fill="white"
    />
    <path
      d="M20 11H25C27.2091 11 29 12.7909 29 15V20H20V11Z"
      fill="white"
      fill-opacity="0.8"
    />
    <path
      d="M20 20H29V25C29 27.2091 27.2091 29 25 29H20V20Z"
      fill="white"
      fill-opacity="0.5"
    />
  </SvgIcon>
);
