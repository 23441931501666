import React from 'react';
import SvgIcon, { SvgIconProps } from '../SvgIcon';

export const WhatsAppIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props} style={{ fill: 'transparent' }} viewBox="0 0 22 22">
    <path
      d="M10.9993 20.0063C16.0838 20.0063 20.2057 15.8845 20.2057 10.8C20.2057 5.71547 16.0838 1.59364 10.9993 1.59364C5.91479 1.59364 1.79297 5.71547 1.79297 10.8C1.79297 15.8845 5.91479 20.0063 10.9993 20.0063Z"
      stroke="white"
      strokeWidth="1.62037"
    />
    <path
      d="M2.58726 16.0381L1.12695 21.2127L6.49204 19.7206L6.77772 18.0698L3.28566 19.181L4.2063 15.8476L2.58726 16.0381Z"
      fill="white"
    />
    <path
      d="M3.22266 19.2127C3.22266 19.181 4.33377 15.4984 4.33377 15.4984L7.00044 18.0063L3.22266 19.2127Z"
      fill="#27D045"
    />
    <path
      d="M7.02158 6.23905C6.69744 6.46396 6.4211 6.75092 6.20859 7.08331C5.99607 7.41571 5.85159 7.78695 5.78347 8.17555C5.62474 9.41365 6.38666 10.6835 6.38666 10.6835C7.15951 11.8333 8.07709 12.879 9.11681 13.7946C10.0263 14.5143 11.0589 15.0629 12.1644 15.4136C12.8093 15.6711 13.5147 15.7372 14.1962 15.6041C14.8002 15.4383 15.3346 15.082 15.72 14.5882C15.8103 14.3636 15.8742 14.1292 15.9105 13.8898C15.9468 13.6798 15.9468 13.465 15.9105 13.2549C15.8787 13.0327 13.593 11.9851 13.3708 12.0486C13.1486 12.1121 12.9581 12.5248 12.7041 12.874C12.4501 13.2232 12.3549 13.3819 12.1009 13.3819C11.847 13.3819 10.7358 12.9375 9.68821 11.9851C9.08984 11.4662 8.6222 10.8137 8.32316 10.0803C8.32316 10.0803 8.51364 9.85809 8.76761 9.50888C8.96523 9.29236 9.10688 9.0308 9.18028 8.74698C9.18028 8.58825 8.29139 6.33429 8.06917 6.23905C7.72167 6.19019 7.36907 6.19019 7.02158 6.23905Z"
      fill="white"
    />
  </SvgIcon>
);
