import React from 'react';
import SvgIcon, { SvgIconProps } from '../SvgIcon';

export const EventbriteIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props} style={{ fill: 'transparent' }} viewBox="7 7 26 26">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20 0H0V20V40H20H40V20V0H20Z"
      fill="#F05537"
    />
    <path
      d="M18.5417 13.8006C19.796 13.5131 21.1072 13.6012 22.3118 14.0537C23.5165 14.5062 24.5613 15.3032 25.3162 16.3453L13.592 18.9984C13.9958 16.5167 15.8896 14.4013 18.5417 13.8006ZM25.3812 23.5601C24.4542 24.9026 23.0542 25.8452 21.4617 26.1994C18.7928 26.8031 16.1279 25.6971 14.6595 23.6133L26.4202 20.9523L28.3357 20.519L31.9982 19.6898C31.9759 18.9031 31.8736 18.1207 31.6929 17.3548C30.2019 11.0263 23.7602 7.08016 17.3028 8.54064C10.8463 10.0021 6.82236 16.3177 8.3114 22.6462C9.80241 28.9746 16.2451 32.9198 22.7016 31.4593C26.5059 30.5986 29.4653 28.0518 30.9504 24.8118L25.3812 23.5601Z"
      fill="white"
    />
  </SvgIcon>
);
