import MainSidebar from "@/components/custom/sidebar";
import { Button } from "@/components/custom/button";
import { TagField, useTags } from "@/components/custom/tag-input";
import { Textarea } from "@/components/cataylst/textarea";
import { MultiTextGroup } from "@/components/custom/multi-text-group";
import { useParams } from "react-router-dom";
import { IPersona, mockPersonas } from "./DemoPersonasPage";
import { useEffect, useState } from "react";
import { apiClient } from "@/services/api";
import { Environment } from "@/utils/constants";
import axios from "axios";

export default function DemoPersonaDetailpage() {
  const {
    tags: tagKeywords,
    handleAddTag: handleAddTagKeyword,
    handleRemoveTag: handleRemoveTagKeyword,
  } = useTags(5);
  const {
    tags: tagTerms,
    handleAddTag: handleAddTagTerm,
    handleRemoveTag: handleRemoveTagTerm,
  } = useTags(5);
  const {
    tags: tagJargons,
    handleAddTag: handleAddTagJargon,
    handleRemoveTag: handleRemoveTagJargon,
  } = useTags(5);

  const { personaId } = useParams();

  const [personaDetails, setPersonaDetails] = useState<IPersona>({
    id: 0,
    title: "",
    icon: mockPersonas[0].icon,
    description: "",
    concerns: [],
    objectives: [],
    painPoints: [],
    primaryResponsibilities: [],
  });

  useEffect(() => {
    const persona = mockPersonas.find(
      (persona) => String(persona.id) === String(personaId)
    );
    if (persona) {
      setPersonaDetails(persona);
    }
  }, [personaId]);

  const onPersonaDetailsChange = (fieldName: string, texts: string[]) => {
    const newPersonaDetails = { ...personaDetails };
    newPersonaDetails[fieldName] = texts;
    setPersonaDetails(newPersonaDetails);
  };

  const onPersonaDetailsSingleChange = (fieldName: string, texts: string) => {
    const newPersonaDetails = { ...personaDetails };
    newPersonaDetails[fieldName] = texts;
    setPersonaDetails(newPersonaDetails);
  };

  const onAddKnowledgebaseFile = async () => {
    const { data: newKnowledgebaseFile } = await apiClient.post(
      `/agents/orgs/x-functions/knowledgebases/${Environment.KNOWLEDGE_BASE_ID}/files/upload-url`,
      {
        fileName: `New-Persona-Knowledgebase-File-${new Date().getTime()}.txt`,
        contentType: "text",
      }
    );
    const filePresignedUrl = newKnowledgebaseFile.uploadUrl;
    await axios.put(
      filePresignedUrl,
      JSON.stringify({ ...personaDetails, icon: undefined }),
      {
        headers: {
          "Content-Type": "text/plain",
        },
      }
    );
  };

  return (
    <main>
      <MainSidebar
        pageTitle={`Personas/${personaDetails ? personaDetails.title : "New"}`}
        shouldHideActionButton
        toolbar={
          <Button color="dark" size="lg" onClick={onAddKnowledgebaseFile}>
            Save
          </Button>
        }
      >
        <div className="text-cell px-8 py-5 max-w-[852px]">
          <div className="flex flex-col gap-2">
            <div className="font-medium">Persona name</div>
            <Textarea
              resizable={false}
              rows={1}
              withItems={null}
              type="text"
              value={personaDetails?.title}
              onChange={(e) => {
                onPersonaDetailsSingleChange("title", e.target.value);
              }}
            />
          </div>
          <div className="flex flex-col gap-2 mt-8">
            <div className="font-medium">Description</div>
            <Textarea
              resizable={false}
              withItems={null}
              type="text"
              value={personaDetails?.description}
              rows={4}
              onChange={(e) => {
                onPersonaDetailsSingleChange("description", e.target.value);
              }}
            />
          </div>
          <div className="mt-8">
            <MultiTextGroup
              buttonName="Add responsibility"
              label={"Primary responsibilities"}
              defaultValues={personaDetails?.primaryResponsibilities}
              onChange={(texts: string[]) =>
                onPersonaDetailsChange("primaryResponsibilities", texts)
              }
            />
          </div>
          <div className="mt-8">
            <MultiTextGroup
              buttonName="Add pain point"
              label={"Pain points"}
              defaultValues={personaDetails?.painPoints}
              onChange={(texts: string[]) =>
                onPersonaDetailsChange("painPoints", texts)
              }
            />
          </div>
          <div className="mt-8">
            <MultiTextGroup
              buttonName="Add concern"
              label={"Concerns"}
              defaultValues={personaDetails?.concerns}
              onChange={(texts: string[]) =>
                onPersonaDetailsChange("concerns", texts)
              }
            />
          </div>
          <div className="mt-8">
            <MultiTextGroup
              buttonName="Add objective"
              label={"Objectives"}
              defaultValues={personaDetails?.objectives}
              onChange={(texts: string[]) =>
                onPersonaDetailsChange("objectives", texts)
              }
            />
          </div>
          <div className="flex flex-col gap-2 mt-8">
            <div className="font-medium">Common job titles</div>
            <TagField
              tags={tagKeywords}
              addTag={handleAddTagKeyword}
              removeTag={handleRemoveTagKeyword}
              maxTags={5}
              color="primary"
              placeholder="Type to add titles..."
            />
          </div>
          <div className="mt-20"></div>
        </div>
      </MainSidebar>
    </main>
  );
}
