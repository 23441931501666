import React from 'react';
import SvgIcon, { SvgIconProps } from '../SvgIcon';

export const MattermostIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props} style={{ fill: 'transparent' }} viewBox="0 0 20 21">
    <path
      d="M12.946 0.931881H12.9257C12.9055 0.952139 12.8852 0.95214 12.8447 0.992657C12.7231 1.11421 12.2977 1.68144 12.2977 1.68144L11.3658 2.83617L10.2719 4.15296L8.40812 6.48266C8.40812 6.48266 7.55727 7.55636 7.7396 8.87315C7.92192 10.1899 8.91458 10.8382 9.66414 11.0813C10.4137 11.3447 11.5887 11.4257 12.5408 10.4938C13.493 9.56193 13.4524 8.18437 13.4524 8.18437L13.3714 5.20639L13.3106 3.48443L13.2701 2.00557C13.2701 2.00557 13.2701 1.29653 13.2499 1.11421C13.2499 1.07369 13.2296 1.05343 13.2296 1.03317V1.01292C13.2093 0.9724 13.1688 0.931883 13.1081 0.911625C13.0878 0.911625 13.0675 0.891365 13.027 0.891365C13.0068 0.911623 12.9662 0.911623 12.946 0.931881ZM9.90724 0C5.77454 0.0405167 1.92546 2.6741 0.527634 6.8068C-1.23484 12.0537 1.56081 17.7463 6.78746 19.529C7.84089 19.8937 8.91458 20.0557 9.98827 20.0557H10.0085C14.1817 20.0557 18.0511 17.4222 19.4692 13.2489C20.9075 8.9947 19.3274 4.41631 15.8834 1.90428L15.9847 4.03141C17.7067 5.93569 18.3955 8.63005 17.5244 11.1826C16.2481 14.9709 12.0344 16.9562 8.10425 15.6394C4.17413 14.3024 2.02675 10.1494 3.32328 6.36112C4.17413 3.80857 6.36203 2.08661 8.89432 1.62067L10.2719 0C10.2111 0 10.1503 0 10.0896 0H9.90724Z"
      fill="currentColor"
    />
  </SvgIcon>
);
