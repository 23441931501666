import { ReactNode } from "react";
import { Button } from "./button";
import { Avatar } from "../cataylst/avatar";
import { formatDateWithMonth } from "@/utils/format";
import { AIIcon, XMarkIcon } from "./icons";

interface INotificationProps {
  logoType: 'user' | 'company' | 'icon',
  profileIcon?: string;
  logoPath: string;
  title?: string;
  date?: Date;
  isUnread?: boolean;
  action: {
    title: string;
    target?: string;
  };
  content: ReactNode;
  color?: 'primary' | 'danger' | 'success' | 'purple',
  iconBackgroundColor?: 'primary' | 'danger' | 'success' | 'black',
  borderTop?: boolean;
  borderBottom?: boolean;
  insideToast?: boolean;
  closeIcon?: boolean;
};

export default function Notification(props: INotificationProps) {
  const getColor = (color?: string) => {
    const classes = {
      'primary': 'text-blue-600',
      'danger': 'text-red-600',
      'success': 'text-[#008052]',
      'purple': 'text-[#6210EA]'
    };
    return (color && classes[color]) || 'text-black';
  };
  const getBackgroundColor = (color?: string) => {
    const classes = {
      'primary': 'bg-blue-600',
      'danger': 'bg-red-600',
      'success': 'bg-green-600',
      'black': 'bg-black'
    };
    return (color && classes[color]) || 'bg-black';
  };
  return (
    <div className={`flex justify-between ${props.insideToast ? 'py-1' : 'py-4'} gap-5 font-firstext border-gray-300 ${props.borderTop && 'border-t'} ${props.borderBottom && 'border-b'}`}>
      <div className="flex gap-3">
        <div className="relative">
          {props.logoType === 'user' && (
            <Avatar square={false} src={props.logoPath} outline={false} className="size-8" />
          )}
          {props.logoType === 'company' && (
            <img src={props.logoPath} className="size-8" />
          )}
          {props.logoType === 'icon' && (
            <div className={`size-8 min-w-8 flex items-center rounded-full justify-center ${getBackgroundColor(props.iconBackgroundColor)}`}>
              <img src={props.logoPath} className="size-4 invert" />
            </div>
          )}
          {props.profileIcon && (
            <Avatar square={false} src={props.profileIcon} outline={true} className="size-4 top-5 left-5 absolute" /> 
          )}
          {props.isUnread && (
            <div className="rounded-full size-2 bg-red-600 absolute top-3 left-[-16px]" />
          )}
        </div>
        <div>
          {props.title && (
            <div className={`flex gap-1 my-1 items-center font-medium text-xs text- ${getColor(props.color)}`}>
              {props.color === 'purple' && (
                <img src={AIIcon} className="size-4" style={{ filter: 'brightness(0) saturate(100%) invert(19%) sepia(90%) saturate(7484%) hue-rotate(260deg) brightness(93%) contrast(117%)' }} />
              )}
              <div>{props.title}</div>
            </div>
          )}
          <div className="mb-3 text-sm">
            {props.content}
          </div>
          <div>
            <Button color="white" size="sm">{props.action.title}</Button>
          </div>
        </div>
      </div>
      {props.date && (
        <div className="text-gray-400 text-xs min-w-fit">
          {formatDateWithMonth(props.date)}
        </div>
      )}
      {props.closeIcon && (
        <img src={XMarkIcon} className="size-4" />
      )}
    </div>
  );
}