import { WSMessage } from "@/types/conversation";
import { Environment } from "@/utils/constants";
import { BloomStorage } from "@/utils/storage";
import Sockette from "sockette";
import { create } from "zustand";

const WS_URL = `${Environment.WS_API_URL}/agents`;

interface WebsocketStore {
  connected: boolean;
  subscriptions: ((message: WSMessage) => void)[];
  socket: Sockette | null;
  connect: () => void;
  disconnect: () => void;
  subscribe: (callback: (message: WSMessage) => void) => void;
  unsubscribe: (callback: (message: WSMessage) => void) => void;
}

export const useWebsocketStore = create<WebsocketStore>((set, get) => ({
  connected: false,
  subscriptions: [],
  socket: null,
  connect: () => {
    const token = BloomStorage.getAccessToken();
    const socket = new Sockette(`${WS_URL}?token=${token}`, {
      onopen: () => {
        console.log("Connected!");
        set({ connected: true });
      },
      onclose: () => {
        console.log("Disconnected!");
        set({ connected: false });
      },
      onmessage: (e) => {
        const message = JSON.parse(e.data) as WSMessage;
        get().subscriptions.forEach((sub) => sub(message));
      },
      onreconnect: (e) => console.log("Reconnecting...", e),
      onmaximum: (e) => console.log("Stop Attempting!", e),
      onerror: (e) => {
        console.log("Error occurred!", e);
      },
      maxAttempts: 100,
      timeout: 60000,
    });
    (window as any).socket = socket;

    set({ socket });
  },
  disconnect: () => {
    get().socket?.close();
    set({ socket: null });
  },
  subscribe: (callback) => {
    set((state) => ({
      subscriptions: [...state.subscriptions, callback],
    }));
  },
  unsubscribe: (callback) => {
    set((state) => ({
      subscriptions: state.subscriptions.filter((sub) => sub !== callback),
    }));
  },
}));
