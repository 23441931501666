import MainSidebar from "@/components/custom/sidebar";
import { Button } from "@/components/custom/button";
import { MultiTextGroup } from "@/components/custom/multi-text-group";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { ISalesStage, mockSalesStages } from "./DemoSalesStagesPage";
import { ColorInput } from "@/components/custom/color-input";
import { apiClient } from "@/services/api";

export default function DemoSalesStageDetailPage() {
  const [stageDetails, setStageDetails] = useState<ISalesStage>({
    id: 0,
    title: "",
    color: "#000000",
    exitCriteria: [],
    metrics: [],
    sellerActions: [],
  });

  const { stageId } = useParams();

  useEffect(() => {
    if (stageId) {
      apiClient.get(`/agents/orgs/x-functions/sales-stages/${stageId}`)
        .then(res => {
          if (res.data) {
            setStageDetails({
              id: res.data.salesStageId,
              title: res.data.name,
              color: res.data.color,
              exitCriteria: res.data.exitCriteria,
              metrics: res.data.metrics,
              sellerActions: res.data.priorityActions
            });
          }
        })
    }
    // const stage = mockSalesStages.find(
    //   (stage) => String(stage.id) === String(stageId)
    // );
    // if (stage) {
    //   setStageDetails(stage);
    // }
  }, [stageId]);

  console.log({ stageDetails });

  const onStageDetailsChange = (fieldName: string, texts: string[] | { id: string; name: string; }[]) => {
    const newStageDetails = { ...stageDetails };
    newStageDetails[fieldName] = texts;
    setStageDetails(newStageDetails);
  };

  const onStageDetailsSingleChange = (fieldName: string, texts: string) => {
    const newStageDetails = { ...stageDetails };
    newStageDetails[fieldName] = texts;
    setStageDetails(newStageDetails);
  };

  const onStageDetailsMultipleChange = (fieldNames: string[], texts: string[]) => {
    const newStageDetails = { ...stageDetails };
    for (let i = 0; i < fieldNames.length; i++)
      newStageDetails[fieldNames[i]] = texts[i];
    setStageDetails(newStageDetails);
  };

  const onSaveSalesStage = async () => {
    const body = {
      salesStageId: stageDetails.id ? stageDetails.id : undefined,
      org_id: 'x-functions',
      name: stageDetails.title,
      description: "Default sales stage description",
      color: stageDetails.color,
      visible: true,
      exitCriteria: stageDetails.exitCriteria,
      priorityActions: stageDetails.sellerActions,
      metrics: stageDetails.metrics || []
    };
    const url = stageDetails.id ? `/agents/orgs/x-functions/sales-stages/${stageDetails.id}` : `/agents/orgs/x-functions/sales-stages`;
    if (stageDetails.id)
      await apiClient.put(url, body);
    else {
      let newData = (await apiClient.post(url, body)).data;
      setStageDetails({
        id: newData.salesStageId,
        title: newData.name,
        color: newData.color,
        exitCriteria: newData.exitCriteria,
        metrics: newData.metrics,
        sellerActions: newData.priorityActions
      });
    }
    alert("Successfully saved!");
  };

  console.log({ stageDetails });

  return (
    <main>
      <MainSidebar
        pageTitle={`Products/${stageDetails ? stageDetails.title : "New"}`}
        shouldHideActionButton
        toolbar={
          <Button color="dark" size="lg" onClick={onSaveSalesStage}>
            Save
          </Button>
        }
      >
        <div className="text-cell px-8 py-5 max-w-[852px]">
          <div className="flex flex-col gap-2">
            <div className="font-medium">Stage name</div>
            <ColorInput
              onChange={(value, color) => {
                onStageDetailsMultipleChange(["title", "color"], [value, color]);
              }}
              value={stageDetails?.title}
              color={stageDetails?.color}
            />
          </div>
          <div className="mt-8">
            <MultiTextGroup
              buttonName="Add action"
              label={"Seller actions"}
              defaultValues={stageDetails?.sellerActions}
              onChange={(texts: string[]) =>
                onStageDetailsChange("sellerActions", texts)
              }
            />
          </div>
          <div className="mt-8">
            <MultiTextGroup
              buttonName="Add metric"
              label={"Metrics"}
              isDropdown
              defaultValues={stageDetails?.metrics?.map(metric => metric.id) || []}
              options={stageDetails?.metrics?.map(metric => ({
                label: metric.name,
                value: metric.id
              })) || []}
              onChange={(texts: string[]) =>
                onStageDetailsChange("metrics", texts.map(text => ({ id: text, name: text })))
              }
            />
          </div>
          <div className="mt-8">
            <MultiTextGroup
              buttonName="Add criteria"
              label={"Exit criteria"}
              defaultValues={stageDetails?.exitCriteria}
              onChange={(texts: string[]) =>
                onStageDetailsChange("exitCriteria", texts)
              }
            />
          </div>
          <div className="mt-20"></div>
        </div>
      </MainSidebar>
    </main>
  );
}
