import React from 'react';
import SvgIcon, { SvgIconProps } from '../SvgIcon';

export const BitbucketIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon
    {...props}
    style={{ ...props.style, fill: 'transparent' }}
    viewBox="0 0 20 18"
  >
    <path
      d="M1.11661 0.500051C1.02777 0.498905 0.939756 0.51722 0.858745 0.55371C0.777735 0.590201 0.705694 0.643982 0.64768 0.711278C0.589667 0.778573 0.547087 0.857752 0.522931 0.943255C0.498775 1.02876 0.493628 1.11851 0.507851 1.20622L3.09205 16.8941C3.12425 17.086 3.22306 17.2605 3.37113 17.3869C3.5192 17.5132 3.70706 17.5834 3.90171 17.585H16.2992C16.4451 17.5869 16.5868 17.5363 16.6986 17.4424C16.8103 17.3486 16.8846 17.2177 16.9079 17.0736L19.4921 1.20926C19.5064 1.12156 19.5012 1.0318 19.4771 0.946299C19.4529 0.860796 19.4103 0.781617 19.3523 0.714322C19.2943 0.647026 19.2223 0.593245 19.1413 0.556754C19.0602 0.520264 18.9722 0.501949 18.8834 0.503094L1.11661 0.500051ZM11.9983 11.8383H8.0413L6.96988 6.24069H12.9571L11.9983 11.8383Z"
      fill="currentColor"
    />
    <path
      d="M18.6704 6.24069H12.9572L11.9984 11.8383H8.0414L3.36914 17.3841C3.51723 17.5122 3.70604 17.5834 3.90181 17.585H16.3023C16.4482 17.5869 16.59 17.5363 16.7017 17.4424C16.8135 17.3486 16.8878 17.2177 16.9111 17.0736L18.6704 6.24069Z"
      fill="url(#paint_bitbucket)"
    />
    <defs>
      <linearGradient
        id="paint_bitbucket"
        x1="19.9914"
        y1="7.80826"
        x2="10.5495"
        y2="15.1773"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.18" stop-color="#7EB2FF" />
        <stop offset="1" stop-color="white" />
      </linearGradient>
    </defs>
  </SvgIcon>
);
