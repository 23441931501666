import { FC, PropsWithChildren } from "react";

interface IProgressBarProps {
  value: number;
  type?: 'circle' | 'linear'
};

export const ProgressBar: FC<PropsWithChildren<IProgressBarProps>> = ({ children, value, type = 'linear' }) => {
  const getColorSet = () => {
    if (value < 60) {
      return {
        label: '#6210EA',
        bg: '#7A3DFA26',
        line: '#7A3DFA'
      };
    } else {
      return {
        label: '#0545C1',
        bg: '#106EF626',
        line: '#106EF6'
      };
    }
  };
  const colorSet = getColorSet();

  if (type === 'circle') {
    return (
      <div className="relative size-14">
        <svg className="size-full -rotate-90" viewBox="0 0 36 36" xmlns="http://www.w3.org/2000/svg">
          <circle cx="18" cy="18" r="16" fill="none" className="stroke-current" style={{ color: colorSet.bg }} stroke-width="3"></circle>
          <circle cx="18" cy="18" r="16" fill="none" className="stroke-current" stroke-width="3" stroke-dasharray="100" stroke-dashoffset={100 - value} stroke-linecap="round" style={{ color: colorSet.line }}></circle>
        </svg>

        <div className="absolute top-1/2 start-1/2 transform -translate-y-1/2 -translate-x-1/2">
          <span className="text-center text-xl leading-[25px] font-medium" style={{ color: 'black' }}>{children}</span>
        </div>
      </div>
    )
  }

  return (
    <div className="flex gap-2 items-center">
      <div className={`text-sm leading-[17.5px] font-normal dark:text-white`} style={{ color: colorSet.label }}>{children}</div>
      <div className="w-full rounded-full h-1 dark:bg-gray-700 max-w-[100px]" style={{ background: colorSet.bg }}>
        <div className={`h-1 rounded-full`} style={{ width: `${value}%`, background: colorSet.line }}></div>
      </div>
    </div>
  )
};