import React from 'react';
import SvgIcon, { SvgIconProps } from '../SvgIcon';

export const TypeformIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props} style={{ fill: 'transparent' }} viewBox="8 8 24 24">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20 0H0V20V40H20H40V20V0H20Z"
      fill="#262627"
    />
    <path
      d="M23.5456 18.8258V16.7665H16.9254V18.8258H19.1752V24.8971H21.2958V18.8258H23.5456Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.0823 9.94562C24.2006 10.371 25.2197 11.0876 26.2511 12.1183C28.4763 14.3423 29.9615 17.2296 30.3623 20.3153C30.8032 23.7087 30.2066 26.3527 28.24 28.3047C26.351 30.1796 23.6332 30.7474 20.1785 30.4586L20.1764 30.4585L20.036 30.4461L20.034 30.4459C17.0334 30.1701 14.8797 29.1742 12.8148 27.0828C10.6289 24.8691 9.43907 22.3137 9.5024 19.597C9.53381 18.2484 9.91928 17.0941 10.6489 15.9773C11.1221 15.253 11.6021 14.6922 12.5605 13.7041L12.5616 13.703L12.8806 13.3763L13.2302 13.0219L13.2316 13.0205C15.5156 10.7247 17.1043 9.70065 19.4308 9.50911C20.7763 9.39833 21.9622 9.51952 23.0823 9.94562ZM22.6518 11.0838C21.732 10.7335 20.7335 10.6228 19.5307 10.7219C17.5898 10.8816 16.2405 11.7045 13.9902 13.9837L13.8259 14.1505C12.7174 15.2774 12.2245 15.8274 11.7935 16.4549L11.7292 16.5496L11.6676 16.6428L11.6669 16.6439C11.0536 17.583 10.7447 18.5185 10.7189 19.6254C10.6648 21.9475 11.6772 24.1988 13.6806 26.2278C15.5662 28.1375 17.4805 29.0119 20.2787 29.2459L20.2798 29.246L20.4209 29.2573L20.422 29.2574C23.6189 29.5031 25.8728 28.9398 27.3828 27.4411C28.9964 25.8395 29.5649 23.6227 29.1555 20.472C28.7914 17.6681 27.4372 15.0241 25.3909 12.979C24.4508 12.0394 23.5736 11.4348 22.6518 11.0838Z"
      fill="white"
    />
  </SvgIcon>
);
