import React from 'react';
import SvgIcon, { SvgIconProps } from '../SvgIcon';

export const UrlScanIoIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props} style={{ fill: 'transparent' }} viewBox="0 0 19 20">
    <path
      d="M17.972 16.6376C18.2824 16.9169 18.4686 17.3203 18.4686 17.7858C18.4686 18.6858 17.7238 19.4306 16.8238 19.4306C16.3583 19.4306 15.9548 19.2444 15.6755 18.9341L11.0205 14.31C9.96531 15.0238 8.66189 15.4583 7.26537 15.4583C3.54131 15.4583 0.5 12.417 0.5 8.69292C0.5 4.96886 3.54131 1.92755 7.26537 1.92755C10.9894 1.92755 14.0307 4.96886 14.0307 8.69292C14.0307 9.84117 13.7514 10.9274 13.2239 11.8584L17.972 16.6376Z"
      fill="#B74837"
    />
    <path
      d="M18.4999 16.3583C18.4999 17.2583 17.7551 18.0031 16.8551 18.0031C16.3896 18.0031 15.9862 17.8169 15.7069 17.5065L9.65527 11.486C10.6484 10.9274 11.4242 10.0584 11.8276 9.00327L18.0034 15.21C18.3137 15.4893 18.4999 15.8928 18.4999 16.3583Z"
      fill="#294658"
    />
    <path
      d="M7.29733 2.42409C9.96623 2.42409 12.1386 4.59645 12.1386 7.26536C12.1386 9.93427 9.96623 12.1066 7.29733 12.1066C4.62842 12.1066 2.45605 9.93427 2.45605 7.26536C2.45605 4.59645 4.62842 2.42409 7.29733 2.42409Z"
      fill="#26495D"
    />
    <path
      d="M7.2971 12.7894C4.75233 12.7894 2.7041 10.7412 2.7041 8.19638C2.7041 5.65161 4.75233 3.60338 7.2971 3.60338C9.84188 3.60338 11.8901 5.65161 11.8901 8.19638C11.8901 10.7412 9.84188 12.7894 7.2971 12.7894Z"
      fill="#3B637D"
    />
    <path
      d="M6.52043 4.34819C7.23421 4.34819 7.79282 4.9068 7.79282 5.62058C7.79282 6.33435 7.23421 6.89296 6.52043 6.89296C5.80666 6.89296 5.24805 6.33435 5.24805 5.62058C5.24805 4.9068 5.80666 4.34819 6.52043 4.34819Z"
      fill="#9DB2C2"
    />
    <path
      d="M7.29564 14.0307C3.57159 14.0307 0.530273 10.9894 0.530273 7.26537C0.530273 3.54131 3.57159 0.5 7.29564 0.5C11.0197 0.5 14.061 3.54131 14.061 7.26537C14.061 10.9894 11.0197 14.0307 7.29564 14.0307ZM7.29564 2.67237C4.75087 2.67237 2.70264 4.7206 2.70264 7.26537C2.70264 9.81014 4.75087 11.8584 7.29564 11.8584C9.84041 11.8584 11.8886 9.81014 11.8886 7.26537C11.8886 4.7206 9.84041 2.67237 7.29564 2.67237Z"
      fill="#E5E9EC"
    />
  </SvgIcon>
);
