import React from 'react';
import SvgIcon, { SvgIconProps } from '../SvgIcon';

export const ZendeskIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props} style={{ fill: 'transparent' }} viewBox="0 0 20 15">
    <path
      d="M9.27717 4.00227V14.6017H0.5L9.27717 4.00227ZM9.27717 0.200012C9.27717 2.62219 7.31522 4.58434 4.89328 4.58434C2.47134 4.58434 0.5 2.62219 0.5 0.200012H9.27717ZM10.7228 14.6017C10.7228 12.1795 12.6848 10.2173 15.1067 10.2173C17.5287 10.2173 19.4906 12.1795 19.4906 14.6017H10.7228ZM10.7228 10.7994V0.200012H19.5L10.7228 10.7994Z"
      fill="currentColor"
    />
  </SvgIcon>
);
