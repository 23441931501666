import React from 'react';
import SvgIcon, { SvgIconProps } from '../SvgIcon';

export const StripeIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props} style={{ fill: 'transparent' }} viewBox="0 0 14 18">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.56044 5.36044C5.56044 4.58901 6.26374 4.29231 7.42857 4.29231C9.0989 4.29231 11.2088 4.74725 12.8791 5.55824V0.90989C11.0549 0.257143 9.25275 0 7.42857 0C2.96703 0 0 2.0967 0 5.5978C0 11.0571 8.35165 10.1868 8.35165 12.5407C8.35165 13.4505 7.47253 13.7473 6.24176 13.7473C4.41758 13.7473 2.08791 13.0747 0.241758 12.1648V16.8725C2.28571 17.6637 4.35165 18 6.24176 18C10.8132 18 13.956 15.9626 13.956 12.422C13.9341 6.52747 5.56044 7.57582 5.56044 5.36044Z"
      fill="currentColor"
    />
  </SvgIcon>
);
