'use client'

import clsx from 'clsx'
import type React from 'react'
import { createContext, useContext, useEffect, useState } from 'react'
import { Link } from './link'
import { LinkedInIcon, SortDownIcon, SortUpIcon } from '../custom/icons'
import { Checkbox } from './checkbox'

const TableContext = createContext<{ bleed: boolean; dense: boolean; grid: boolean; striped: boolean; fixedHeader: boolean; fixedFirstColumn: boolean; fixedLastColumn: boolean; sortBy: string; sortDirection: string; setSortBy: Function; setSortDirection: Function; }>({
  bleed: false,
  dense: false,
  grid: false,
  striped: false,
  fixedHeader: false,
  fixedFirstColumn: false,
  fixedLastColumn: false,
  sortBy: '',
  sortDirection: '',
  setSortBy: () => {},
  setSortDirection: () => {}
})

export function Table({
  bleed = false,
  dense = false,
  grid = false,
  striped = false,
  fixedHeader = false,
  fixedFirstColumn = false,
  fixedLastColumn = false,
  className,
  children,
  ...props
}: { bleed?: boolean; dense?: boolean; grid?: boolean; striped?: boolean; fixedHeader?: boolean; fixedFirstColumn?: boolean; fixedLastColumn?: boolean; } & React.ComponentPropsWithoutRef<'div'>) {
  const [sortBy, setSortBy] = useState('');
  const [sortDirection, setSortDirection] = useState('');
  return (
    <TableContext.Provider value={{ bleed, dense, grid, striped, fixedFirstColumn, fixedHeader, fixedLastColumn, sortBy, sortDirection, setSortBy, setSortDirection  } as React.ContextType<typeof TableContext>}>
      <div className="flow-root">
        <div {...props} className={clsx(className, '-mx-[--gutter] overflow-x-auto whitespace-nowrap')}>
          <div className={clsx('inline-block w-full align-middle', !bleed && 'sm:px-[--gutter]')}>
            <table className="text-left w-full text-sm/6 text-zinc-950 dark:text-white border-separate">{children}</table>
          </div>
        </div>
      </div>
    </TableContext.Provider>
  )
}

export function TableHead({ className, ...props }: React.ComponentPropsWithoutRef<'thead'>) {
  return <thead {...props} className={clsx(className, 'text-zinc-500 dark:text-zinc-400')} />
}

export function TableBody(props: React.ComponentPropsWithoutRef<'tbody'>) {
  return <tbody {...props} />
}

const TableRowContext = createContext<{ href?: string; target?: string; title?: string }>({
  href: undefined,
  target: undefined,
  title: undefined,
})

export function TableRow({
  href,
  target,
  title,
  className,
  ...props
}: { href?: string; target?: string; title?: string } & React.ComponentPropsWithoutRef<'tr'>) {
  let { striped, fixedFirstColumn } = useContext(TableContext)

  return (
    <TableRowContext.Provider value={{ href, target, title } as React.ContextType<typeof TableRowContext>}>
      <tr
        {...props}
        className={clsx(
          className,
          href &&
            'has-[[data-row-link][data-focus]]:outline has-[[data-row-link][data-focus]]:outline-2 has-[[data-row-link][data-focus]]:-outline-offset-2 has-[[data-row-link][data-focus]]:outline-blue-500 dark:focus-within:bg-white/[2.5%]',
          striped && 'even:bg-zinc-950/[2.5%] dark:even:bg-white/[2.5%]',
          href && striped && 'hover:bg-zinc-950/5 dark:hover:bg-white/5',
          href && !striped && 'hover:bg-zinc-950/[2.5%] dark:hover:bg-white/[2.5%]',
          fixedFirstColumn && '[&>*:nth-child(2)]:border-l-0'
        )}
      />
    </TableRowContext.Provider>
  )
}

interface ITableHeaderProps extends React.ComponentPropsWithoutRef<'th'> {
  checkbox?: boolean;
  sort?: 'desc' | 'asc' | '';
  sortable?: boolean;
  icon?: 'linkedin',
  name?: string;
}

export function TableHeader({ className, checkbox, sort, icon, children, sortable, name, ...props }: ITableHeaderProps) {
  let { bleed, grid, fixedFirstColumn, dense, fixedHeader, fixedLastColumn, setSortDirection, setSortBy, sortBy } = useContext(TableContext)

  const [sortDir, setSortDir] = useState(sort);

  useEffect(() => {
    if (sortable) {
      if (sortDir) {
        setSortDirection(sortDir);
        setSortBy(name);
      }
    }
  }, [sortDir]);

  useEffect(() => {
    if (sortable) {
      if (sortBy && sortBy !== name) {
        setSortDir('');
      }
    }
  }, [sortBy]);

  const onClickHeader = () => {
    if (!sortable)
      return;
    let newSortDirection;
    if (sortDir === 'desc')
      newSortDirection = 'asc';
    else if (sortDir === 'asc')
      newSortDirection = '';
    else newSortDirection = 'desc';
    setSortDir(newSortDirection);
  }

  return (
    <th
      {...props}
      className={clsx(
        className,
        'border-y border-y-zinc-950/10 px-4 py-1 font-normal first:pl-[var(--gutter,theme(spacing.2))] last:pr-[var(--gutter,theme(spacing.2))] dark:border-y-white/10 h-9 text-sm leading-[17.5px] font-firstext',
        grid && 'border-l border-l-zinc-950/5 first:border-l-0 dark:border-l-white/5',
        !bleed && 'sm:first:pl-1 sm:last:pr-1',
        fixedFirstColumn && 'first:!sticky first:!left-0 first:!z-50 first:!border-r',
        fixedLastColumn && 'last:!sticky last:!right-0 last:!z-50',
        dense && '!py-1',
        fixedHeader && '!sticky !z-20 !top-0 !bg-white'
      )}
      onClick={onClickHeader}
    >
      <div className='flex justify-between items-center'>
        <div className='flex gap-4 items-center'>
          {checkbox && (
            <Checkbox />
          )}
          <div className='flex gap-1 items-center'>
            {icon === 'linkedin' && <img src={LinkedInIcon} />}
            {children}
          </div>
        </div>
        {sortDir === 'asc' && (
          <img src={SortUpIcon} />
        )}
        {sortDir === 'desc' && (
          <img src={SortDownIcon} />
        )}
      </div>
    </th>
  )
}

export function TableCell({ className, children, ...props }: React.ComponentPropsWithoutRef<'td'>) {
  let { bleed, dense, grid, striped, fixedFirstColumn, fixedLastColumn } = useContext(TableContext)
  let { href, target, title } = useContext(TableRowContext)
  let [cellRef, setCellRef] = useState<HTMLElement | null>(null)

  return (
    <td
      ref={href ? setCellRef : undefined}
      {...props}
      className={clsx(
        className,
        'relative px-4 first:pl-[var(--gutter,theme(spacing.2))] last:pr-[var(--gutter,theme(spacing.2))] h-11 text-sm font-normal leading-[17.5px] font-firstext',
        !striped && 'border-b border-zinc-950/5 dark:border-white/5',
        grid && 'border-l border-l-zinc-950/5 first:border-l-0 dark:border-l-white/5',
        dense ? 'py-1' : 'py-4',
        !bleed && 'sm:first:pl-1 sm:last:pr-1',
        fixedFirstColumn && 'first:!sticky first:!left-0 first:!z-10 !bg-white',
        fixedLastColumn && 'last:!sticky last:!right-0 last:!z-10 !bg-white',
      )}
    >
      {href && (
        <Link
          data-row-link
          href={href}
          target={target}
          aria-label={title}
          tabIndex={cellRef?.previousElementSibling === null ? 0 : -1}
          className="absolute inset-0 focus:outline-none"
        />
      )}
      {children}
    </td>
  )
}
