import { Routes, Route } from "react-router-dom";
import DashboardPage from "@/pages/DashboardPage";
import HomePage from "@/pages/HomePage";
import ContentPage from "@/pages/ContentPage";
import ContentTemplatePage from "@/pages/ContentTemplatePage";
import LogoutPage from "@/pages/LogoutPage";
import DemoCompaniesPage from "@/pages/demo/DemoCompaniesPage";
import DemoAudiencePage from "@/pages/demo/DemoAudiencePage";
import DemoSegmentsPage from "@/pages/demo/DemoSegmentsPage";
import DemoCompanyDetailTasksPage from "@/pages/demo/company/DemoCompanyDetailTasksPage";
import DemoCompanyDetailContactsPage from "@/pages/demo/company/DemoCompanyDetailContactsPage";
import DemoCompanyDetailDocumentsPage from "@/pages/demo/company/DemoCompanyDetailDocumentsPage";
import DemoComponentPage from "@/pages/demo/DemoComponentPage";
import DemoCompanyDetailInsightsPage from "@/pages/demo/company/DemoCompanyDetailInsightsPage";
import DemoCompanyDetailActivitiesPage from "@/pages/demo/company/DemoCompanyDetailActivitiesPage";
import DemoContactDetailDocumentsPage from "@/pages/demo/contact/DemoContactDetailDocumentsPage";
import DemoContactDetailTasksPage from "@/pages/demo/contact/DemoContactDetailTasksPage";
import DemoContactDetailInsightsPage from "@/pages/demo/contact/DemoContactDetailInsightsPaget";
import DemoContactDetailActivitiesPage from "@/pages/demo/contact/DemoContactDetailActivitiesPage";
import DemoConnectionsPage from "@/pages/demo/DemoConnectionsPage";
import DemoCompanyDetailAccountPlanPage from "@/pages/demo/company/DemoCompanyAccountPlanPage";
import DemoKnowledgebasePage from "@/pages/demo/DemoKnowledgebasePage";
import DemoBrandingPage from "@/pages/demo/DemoBrandingPage";
import DemoToneOfVoicePage from "@/pages/demo/DemoToneOfVoicePage";
import DemoProductsPage from "@/pages/demo/DemoProductsPage";
import DemoProductDetailPage from "@/pages/demo/DemoProductDetailPage";
import DemoPersonasPage from "@/pages/demo/DemoPersonasPage";
import DemoPersonaDetailpage from "@/pages/demo/DemoPersonaDetailPage";
import DemoCompetitorsPage from "@/pages/demo/DemoCompetitorsPage";
import DemoCompetitorDetailpage from "@/pages/demo/DemoCompetitorDetailPage";
import DemoUseCasesPage from "@/pages/demo/DemoUseCasesPage";
import DemoUseCaseDetailpage from "@/pages/demo/DemoUseCaseDetailPage";
import DemoProofPage from "@/pages/demo/DemoProofPage";
import DemoSalesStagesPage from "@/pages/demo/DemoSalesStagesPage";
import DemoSalesStageDetailPage from "@/pages/demo/DemoSalesStageDetailPage";

export default function Router() {
  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/dashboard" element={<DashboardPage />} />

      {/* <Route path='/customers' element={<CompaniesPage />} /> */}
      <Route path="/customers" element={<DemoCompaniesPage />} />
      {/* <Route path='/customers/companies' element={<CompaniesPage />} /> */}
      <Route path="/customers/companies" element={<DemoCompaniesPage />} />
      <Route
        path="/customers/companies/:companyId/account-plan"
        element={<DemoCompanyDetailAccountPlanPage />}
      />
      <Route
        path="/customers/companies/:companyId/activities"
        element={<DemoCompanyDetailActivitiesPage />}
      />
      <Route
        path="/customers/companies/:companyId/insights"
        element={<DemoCompanyDetailInsightsPage />}
      />
      <Route
        path="/customers/companies/:companyId/tasks-notes"
        element={<DemoCompanyDetailTasksPage />}
      />
      <Route
        path="/customers/companies/:companyId/contacts"
        element={<DemoCompanyDetailContactsPage />}
      />
      <Route
        path="/customers/companies/:companyId/documents"
        element={<DemoCompanyDetailDocumentsPage />}
      />
      {/* <Route path='/customers/contacts' element={<AudiencePage />} /> */}
      <Route path="/customers/contacts" element={<DemoAudiencePage />} />
      <Route
        path="/customers/contacts/:contactId/activities"
        element={<DemoContactDetailActivitiesPage />}
      />
      <Route
        path="/customers/contacts/:contactId/insights"
        element={<DemoContactDetailInsightsPage />}
      />
      <Route
        path="/customers/contacts/:contactId/tasks-notes"
        element={<DemoContactDetailTasksPage />}
      />
      <Route
        path="/customers/contacts/:contactId/documents"
        element={<DemoContactDetailDocumentsPage />}
      />
      {/* <Route path='/customers/segments' element={<SegmentsPage />} /> */}
      <Route path="/customers/segments" element={<DemoSegmentsPage />} />
      <Route path="/components" element={<DemoComponentPage />} />
      <Route path="/channels" element={<DemoConnectionsPage />} />
      <Route path="/channels/:category" element={<DemoConnectionsPage />} />
      <Route path="/knowledge-base" element={<DemoKnowledgebasePage />} />

      <Route path="/settings/branding" element={<DemoBrandingPage />} />
      <Route path="/settings/tone-of-voice" element={<DemoToneOfVoicePage />} />
      <Route path="/settings/products" element={<DemoProductsPage />} />
      <Route path="/settings/products/:productId" element={<DemoProductDetailPage />} />
      <Route path="/settings/personas" element={<DemoPersonasPage />} />
      <Route path="/settings/personas/:personaId" element={<DemoPersonaDetailpage />} />
      <Route path="/settings/competitors" element={<DemoCompetitorsPage />} />
      <Route path="/settings/competitors/:competitorId" element={<DemoCompetitorDetailpage />} />
      <Route path="/settings/use-cases" element={<DemoUseCasesPage />} />
      <Route path="/settings/use-cases/:usecaseId" element={<DemoUseCaseDetailpage />} />
      <Route path="/settings/proof" element={<DemoProofPage />} />
      <Route path="/settings/sales-stages" element={<DemoSalesStagesPage />} />
      <Route path="/settings/sales-stages/:stageId" element={<DemoSalesStageDetailPage />} />

      <Route path="/content" element={<ContentPage />} />
      <Route path="/content/:templateType" element={<ContentPage />} />
      <Route
        path="/content/:templateType/:templateId"
        element={<ContentTemplatePage />}
      />
      <Route path="/logout" element={<LogoutPage />} />
      <Route path="*" element={<DashboardPage />} />
      {/* <Route path="/not-found" element={<Error404Page />} />
      <Route path="*" element={<Navigate to='/not-found' replace />} /> */}
    </Routes>
  );
}
