import React from 'react';
import SvgIcon, { SvgIconProps } from '../SvgIcon';

export const MagentoIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props} style={{ fill: 'transparent' }} viewBox="0 0 18 21">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 5.20025L9.00908 0L18 5.19117V15.5871L15.4282 17.0694V6.68474L8.99092 2.96898L2.55359 6.68474L2.57175 17.0739L0 15.5871V5.20025ZM9.00032 17.8184L10.2851 17.0716V6.68475L12.8568 8.16924V18.5584L8.99805 20.7851L5.14383 18.5584L5.13929 8.16924L7.70877 6.68475V17.0784L9.00032 17.8184Z"
      fill="currentColor"
    />
  </SvgIcon>
);
