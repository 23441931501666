import React from 'react';
import SvgIcon, { SvgIconProps } from '../SvgIcon';

export const WiseIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props} style={{ fill: 'transparent' }} viewBox="0 0 21 20">
    <path
      d="M5.90697 6.22866L0.5 12.5471H10.1539L11.2392 9.56738H7.10274L9.63076 6.64459L9.6389 6.5666L7.99471 3.73803H15.3887L9.65677 19.5089H13.5788L20.5 0.5H2.6186L5.90534 6.22866H5.90697Z"
      fill="#163300"
    />
  </SvgIcon>
);
