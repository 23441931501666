import React from 'react';
import SvgIcon, { SvgIconProps } from '../SvgIcon';

export const CustomerIoIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon
    {...props}
    style={{ ...props.style, fill: 'transparent' }}
    viewBox="0 0 22 16"
  >
    <path
      d="M10.9856 8.8169C13.4222 8.8169 15.394 6.84507 15.394 4.40845C15.394 1.97183 13.4222 0 10.9856 0C8.54898 0 6.57715 1.97183 6.57715 4.40845C6.59123 6.84507 8.56306 8.8169 10.9856 8.8169Z"
      fill="#FFCD00"
    />
    <path
      d="M11.0141 11.0141H11C7.98592 11.0141 5.35211 8.97184 4.60563 6.04226C4.38028 5.16902 3.66197 4.42254 2.76056 4.42254H0C0 10.493 4.92958 15.4225 11 15.4225H11.0141V11.0141Z"
      fill="#00ECBB"
    />
    <path
      d="M10.9863 11.0141C14.0145 11.0141 16.6483 8.97184 17.3948 6.04226C17.6201 5.16902 18.3384 4.42254 19.2398 4.42254H22.0004C22.0004 10.493 17.0708 15.4225 11.0004 15.4225H10.9863V11.0141Z"
      fill="#AF64FF"
    />
    <path
      d="M18.789 12.1972C14.4932 16.493 7.52136 16.493 3.22559 12.1972L6.33826 9.08451C8.91573 11.662 13.0988 11.662 15.6763 9.08451L18.789 12.1972Z"
      fill="#7131FF"
    />
  </SvgIcon>
);
