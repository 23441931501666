import {
  ConversationView,
  Message,
  MessageDelta,
  WSConversationUpdate,
  WSMessage,
} from "@/types/conversation";
import { create } from "zustand";
import { apiClient } from "@/services/api";
import { MessageArray, processMessageDelta } from "@/services/conversation";
import { useContextStore } from "@/zustand/context";
import { v4 } from "uuid";
import { BloomStorage } from "@/utils/storage";

const agentId = "1617c932-8bb7-49fc-b726-622e1e129f13";

interface AccountPlanStore {
  conversation: ConversationView | null;
  messages: MessageArray;
  suggestions: string[];
  cursors: {
    messages: {
      fetching: boolean;
    };
    newMessage: {
      sending: boolean;
      receiving: boolean;
      lastRequestId: string;
    };
  };
  getOrCreateConversation: () => Promise<void>;
  requestAccountPlan: () => Promise<void>;
  fetchMessages: () => Promise<void>;
  processWSMessage(message: WSMessage): void;
  clearSuggestions: () => Promise<void>;
  deleteMessages: () => Promise<void>;
  sendSlackNotification: () => Promise<void>;
}

export const useAccountPlanStore = create<AccountPlanStore>((set, get) => ({
  conversation: null,
  messages: [],
  suggestions: [],
  cursors: {
    messages: {
      fetching: false,
    },
    newMessage: {
      sending: false,
      receiving: false,
      lastRequestId: "",
    },
  },
  clearSuggestions: async () => {
    set((state) => ({
      ...state,
      suggestions: []
    }));
  },
  getOrCreateConversation: async (): Promise<void> => {
    const orgId = useContextStore.getState().selectedOrgId || "x-functions";
    const { conversation } = get();
    if (conversation) {
      return;
    }

    set((state) => ({
      ...state,
      cursors: {
        ...state.cursors,
        messages: {
          ...state.cursors.messages,
          fetching: true,
        },
      },
    }));

    const { data } = await apiClient.get<{
      agentId: string;
      conversations: { items: ConversationView[] };
    }>(`/agents/orgs/${orgId}/agents/${agentId}/conversations`);
    if (data?.conversations?.items?.length) {
      return set((state) => ({
        ...state,
        conversation: data.conversations.items[0],
        cursors: {
          ...state.cursors,
          messages: {
            ...state.cursors.messages,
            fetching: false,
          },
        },
      }));
    }

    // Create a new conversation
    const { data: newConversation } = await apiClient.post<ConversationView>(
      `/agents/orgs/${orgId}/conversations`,
      {
        agentId,
        title: "Account Plan",
      }
    );
    return set((state) => ({
      ...state,
      conversation: newConversation,
      cursors: {
        ...state.cursors,
        messages: {
          ...state.cursors.messages,
          fetching: false,
        },
      },
    }));
  },
  requestAccountPlan: async () => {
    if (!(window as any).socket) return;

    const { conversation } = get();
    if (!conversation) {
      return;
    }

    const requestId = v4();
    const orgId = useContextStore.getState().selectedOrgId || "x-functions";
    const newMsg: Message = {
      orgId,
      id: v4(),
      type: "user",
      content: `Create an account plan with the following data:
Title: Launch Account Plan for <Economic Buyer's Name>;
Account Name: Zoom Video Communications, Inc.;
Rep Name: James Charlesworth;
Rep Manager: Andy Kellam;
Current Spend: $1,230,000;
Current Products: Bloom Knowledge Base, Bloom Agents for Every Use Case, Bloom Premium Service Pack, Bloom Data Science Pipelines, Bloom Target Account Mapping;
Renewal Date: September 25, 2025;
Total Potential Spend: $3,500,000;
Next Target Deal Size: $500,000;
Target Close Date: October 10, 2025;
Reason for Target Close Date: CEO mandate for key initiative (AI-driven expansion, revenue goals);
Current Stage in Sales Process: Proposal Scoping Stage;
Economic Buyer: Awinash Sinha;
Economic Buyer Title: CIO;
Technical Buyer: Shweta Kamble;
Technical Buyer Title: Senior Data Scientist;
User Contact: John Richards;
User Contact Title: Majors, Account Executive;
Coach: Bryan Baldi;
Coach Title: Head of Upmarket Sales, West;
Other Evaluators: Lisa Ayers;
Sales Goal: $500,000;
Conversion Rate: 15%;
Average Deal Size: $350,000;
Close Probability: 40%;
Forecasted Close Date: October 10, 2025;
Business Challenges: AI Integration costs, slower revenue growth in new product lines, geographic challenges in EMEA;
Opportunities for Bloom ARS: AI Automation, Contact Center Expansion, Upselling Advanced AI-driven Solutions;
Competitors: Microsoft, RingCentral, Cisco Webex;
Competitive Strengths: Microsoft: Strong enterprise presence, RingCentral: Aggressive SMB pricing, Cisco: Robust security;
Competitive Weaknesses: Microsoft: Expensive and complex, RingCentral: Lacks AI tools, Cisco: Limited scalability for mid-market;
Preferred Communication Tone: Formal and professional for C-level, casual for mid-level;
Pre-Approved Messaging & Templates: Personalized emails for each stage, AI-generated ROI reports;
Relevant Case Studies: AI-driven customer success stories in FinTech and SaaS;
Primary Objective: Close $500,000 in new sales with Zoom’s GTM team by Q4 2025;
Key Results: Secure AI expansion deal, Demonstrate 20% productivity gains with Bloom ARS;
Secondary Objective: Secure renewal for Bloom’s existing products and services;
Secondary Objective Key Results: Increase renewal value to $1.5 million, Expand Bloom AI agents across more business units;
Data Privacy Requirements: Full compliance with GDPR and global data privacy laws;
Contract Status: Scoping phase for new proposals, ongoing renewal discussions;
Risk Factors: Economic uncertainty in EMEA, competition from Microsoft;
Customer Challenges: Increasing AI integration costs, difficulty scaling Contact Center, macroeconomic pressures in Europe;
Customer Objectives: AI Integration with Zoom AI Companion, Product diversification, Upselling to enterprise customers;
Actions & Recommendations: Build demo instance, Present tailored proposals, Engage key decision-makers;`,
    } as Message;

    const messages = [...get().messages, newMsg];
    set((state) => ({
      messages: messages,
      cursors: {
        ...state.cursors,
        newMessage: {
          ...state.cursors.newMessage,
          sending: true,
          lastRequestId: requestId,
        },
      },
    }));

    try {
      console.log("Ready: ", (window as any).socket.readyState);
      (window as any).socket.json({
        action: "message",
        orgId,
        agentId,
        conversationId: conversation.conversationId,
        requestId,
        message: newMsg.content,
        token: BloomStorage.getAccessToken(),
      });

      set((state) => ({
        ...state,
        cursors: {
          ...state.cursors,
          newMessage: {
            ...state.cursors.newMessage,
            sending: false,
          },
        },
      }));
    } catch (err) {
      set((state) => ({
        ...state,
        cursors: {
          ...state.cursors,
          newMessage: {
            ...state.cursors.newMessage,
            sending: false,
          },
        },
      }));
      throw err;
    }
  },
  fetchMessages: async () => {
    const { conversation } = get();
    if (!conversation) {
      return;
    }

    const orgId = useContextStore.getState().selectedOrgId || "x-functions";
    const { data } = await apiClient.get<
      ConversationView & { messages: Message[] }
    >(`/agents/orgs/${orgId}/conversations/${conversation.conversationId}?includeSuggestedPrompts=true`);

    set((state) => ({
      ...state,
      messages: data.messages,
      conversation: data,
    }));
  },
  processWSMessage: (message: WSMessage) => {
    if (message.type !== "conversation") {
      return;
    }

    const { conversation } = get();
    const payload = message.payload as WSConversationUpdate;
    if (
      !conversation ||
      payload.conversationId !== conversation.conversationId
    ) {
      return;
    }

    if (payload.signal === "start") {
      return set((state) => ({
        ...state,
        cursors: {
          ...state.cursors,
          newMessage: {
            ...state.cursors.newMessage,
            receiving: true,
          },
        },
      }));
    }

    if (payload.signal === "end") {
      return set((state) => {
        const messages = state.messages;
        let newMessages = messages;
        const messageIndex = messages.findIndex(
          (m) => (m as { requestId?: string }).requestId === payload.requestId
        );
        if (messageIndex > -1) {
          newMessages = [
            ...messages.slice(0, messageIndex),
            {
              ...messages[messageIndex],
              receiving: false,
            } as unknown as Message,
            ...messages.slice(messageIndex + 1),
          ];
        }

        return {
          ...state,
          messages: newMessages,
          cursors: {
            ...state.cursors,
            newMessage: {
              ...state.cursors.newMessage,
              receiving: false,
            },
          },
        };
      });
    }

    if (!payload.delta) {
      return;
    }

    const messages = get().messages;
    let messageIndex = messages.findIndex(
      (m) => (m as { requestId?: string }).requestId === payload.requestId
    );
    let m: Message | (Partial<Message> & { requestId?: string });
    if (messageIndex < 0) {
      messageIndex = messages.length;
      m = { requestId: payload.requestId };
    } else {
      m = messages[messageIndex];
    }

    m.type ||= "assistant";
    let updated = m;
    if (payload.delta.type !== "tool") {
      updated = processMessageDelta(
        m as Message,
        payload.delta as MessageDelta
      );
    }

    set((state) => ({
      messages: [
        ...state.messages.slice(0, messageIndex),
        updated,
        ...state.messages.slice(messageIndex + 1),
      ],
    }));
  },
  deleteMessages: async () => {
    const { conversation, messages } = get();
    if (!conversation || !messages?.length) {
      return;
    }

    for (const message of messages) {
      const id = (message as { id: string }).id;
      if (!id) continue;

      await apiClient.delete(
        `/agents/orgs/${conversation.orgId}/conversations/${conversation.conversationId}/messages/${id}`
      );

      set((state) => ({
        messages: state.messages.filter((m) => (m as { id: string }).id !== id),
      }));
    }
  },
  sendSlackNotification: async () => {
    const { conversation } = get();
    if (!conversation) {
      return;
    }

    await apiClient.post(
      `/agents/orgs/${conversation.orgId}/slack-notification`,
      {
        channel: "CQZ2DS33M",
        message: `*🔔 Account Update: Zoom Video Communications, Inc.*  
*Account Owner:* James Charlesworth  
*Status:* Active - In Proposal Scoping Stage  
*🎯 Next Target Deal Size:* $500,000  
*📈 Close Probability:* 40%  
*📅 Forecasted Close Date:* October 10, 2025  

*Recent Activity:* Follow-up email sent to key contacts on AI Automation & Efficiency, Contact Center Expansion, and AI Workflow Integration.  

*Key Points Discussed:*  
• AI Automation & Efficiency  
• Contact Center Expansion  
• AI Workflow Integration  

*Next Steps Proposed:*  
• Tailored Product Demo (Pending confirmation)  
• Draft Proposal (Post-demo)  
• Key Executive Session (Align leadership on AI roadmap)  

*💰 Account Metrics:*  
• Current Spend: $1,230,000  
• Total Potential Spend: $3,500,000  
• Renewal Date: September 25, 2025  

*Current Products:*  
• Bloom Knowledge Base  
• Bloom Agents for Every Use Case  
• Bloom Premium Service Pack  
• Bloom Data Science Pipelines  
• Bloom Target Account Mapping  

*Key Contacts Engaged:*  
• Awinash Sinha (CIO)  
• Shweta Kamble (Senior Data Scientist)  
• Bryan Baldi (Head of Upmarket Sales)  

*⚠️ Risks Identified:*  
• Competitive Landscape (Microsoft, RingCentral)  
• Macro Factors (Economic uncertainty in EMEA)  

*🔧 Action Items for the Team:*  
• Confirm Demo Time  
• Prepare tailored proposal post-demo  
• Monitor engagement post-demo`,
      }
    );
  },
}));
