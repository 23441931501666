import React from 'react';
import SvgIcon, { SvgIconProps } from '../SvgIcon';

export const SnovioIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props} style={{ fill: 'transparent' }} viewBox="6 6 28 28">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20 0H0V20V40H20H40V20V0H20Z"
      fill="white"
    />
    <path
      d="M25.3252 30.18C26.8475 28.9658 27.6087 27.3047 27.6087 25.1974C27.6087 23.675 27.2038 22.3919 26.3946 21.3495C25.5854 20.2959 24.0893 19.2467 21.907 18.2036C20.2571 17.4161 19.2087 16.8732 18.7618 16.5747C18.3253 16.2665 18.0058 15.9523 17.8034 15.6328C17.6009 15.3028 17.5004 14.9196 17.5004 14.4831C17.5004 13.7797 17.7501 13.2105 18.2503 12.7748C18.7513 12.3376 19.469 12.1201 20.4064 12.1201C21.2142 12.1225 22.0186 12.2242 22.8017 12.4231C23.6213 12.6256 24.654 12.9818 25.8997 13.4932L27.4955 9.6446C26.2933 9.1234 25.1384 8.71843 24.0308 8.43121C22.9344 8.14399 21.7795 8 20.5661 8C18.0966 8 16.1588 8.59095 14.7549 9.77208C13.36 10.954 12.6626 12.5776 12.6626 14.6429C12.6626 15.7393 12.8756 16.6969 13.3015 17.5166C13.7282 18.3377 14.3081 19.0696 15.0099 19.6727C15.7231 20.2899 16.7932 20.9341 18.2196 21.6045C19.7419 22.3289 20.7476 22.8554 21.2373 23.1854C21.7375 23.5153 22.1162 23.8618 22.3712 24.2233C22.6262 24.5749 22.7536 24.9799 22.7536 25.4374C22.7536 26.257 22.4612 26.8743 21.8763 27.2897C21.301 27.7045 20.4709 27.9122 19.385 27.9122C18.4806 27.9122 17.4794 27.7681 16.383 27.481C15.2971 27.1937 13.966 26.6988 12.3911 25.9961V30.5947C14.3072 31.5314 16.5112 32 19.0018 32C21.706 32 23.8133 31.3934 25.3252 30.18Z"
      fill="#7D65E9"
    />
  </SvgIcon>
);
