import React from 'react';
import SvgIcon, { SvgIconProps } from '../SvgIcon';

export const CrateDbIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon
    {...props}
    style={{ ...props.style, fill: 'transparent' }}
    viewBox="0 0 16 16"
  >
    <path d="M0 0H3.9646V3.97293H0V0Z" fill="currentColor" />
    <path d="M0 5.99688H9.98647V9.96981H0V5.99688Z" fill="currentColor" />
    <path d="M5.98828 0H9.96121V3.97293H5.98828V0Z" fill="currentColor" />
    <path d="M0 11.9938H16V15.9584H0V11.9938Z" fill="currentColor" />
    <path d="M11.9854 0H15.95V9.99479H11.9854V0Z" fill="currentColor" />
  </SvgIcon>
);
