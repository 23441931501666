import React from 'react';
import SvgIcon, { SvgIconProps } from '../SvgIcon';

export const QuickbooksIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props} style={{ fill: 'transparent' }} viewBox="0 0 22 20">
    <path
      d="M0 9.98462C0 13.2677 2.64 15.9077 5.92308 15.9077H6.76923V13.7077H5.92308C3.85846 13.7077 2.2 12.0492 2.2 9.98462C2.2 7.92 3.85846 6.26154 5.92308 6.26154H7.95385V17.7692C7.95385 18.9877 8.93538 19.9692 10.1538 19.9692V4.06154H5.92308C2.64 4.06154 0 6.70154 0 9.98462ZM16.0769 4.06154H15.2308V6.26154H16.0769C18.1415 6.26154 19.8 7.92 19.8 9.98462C19.8 12.0492 18.1415 13.7077 16.0769 13.7077H14.0462V2.2C14.0462 0.981539 13.0646 0 11.8462 0V15.9077H16.0769C19.36 15.9077 22 13.2677 22 9.98462C22 6.70154 19.36 4.06154 16.0769 4.06154Z"
      fill="currentColor"
    />
  </SvgIcon>
);
