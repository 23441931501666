import React from 'react';
import SvgIcon, { SvgIconProps } from '../SvgIcon';

export const TrelloIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props} style={{ fill: 'transparent' }} viewBox="0 0 18 18">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.8632 0H2.13973C0.959132 0 0.00162586 0.956215 1.72492e-05 2.13681V15.8401C-0.00226571 16.409 0.222149 16.9555 0.623669 17.3586C1.02519 17.7618 1.57074 17.9884 2.13973 17.9884H15.8632C16.4316 17.9876 16.9765 17.7607 17.3773 17.3576C17.7782 16.9546 18.0023 16.4085 18 15.8401V2.13681C17.9984 0.95734 17.0426 0.00158851 15.8632 0ZM7.7629 12.9564C7.76214 13.1465 7.68566 13.3286 7.55038 13.4622C7.4151 13.5959 7.23215 13.6702 7.04198 13.6687H4.04295C3.65071 13.6671 3.33357 13.3486 3.33357 12.9564V4.03138C3.33357 3.63913 3.65071 3.3207 4.04295 3.31912H7.04198C7.4347 3.3207 7.75267 3.63867 7.75425 4.03138L7.7629 12.9564ZM14.6837 8.85866C14.6837 9.04908 14.6075 9.23157 14.4721 9.3654C14.3366 9.49923 14.1532 9.57325 13.9628 9.57099H10.9638C10.5711 9.56935 10.2531 9.25138 10.2515 8.85866V4.03138C10.2531 3.63867 10.5711 3.3207 10.9638 3.31912H13.9628C14.3551 3.3207 14.6722 3.63913 14.6722 4.03138L14.6837 8.85866Z"
      fill="currentColor"
    />
  </SvgIcon>
);
