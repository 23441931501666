import MainSidebar from "@/components/custom/sidebar";
import { Button } from "@/components/custom/button";
import { Textarea } from "@/components/cataylst/textarea";
import { useState } from "react";
import { apiClient } from "@/services/api";
import { Environment } from "@/utils/constants";
import axios from "axios";

interface IBrand {
  name: string;
  missionStatement: string;
  vision: string;
};

const mockBrands: IBrand[] = [
  {
    name: 'Bloom ARS',
    missionStatement: 'To create autonomous revenue systems that empower businesses to achieve growth, create jobs, and drive economic prosperity through AI and automation.',
    vision: 'We envision a world where AI-driven automation revolutionizes revenue production and customer experiences, enabling businesses to connect and scale efficiently.'
  }
];

export default function DemoBrandingPage() {
  const [brandDetails, setBrandDetails] = useState<IBrand>(mockBrands[0]);

  const onTextChange = (fieldName: string, text: string) => {
    const newBrandDetails = { ...brandDetails };
    newBrandDetails[fieldName] = text;
    setBrandDetails(newBrandDetails);
  };

  const onAddKnowledgebaseFile = async () => {
    const { data: newKnowledgebaseFile } = await apiClient.post(`/agents/orgs/x-functions/knowledgebases/${Environment.KNOWLEDGE_BASE_ID}/files/upload-url`, {
      fileName: `New-Brand-Knowledgebase-File-${new Date().getTime()}.txt`,
      contentType: 'text'
    });
    const filePresignedUrl = newKnowledgebaseFile.uploadUrl;
    await axios.put(filePresignedUrl, JSON.stringify({...brandDetails, icon: undefined}), {
      headers: {
        'Content-Type': 'text/plain'
      }
    });
  }

  return (
    <main>
      <MainSidebar
        pageTitle="Branding"
        shouldHideActionButton
        toolbar={
          <Button color="dark" size="lg" onClick={onAddKnowledgebaseFile}>
            Save
          </Button>
        }
      >
        <div className="text-cell px-8 py-5 max-w-[772px]">
          <div className="flex flex-col gap-2">
            <div className="font-medium">Brand name</div>
            <Textarea
              resizable={false}
              rows={1}
              withItems={null}
              type="text"
              value={brandDetails.name}
              onChange={(e) => onTextChange('name', e.target.value)}
            />
          </div>
          <div className="flex flex-col gap-2 mt-8">
            <div className="font-medium">Brand mission statement</div>
            <Textarea
              resizable={false}
              withItems={null}
              type="text"
              value={brandDetails.missionStatement}
              onChange={(e) => onTextChange('missionStatement', e.target.value)}
            />
          </div>
          <div className="flex flex-col gap-2 mt-8">
            <div className="font-medium">Brand vision</div>
            <Textarea
              resizable={false}
              withItems={null}
              type="text"
              value={brandDetails.vision}
              onChange={(e) => onTextChange('vision', e.target.value)}
            />
          </div>
          <div className="flex flex-col gap-2 mt-8">
            <div className="font-medium">Brand logo</div>
            <div className="w-full py-4 pl-4 pr-6 rounded-lg h-[64px] bg-[#F7F7F7] text-sm">
              <div className="flex justify-between items-center">
                <div className="flex items-center gap-3">
                  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16Z" fill="black"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M11.4167 12.6667C11.4167 11.9763 11.9763 11.4167 12.6667 11.4167H19.3334C20.0237 11.4167 20.5834 11.9763 20.5834 12.6667V16.1894L20.5303 16.1363L20.52 16.1262C20.1062 15.7281 19.583 15.4701 19 15.4701C18.417 15.4701 17.8937 15.7281 17.4799 16.1262L17.4696 16.1363L17.3333 16.2727L15.8637 14.803L15.8534 14.7929C15.4396 14.3947 14.9163 14.1367 14.3334 14.1367C13.7504 14.1367 13.2271 14.3947 12.8133 14.7929L12.803 14.803L11.4167 16.1893V12.6667ZM16.8083 17.869L18.1364 19.197C18.4293 19.4899 18.9041 19.4899 19.197 19.197C19.4899 18.9041 19.4899 18.4292 19.197 18.1363L18.394 17.3333L18.5247 17.2027C18.717 17.0193 18.8818 16.9701 19 16.9701C19.1182 16.9701 19.283 17.0193 19.4753 17.2027L20.5834 18.3107V19.3333C20.5834 20.0237 20.0237 20.5833 19.3334 20.5833H12.6667C11.9763 20.5833 11.4167 20.0237 11.4167 19.3333V18.3107L13.858 15.8693C14.0503 15.686 14.2151 15.6367 14.3334 15.6367C14.4516 15.6367 14.6164 15.686 14.8087 15.8693L16.7977 17.8583C16.7995 17.8601 16.8012 17.8619 16.803 17.8637C16.8048 17.8654 16.8065 17.8672 16.8083 17.869ZM9.91669 19.3333V18V12.6667C9.91669 11.1479 11.1479 9.91666 12.6667 9.91666H19.3334C20.8521 9.91666 22.0834 11.1479 22.0834 12.6667V19.3333C22.0834 20.8521 20.8521 22.0833 19.3334 22.0833H12.6667C11.1479 22.0833 9.91669 20.8521 9.91669 19.3333ZM18 12.3333C17.4477 12.3333 17 12.7811 17 13.3333C17 13.8856 17.4477 14.3333 18 14.3333H18.01C18.5623 14.3333 19.01 13.8856 19.01 13.3333C19.01 12.7811 18.5623 12.3333 18.01 12.3333H18Z" fill="white"/>
                  </svg>
                  <div className="flex flex-col gap-0.5">
                    <div>Logo color horizontal</div>
                    <div className="opacity-60 text-xs">
                      SVG - 800KB
                    </div>
                  </div>
                </div>
                <div>
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M3.33335 6.58331C2.55095 6.58331 1.91669 7.21758 1.91669 7.99998C1.91669 8.78238 2.55095 9.41665 3.33335 9.41665C4.11576 9.41665 4.75002 8.78238 4.75002 7.99998C4.75002 7.21758 4.11576 6.58331 3.33335 6.58331ZM7.99998 6.58331C7.21758 6.58331 6.58331 7.21758 6.58331 7.99998C6.58331 8.78238 7.21758 9.41665 7.99998 9.41665C8.78238 9.41665 9.41665 8.78238 9.41665 7.99998C9.41665 7.21758 8.78238 6.58331 7.99998 6.58331ZM12.6667 6.58331C11.8843 6.58331 11.25 7.21758 11.25 7.99998C11.25 8.78238 11.8843 9.41665 12.6667 9.41665C13.4491 9.41665 14.0833 8.78238 14.0833 7.99998C14.0833 7.21758 13.4491 6.58331 12.6667 6.58331Z" fill="black" fill-opacity="0.4"/>
                  </svg>
                </div>
              </div>
            </div>
            <div className="w-full border border-[#106EF6] border-dashed rounded-lg h-[64px] bg-[#F8FAFE] flex items-center justify-center text-sm cursor-pointer">
              Drag here to upload logo, or&nbsp;<span className="underline">browse files</span>
            </div>
          </div>
          <div className="flex flex-col gap-2 mt-8">
            <div className="font-medium">Brand guidelines documents</div>
            <div className="w-full py-4 pl-4 pr-6 rounded-lg h-[64px] bg-[#F7F7F7] text-sm">
              <div className="flex justify-between items-center">
                <div className="flex items-center gap-3">
                  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16Z" fill="black"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M11.4167 12.6667C11.4167 11.9763 11.9763 11.4167 12.6667 11.4167H19.3334C20.0237 11.4167 20.5834 11.9763 20.5834 12.6667V16.1894L20.5303 16.1363L20.52 16.1262C20.1062 15.7281 19.583 15.4701 19 15.4701C18.417 15.4701 17.8937 15.7281 17.4799 16.1262L17.4696 16.1363L17.3333 16.2727L15.8637 14.803L15.8534 14.7929C15.4396 14.3947 14.9163 14.1367 14.3334 14.1367C13.7504 14.1367 13.2271 14.3947 12.8133 14.7929L12.803 14.803L11.4167 16.1893V12.6667ZM16.8083 17.869L18.1364 19.197C18.4293 19.4899 18.9041 19.4899 19.197 19.197C19.4899 18.9041 19.4899 18.4292 19.197 18.1363L18.394 17.3333L18.5247 17.2027C18.717 17.0193 18.8818 16.9701 19 16.9701C19.1182 16.9701 19.283 17.0193 19.4753 17.2027L20.5834 18.3107V19.3333C20.5834 20.0237 20.0237 20.5833 19.3334 20.5833H12.6667C11.9763 20.5833 11.4167 20.0237 11.4167 19.3333V18.3107L13.858 15.8693C14.0503 15.686 14.2151 15.6367 14.3334 15.6367C14.4516 15.6367 14.6164 15.686 14.8087 15.8693L16.7977 17.8583C16.7995 17.8601 16.8012 17.8619 16.803 17.8637C16.8048 17.8654 16.8065 17.8672 16.8083 17.869ZM9.91669 19.3333V18V12.6667C9.91669 11.1479 11.1479 9.91666 12.6667 9.91666H19.3334C20.8521 9.91666 22.0834 11.1479 22.0834 12.6667V19.3333C22.0834 20.8521 20.8521 22.0833 19.3334 22.0833H12.6667C11.1479 22.0833 9.91669 20.8521 9.91669 19.3333ZM18 12.3333C17.4477 12.3333 17 12.7811 17 13.3333C17 13.8856 17.4477 14.3333 18 14.3333H18.01C18.5623 14.3333 19.01 13.8856 19.01 13.3333C19.01 12.7811 18.5623 12.3333 18.01 12.3333H18Z" fill="white"/>
                  </svg>
                  <div className="flex flex-col gap-0.5">
                    <div>Brand Guidelines</div>
                    <div className="opacity-60 text-xs">
                      PDF - 17MB
                    </div>
                  </div>
                </div>
                <div>
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M3.33335 6.58331C2.55095 6.58331 1.91669 7.21758 1.91669 7.99998C1.91669 8.78238 2.55095 9.41665 3.33335 9.41665C4.11576 9.41665 4.75002 8.78238 4.75002 7.99998C4.75002 7.21758 4.11576 6.58331 3.33335 6.58331ZM7.99998 6.58331C7.21758 6.58331 6.58331 7.21758 6.58331 7.99998C6.58331 8.78238 7.21758 9.41665 7.99998 9.41665C8.78238 9.41665 9.41665 8.78238 9.41665 7.99998C9.41665 7.21758 8.78238 6.58331 7.99998 6.58331ZM12.6667 6.58331C11.8843 6.58331 11.25 7.21758 11.25 7.99998C11.25 8.78238 11.8843 9.41665 12.6667 9.41665C13.4491 9.41665 14.0833 8.78238 14.0833 7.99998C14.0833 7.21758 13.4491 6.58331 12.6667 6.58331Z" fill="black" fill-opacity="0.4"/>
                  </svg>
                </div>
              </div>
            </div>
            <div className="w-full border border-[#106EF6] border-dashed rounded-lg h-[64px] bg-[#F8FAFE] flex items-center justify-center text-sm cursor-pointer">
              Drag here to upload logo, or&nbsp;<span className="underline">browse files</span>
            </div>
          </div>
        </div>
      </MainSidebar>
    </main>
  )
}
