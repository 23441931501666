import React from 'react';
import SvgIcon, { SvgIconProps } from '../SvgIcon';

export const PushbulletIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props} style={{ fill: 'transparent' }} viewBox="0 0 30 29">
    <path
      d="M0 13.7726L3.82447 0.469376L11.2882 7.52198L15.8323 2.56661L30.65 16.3016L17.83 30.7461L0 13.7726Z"
      fill="url(#paint0_pushbullet)"
    />
    <path
      d="M1.93531 14.4958C2.20601e-07 14.4958 0 14.4958 0 12.5605V1.93531C0 2.20601e-07 2.20601e-07 0 1.93531 0H2.32091C4.25622 0 4.25622 2.20601e-07 4.25622 1.93531V12.5605C4.25622 14.4958 4.25622 14.4958 2.32091 14.4958H1.93531Z"
      fill="white"
    />
    <path
      d="M7.02894 14.4958C6.36981 14.4958 6.04492 14.1709 6.04492 13.5118V0.983905C6.04492 0.324884 6.36981 0 7.02894 0H11.3704C15.0921 0 17.4573 3.73264 17.4573 7.28936C17.4573 10.8461 15.0715 14.4958 11.3704 14.4958H7.02894Z"
      fill="white"
    />
    <defs>
      <linearGradient
        id="paint0_pushbullet"
        x1="222.964"
        y1="52.3057"
        x2="2051.08"
        y2="1920.96"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#4CB36B" />
        <stop offset="1" stop-color="#3EA16F" />
      </linearGradient>
    </defs>
  </SvgIcon>
);
