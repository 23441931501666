import React from 'react';
import SvgIcon, { SvgIconProps } from '../SvgIcon';

export const RedisIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props} style={{ fill: 'transparent' }} viewBox="0 0 22 19">
    <mask
      id="mask0_redis"
      style={{ maskType: 'luminance' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="22"
      height="19"
    >
      <path d="M22 0H0V18.8537H22V0Z" fill="white" />
    </mask>
    <g mask="url(#mask0_redis)">
      <path
        d="M21.1336 14.5354C19.9616 15.1485 13.8794 17.6502 12.5692 18.3368C11.259 19.0235 10.5573 19.004 9.53256 18.5141C8.50783 18.0243 2.05749 15.4009 0.888519 14.842C0.299529 14.5655 -0.000976562 14.3296 -0.000976562 14.1087V11.8835C-0.000976562 11.8835 8.42519 10.0549 9.78498 9.56209C11.1448 9.06926 11.6151 9.05724 12.7705 9.48095C13.9259 9.90466 20.8526 11.1548 21.996 11.5695V13.7632C21.996 13.9915 21.7331 14.2139 21.1336 14.5339V14.5354Z"
        fill="#A32422"
      />
      <path
        d="M21.1341 12.3207C19.9621 12.9307 13.8799 15.4325 12.5697 16.1071C11.2595 16.7817 10.5578 16.7757 9.53308 16.2874C8.50836 15.7991 2.05801 13.1757 0.889043 12.6212C-0.279923 12.0668 -0.302461 11.6791 0.843967 11.2314C1.9904 10.7836 8.42572 8.2639 9.78551 7.76807C11.1453 7.27223 11.6156 7.26171 12.771 7.68693C13.9265 8.11214 19.9832 10.5177 21.1206 10.9369C22.258 11.3561 22.3106 11.7002 21.1341 12.3087V12.3207Z"
        fill="#DC382C"
      />
      <path
        d="M21.1336 10.9114C19.9616 11.5229 13.8794 14.0231 12.5692 14.6977C11.259 15.3724 10.5633 15.3663 9.53857 14.875C8.51384 14.3837 2.05749 11.7753 0.888519 11.2179C0.299529 10.9384 -0.000976562 10.704 -0.000976562 10.4816V8.2639C-0.000976562 8.2639 8.42519 6.42931 9.78498 5.93648C11.1448 5.44365 11.6151 5.43013 12.7705 5.85985C13.9259 6.28958 20.8526 7.52766 21.996 7.94536V10.1391C21.996 10.3674 21.7331 10.5958 21.1336 10.9114Z"
        fill="#A32422"
      />
      <path
        d="M21.1345 8.68612C19.9625 9.29915 13.8803 11.7994 12.5701 12.474C11.2599 13.1486 10.5582 13.1441 9.53349 12.6543C8.50876 12.1645 2.05841 9.55157 0.889448 8.99263C-0.279518 8.43369 -0.300554 8.05355 0.844372 7.6058C1.9893 7.15805 8.42612 4.6323 9.78591 4.14999C11.1457 3.66768 11.616 3.63763 12.7714 4.05683C13.9269 4.47604 19.9836 6.90262 21.121 7.3098C22.2584 7.71699 22.311 8.07459 21.1345 8.68161V8.68612Z"
        fill="#DC382C"
      />
      <path
        d="M21.1336 7.15053C19.9616 7.76357 13.8794 10.2653 12.5692 10.9384C11.259 11.6115 10.5633 11.607 9.53857 11.1187C8.51384 10.6304 2.05749 8.01449 0.888519 7.45705C0.299529 7.18058 -0.000976562 6.94469 -0.000976562 6.72231V4.49707C-0.000976562 4.49707 8.42519 2.6685 9.78498 2.17717C11.1448 1.68584 11.6151 1.67082 12.7705 2.09453C13.9259 2.51824 20.8496 3.76835 21.993 4.18605V6.37974C21.996 6.61113 21.7331 6.83651 21.1336 7.15053Z"
        fill="#A32422"
      />
      <path
        d="M21.1341 4.9284C19.9621 5.52941 13.8799 8.04614 12.5697 8.71477C11.2595 9.38339 10.5578 9.38189 9.53308 8.89507C8.50836 8.40825 2.05801 5.78935 0.889043 5.23341C-0.279923 4.67748 -0.302461 4.29133 0.843967 3.84357C1.9904 3.39582 8.42572 0.871575 9.78551 0.381752C11.1453 -0.108072 11.6156 -0.123097 12.771 0.300615C13.9265 0.724328 19.9832 3.13288 21.1206 3.55058C22.258 3.96828 22.3106 4.31537 21.1341 4.92389V4.9284Z"
        fill="#DC382C"
      />
    </g>
    <path
      d="M11.3437 1.76848L12.7952 1.29218L12.4015 2.23276L13.8785 2.78569L11.9748 2.98403L11.5466 4.01176L10.8584 2.86533L8.65723 2.66699L10.301 2.075L9.80666 1.16447L11.3437 1.76848Z"
      fill="white"
    />
    <path
      d="M11.4359 7.76806L7.87793 6.29108L12.9745 5.50826L11.4359 7.76806Z"
      fill="white"
    />
    <path
      d="M6.50293 3.35664C8.00545 3.35664 9.22701 3.82994 9.22701 4.40841C9.22701 4.98689 8.00245 5.46018 6.50293 5.46018C5.00341 5.46018 3.77734 4.98689 3.77734 4.40841C3.77734 3.82994 4.9989 3.35664 6.50293 3.35664Z"
      fill="white"
    />
    <path
      d="M16.1338 3.06667L19.1494 4.25817L16.1368 5.44967L16.1338 3.06667Z"
      fill="#741113"
    />
    <path
      d="M16.1345 3.06667L16.1375 5.44967L15.81 5.57739L12.7959 4.38588L16.1345 3.06667Z"
      fill="#AC2724"
    />
  </SvgIcon>
);
