import { FC, HTMLAttributes, PropsWithChildren } from "react"

interface IMenuIconProps extends HTMLAttributes<HTMLElement> {
  src: string;
  opacity?: number;
  disabled?: boolean;
};

export const MenuIcon: FC<PropsWithChildren<IMenuIconProps>> = ({ children, className, disabled, src, opacity, ...props })  => {
  return (
    <div className={`${className} size-6 flex items-center justify-center ${disabled ? 'pointer-events-none grayscale opacity-30' : ''}`} {...props}>
      <img src={src} style={{ filter: `opacity(${opacity})` }} />
    </div>
  )
}