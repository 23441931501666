import { create } from "zustand";
import { v4 } from "uuid";
import {
  AssistantMessage,
  ConversationView,
  Message,
  MessageDelta,
  ToolCall,
  WSConversationUpdate,
  WSMessage,
} from "@/types/conversation";
import Sockette from "sockette";
import { useContextStore } from "@/zustand/context";
import { apiClient } from "./api";
import { BloomStorage } from "@/utils/storage";
import { Environment } from "@/utils/constants";

export type MessageArray = (
  | (Message & { id: string })
  | (Partial<Message> & { requestId?: string })
)[];

export const processMessageDelta = (
  currentMessage: Omit<Message, "type">,
  delta: MessageDelta
) => {
  const newMessage = structuredClone(currentMessage);
  if (delta?.content) {
    newMessage.content ??= "";
    newMessage.content += delta.content ?? "";
  }
  if (delta?.tool_calls) {
    const tm = newMessage as MessageDelta & AssistantMessage;
    if (!tm.tool_calls) tm.tool_calls = [];
    if (tm.tool_calls.length < delta.tool_calls.length) {
      tm.tool_calls.push({
        index: 0,
        id: "",
        function: {
          name: "",
          arguments: "",
        },
        type: "function",
      });
    }

    for (const tool of delta.tool_calls) {
      const idx = tool.index;
      tm.tool_calls[idx] ??= {
        index: idx,
        id: "",
        function: { name: "", arguments: "" },
        type: "function",
      };
      tm.tool_calls[idx].function.arguments += tool.function?.arguments || "";
      if (tm.tool_calls[idx].function.name !== tool.function?.name) {
        tm.tool_calls[idx].function.name += tool.function?.name || "";
      }
      if (tm.tool_calls[idx].id !== tool.id) {
        tm.tool_calls[idx].id += tool.id || "";
      }
    }
  }

  return newMessage;
};

interface ConversationStore {
  agentId: string;
  conversation: ConversationView | null;
  messages: MessageArray;
  suggestions: string[];
  cursors: {
    messages: {
      fetching: boolean;
      cursor: number;
      totalRows: number;
    };
    newMessage: {
      sending: boolean;
      receiving: boolean;
      lastRequestId: string;
    };
  };
  setAgentId: (agentId: string) => void;
  sendMessage: (message: string, socket: Sockette) => Promise<void>;
  fetchMessages: (conversationId: string) => Promise<void>;
  clearSuggestions: () => Promise<void>;
  processWSMessage: (message: WSMessage) => void;
  updateTitle: (title: string) => Promise<void>;
}

export const useConversationStore = create<ConversationStore>((set, get) => ({
  agentId: Environment.AGENT_ID,
  conversation: null,
  messages: [],
  suggestions: [],
  cursors: {
    messages: {
      fetching: false,
      cursor: 0,
      totalRows: 0,
    },
    newMessage: {
      sending: false,
      receiving: false,
      lastRequestId: "",
    },
  },
  setAgentId: (agentId: string) => {
    set({ agentId });
  },
  clearSuggestions: async () => {
    set((state) => ({
      ...state,
      suggestions: []
    }));
  },
  fetchMessages: async (conversationId: string) => {
    const orgId = useContextStore.getState().selectedOrgId || "x-functions";
    set((state) => ({
      cursors: {
        ...state.cursors,
        messages: {
          ...state.cursors.messages,
          fetching: true,
        },
      },
    }));

    const { data } = await apiClient.get<ConversationView & { messages: Message[]; suggestions: []; }>(`/agents/orgs/${orgId}/conversations/${conversationId}?includeSuggestedPrompts=false`);
    const { messages, suggestions, ...convo } = data;

    set((state) => ({
      conversation: convo,
      messages: messages,
      suggestions: suggestions,
      cursors: {
        ...state.cursors,
        messages: {
          ...state.cursors.messages,
          fetching: false,
        },
      },
    }));
  },
  sendMessage: async (message: string, socket: Sockette) => {
    console.log("@Socket: ", { socket });
    if (!socket) return;

    const orgId = useContextStore.getState().selectedOrgId;
    const token = BloomStorage.getAccessToken();
    const requestId = v4();
    const newUserMsg = {
      id: v4(),
      type: "user",
      content: message,
      createdAt: new Date().toISOString(),
    } as Message;
    const newAgentMsg = {
      id: v4(),
      type: "assistant",
      content: "",
      requestId,
      receiving: true,
    } as Message;

    const messages = get().messages;

    set((state) => ({
      messages: [...messages, newUserMsg, newAgentMsg],
      cursors: {
        ...state.cursors,
        newMessage: {
          ...state.cursors.newMessage,
          sending: true,
        },
      },
    }));

    try {
      const { agentId, conversation } = get();

      console.log("@Sending message: ", { conversation });
      (window as any).socket.send(
        JSON.stringify({
          action: "message",
          agentId: conversation?.agentId || agentId,
          conversationId: conversation?.conversationId,
          requestId,
          message,
          orgId,
          token,
        })
      );

      set((state) => ({
        ...state,
        cursors: {
          ...state.cursors,
          newMessage: {
            ...state.cursors.newMessage,
            sending: false,
            lastRequestId: requestId,
          },
        },
      }));
    } catch (err) {
      console.log("@Error: ", err);
      set((state) => ({
        ...state,
        messages: [
          ...messages,
          { ...newUserMsg, error: (err as Error).message },
          { ...newAgentMsg, error: (err as Error).message },
        ],
        cursors: {
          ...state.cursors,
          newMessage: {
            ...state.cursors.newMessage,
            sending: false,
          },
        },
      }));
    }
  },
  processWSMessage: (message: WSMessage) => {
    console.log({ message });
    if (message.type !== "conversation") return;

    const orgId = useContextStore.getState().selectedOrgId;
    const payload = message.payload as WSConversationUpdate;
    if (payload.conversationId !== get().conversation?.conversationId) {
      return;
    }
    if (payload.orgId !== orgId) {
      return;
    }

    if (payload.signal === "start") {
      return set((state) => ({
        ...state,
        cursors: {
          ...state.cursors,
          newMessage: {
            ...state.cursors.newMessage,
            receiving: true,
          },
        },
      }));
    }

    if (payload.signal === "end") {
      return set((state) => {
        const messages = state.messages;
        let newMessages = messages;
        const messageIndex = messages.findIndex(
          (m) => (m as { requestId?: string }).requestId === payload.requestId
        );
        if (messageIndex > -1) {
          newMessages = [
            ...messages.slice(0, messageIndex),
            {
              ...messages[messageIndex],
              receiving: false,
            } as unknown as Message,
            ...messages.slice(messageIndex + 1),
          ];
        }
        return {
          ...state,
          messages: newMessages,
          suggestions: state.suggestions,
          cursors: {
            ...state.cursors,
            newMessage: {
              ...state.cursors.newMessage,
              receiving: false,
            },
          },
        };
      });
    }

    if (!payload.delta && payload.title) {
      return set((state) => ({
        ...state,
        suggestions: payload.promptSuggestions || [],
        conversation: {
          ...state.conversation,
          title: payload.title as string,
        } as ConversationView,
      }));
    }

    if (payload.error) {
      const messages = get().messages;
      const messageIndex = messages.findIndex(
        (m) => (m as { requestId?: string }).requestId === payload.requestId
      );
      if (messageIndex > -1) {
        set((state) => ({
          messages: [
            ...state.messages.slice(0, messageIndex),
            {
              ...messages[messageIndex],
              error: payload.error,
            } as unknown as Message,
            ...state.messages.slice(messageIndex + 1),
          ],
        }));
      }
      return;
    }

    if (!payload.delta) {
      return;
    }

    const messages = get().messages;
    let messageIndex = messages.findIndex(
      (m) => (m as { requestId?: string }).requestId === payload.requestId
    );
    let m: Message | (Partial<Message> & { requestId?: string | undefined });
    if (messageIndex < 0) {
      messageIndex = messages.length;
      m = { requestId: payload.requestId };
    } else {
      m = messages[messageIndex];
    }

    m.type ||= "assistant";
    let updated = m;
    if (payload.delta.type === "tool") {
      if (!payload.delta?.toolCallId) return;

      const toolCalls = (m as AssistantMessage).tool_calls || [];
      const callIndex = toolCalls.findIndex(
        (c) => c.id === payload.delta?.toolCallId
      );
      if (callIndex === -1) return;

      const updatedCall = {
        ...toolCalls[callIndex],
        completed: true,
      };

      updated = {
        ...(m as AssistantMessage),
        tool_calls: [
          ...toolCalls.slice(0, callIndex),
          updatedCall as unknown as ToolCall,
          ...toolCalls.slice(callIndex + 1),
        ],
      };
    } else {
      updated = processMessageDelta(
        m as Message,
        payload.delta as MessageDelta
      );
    }

    set((state) => ({
      messages: [
        ...state.messages.slice(0, messageIndex),
        updated,
        ...state.messages.slice(messageIndex + 1),
      ],
    }));
  },
  updateTitle: async (title: string) => {
    if (!title) return;
    if (!get().conversation) return;

    const oldTitle = get().conversation?.title;
    if (oldTitle === title) return;

    set((state) => ({
      conversation: {
        ...state.conversation,
        title,
      } as ConversationView,
    }));
    try {
      const { data } = await apiClient.put<ConversationView>(
        `/conversations/${get().conversation?.conversationId}`,
        {
          title,
        }
      );
      set((state) => ({
        ...state,
        conversation: data,
      }));
    } catch (err) {
      set((state) => ({
        conversation: {
          ...state.conversation,
          title: oldTitle,
        } as ConversationView,
      }));
      throw err;
    }
  },
}));

const getConversations = () => {
  const orgId = useContextStore.getState().selectedOrgId || "x-functions";
  return apiClient.get(`/agents/orgs/${orgId}/conversations`);
};
