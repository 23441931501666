import { KeyboardEvent, useEffect, useRef, useState } from "react";
import { Input, InputGroup } from "../cataylst/input";
import { Textarea } from "../cataylst/textarea";
import {
  AIIcon,
  AlertIcon,
  AlertRed32Icon,
  ArrowRightIcon,
  AttachIcon,
  AudioIcon,
  CalendarIcon,
  CameraIcon,
  CodeIcon,
  DocIcon,
  ImageIcon,
  LogoIcon,
  MetaIcon,
  SarahIcon,
  TableIcon,
  ThunderIcon,
  UnionIcon,
  VideoIcon,
  XMarkIcon,
  YoutubeIcon,
} from "./icons";
import { MenuIcon } from "./menu-icon";
import {
  Dropdown,
  DropdownButton,
  DropdownItem,
  DropdownMenu,
} from "../cataylst/dropdown";
import { Avatar } from "../cataylst/avatar";
import AvatarGroup from "./avatar-group";
import { formatDate } from "@/utils/format";
import { ChevronRightIcon } from "@heroicons/react/16/solid";
import { Button } from "./button";
import Notification from "./notification";

interface IActionSelectableProps {
  title: string;
}

function ActionSelectable({ title }: IActionSelectableProps) {
  return (
    <div className="bg-[#106EF622] flex items-center px-3 py-1 rounded-full min-w-fit">
      <span className="text-xs font-medium text-black">{title}</span>
    </div>
  );
}

interface IActionTagProps {
  icon: string;
  title: string;
}

function ActionTag({ icon, title }: IActionTagProps) {
  return (
    <div className="bg-[#F2F2F2] flex items-center gap-1.5 rounded-full px-3 py-1 min-w-fit">
      <img src={icon} className="size-4" />
      <span className="text-xs font-medium text-black whitespace-nowrap">
        {title}
      </span>
      <img src={XMarkIcon} className="size-4" />
    </div>
  );
}

interface IAgentInputProps {
  withSuffixIcon?: boolean;
  rounded?: boolean;
  message?: string;
  type?: 'empty' | 'input-options' | 'yes-no-quiz' | 'question-options' | 'warning-with-schedule' | 'multi-select-with-actions' | 'blue-options' | 'notification';
  size?: 'lg' | 'sm';
  onConfirm?: (prompt: string) => void;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export default function AgentInput({ withSuffixIcon, rounded, type='empty', size='lg', onConfirm, onChange, message }: IAgentInputProps) {
  const [value, setValue] = useState(message || "");
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  useEffect(() => {
    adjustHeight();
  }, [value]);

  useEffect(() => {
    if (message)
      setValue(message);
  }, [message]);

  const [agentType, setAgentType] = useState(type);

  const adjustHeight = () => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = "auto";
      textarea.style.height = `${textarea.scrollHeight}px`;
    }
  };

  const handleChange = (event) => {
    const newValue = event.target.value;
    setValue(newValue);
    if (onChange)
      onChange(event);
    // switch (newValue[0]) {
    //   case 'a':
    //     setAgentType('empty');
    //     break;
    //   case 'b':
    //     setAgentType('input-options');
    //     break;
    //   case 'c':
    //     setAgentType('yes-no-quiz');
    //     break;
    //   case 'd':
    //     setAgentType('question-options');
    //     break;
    //   case 'e':
    //     setAgentType('warning-with-schedule');
    //     break;
    //   case 'f':
    //     setAgentType('multi-select-with-actions');
    //     break;
    //   case 'g':
    //     setAgentType('blue-options');
    //     break;
    //   case 'h':
    //     setAgentType('notification');
    //     break;
    // }
    // if (newValue[1] === '/') {
    //   document.getElementById('inputDropdownButton')?.click();
    // }
  };

  const onKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      if (value && onConfirm) {
        onConfirm(value);
        setTimeout(() => setValue(''), 200);
      }
    }
  };

  const onSendMessage = () => {
    if (value && onConfirm) {
      onConfirm(value);
      setValue('');
    }
  };

  const renderActionTags = () => {
    return (
      <div className="flex items-center gap-2 flex-wrap px-2">
        <ActionTag icon={YoutubeIcon} title="demo-video-14-08-2024.mov" />
        <ActionTag icon={DocIcon} title="Meta-Sales-Proposal.pdf" />
        <ActionTag icon={DocIcon} title="Meta-Sales-Proposal.pdf" />
      </div>
    );
  };

  const renderActionSelectables = () => {
    return (
      <div className="flex items-center gap-2 overflow-x-auto scrollbar-none scrollbar-thumb-gray-200 scrollbar-track-gray-50 py-1 px-2">
        <ActionSelectable title="What is our projected revenue for Q4?" />
        <ActionSelectable title="How do market trends impact our forecast?" />
        <ActionSelectable title="Which product lines are driving the most growth?" />
      </div>
    );
  };

  const renderMultipleSelected = () => {
    const onMoreActions = () => {
      document.getElementById('actionDropdownButton')?.click();
    };
    return (
      <div className="flex justify-between items-center rounded-lg px-2 w-full">
        <div className="flex gap-5 items-center">
          <div className="text-sm font-normal text-[rgba(0,0,0,0.6)] leading-[17.5px]">4 selected</div>
          <div className="text-sm font-normal text-black underline leading-[17.5px] cursor-pointer">Deselect all</div>
        </div>
        <div className="flex gap-2 items-center">
          <Button color="white" className="flex gap-2 h-6" size="sm">
            <img src={ArrowRightIcon} className="size-4" />
            Update sales stage
          </Button>
          <Button color="white" className="flex gap-2 h-6" size="sm">
            <img src={ThunderIcon} className="size-4" />
            Next best action
          </Button>
          <div>
            {renderDropdown('actionDropdownButton')}
            <Button color="dark" className="flex gap-2 h-6" size="sm" onClick={onMoreActions}>
              <img src={AIIcon} className="size-4 invert" />
              More actions
            </Button>
          </div>
        </div>
      </div>
    )
  };

  const renderAlert = () => {
    return (
      <div className="flex justify-between items-center rounded-lg px-2 w-full">
        <div className="flex gap-2 items-center">
          <img src={AlertIcon} className="size-4" />
          <div className="text-sm font-normal text-black leading-[17.5px] cursor-pointer">
            Meta’s data security demo is 2 days overdue
          </div>
        </div>
        <div className="flex gap-2 items-center">
          <Button color="dark" className="flex gap-2 h-6" size="sm">
            <img src={CalendarIcon} className="size-4 invert" />
            Schedule demo
          </Button>
          <img src={XMarkIcon} className="size-4 cursor-pointer" />
        </div>
      </div>
    )
  };

  const renderLabel = () => {
    return (
      <div className="flex justify-between items-center rounded-lg px-2 w-full">
        <div className="flex gap-2 items-center">
          <div className="text-sm font-normal text-black leading-[17.5px] cursor-pointer">
            I noticed you’re preparing your quarterly review. Need help with any of these tasks?
          </div>
        </div>
        <div className="flex gap-2 items-center">
          <img src={XMarkIcon} className="size-4 cursor-pointer" />
        </div>
      </div>
    )
  };

  const renderActionButtons = () => {
    return (
      <div className="flex items-center gap-2 overflow-x-auto scrollbar-none scrollbar-thumb-gray-200 scrollbar-track-gray-50 py-1 px-2">
        <Button color="white" className="flex gap-2" size="sm">
          <img src={ThunderIcon} className="size-4" />
          Compile sales performance
        </Button>
        <Button color="white" className="flex gap-2" size="sm">
          <img src={CalendarIcon} className="size-4" />
          Generate revenue forecast
        </Button>
        <Button color="white" className="flex gap-2" size="sm">
          <img src={AudioIcon} className="size-4" />
          Analyze team productivity
        </Button>
      </div>
    )
  };

  const renderQuestion = () => {
    return (
      <div className="px-2">
        <div className="flex justify-between items-center rounded-lg w-full">
          <div className="flex gap-2 items-center">
            <div className="text-sm font-normal text-black leading-[17.5px] cursor-pointer">
              I noticed you’re preparing your quarterly review. Need help with any of these tasks?
            </div>
          </div>
          <div className="flex gap-2 items-center">
            <img src={XMarkIcon} className="size-4 cursor-pointer" />
          </div>
        </div>
        <div className="mt-3 flex gap-2 items-center">
          <Button color="dark" size="sm">
            Yes please
          </Button>
          <Button color="white" size="sm">
            No thanks
          </Button>
        </div>
      </div>
    );
  }

  const renderDropdown = (actionButtonId: string) => {
    return (
      <Dropdown>
        <DropdownButton
          outline
          className="!border-none hover:!bg-transparent !outline-none !p-0 z-[-1] !absolute"
          id={actionButtonId}
        >
          Open
        </DropdownButton>
        <DropdownMenu anchor={{ to: 'top start', gap: 8 }} className="min-w-32 p-4">
          <div className="text-xs text-[#00000099] mb-2">Attach media</div>
          <DropdownItem className="!px-0 mb-1">
            <div className="flex gap-2 items-center">
              <img src={CameraIcon} className="size-4" />
              <span className="text-sm leading-[17.5px] font-firstext text-black">Camera</span>
            </div>
          </DropdownItem>
          <DropdownItem className="!px-0 mb-1">
            <div className="flex gap-2 items-center">
              <img src={ImageIcon} className="size-4" />
              <span className="text-sm leading-[17.5px] font-firstext text-black">Photo & Video Library</span>
            </div>
          </DropdownItem>
          <DropdownItem className="!px-0 mb-1">
            <div className="flex gap-2 items-center">
              <img src={AudioIcon} className="size-4" />
              <span className="text-sm leading-[17.5px] font-firstext text-black">Audio</span>
            </div>
          </DropdownItem>
          <DropdownItem className="!px-0 mb-1">
            <div className="flex gap-2 items-center">
              <img src={DocIcon} className="size-4" />
              <span className="text-sm leading-[17.5px] font-firstext text-black">Text Document</span>
            </div>
          </DropdownItem>
          <DropdownItem className="!px-0 mb-1">
            <div className="flex gap-2 items-center">
              <img src={TableIcon} className="size-4" />
              <span className="text-sm leading-[17.5px] font-firstext text-black">Data File</span>
            </div>
          </DropdownItem>
          <DropdownItem className="!px-0 mb-1">
            <div className="flex gap-2 items-center">
              <img src={CodeIcon} className="size-4" />
              <span className="text-sm leading-[17.5px] font-firstext text-black">Code</span>
            </div>
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    );
  };

  const suffix = withSuffixIcon && (agentType === 'empty' && !value);

  return (
    <div
      className={`min-h-[64px] ${size === 'lg' && 'min-w-[640px]'} ${size === 'sm' && 'min-w-[280px]'} h-auto p-2 ${rounded && 'rounded-xl'} ${agentType !== 'empty' && 'border-[rgba(0,0,0,0.1)] border border-solid bg-white'} font-firstext`}
      style={{ boxShadow: agentType !== 'empty' ? "0px 16px 64px -12px rgba(0, 0, 0, 0.50)" : '' }}
    >
      <InputGroup className="w-full">
        {suffix && (
          <div
            data-slot="icon"
            className="!w-min !h-full mt-[-9px] flex items-end"
          >
            <div className="h-auto flex gap-4 items-center mb-3">
              <MenuIcon src={LogoIcon} className="size-4" />
            </div>
          </div>
        )}
        <div>
          {agentType === 'blue-options' && (
            <div className="mb-2">{renderActionSelectables()}</div>
          )}
          {agentType === 'multi-select-with-actions' && (
            <div className="mb-2">{renderMultipleSelected()}</div>
          )}
          {agentType === 'warning-with-schedule' && (
            <div className="mb-2">{renderAlert()}</div>
          )}
          {agentType === 'question-options' && (
            <>
              <div className="mb-2">{renderLabel()}</div>
              <div className="mb-2">{renderActionButtons()}</div>
            </>
          )}
          {agentType === 'yes-no-quiz' && (
            <div className="mb-2">{renderQuestion()}</div>
          )}
          {agentType === 'notification' && (
            <div className="px-2">
              <Notification
                logoType="company"
                logoPath={MetaIcon}
                action={{
                  title: 'Generate case study ideas'
                }}
                title="Suggested action"
                color="purple"
                content={
                  <div>
                    Based on your engagement with <span className="underline">Zoom</span>, we recommend sending a case study highlighting a recent success in their industry.
                  </div>
                }
                closeIcon
              />
            </div>
          )}
          {renderDropdown('inputDropdownButton')}
          <Textarea
            ref={textareaRef}
            placeholder="How can I help you?"
            className={`min-h-12 h-auto ${
              suffix ? "!pl-10" : "!pl-2"
            } !pr-[160px] flex items-center`}
            border={false}
            resizable={false}
            value={value}
            onChange={handleChange}
            onKeyDown={(e) => onKeyDown(e)}
            rows={1}
            withItems={agentType === 'input-options' ? renderActionTags() : null}
          ></Textarea>
        </div>
        <div
          data-slot="icon"
          className="!w-min !h-full mt-[-9px] flex items-end"
        >
          <div className="h-auto flex gap-4 items-center mb-2">
            <MenuIcon src={AttachIcon} className="size-4" />
            <MenuIcon src={AudioIcon} className="size-4" />
            <MenuIcon src={UnionIcon} onClick={onSendMessage} disabled={!value} className="size-8" />
          </div>
        </div>
      </InputGroup>
    </div>
  );
}
