import React from 'react';
import SvgIcon, { SvgIconProps } from '../SvgIcon';

export const BoxIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon
    {...props}
    style={{ ...props.style, fill: 'transparent' }}
    viewBox="0 0 24 14"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.77741 6.38331C9.56045 4.91044 11.11 3.9071 12.8945 3.9071C15.4682 3.9071 17.5549 5.99357 17.5548 8.56763C17.5548 11.1414 15.4682 13.2281 12.8944 13.2281C11.1099 13.2281 9.56045 12.2248 8.77736 10.7518C7.99437 12.2247 6.44486 13.2282 4.66042 13.2282C2.11247 13.2282 0.0433703 11.1829 0.00194195 8.64482H0V0.912987H0.000431545C0.0112202 0.407109 0.423885 0 0.932461 0C1.44098 0 1.85376 0.407109 1.86465 0.912987H1.86508V4.83859C2.64381 4.25374 3.61165 3.90721 4.66047 3.90721C6.44491 3.90721 7.99437 4.91039 8.77741 6.38331ZM1.86503 8.56768C1.86503 10.1115 3.11651 11.363 4.66042 11.363C6.20432 11.363 7.45586 10.1115 7.45586 8.56768C7.45586 7.02367 6.20432 5.77218 4.66042 5.77218C3.11656 5.77218 1.86503 7.02367 1.86503 8.56768ZM10.0991 8.56757C10.0991 10.1115 11.3505 11.363 12.8945 11.363C14.4383 11.363 15.6898 10.1115 15.6899 8.56757C15.6899 7.02356 14.4383 5.77218 12.8945 5.77218C11.3506 5.77218 10.0991 7.02356 10.0991 8.56757ZM21.4375 8.57102L23.8243 11.7614L23.8238 11.7618C24.1191 12.1728 24.0335 12.7462 23.6268 13.0516C23.2203 13.3569 22.6458 13.2794 22.3333 12.8815L22.3331 12.8816L20.2731 10.1278L18.2131 12.8816L18.2128 12.8812C17.9003 13.2793 17.3259 13.3569 16.9192 13.0514C16.5125 12.746 16.4269 12.1727 16.7223 11.7617L16.7219 11.7614L19.1086 8.57102L16.7219 5.38061L16.7223 5.38034C16.427 4.9694 16.5125 4.39604 16.9192 4.09051C17.3259 3.78508 17.9003 3.8626 18.2128 4.26097L18.2131 4.26048L20.2731 7.01412L22.3331 4.26048L22.3333 4.26059C22.6458 3.8626 23.2203 3.78508 23.6268 4.09029C24.0335 4.39583 24.1191 4.96919 23.8238 5.38023L23.8243 5.38061L21.4375 8.57102Z"
      fill="currentColor"
    />
  </SvgIcon>
);
