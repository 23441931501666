import React from 'react';
import SvgIcon, { SvgIconProps } from '../SvgIcon';

export const S3Icon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props} style={{ fill: 'transparent' }} viewBox="0 0 18 22">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.4553 3.77274L12.6826 10.9546L16.4553 18.1364L18.0008 17.2727V4.63637L16.4553 3.77274Z"
      fill="#E25444"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.4547 3.77274L8.90927 4.63637L5.06836 10.9546L8.90927 17.2727L16.4547 18.1364V3.77274Z"
      fill="#7B1D13"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.22727 3.77274L0 4.31819V17.5909L1.22727 18.1364L8.90909 10.9546L1.22727 3.77274Z"
      fill="#58150D"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.21094 3.75758L8.91944 5.89899V16.202L1.21094 18.1414V3.75758Z"
      fill="#E25444"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.92446 7.03033L5.65137 6.50505L8.92446 2.78787L12.1895 6.50505L8.92446 7.03033Z"
      fill="#58150D"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.1895 6.50505L8.92041 7.03837L5.65137 6.50505V2.78787"
      fill="#58150D"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.92446 14.8283L5.65137 15.4344L8.92446 18.6263L12.1895 15.4344L8.92446 14.8283Z"
      fill="#58150D"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.90847 0L5.63574 1.72727V6.5L8.91865 5.57577L8.90847 0Z"
      fill="#7B1D13"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.92041 8.20198L5.65137 8.55757V13.3229L8.92041 13.6969V8.20198Z"
      fill="#7B1D13"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.92041 16.2424L5.65137 15.4227V20.1074L8.92041 21.8182V16.2424Z"
      fill="#7B1D13"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.189 15.4227L8.91992 16.2426V21.8182L12.189 20.1074V15.4227Z"
      fill="#E25444"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.91992 8.20198L12.189 8.55757V13.3229L8.91992 13.6969V8.20198Z"
      fill="#E25444"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.90918 0L12.1819 1.72727V6.5L8.90918 5.59091V0Z"
      fill="#E25444"
    />
  </SvgIcon>
);
