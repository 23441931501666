import React from 'react';
import SvgIcon, { SvgIconProps } from '../SvgIcon';

export const MetabaseIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props} style={{ fill: 'transparent' }} viewBox="0 0 18 24">
    <g clip-path="url(#clip_metabase)">
      <path
        d="M1.36364 7.80425C2.11675 7.80425 2.72727 7.18397 2.72727 6.41882C2.72727 5.65367 2.11675 5.03339 1.36364 5.03339C0.610521 5.03339 0 5.65367 0 6.41882C0 7.18397 0.610521 7.80425 1.36364 7.80425Z"
        fill="currentColor"
      />
      <path
        d="M1.36364 11.6835C2.11675 11.6835 2.72727 11.0632 2.72727 10.298C2.72727 9.53288 2.11675 8.9126 1.36364 8.9126C0.610521 8.9126 0 9.53288 0 10.298C0 11.0632 0.610521 11.6835 1.36364 11.6835Z"
        fill="currentColor"
      />
      <path
        opacity="0.2"
        d="M5.182 7.80425C5.93511 7.80425 6.54563 7.18397 6.54563 6.41882C6.54563 5.65367 5.93511 5.03339 5.182 5.03339C4.42888 5.03339 3.81836 5.65367 3.81836 6.41882C3.81836 7.18397 4.42888 7.80425 5.182 7.80425Z"
        fill="currentColor"
      />
      <path
        d="M5.182 11.7212C5.93511 11.7212 6.54563 11.1009 6.54563 10.3357C6.54563 9.57057 5.93511 8.95029 5.182 8.95029C4.42888 8.95029 3.81836 9.57057 3.81836 10.3357C3.81836 11.1009 4.42888 11.7212 5.182 11.7212Z"
        fill="currentColor"
      />
      <path
        opacity="0.2"
        d="M9.00035 7.80425C9.75347 7.80425 10.364 7.18397 10.364 6.41882C10.364 5.65367 9.75347 5.03339 9.00035 5.03339C8.24724 5.03339 7.63672 5.65367 7.63672 6.41882C7.63672 7.18397 8.24724 7.80425 9.00035 7.80425Z"
        fill="currentColor"
      />
      <path
        opacity="0.2"
        d="M9.00035 3.37087C9.75347 3.37087 10.364 2.75059 10.364 1.98544C10.364 1.22029 9.75347 0.600006 9.00035 0.600006C8.24724 0.600006 7.63672 1.22029 7.63672 1.98544C7.63672 2.75059 8.24724 3.37087 9.00035 3.37087Z"
        fill="currentColor"
      />
      <path
        opacity="0.2"
        d="M9.00035 11.7212C9.75347 11.7212 10.364 11.1009 10.364 10.3357C10.364 9.57057 9.75347 8.95029 9.00035 8.95029C8.24724 8.95029 7.63672 9.57057 7.63672 10.3357C7.63672 11.1009 8.24724 11.7212 9.00035 11.7212Z"
        fill="currentColor"
      />
      <path
        opacity="0.2"
        d="M12.8177 7.80425C13.5709 7.80425 14.1814 7.18397 14.1814 6.41882C14.1814 5.65367 13.5709 5.03339 12.8177 5.03339C12.0646 5.03339 11.4541 5.65367 11.4541 6.41882C11.4541 7.18397 12.0646 7.80425 12.8177 7.80425Z"
        fill="currentColor"
      />
      <path
        d="M16.6361 7.80425C17.3892 7.80425 17.9997 7.18397 17.9997 6.41882C17.9997 5.65367 17.3892 5.03339 16.6361 5.03339C15.883 5.03339 15.2725 5.65367 15.2725 6.41882C15.2725 7.18397 15.883 7.80425 16.6361 7.80425Z"
        fill="currentColor"
      />
      <path
        d="M12.8177 11.7212C13.5709 11.7212 14.1814 11.1009 14.1814 10.3357C14.1814 9.57057 13.5709 8.95029 12.8177 8.95029C12.0646 8.95029 11.4541 9.57057 11.4541 10.3357C11.4541 11.1009 12.0646 11.7212 12.8177 11.7212Z"
        fill="currentColor"
      />
      <path
        d="M16.6361 11.7212C17.3892 11.7212 17.9997 11.1009 17.9997 10.3357C17.9997 9.57057 17.3892 8.95029 16.6361 8.95029C15.883 8.95029 15.2725 9.57057 15.2725 10.3357C15.2725 11.1009 15.883 11.7212 16.6361 11.7212Z"
        fill="currentColor"
      />
      <path
        d="M1.36364 15.5627C2.11675 15.5627 2.72727 14.9424 2.72727 14.1773C2.72727 13.4121 2.11675 12.7918 1.36364 12.7918C0.610521 12.7918 0 13.4121 0 14.1773C0 14.9424 0.610521 15.5627 1.36364 15.5627Z"
        fill="currentColor"
      />
      <path
        opacity="0.2"
        d="M5.182 15.6004C5.93511 15.6004 6.54563 14.9801 6.54563 14.2149C6.54563 13.4498 5.93511 12.8295 5.182 12.8295C4.42888 12.8295 3.81836 13.4498 3.81836 14.2149C3.81836 14.9801 4.42888 15.6004 5.182 15.6004Z"
        fill="currentColor"
      />
      <path
        d="M9.00035 15.6004C9.75347 15.6004 10.364 14.9801 10.364 14.2149C10.364 13.4498 9.75347 12.8295 9.00035 12.8295C8.24724 12.8295 7.63672 13.4498 7.63672 14.2149C7.63672 14.9801 8.24724 15.6004 9.00035 15.6004Z"
        fill="currentColor"
      />
      <path
        opacity="0.2"
        d="M12.8177 15.6004C13.5709 15.6004 14.1814 14.9801 14.1814 14.2149C14.1814 13.4498 13.5709 12.8295 12.8177 12.8295C12.0646 12.8295 11.4541 13.4498 11.4541 14.2149C11.4541 14.9801 12.0646 15.6004 12.8177 15.6004Z"
        fill="currentColor"
      />
      <path
        d="M16.6361 15.6004C17.3892 15.6004 17.9997 14.9801 17.9997 14.2149C17.9997 13.4498 17.3892 12.8295 16.6361 12.8295C15.883 12.8295 15.2725 13.4498 15.2725 14.2149C15.2725 14.9801 15.883 15.6004 16.6361 15.6004Z"
        fill="currentColor"
      />
      <path
        d="M1.36364 19.4419C2.11675 19.4419 2.72727 18.8216 2.72727 18.0565C2.72727 17.2913 2.11675 16.671 1.36364 16.671C0.610521 16.671 0 17.2913 0 18.0565C0 18.8216 0.610521 19.4419 1.36364 19.4419Z"
        fill="currentColor"
      />
      <path
        opacity="0.2"
        d="M5.182 19.4796C5.93511 19.4796 6.54563 18.8593 6.54563 18.0941C6.54563 17.329 5.93511 16.7087 5.182 16.7087C4.42888 16.7087 3.81836 17.329 3.81836 18.0941C3.81836 18.8593 4.42888 19.4796 5.182 19.4796Z"
        fill="currentColor"
      />
      <path
        opacity="0.2"
        d="M9.00035 19.4796C9.75347 19.4796 10.364 18.8593 10.364 18.0941C10.364 17.329 9.75347 16.7087 9.00035 16.7087C8.24724 16.7087 7.63672 17.329 7.63672 18.0941C7.63672 18.8593 8.24724 19.4796 9.00035 19.4796Z"
        fill="currentColor"
      />
      <path
        opacity="0.2"
        d="M9.00035 23.3588C9.75347 23.3588 10.364 22.7385 10.364 21.9734C10.364 21.2082 9.75347 20.5879 9.00035 20.5879C8.24724 20.5879 7.63672 21.2082 7.63672 21.9734C7.63672 22.7385 8.24724 23.3588 9.00035 23.3588Z"
        fill="currentColor"
      />
      <path
        opacity="0.2"
        d="M12.8177 19.4796C13.5709 19.4796 14.1814 18.8593 14.1814 18.0941C14.1814 17.329 13.5709 16.7087 12.8177 16.7087C12.0646 16.7087 11.4541 17.329 11.4541 18.0941C11.4541 18.8593 12.0646 19.4796 12.8177 19.4796Z"
        fill="currentColor"
      />
      <path
        d="M16.6361 19.4796C17.3892 19.4796 17.9997 18.8593 17.9997 18.0941C17.9997 17.329 17.3892 16.7087 16.6361 16.7087C15.883 16.7087 15.2725 17.329 15.2725 18.0941C15.2725 18.8593 15.883 19.4796 16.6361 19.4796Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip_metabase">
        <rect
          width="18"
          height="22.7812"
          fill="currentColor"
          transform="translate(0 0.600006)"
        />
      </clipPath>
    </defs>
  </SvgIcon>
);
