import React from 'react';
import SvgIcon, { SvgIconProps } from '../SvgIcon';

export const TwillioIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props} style={{ fill: 'transparent' }} viewBox="0 0 20 20">
    <path
      d="M10 0C15.52 0 20 4.48 20 10C20 15.52 15.52 20 10 20C4.48 20 0 15.52 0 10C0 4.48 4.48 0 10 0ZM10 2.64C5.92 2.64 2.64 5.92 2.64 10C2.64 14.08 5.92 17.36 10 17.36C14.08 17.36 17.36 14.08 17.36 10C17.36 5.92 14.08 2.64 10 2.64ZM12.48 10.4C13.6288 10.4 14.56 11.3312 14.56 12.48C14.56 13.6288 13.6288 14.56 12.48 14.56C11.3312 14.56 10.4 13.6288 10.4 12.48C10.4 11.3312 11.3312 10.4 12.48 10.4ZM7.52 10.4C8.66875 10.4 9.6 11.3312 9.6 12.48C9.6 13.6288 8.66875 14.56 7.52 14.56C6.37125 14.56 5.44 13.6288 5.44 12.48C5.44 11.3312 6.37125 10.4 7.52 10.4ZM12.48 5.44C13.6288 5.44 14.56 6.37125 14.56 7.52C14.56 8.66875 13.6288 9.6 12.48 9.6C11.3312 9.6 10.4 8.66875 10.4 7.52C10.4 6.37125 11.3312 5.44 12.48 5.44ZM7.52 5.44C8.66875 5.44 9.6 6.37125 9.6 7.52C9.6 8.66875 8.66875 9.6 7.52 9.6C6.37125 9.6 5.44 8.66875 5.44 7.52C5.44 6.37125 6.37125 5.44 7.52 5.44Z"
      fill="currentColor"
    />
  </SvgIcon>
);
