import React from 'react';
import SvgIcon, { SvgIconProps } from '../SvgIcon';

export const DHLIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon
    {...props}
    style={{ ...props.style, fill: 'transparent' }}
    viewBox="0 0 28 6"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.43268 2.55528L0 5.86071L5.93202 5.86064C7.89102 5.86064 8.98202 4.52832 9.31836 4.06754H5.27412C4.76125 4.06754 4.91598 3.85658 5.00164 3.74097C5.09342 3.61702 5.21865 3.44629 5.34044 3.28025C5.44251 3.1411 5.54216 3.00525 5.61768 2.90299C5.78077 2.68206 5.78511 2.55528 5.45145 2.55528H2.43268ZM12.6128 5.86058L13.932 4.06714L10.4525 4.06754L9.13293 5.86058H12.6128ZM10.7515 3.66153H19.2631L21.5898 0.5H18.1113L16.7767 2.31283H15.2238L16.558 0.5H13.0791L10.7515 3.66153ZM17.6445 5.86071L18.9643 4.06761H15.4855L14.1659 5.86071H17.6445ZM24.0805 3.66146L26.4076 0.5H22.7231L20.3942 3.66146H24.0805ZM19.7181 4.58215C19.8416 4.41512 20.0959 4.06761 20.0959 4.06761H28L26.6803 5.86071H21.0934C19.6675 5.86071 19.2812 5.17272 19.7181 4.58215ZM0.491175 2.14546L1.70233 0.5L8.68764 0.500067C10.4614 0.500067 10.9146 1.90119 10.4184 2.57448C10.0537 3.06949 9.61863 3.66153 9.61863 3.66153H6.91928C6.40634 3.66153 6.56114 3.4509 6.64673 3.33529C6.73373 3.2178 6.84958 3.05717 6.9641 2.89838C7.07164 2.74927 7.17801 2.60178 7.25823 2.49317C7.42132 2.27224 7.42573 2.14546 7.09206 2.14546H0.491175Z"
      fill="#D2002E"
    />
  </SvgIcon>
);
