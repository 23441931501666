import { Input, InputGroup } from "@/components/cataylst/input";
import { PlusIcon, SearchIcon } from "@/components/custom/icons";
import { MenuIcon } from "@/components/custom/menu-icon";
import MainSidebar from "@/components/custom/sidebar";
import { useState } from "react";
import Loading from "@/components/custom/loading";
import { SourceCategoryId, sourceConfigs, SourceIcon, SourceId } from "@/components/custom/source-icon";
import { Button } from "@/components/custom/button";
import { useParams } from "react-router-dom";

export default function DemoConnectionsPage() {
  const [searchKeyword, setSearchKeyword] = useState("");
  const [isLoading, setLoading] = useState(false);
  const { category } = useParams();
  const [sourceId, setSourceId] = useState(SourceId.Slack);
  const [triggerSourceDrawerOpen, setTriggerSourceDrawerOpen] = useState(0);

  const onSearchConnections = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchKeyword(event.target.value);
  };

  const filterConnections = () => {
    let connections = sourceConfigs;
    if (!category)
      connections = sourceConfigs;
    else if (category === 'data-warehouse')
      connections = sourceConfigs.filter(source => source.category === SourceCategoryId.DataWarehouse);
    else if (category === 'cloud-apps')
      connections = sourceConfigs.filter(source => source.category === SourceCategoryId.CloudApps);
    else connections = sourceConfigs.filter(source => source.category === SourceCategoryId.AIML);
    return connections.filter(source => source.title.includes(searchKeyword));
  };

  const getSource = () => {
    return sourceConfigs.find(source => source.id === sourceId)!;
  };

  const onConnectSource = (source: SourceId) => {
    setSourceId(source);
    setTriggerSourceDrawerOpen(triggerSourceDrawerOpen + 1);
  };

  const sourceConfig = getSource();

  return (
    <main>
      <MainSidebar
        triggerSourceDrawerOpen={triggerSourceDrawerOpen}
        sourceId={sourceId}
      >
        <div className="text-cell px-8 py-5">
          <div className="mb-5 flex justify-between">
            <div className="flex gap-2">
              <div className="w-[250px]">
                <InputGroup>
                  <MenuIcon src={SearchIcon} data-slot="icon" className="!size-4" />
                  <Input placeholder="Search sources" className="h-9 pl-9 pr-2 !rounded-full" onChange={onSearchConnections} />
                </InputGroup>
              </div>
            </div>
          </div>
          <div className="max-w-full" id="sourceList">
            {filterConnections().map((sourceConfig, index) => (
              <div key={sourceConfig.id + index} className="flex border-b border-solid border-gray-400 p-4 justify-between">
                <div className="flex gap-4 items-center">
                  <SourceIcon id={sourceConfig.id} />
                  <div className="flex flex-col gap-1">
                    <div className="text-sm">{sourceConfig.title}</div>
                    <div className="text-xs text-gray-600">
                      {sourceConfig.subtitle}
                    </div>
                  </div>
                </div>
                <div>
                  <Button color="white" className="gap-2 flex items-center" onClick={() => onConnectSource(sourceConfig.id)}>
                    <img src={PlusIcon} className="w-4 h-4 invert" />
                    connect
                  </Button>
                </div>
              </div>
            ))}
            {isLoading && (
              <div className="w-full h-full mt-4">
                <Loading />
              </div>
            )}
          </div>
        </div>
      </MainSidebar>
    </main>
  )
}