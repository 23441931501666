import React, { useRef } from 'react';
import Markdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import { capitalCase } from 'change-case';
import { MessageArray } from '@/services/conversation';
import { useContextStore } from '@/zustand/context';
import { AssistantMessage, Message, ToolCall } from '@/types/conversation';
import { Avatar } from '@/components/cataylst/avatar';
import { CircleGreenCheckIcon, DaveIcon, LogoIcon } from '@/components/custom/icons';
import remarkGfm from 'remark-gfm';
import remarkMath from 'remark-math';

interface MessagesProps {
  messages: MessageArray;
  assistantName: string;
}

interface WithError {
  error?: {
    message: string;
  };
}

const Messages: React.FC<MessagesProps> = ({ messages, assistantName }) => {
  const { currentUser } = useContextStore();
  console.log({ messages, assistantName });

  const showTools = (tool_calls: ToolCall[], receiving?: boolean) => {
    const getFunctionName = (name: string) => {
      if (name?.startsWith('searchKB')) return 'Checking Knowledgebase';
      if (name?.startsWith('kbInfo')) return 'Getting Knowledgebase Info';

      return capitalCase(name || 'Processing');
    };

    return (
      <div className='flex flex-wrap'>
        {tool_calls.map((tool) => (
          <div
            className='flex items-center p-2 py-1.5 rounded-sm bg-gray-300 text-sm'
            key={tool.id}
          >
            {receiving && !(tool as { completed?: boolean }).completed && (
              <>***</>
            )}
            {getFunctionName(tool.function?.name)}
          </div>
        ))}
      </div>
    );
  };

  const showMessageContent = (
    message: Message,
    receiving: boolean,
    sameSourceAsPrevious: boolean
  ) => {
    console.log({ sameSourceAsPrevious });
    return (
      <>
        <div className='flex flex-col gap-4'>
          {/* {(message as AssistantMessage)?.tool_calls?.length
            ? showTools(
                (message as AssistantMessage).tool_calls as ToolCall[],
                receiving
              )
            : null} */}
          {/* {(message as WithError).error && (
            <div className='text-red-500 mb-1 ml-2'>
              [Error]: {(message as WithError).error?.message}
            </div>
          )} */}
          <div className='markdown-body'>
            <Markdown rehypePlugins={[rehypeRaw]} remarkPlugins={[remarkGfm, remarkMath]}>
              {receiving
                ? `${message.content ?? ''}<span class="animate-pulse">⎮</span>`
                : message.content ?? ''}
            </Markdown>
          </div>
        </div>
      </>
    );
  }

  const showCompletedStep = (description: string) => {
    return (
      <div className='flex gap-2 items-center text-sm text-gray-600'>
        <img src={CircleGreenCheckIcon} />
        <div>{description}</div>
      </div>
    )
  };

  let lastCompleted = false;

  return (
    <div>
      {messages.map((message, i) => {
        if (message.type === 'user')
          lastCompleted = false;
        const isUser = (type: string) =>
          type === 'user' ? 'user' : 'assistant';
        const receiving = (message as { receiving?: boolean }).receiving;
        const error = !!(message as WithError).error;
        const shouldShowTools =
          (message as AssistantMessage)?.tool_calls?.length ||
          receiving ||
          error;
        const sameSourceAsPrevious = 
          i > 0 &&
            isUser(messages[i - 1].type || '') === isUser(message.type || '');
        const sameSourceAsNext =
          i < messages.length - 1 &&
          isUser(messages[i + 1].type || '') === isUser(message.type || '');
        
        const previousCompleted = lastCompleted;
        
        if (message.type === 'assistant' && !message.content && shouldShowTools) {
          const funcArguments = (message as AssistantMessage)?.tool_calls?.at(0)?.function.arguments;
          const funcName = (message as AssistantMessage)?.tool_calls?.at(0)?.function.name;
          try {
            const { prompt, message: fmessage, text, query } = JSON.parse(funcArguments || '');
            let description = prompt || fmessage || text;
            if (funcArguments && funcName && description) {
              description = description.slice(0, 50) + (description.length >= 50 ? "..." : "");
              lastCompleted = true;
              return (
                <div className='p-2 rounded-sm mb-1 mt-1 py-1 flex gap-3' key={message.id}>
                  <Avatar src={LogoIcon} square={true} outline={false} className={`w-5 h-5 ${previousCompleted ? 'invisible' : 'visible'}`} />
                  {showCompletedStep(description)}
                </div>
              )
            } else if (funcName && !description) {
              lastCompleted = true;
              if (['list-all-kbs'].includes(funcName)) {
                description = 'Running a knowledgebase tool...';
              } else {
                description = `Running a tool of ${funcName}...`;
              }
              return (
                <div className='p-2 rounded-sm mb-1 mt-1 py-1 flex gap-3' key={message.id}>
                  <Avatar src={LogoIcon} square={true} outline={false} className={`w-5 h-5 ${previousCompleted ? 'invisible' : 'visible'}`} />
                  {showCompletedStep(description)}
                </div>
              )
            }
          } catch (err) {
            console.error("@Description error: ", err);
          }
        }
        // if (message.type === 'tool' && message.content) {
        //   try {
        //     const { rows, sqlQuery } = JSON.parse(message.content);
        //     if (rows && sqlQuery) {
        //       return (
        //         <div className='mb-1 ml-8'>
        //           {showCompletedStep('Executing PostgreSQL query')}
        //         </div>
        //       ) 
        //     }
        //   } catch (err) {

        //   }
        // }

        switch (message.type) {
          case 'user':
          case 'assistant':
            if (!message.content && !shouldShowTools || !message.content) return null;

            return (
              <div
                className='p-2 rounded-sm mb-1 mt-1 py-1 flex gap-3 text-sm font-firstext'
                key={message.id}
              >
                <div>
                  {message.type === 'assistant' && (
                    <Avatar src={LogoIcon} square={true} outline={false} className={`w-5 h-5 ${lastCompleted ? 'invisible' : 'visible'}`} />
                  )}
                  {message.type === 'user' && (
                    <Avatar src={DaveIcon} square={false} outline={false} className={`w-6 h-6`} />
                  )}
                </div>
                {showMessageContent(
                  message as Message,
                  !!receiving,
                  sameSourceAsPrevious
                )}

              </div>
            );
          default:
            return null;
        }
      })}
    </div>
  );
};

export default Messages;