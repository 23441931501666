import React from 'react';
import SvgIcon, { SvgIconProps } from '../SvgIcon';

export const TelegramIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props} style={{ fill: 'transparent' }} viewBox="0 0 21 17">
    <path
      d="M1.72617 7.20195C1.72617 7.20195 10.5717 3.57174 13.6395 2.29346C14.8155 1.78219 18.8036 0.14598 18.8036 0.14598C18.8036 0.14598 20.6443 -0.5698 20.4909 1.16859C20.4398 1.88444 20.0307 4.38978 19.6217 7.09969C19.0081 10.9345 18.3434 15.1271 18.3434 15.1271C18.3434 15.1271 18.2412 16.3031 17.372 16.5076C16.5028 16.7122 15.0711 15.7919 14.8155 15.5873C14.6109 15.4339 10.9807 13.133 9.65133 12.0082C9.2934 11.7014 8.88439 11.0879 9.70242 10.372C11.5431 8.68474 13.7417 6.58842 15.0711 5.25905C15.6847 4.64545 16.2982 3.21382 13.7417 4.95221C10.1115 7.45762 6.5324 9.80961 6.5324 9.80961C6.5324 9.80961 5.71429 10.3209 4.18041 9.8607C2.64645 9.40059 0.856899 8.78699 0.856899 8.78699C0.856899 8.78699 -0.370153 8.02005 1.72617 7.20195Z"
      fill="white"
    />
  </SvgIcon>
);
