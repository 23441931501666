import { useEffect, useState } from "react";
import { Textarea } from "../cataylst/textarea";
import { Select } from "../cataylst/select";

interface IMultiTextGroupProps {
  label: string;
  defaultValues?: string[];
  buttonName?: string;
  onChange?: (values: string[]) => void;
  isDropdown?: boolean;
  options?: { value: string; label: string }[];
}

interface ITextField {
  value: string;
  isHover: boolean;
  id: string;
}

export const MultiTextGroup = ({
  label,
  defaultValues,
  buttonName,
  onChange,
  isDropdown = false,
  options = [],
}: IMultiTextGroupProps) => {
  const [texts, setTexts] = useState<ITextField[]>([]);

  useEffect(() => {
    if (defaultValues) {
      setTexts(
        defaultValues.map((value, index) => {
          let id = String(Math.random());
          if (value === texts[index]?.value)
            id = texts[index].id;
          return { value, isHover: false, id };
        })
      );
    }
  }, [defaultValues]);

  const setTextsWithParent = (texts: ITextField[]) => {
    setTexts(texts);
    if (onChange) onChange(texts.map((text) => text.value));
  };

  const onAddText = () => {
    setTextsWithParent([
      ...texts,
      { value: "", isHover: false, id: String(Math.random()) },
    ]);
  };

  const onTextChange = (index: number, value: string) => {
    const newTexts = [...texts];
    newTexts[index] = { ...newTexts[index], value, isHover: false };
    setTextsWithParent(newTexts);
  };

  const onDeleteText = (index: number) => {
    const newTexts = [...texts];
    newTexts.splice(index, 1);
    setTextsWithParent(newTexts);
  };

  const renderInput = (text: ITextField, index: number) => {
    if (isDropdown) {
      return (
        <Select
          value={text.value}
          onChange={(e) => onTextChange(index, e.target.value)}
        >
          {options.map((option) => (
            <option key={option.value}>{option.label}</option>
          ))}
        </Select>
      );
    } else {
      return (
        <Textarea
          resizable={false}
          withItems={null}
          type="text"
          value={text.value}
          onChange={(e) => onTextChange(index, e.target.value)}
          rows={text.value.length / 100 + 1}
        />
      );
    }
  };

  return (
    <div className="flex flex-col gap-2 font-firstext">
      <div className="font-medium">{label}</div>
      {texts.map((text, index) => (
        <div className="w-[125%] flex items-center gap-3" key={text.id}>
          <div className="w-[80%]">{renderInput(text, index)}</div>
          <div
            className="cursor-pointer block"
            onClick={() => onDeleteText(index)}
          >
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.5303 4.53033C12.8232 4.23744 12.8232 3.76256 12.5303 3.46967C12.2374 3.17678 11.7626 3.17678 11.4697 3.46967L8 6.93934L4.53033 3.46967C4.23744 3.17678 3.76256 3.17678 3.46967 3.46967C3.17678 3.76256 3.17678 4.23744 3.46967 4.53033L6.93934 8L3.46967 11.4697C3.17678 11.7626 3.17678 12.2374 3.46967 12.5303C3.76256 12.8232 4.23744 12.8232 4.53033 12.5303L8 9.06066L11.4697 12.5303C11.7626 12.8232 12.2374 12.8232 12.5303 12.5303C12.8232 12.2374 12.8232 11.7626 12.5303 11.4697L9.06066 8L12.5303 4.53033Z"
                fill="black"
                fillOpacity="0.4"
              />
            </svg>
          </div>
        </div>
      ))}
      <div
        className="w-full border border-[#00000022] text-[#00000099] border-dashed rounded-lg px-4 h-[42px] flex gap-1.5 items-center text-sm cursor-pointer"
        onClick={onAddText}
      >
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.75003 3.33331C8.75003 2.9191 8.41424 2.58331 8.00003 2.58331C7.58582 2.58331 7.25003 2.9191 7.25003 3.33331V7.25H3.33337C2.91916 7.25 2.58337 7.58579 2.58337 8C2.58337 8.41421 2.91916 8.75 3.33337 8.75H7.25003V12.6666C7.25003 13.0809 7.58582 13.4166 8.00003 13.4166C8.41424 13.4166 8.75003 13.0809 8.75003 12.6666V8.75H12.6667C13.0809 8.75 13.4167 8.41421 13.4167 8C13.4167 7.58579 13.0809 7.25 12.6667 7.25H8.75003V3.33331Z"
            fill="black"
            fillOpacity="0.6"
          />
        </svg>
        <span>{buttonName || "Add example"}</span>
      </div>
    </div>
  );
};
