import { ButtonHTMLAttributes, FC, PropsWithChildren } from "react";
import { Link } from "react-router-dom";

interface IButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  url?: string;
  className?: string;
};

export const CircularButton: FC<PropsWithChildren<IButtonProps>> = ({ children, url, className, ...props })  => {
  const button = (
    <button className={`${className} border w-[36px] h-[36px] bg-white rounded-full flex items-center justify-center gap-2`} {...props}>
      {children}
    </button>
  );

  if (url) {
    return (
      <Link to={url}>
        {button}
      </Link>
    );
  }

  return <>{button}</>;
}