import React from 'react';
import SvgIcon, { SvgIconProps } from '../SvgIcon';

export const NextCloudIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props} style={{ fill: 'transparent' }} viewBox="0 0 26 12">
    <path
      d="M13.0198 0C10.3248 0 8.04062 1.82702 7.33266 4.30242C6.71738 2.98943 5.38403 2.06957 3.84824 2.06957C1.73617 2.06957 0 3.80574 0 5.91781C0 8.02988 1.73617 9.76684 3.84824 9.76684C5.38403 9.76684 6.71738 8.84642 7.33266 7.5332C8.04062 10.0088 10.3248 11.8364 13.0198 11.8364C15.6949 11.8364 17.9674 10.0364 18.6935 7.58869C19.3202 8.8721 20.637 9.76684 22.151 9.76684C24.263 9.76684 26 8.02988 26 5.91781C26 3.80574 24.263 2.06957 22.151 2.06957C20.637 2.06957 19.3202 2.96376 18.6935 4.24694C17.9674 1.79946 15.6949 0 13.0198 0ZM13.0198 2.25901C15.0542 2.25901 16.6794 3.88341 16.6794 5.91781C16.6794 7.95221 15.0542 9.57741 13.0198 9.57741C10.9854 9.57741 9.36102 7.95221 9.36102 5.91781C9.36102 3.88341 10.9854 2.25901 13.0198 2.25901ZM3.84824 4.32858C4.73956 4.32858 5.43827 5.02649 5.43827 5.91781C5.43827 6.80913 4.73956 7.50784 3.84824 7.50784C2.95693 7.50784 2.259 6.80913 2.259 5.91781C2.259 5.02649 2.95693 4.32858 3.84824 4.32858ZM22.151 4.32858C23.0423 4.32858 23.741 5.02649 23.741 5.91781C23.741 6.80913 23.0423 7.50784 22.151 7.50784C21.2597 7.50784 20.5617 6.80913 20.5617 5.91781C20.5617 5.02649 21.2597 4.32858 22.151 4.32858Z"
      fill="currentColor"
    />
  </SvgIcon>
);
