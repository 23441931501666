import React from 'react';
import SvgIcon, { SvgIconProps } from '../SvgIcon';

export const DiscordIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon
    {...props}
    style={{ ...props.style, fill: 'transparent' }}
    viewBox="0 0 22 17"
  >
    <path
      d="M18.6361 1.39641C17.1907 0.734661 15.6648 0.265193 14.0973 0C13.8828 0.38343 13.6888 0.777928 13.5159 1.18184C11.8463 0.930252 10.1484 0.930252 8.47881 1.18184C8.30587 0.77797 8.1118 0.383476 7.8974 0C6.32897 0.267433 4.80205 0.738015 3.35518 1.39987C0.482757 5.64967 -0.29591 9.79391 0.0934235 13.8793C1.77558 15.1222 3.6584 16.0674 5.66003 16.6739C6.11074 16.0677 6.50956 15.4246 6.85226 14.7514C6.20135 14.5083 5.57311 14.2084 4.9748 13.8551C5.13227 13.7409 5.28627 13.6232 5.43508 13.509C7.17601 14.3277 9.07613 14.7522 11 14.7522C12.9238 14.7522 14.8239 14.3277 16.5648 13.509C16.7154 13.6319 16.8694 13.7495 17.0251 13.8551C16.4257 14.209 15.7963 14.5095 15.1442 14.7532C15.4865 15.426 15.8853 16.0686 16.3364 16.6739C18.3398 16.0698 20.224 15.1251 21.9065 13.881C22.3633 9.14329 21.1261 5.03711 18.6361 1.39641ZM7.34541 11.3668C6.26047 11.3668 5.36414 10.3822 5.36414 9.17097C5.36414 7.95971 6.22932 6.96648 7.34195 6.96648C8.45458 6.96648 9.344 7.95971 9.32496 9.17097C9.30593 10.3822 8.45112 11.3668 7.34541 11.3668ZM14.6545 11.3668C13.5678 11.3668 12.675 10.3822 12.675 9.17097C12.675 7.95971 13.5401 6.96648 14.6545 6.96648C15.7689 6.96648 16.6514 7.95971 16.6323 9.17097C16.6133 10.3822 15.7602 11.3668 14.6545 11.3668Z"
      fill="currentColor"
    />
  </SvgIcon>
);
