import useAuthRedirect from "@/hooks/useAuthRedirect";
import { useEffect } from "react"
import { useNavigate } from "react-router-dom";

export default function HomePage() {
  const navigate = useNavigate();
  const { accessToken } = useAuthRedirect();

  useEffect(() => {
    if (accessToken) {
      navigate('/dashboard');
    }
  }, [accessToken]);

  return (
    <div>Logging in...</div>
  );
}