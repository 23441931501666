import React from 'react';
import SvgIcon, { SvgIconProps } from '../SvgIcon';

export const MindeeIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props} style={{ fill: 'transparent' }} viewBox="0 0 18 18">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.57146 2.56541V7.69622V15.3924H7.71434V17.9577H0V7.69622V0H7.71434V2.56541H2.57146ZM10.2858 2.56545V4.48942e-05H18V7.69627V17.9577H10.2858V15.3923H15.4285V7.69627V2.56545H10.2858ZM5.14273 7.69633H12.8571V5.13092H5.14273V7.69633ZM7.71434 12.827H5.14288V10.2614H7.71434V12.827Z"
      fill="currentColor"
    />
  </SvgIcon>
);
