import React from 'react';
import SvgIcon, { SvgIconProps } from '../SvgIcon';

export const FreshworksCrmIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props} style={{ fill: 'transparent' }} viewBox="0 0 20 20">
    <path
      d="M0.117188 11.4815C0.448573 13.6453 1.46222 15.5946 2.92422 17.0566L6.33553 13.6453C6.33553 10.195 8.12891 6.84214 11.3258 4.99028L0.117188 11.4815Z"
      fill="#9B65C3"
    />
    <path
      d="M6.33553 13.6453C4.63962 13.6453 2.92422 13.2164 1.34526 12.3002C0.916411 12.0468 0.507053 11.7739 0.117188 11.462C0.448573 13.6258 1.46222 15.5751 2.92422 17.0371L6.33553 13.6453Z"
      fill="#9B65C3"
    />
    <path
      d="M6.33515 13.6453L2.92383 17.0566C4.40532 18.5381 6.33515 19.5517 8.4989 19.8636L14.9707 8.65501C13.1383 11.8519 9.78545 13.6453 6.33515 13.6453Z"
      fill="#BE63C5"
    />
    <path
      d="M7.68018 18.655C6.764 17.0761 6.33515 15.3607 6.33515 13.6648L2.92383 17.0761C4.40532 18.5576 6.33515 19.5712 8.4989 19.8831C8.2065 19.4933 7.93359 19.0839 7.68018 18.655Z"
      fill="#BF63C6"
    />
    <path
      d="M19.0449 0.545822L11.3451 5.00978C8.14822 6.86163 4.34703 6.72518 1.36457 5.00978C0.506862 6.47177 0.0195312 8.18718 0.0195312 10C0.0195312 10.5069 0.0585175 10.9942 0.13649 11.4815C0.526356 11.7934 0.935714 12.0663 1.36457 12.3197C2.94352 13.2359 4.65893 13.6648 6.35484 13.6648L19.2594 0.760249C19.1814 0.682276 19.1229 0.604302 19.0449 0.545822Z"
      fill="#3278B1"
    />
    <path
      d="M1.34522 12.3198C2.92418 13.2359 4.63958 13.6648 6.3355 13.6648C6.3355 10.2145 8.12887 6.86164 11.3258 5.00978L0.0976562 11.4815C0.507014 11.7739 0.916373 12.0663 1.34522 12.3198Z"
      fill="#3278B2"
    />
    <path
      d="M1.34503 5.00978C0.487331 6.47177 0 8.18718 0 10.0001C0 10.5069 0.0389866 10.9942 0.11696 11.4815L11.3451 5.00978C8.12869 6.84215 4.3275 6.72519 1.34503 5.00978Z"
      fill="#45A4EC"
    />
    <path
      d="M19.259 0.740747C19.2005 0.682267 19.1226 0.604294 19.0446 0.545815C18.9471 0.467842 18.8497 0.389867 18.7327 0.331388C18.3623 0.116962 17.9335 0 17.4851 0H9.99973C6.29601 0 3.07963 2.00781 1.34473 4.99027C4.32719 6.70568 8.12838 6.84213 11.3253 4.99027C8.12838 6.84213 6.335 10.195 6.335 13.6453C9.78531 13.6453 13.1381 11.8519 14.99 8.65501L19.4345 0.955174C19.376 0.877201 19.3175 0.799227 19.259 0.740747Z"
      fill="#19BB7D"
    />
    <path
      d="M11.3448 5.00977L19.0446 0.565314C18.9471 0.487341 18.8497 0.409367 18.7327 0.350887L1.34473 5.00977C4.32719 6.72518 8.12838 6.84214 11.3448 5.00977Z"
      fill="#08C7FB"
    />
    <path
      d="M17.4851 0H9.99973C6.29601 0 3.07963 2.00781 1.34473 4.99027L18.7327 0.331388C18.3623 0.136455 17.953 0 17.4851 0Z"
      fill="#59F2F6"
    />
    <path
      d="M14.99 8.65501L19.4344 0.95518C19.3759 0.877207 19.3175 0.818726 19.2395 0.740753L6.33496 13.6453C6.33496 15.3412 6.76381 17.0566 7.68 18.6356C7.93341 19.0644 8.20631 19.4738 8.51821 19.8636C9.00554 19.9416 9.49287 19.9806 9.99969 19.9806C11.8126 19.9806 13.528 19.4933 14.99 18.6356C13.2746 15.6531 13.1381 11.8519 14.99 8.65501Z"
      fill="#DA3757"
    />
    <path
      d="M6.33496 13.6453C6.33496 15.3412 6.76381 17.0566 7.68 18.6356C7.93341 19.0644 8.20631 19.4738 8.51821 19.8636L14.99 8.65501C13.1381 11.8519 9.78527 13.6453 6.33496 13.6453Z"
      fill="#D33C4E"
    />
    <path
      d="M14.9903 8.65501L8.51855 19.8831C9.00589 19.9611 9.49322 20.0001 10 20.0001C11.8129 20.0001 13.5283 19.5127 14.9903 18.655C13.2749 15.6531 13.1385 11.8519 14.9903 8.65501Z"
      fill="#EE5A24"
    />
    <path
      d="M19.6489 1.26707C19.5904 1.15011 19.5124 1.05265 19.4345 0.955182C19.376 0.877209 19.3175 0.818728 19.2395 0.740755C19.1811 0.682275 19.1031 0.604302 19.0251 0.545822L11.3448 5.00978C8.14787 6.86163 6.35449 10.2145 6.35449 13.6648C9.8048 13.6648 13.1576 11.8714 15.0095 8.6745C13.1576 11.8714 13.2941 15.6726 15.0095 18.6551C17.992 16.9202 19.9998 13.7038 19.9998 10V2.51464C19.9803 2.0468 19.8633 1.61795 19.6489 1.26707Z"
      fill="#8BDF55"
    />
    <path
      d="M19.0446 0.545822L11.3448 5.00978C8.14787 6.86163 6.35449 10.2145 6.35449 13.6648L19.259 0.760249C19.1811 0.682276 19.1226 0.604302 19.0446 0.545822Z"
      fill="#25C16F"
    />
    <path
      d="M6.33496 13.6453C9.78527 13.6453 13.1381 11.8519 14.99 8.65501L19.4344 0.95518C19.3759 0.877207 19.3175 0.818726 19.2395 0.740753L6.33496 13.6453Z"
      fill="#8BDF55"
    />
    <path
      d="M19.435 0.955173L14.9906 8.65501C13.1387 11.8519 13.2752 15.6531 14.9906 18.6355L19.6494 1.24757C19.591 1.15011 19.513 1.05264 19.435 0.955173Z"
      fill="#FFBB00"
    />
    <path
      d="M14.9902 18.655C17.9727 16.9201 19.9805 13.7038 19.9805 10V2.51464C19.9805 2.06629 19.8635 1.63743 19.6491 1.26706L14.9902 18.655Z"
      fill="#FFA800"
    />
  </SvgIcon>
);
