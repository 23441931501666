import { Textarea } from "@/components/cataylst/textarea";
import MainSidebar from "@/components/custom/sidebar";

const mockKnowledgeData = [
  {
    id: "1",
    name: "Brand Name",
    type: "text",
    value: `Bloom ARS`,
  },

  {
    id: "2",
    name: "Brand Mission Statement",
    type: "text",
    value: `To create autonomous revenue systems that empower businesses to achieve growth, create jobs, and drive economic prosperity through AI and automation.`,
  },

  {
    id: "3",
    name: "Brand Vision",
    type: "text",
    value: `We envision a world where AI-driven automation revolutionizes revenue production and customer experiences, enabling businesses to connect and scale efficiently.`,
  },

  {
    id: "4",
    name: "Brand Guidelines Document",
    type: "file",
    value: `Upload documents`,
  },

  {
    id: "5",
    name: "Tone of Voice",
    type: "text",
    value: `Professional, Direct, and Insightful`,
  },

  {
    id: "6",
    name: "Voice Consistency",
    type: "text",
    value: `Adaptable based on audience (formal for executives, conversational for operational teams)`,
  },

  {
    id: "7",
    name: "Sample Responses",
    type: "text",
    value: `"Thank you for your interest in Bloom ARS. Our solutions can help drive measurable outcomes for your business through autonomous revenue systems."`,
  },

  {
    id: "8",
    name: "Avoid Phrases/Words",
    type: "text",
    value: `Avoid using: 'cheap,' 'risky,' 'uncertain.'`,
  },

  {
    id: "9",
    name: "Primary Value Proposition",
    type: "text",
    value: `We provide AI-powered Autonomous Revenue Systems (ARS) that enable GTM teams to automate and optimize revenue generation through real-time intelligence and event-driven actions.`,
  },

  {
    id: "10",
    name: "Secondary Value Propositions",
    type: "text",
    value: `Reduced customer acquisition costs (CAC), scalable growth, enhanced customer engagement, and increased operational efficiency.`,
  },

  {
    id: "11",
    name: "Differentiators",
    type: "text",
    value: `Event-driven automation, Generative AI integration, Unified customer data, Real-time engagement and strategic actions.`,
  },

  {
    id: "12",
    name: "Customer Pain Points",
    type: "text",
    value: `High customer acquisition costs, inefficient revenue processes, fragmented sales/marketing operations, and limited use of real-time data.`,
  },

  {
    id: "13",
    name: "Current Challenges in Market",
    type: "text",
    value: `Rising competition, increasing CAC, siloed revenue operations, and the growing need for AI-powered automation.`,
  },

  {
    id: "14",
    name: "Primary Solution",
    type: "text",
    value: `Bloom ARS unifies intelligence across sales and marketing channels, integrates Generative AI for real-time customer engagement, and automates strategic actions to drive revenue growth.`,
  },

  {
    id: "15",
    name: "Solution Benefits",
    type: "text",
    value: `Unified revenue systems, automation of manual tasks, personalized customer interactions, and real-time decision-making.`,
  },

  {
    id: "16",
    name: "Example Use Cases",
    type: "text",
    value: `Automating customer follow-ups, real-time content generation, lead scoring and qualification, event-triggered sales actions.`,
  },

  {
    id: "17",
    name: "Product/Service List",
    type: "text",
    value: `1. Autonomous Revenue System (ARS) Platform, 2. AI-driven Sales Process Automation, 3. Real-time Audience Management`,
  },

  {
    id: "18",
    name: "Product Descriptions",
    type: "text",
    value: `ARS: Automates revenue generation by leveraging AI, LLMs, and event-driven workflows for GTM teams.`,
  },

  {
    id: "19",
    name: "Target Audience",
    type: "text",
    value: `Mid-market and enterprise B2B GTM teams, focusing on post-series A SaaS companies.`,
  },

  {
    id: "20",
    name: "Product Features and Benefits",
    type: "text",
    value: `Event-driven automation, real-time customer data enrichment, Generative AI for content and messaging, cross-channel orchestration.`,
  },

  {
    id: "21",
    name: "Product Documentation",
    type: "text",
    value: `[Uploadable Documents]`,
  },

  {
    id: "22",
    name: "Integration Capabilities",
    type: "text",
    value: `Integrates with CRM, ERP, and marketing platforms, including Salesforce, HubSpot, and cloud data warehouses.`,
  },

  {
    id: "23",
    name: "Sales Stages Definition",
    type: "text",
    value: `Prospect, Qualify, Discover, Develop, Propose, Negotiate, Verbal, Review, Won, Lost, Implement, Success`,
  },

  {
    id: "24",
    name: "Exit Criteria for Each Stage",
    type: "text",
    value: `Based on lead qualification metrics, deal milestones, and customer readiness.`,
  },

  {
    id: "25",
    name: "Seller Actions",
    type: "text",
    value: `Outreach, discovery calls, demo presentations, proposal development, negotiation, contract review.`,
  },

  {
    id: "26",
    name: "Key Metrics",
    type: "text",
    value: `Lead-to-opportunity conversion rate, average deal size, sales cycle length, win rate.`,
  },

  {
    id: "27",
    name: "Success Stories by Stage",
    type: "text",
    value: `Case studies of successful client implementations at each sales stage.`,
  },

  {
    id: "28",
    name: "Journey Stage Definitions",
    type: "text",
    value: `Awareness, Consideration, Evaluation, Decision, Purchase, Retention`,
  },

  {
    id: "29",
    name: "Customer Pain Points by Stage",
    type: "text",
    value: `Lack of knowledge at Awareness stage, unclear ROI at Consideration stage, decision delays during Evaluation stage.`,
  },

  {
    id: "30",
    name: "Engagement Tactics by Stage",
    type: "text",
    value: `Educational content during Awareness, demos and case studies in Evaluation, personalized proposals during Decision.`,
  },

  {
    id: "31",
    name: "Customer Expectations",
    type: "text",
    value: `Prompt follow-up, clear product benefits, real-time engagement, and proactive communication.`,
  },

  {
    id: "32",
    name: "Touchpoints",
    type: "text",
    value: `Website, email, sales calls, CRM, marketing automation tools.`,
  },

  {
    id: "33",
    name: "Top Competitors",
    type: "text",
    value: `Salesforce, HubSpot, Gainsight, Oracle, Zendesk`,
  },

  {
    id: "34",
    name: "Competitor Value Propositions",
    type: "text",
    value: `CRM systems, sales process automation, customer success platforms.`,
  },

  {
    id: "35",
    name: "How We Differentiate",
    type: "text",
    value: `Event-driven workflows, unified customer intelligence, real-time Generative AI-powered actions, dynamic segmentation.`,
  },

  {
    id: "36",
    name: "Ideal Customer Profile (ICP)",
    type: "text",
    value: `Post-Series A SaaS companies with 5+ sales reps and over $5M ARR.`,
  },

  {
    id: "37",
    name: "Buyer Persona (BP)",
    type: "text",
    value: `CROs, VPs of Sales, VPs of Marketing, RevOps leaders.`,
  },

  {
    id: "38",
    name: "Pain Points per Persona",
    type: "text",
    value: `Misalignment between sales/marketing, manual processes, poor customer engagement, delayed decision-making.`,
  },

  {
    id: "39",
    name: "Formality Levels",
    type: "text",
    value: `Formal for C-Suite, conversational for sales and operations.`,
  },

  {
    id: "40",
    name: "Persona-Specific Messaging",
    type: "text",
    value: `Targeted messaging for CROs: "Achieve your revenue goals faster with ARS's automation and real-time intelligence."`,
  },

  {
    id: "41",
    name: "Globalization Settings",
    type: "text",
    value: `Supports US and UK English, and regional adaptations for language and tone.`,
  },

  {
    id: "42",
    name: "Case Studies",
    type: "text",
    value: `Example: SaaS company scaled 20% revenue growth in 6 months using Bloom ARS.`,
  },

  {
    id: "43",
    name: "Quotes and Testimonials",
    type: "text",
    value: `"Bloom ARS helped us increase deal velocity by 30% with real-time insights and automation."`,
  },

  {
    id: "44",
    name: "Key Results",
    type: "text",
    value: `Increased sales pipeline, reduced CAC, improved customer engagement.`,
  },

  {
    id: "45",
    name: "Legal Messaging",
    type: "text",
    value: `"Complies with GDPR, HIPAA, and global data privacy regulations."`,
  },

  {
    id: "46",
    name: "Compliance Requirements",
    type: "text",
    value: `Mandatory consent for data collection, compliance with international data protection laws.`,
  },

  {
    id: "47",
    name: "Preferred Keywords",
    type: "text",
    value: `Autonomous Revenue Systems, AI-powered, Real-time engagement, Event-driven automation`,
  },

  {
    id: "48",
    name: "Prohibited Terms",
    type: "text",
    value: `Avoid terms like 'manual,' 'non-scalable,' 'fragmented.'`,
  },

  {
    id: "49",
    name: "Technical Jargon",
    type: "text",
    value: `"Generative AI," "LLMs," "Event-driven automation," "Customer intelligence."`,
  },

  {
    id: "50",
    name: "Email Templates",
    type: "text",
    value: `Cold outreach, follow-up emails, proposal confirmation.`,
  },

  {
    id: "51",
    name: "Meeting Invitation Templates",
    type: "text",
    value: `Scheduling leadership workshop, demo session invites.`,
  },

  {
    id: "52",
    name: "Proposal Templates",
    type: "text",
    value: `Standard proposal format aligned with Bloom ARS's services and benefits.`,
  },
];

export default function DemoKnowledgebasePage() {
  return (
    <main>
      <MainSidebar>
        <div className="text-cell px-8 py-5">
          {mockKnowledgeData.map((field) => (
            <div className="flex w-full gap-12 font-firstext border-b border-gray-300 pt-4 pb-6">
              <div className="flex w-1/2 flex-col gap-3">
                <div className="font-medium">{field.name}</div>
              </div>
              <div className="flex-1">
                {field.type === "file" && (
                  <div className="max-w-80">
                    <label
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      htmlFor="file_input"
                    >
                      {field.value}
                    </label>
                    <input
                      className="block w-full text-sm leading-8 text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                      id="file_input"
                      type="file"
                      multiple
                    />
                  </div>
                )}
                {field.type === "text" && (
                  <Textarea
                    name={field.name}
                    resizable={field.value.length >= 30}
                    rows={field.value.length < 30 ? 1 : 3}
                    withItems={null}
                    type="text"
                    value={field.value}
                  />
                )}
              </div>
            </div>
          ))}
        </div>
      </MainSidebar>
    </main>
  );
}
