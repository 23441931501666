import React from 'react';
import SvgIcon, { SvgIconProps } from '../SvgIcon';

export const RabbitMQIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props} style={{ fill: 'transparent' }} viewBox="0 0 18 18">
    <path
      d="M16.7987 7.19233H11.1482C11.012 7.19228 10.8814 7.13815 10.7851 7.04185C10.6888 6.94555 10.6347 6.81494 10.6346 6.67874V0.789836C10.6346 0.353746 10.281 0 9.84509 0H7.828C7.39184 0 7.03843 0.353746 7.03843 0.789836V6.64308C7.03843 6.94676 6.79292 7.19359 6.48925 7.19492L4.63751 7.20375C4.33124 7.20541 4.08242 6.95692 4.08302 6.65092L4.0945 0.791164C4.0955 0.35441 3.74175 0 3.305 0H1.28957C0.853348 0 0.5 0.353746 0.5 0.789836V17.2796C0.5 17.667 0.813836 17.9808 1.20092 17.9808H16.7987C17.1862 17.9808 17.5 17.667 17.5 17.2796V7.89358C17.5 7.50616 17.1862 7.19233 16.7987 7.19233ZM14.149 13.3836C14.149 13.8913 13.7374 14.3029 13.2297 14.3029H11.6357C11.1279 14.3029 10.7163 13.8913 10.7163 13.3836V11.7895C10.7163 11.2818 11.1279 10.8702 11.6357 10.8702H13.2297C13.7374 10.8702 14.149 11.2818 14.149 11.7895V13.3836Z"
      fill="currentColor"
    />
  </SvgIcon>
);
