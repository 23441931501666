import React from 'react';
import SvgIcon, { SvgIconProps } from '../SvgIcon';

export const YourAppIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props} style={{ fill: 'transparent' }} viewBox="0 0 24 24">
    <path
      d="M20 8.03758H14C13.4477 8.03758 13 8.4874 13 9.04228V19.0893C13 19.6441 13.4477 20.094 14 20.094H20C20.5523 20.094 21 19.6441 21 19.0893V9.04228C21 8.4874 20.5523 8.03758 20 8.03758Z"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18 8.03757V5.02348C18 4.75702 17.8946 4.50147 17.7071 4.31305C17.5196 4.12464 17.2652 4.01878 17 4.01878H4C3.73478 4.01878 3.48043 4.12464 3.29289 4.31305C3.10536 4.50147 3 4.75702 3 5.02348V17.0798C3 17.3463 3.10536 17.6019 3.29289 17.7903C3.48043 17.9787 3.73478 18.0845 4 18.0845H13"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16 9.04228H18"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
