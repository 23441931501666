import React from 'react';
import SvgIcon, { SvgIconProps } from '../SvgIcon';

export const AutopilotIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon
    {...props}
    style={{ ...props.style, fill: 'transparent' }}
    viewBox="0 0 22 11"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22 0H0L2.64815 4.07407H19.3519L22 0ZM19.1481 6.92593H2.85185L5.5 11H16.5L19.1481 6.92593Z"
      fill="currentColor"
    />
  </SvgIcon>
);
