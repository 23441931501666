import React from 'react';
import SvgIcon, { SvgIconProps } from '../SvgIcon';

export const MondayComIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props} style={{ fill: 'transparent' }} viewBox="0 0 22 14">
    <path
      d="M10.9605 13.044C9.99164 13.0203 9.1923 12.5929 8.6819 11.7006C8.15873 10.7782 8.18152 9.83393 8.73932 8.93434C10.0673 6.79337 11.4126 4.66425 12.7497 2.52966C13.0231 2.09308 13.2901 1.65012 13.5763 1.22174C13.9563 0.635831 14.5499 0.221376 15.2308 0.0666303C15.9118 -0.0881153 16.6263 0.0290739 17.2221 0.393244C18.4808 1.16888 18.9019 2.78213 18.118 4.05358C16.5157 6.66334 14.8906 9.26033 13.2427 11.8446C12.7351 12.6439 11.9649 13.0294 10.9605 13.044Z"
      fill="#FFCB00"
    />
    <path
      d="M2.6392 13.044C1.66123 13.0203 0.866454 12.5737 0.366074 11.6851C-0.149801 10.7627 -0.116989 9.81661 0.442635 8.91702C1.87116 6.62505 3.30607 4.33643 4.74736 2.05114C4.92965 1.75584 5.11194 1.45324 5.30699 1.16705C5.7025 0.593856 6.30557 0.197091 6.9885 0.0607574C7.67143 -0.075576 8.38061 0.059225 8.96589 0.43662C9.55118 0.814015 9.96661 1.40438 10.1242 2.08271C10.2819 2.76104 10.1693 3.47409 9.81041 4.07089C8.21356 6.64935 6.59485 9.2187 4.97522 11.7853C4.44932 12.6175 3.67551 13.0294 2.6392 13.044Z"
      fill="#FF3D57"
    />
    <path
      d="M19.3359 7.8178C20.8097 7.82509 22.0028 8.99629 22.0001 10.4327C22.0001 11.891 20.7751 13.0668 19.2876 13.0486C17.8002 13.0303 16.6281 11.8719 16.6299 10.4291C16.6317 8.9653 17.8293 7.80869 19.3359 7.8178Z"
      fill="#00D647"
    />
  </SvgIcon>
);
