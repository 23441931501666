import React from 'react';
import SvgIcon, { SvgIconProps } from '../SvgIcon';

export const InfoIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props} style={{ ...props.style, fill: 'transparent' }}>
    <path
      d="M12.75 11.554C12.75 11.1398 12.4142 10.804 12 10.804C11.5858 10.804 11.25 11.1398 11.25 11.554H12.75ZM11.25 15.5728C11.25 15.987 11.5858 16.3228 12 16.3228C12.4142 16.3228 12.75 15.987 12.75 15.5728H11.25ZM20.25 12.0563C20.25 16.6393 16.553 20.3486 12 20.3486V21.8486C17.3881 21.8486 21.75 17.4611 21.75 12.0563H20.25ZM12 20.3486C7.44696 20.3486 3.75 16.6393 3.75 12.0563H2.25C2.25 17.4611 6.61191 21.8486 12 21.8486V20.3486ZM3.75 12.0563C3.75 7.4733 7.44696 3.76404 12 3.76404V2.26404C6.61191 2.26404 2.25 6.65151 2.25 12.0563H3.75ZM12 3.76404C16.553 3.76404 20.25 7.4733 20.25 12.0563H21.75C21.75 6.65151 17.3881 2.26404 12 2.26404V3.76404ZM11.25 11.554V15.5728H12.75V11.554H11.25ZM12.25 7.78992H11.75V9.28992H12.25V7.78992ZM12.75 8.78992V8.28992H11.25V8.78992H12.75ZM12.5 8.53992C12.5 8.67221 12.4476 8.7939 12.364 8.88275L11.272 7.85438C11.104 8.03273 11 8.275 11 8.53992H12.5ZM12.364 8.88275C12.2737 8.97855 12.1434 9.03992 12 9.03992V7.53992C11.7131 7.53992 11.4534 7.66175 11.272 7.85438L12.364 8.88275ZM11.5 8.53992C11.5 8.41187 11.5491 8.29351 11.6283 8.20545L12.7435 9.20869C12.9024 9.03206 13 8.7965 13 8.53992H11.5ZM11.6283 8.20545C11.7188 8.1049 11.8523 8.03992 12 8.03992V9.53992C12.2953 9.53992 12.5615 9.41098 12.7435 9.20869L11.6283 8.20545ZM11.3102 8.92051L11.6781 9.25902L12.6937 8.15513L12.3258 7.81662L11.3102 8.92051ZM12 9.03992C11.8566 9.03992 11.7263 8.97855 11.636 8.88275L12.728 7.85438C12.5466 7.66175 12.2869 7.53992 12 7.53992V9.03992ZM11.636 8.88275C11.5524 8.7939 11.5 8.67221 11.5 8.53992H13C13 8.275 12.896 8.03273 12.728 7.85438L11.636 8.88275ZM12 8.03992C12.1434 8.03992 12.2737 8.1013 12.364 8.1971L11.272 9.22547C11.4534 9.41809 11.7131 9.53992 12 9.53992V8.03992ZM12.364 8.1971C12.4476 8.28595 12.5 8.40764 12.5 8.53992H11C11 8.80485 11.104 9.04712 11.272 9.22547L12.364 8.1971ZM11.668 7.82247L11.3039 8.16519L12.332 9.25738L12.6961 8.91466L11.668 7.82247Z"
      fill="currentColor"
    />
  </SvgIcon>
);
