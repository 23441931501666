import React from 'react';
import SvgIcon, { SvgIconProps } from '../SvgIcon';

export const MongoDBIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props} style={{ fill: 'transparent' }} viewBox="0 0 12 25">
    <path
      d="M8.09909 3.17708C7.08946 1.98565 6.21955 0.767161 6.04291 0.517118C6.03925 0.511836 6.03436 0.507519 6.02867 0.504538C6.02298 0.501557 6.01665 0.499999 6.01022 0.5C6.00379 0.500001 5.99746 0.501559 5.99177 0.504541C5.98608 0.507523 5.98119 0.51184 5.97753 0.517123C5.80092 0.767172 4.93122 1.98566 3.92162 3.17708C-4.73182 14.2012 5.28742 21.639 5.28742 21.639L5.36864 21.6954C5.4446 22.8462 5.63332 24.5 5.63332 24.5H6.38735C6.38735 24.5 6.57526 22.8533 6.65155 21.7029L6.73328 21.639C6.73328 21.639 16.7524 14.2012 8.09909 3.17708ZM6.01037 21.4774H6.01028C6.01028 21.4774 5.56114 21.0942 5.4399 20.9023L5.43881 20.8816L5.9821 8.87953C5.98243 8.87226 5.98554 8.8654 5.9908 8.86037C5.99606 8.85534 6.00306 8.85254 6.01034 8.85254C6.01761 8.85254 6.02461 8.85534 6.02987 8.86037C6.03513 8.8654 6.03825 8.87226 6.03858 8.87953L6.58187 20.8816L6.58081 20.9022C6.45966 21.0941 6.01037 21.4774 6.01037 21.4774Z"
      fill="currentColor"
    />
  </SvgIcon>
);
