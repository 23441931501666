import { useCallback, useState, useContext, ReactNode } from 'react';
import Ajv from 'ajv';
import { JSONSchema, JSONSchemaArray, JSONSchemaObject, JSONSchemaType, JSONSchemaTypeName, Maybe } from '@makeropsinc/workflow-types';
import { capitalize } from '../utils';
import { context } from '../form-from-schema';
import { TextField, TextFieldProps } from '../text-field';
import { TextInputProps } from '../types';
import { StaticLabel } from '../static-label';
import { isJSONPathOrIntrinsicFunction } from '../json-schemas';

export type JSONChangeEvent = {
  target: {
    value: JSONSchemaType;
  };
};

interface Props extends TextFieldProps {
  path: string;
  type: JSONSchemaTypeName;
  onChange: (arg0: JSONChangeEvent) => void;
  value: JSONSchemaType;
  schema: JSONSchema;
  required?: boolean;
  noSelects: boolean;
  label?: ReactNode;
}

const VALID_INPUT_TYPES = [
  'text',
  'password',
  'email',
  'color',
  'date',
  'datetime-local',
  'month',
  'number',
  'time',
  'url',
  'week',
];

const getTextInputProps = (
  schema: JSONSchema,
  opts: { noSelects?: boolean } = {}
): TextFieldProps => {
  if (!opts.noSelects && schema.enum && schema.enum instanceof Array) {
    return { select: true };
  }

  if (schema.input?.type && VALID_INPUT_TYPES.includes(schema.input.type)) {
    return { type: schema.input.type as string };
  }

  switch (schema.type) {
    case 'number':
      return {
        inputProps: {
          inputMode: 'numeric',
          pattern: '[0-9\\.]*',
        },
      };
    case 'integer':
      return {
        inputProps: {
          inputMode: 'numeric',
          pattern: '[0-9]*',
        },
      };
    case 'boolean':
      return opts.noSelects ? {} : { select: true };

    default:
      return {};
  }
};

const getTextInputChildren = (
  schema: JSONSchema,
  opts: { noSelects?: boolean } = {}
) => {
  const hasChildren =
    !opts.noSelects &&
    (schema.type === 'boolean' ||
      (schema.enum && schema.enum instanceof Array));

  if (!hasChildren) return;

  const options = (schema.enum as string[]) || ['true', 'false'];

  return options.map((opt) => (
    <option key={opt} value={opt}>
      {capitalize(opt)}
    </option>
  ));
};

const ajv = new Ajv({ strict: false });

const tryParseJSON = (json: string): JSONSchemaObject | JSONSchemaArray => {
  let result: any = undefined;
  try {
    result = JSON.parse(json);
  } catch {
    // do nothing
  }

  return result;
};

export const Input: React.FC<Props> = ({
  path,
  type,
  label,
  onChange,
  value,
  schema,
  required,
  noSelects,
  ...rest
}) => {
  const {
    getErrors,
    renderTextField = ({ label, ...otherProps }: TextInputProps) => (
      <StaticLabel content={label} infoText={schema.description}>
        <TextField fullWidth {...otherProps} />
      </StaticLabel>
    ),
  } = useContext(context);

  const formErrorMessages = getErrors(path);

  const [errorMessage, setErrorMessage] = useState(undefined as Maybe<string>);

  const validate = useCallback(
    (val: JSONSchemaType) => {
      let newErrorMessage = '';
      if (val === null || val === undefined || val === '') {
        newErrorMessage = required ? 'Required field' : '';
      } else if (isJSONPathOrIntrinsicFunction(val as string, false)) {
        newErrorMessage = '';
      } else if (!ajv.validate(schema, val)) {
        newErrorMessage = schema.errorMessage ?? ajv.errorsText(ajv.errors);
      }

      setErrorMessage(newErrorMessage);
    },
    [schema, required]
  );

  const parse = useCallback(
    (value: string) => {
      if (!value) return null;
      if (isJSONPathOrIntrinsicFunction(value, false)) return value; // allow JSON paths

      switch (type) {
        case 'boolean':
          const res = ['true', 'false'].includes(value)
            ? value === 'true'
            : value;
          return res;
        case 'number':
          return Number(value) || 0;
        case 'integer':
          return Number.parseInt(value) || 0;
        case 'object':
        case 'array':
          return tryParseJSON(value) || value;
        default:
          return value;
      }
    },
    [type]
  );

  const handleChange = useCallback(
    (e: { target: { value: string } }) => {
      if (!onChange) return;

      const { value } = e.target;
      const newValue = parse(value);
      if (errorMessage) validate(newValue);
      onChange({ ...e, target: { ...e.target, value: newValue } });
    },
    [onChange, parse, errorMessage, validate]
  );

  const handleBlur = useCallback(() => validate(value), [value, validate]);

  const finalErrorMessage =
    errorMessage ?? formErrorMessages.map((e) => e.message).join('\n');

  const valueToString = () => {
    switch (schema.type) {
      case 'object':
      case 'array':
        const asStr =
          typeof value === 'object'
            ? JSON.stringify(value)
            : (value ?? '').toString();
        return asStr;
      default:
        return (value ?? '').toString();
    }
  };

  const placeholder = required
    ? `*required (${type ?? 'string'})`
    : type ?? 'string';

  return (
    <>
      {renderTextField({
        ...getTextInputProps(schema, { noSelects }),
        ...rest,
        schema,
        label,
        onChange: handleChange,
        value: valueToString(),
        error: !!finalErrorMessage,
        helperText: finalErrorMessage,
        onBlur: handleBlur,
        required,
        placeholder,
        children: <>{getTextInputChildren(schema, { noSelects })}</>,
      })}
    </>
  );
};
