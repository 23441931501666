import React from 'react';
import SvgIcon, { SvgIconProps } from '../SvgIcon';

export const ZoomIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props} style={{ fill: 'transparent' }} viewBox="10 10 20 20">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20 0H0V20V40H20H40V20V0H20Z"
      fill="white"
    />
    <path
      d="M27.3553 27.7419H14.883C14.4751 27.7477 14.0735 27.6418 13.7214 27.4357C13.3694 27.2297 13.0803 26.9313 12.8856 26.5729C12.675 26.1634 12.5998 25.6977 12.6708 25.2428C12.7417 24.7879 12.9552 24.3672 13.2804 24.0413L21.9359 15.3548H15.7424C14.921 15.3548 14.1334 15.0286 13.5526 14.4478C12.9719 13.8671 12.6456 13.0794 12.6456 12.2581H24.1424C24.5502 12.2523 24.9519 12.3582 25.304 12.5643C25.656 12.7703 25.945 13.0687 26.1398 13.4271C26.3503 13.8366 26.4256 14.3023 26.3546 14.7572C26.2836 15.2121 26.0702 15.6328 25.7449 15.9587L17.0585 24.6452H24.2585C25.0798 24.6452 25.8675 24.9714 26.4482 25.5522C27.029 26.1329 27.3553 26.9206 27.3553 27.7419Z"
      fill="#0B5CFF"
    />
  </SvgIcon>
);
