import React from 'react';
import SvgIcon, { SvgIconProps } from '../SvgIcon';

export const SentryIoIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props} style={{ fill: 'transparent' }} viewBox="0 0 20 18">
    <path
      d="M11.6382 0.927528C11.1299 0.0446704 10.0062 -0.262993 9.12337 0.245319L9.10999 0.258696C8.82908 0.432593 8.60168 0.659996 8.42778 0.940905L5.83271 5.44883L6.51492 5.85013C10.2738 8.00377 12.6682 11.9231 12.8822 16.2438H11.0228C10.7687 12.5919 8.72207 9.31467 5.5518 7.48208L4.8696 7.08078L2.38154 11.2409L3.06375 11.6422C4.6957 12.6053 5.80596 14.264 6.04674 16.1501H1.89999C1.73947 16.1635 1.59232 16.0431 1.56557 15.8826C1.55219 15.8157 1.57895 15.7622 1.61908 15.7087L2.79622 13.6755C2.39492 13.3277 1.94012 13.0601 1.44518 12.8862L0.25466 14.9195C-0.267028 15.8023 0.0406343 16.9394 0.923492 17.461C0.923492 17.461 0.923492 17.461 0.936868 17.461C1.21778 17.6216 1.52544 17.7018 1.84648 17.6884H7.65194V16.8992C7.63856 14.4513 6.46142 12.1505 4.49505 10.6925L5.39128 9.1274C7.9596 10.8931 9.49791 13.8092 9.51129 16.926V17.6751H14.474V16.8858C14.4473 12.0301 11.986 7.49545 7.93284 4.82013L9.7922 1.60974C9.89921 1.46259 10.0865 1.40909 10.247 1.48935C10.3005 1.48935 10.3005 1.54285 10.3674 1.60974L18.487 15.6418C18.5539 15.789 18.5004 15.9495 18.3666 16.0431C18.3131 16.0431 18.2462 16.0966 18.2061 16.0966H16.2264V17.6216H18.1392C19.1558 17.6082 19.9852 16.7788 19.9986 15.7622C20.0119 15.4412 19.9317 15.1335 19.7712 14.866L11.6382 0.927528Z"
      fill="currentColor"
    />
  </SvgIcon>
);
