import useAuthRedirect from "@/hooks/useAuthRedirect";
import { BloomStorage } from "@/utils/storage";
import { useContextStore } from "@/zustand/context";
import { useAuth0 } from "@auth0/auth0-react";
import { useEffect } from "react"
import { useNavigate } from "react-router-dom";

export default function ContextObserver() {
  const { accessToken } = useAuthRedirect();
  const { user, getIdTokenClaims, isAuthenticated, isLoading } = useAuth0();
  const contextStore = useContextStore();
  const navigate = useNavigate();

  useEffect(() => {
    if (accessToken) {
      processContextData();
    }
  }, [accessToken]);

  useEffect(() => {
    if (!isAuthenticated && !isLoading) {
      navigate('/');      
    }
  }, [isAuthenticated, isLoading]);

  const processContextData = async () => {
    // Save the token to LocalStorage
    BloomStorage.saveAccessToken(accessToken);

    const claims = await getIdTokenClaims();
    const orgId = claims?.['https://app.makerops.com/org_id'] ?? '';
    const role =
      claims?.['https://app.makerops.com/roles']?.[0]?.toLowerCase() ?? '';
    const isSocial = Boolean(!claims?.sub.startsWith('auth0|'));
    const roles = {
      [orgId]: role,
    };

    contextStore.setCurrentUser({
      email: user?.email as string,
      id: claims?.sub,
      name: claims?.name as string,
      socialLogin: isSocial,
      picture: claims?.picture,
      iat: claims?.iat
    });
    contextStore.setRoles(roles);
    contextStore.setSelectedOrgId('x-functions');
  };

  return null;
}