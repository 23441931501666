import React from 'react';
import SvgIcon, { SvgIconProps } from '../SvgIcon';

export const BrevoIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon
    {...props}
    style={{ ...props.style, fill: 'transparent' }}
    viewBox="0 0 15 20"
  >
    <path
      d="M2.73065 9.98544V2.60158H7.22073C8.73727 2.60158 9.73905 3.47892 9.73905 4.81014C9.73905 6.32266 8.43476 7.47259 5.76512 8.34991C3.94468 8.92424 3.126 9.40858 2.82218 9.98413L2.73065 9.98503V9.98544ZM2.73065 17.3984V14.3125C2.73065 12.9509 3.88367 11.6197 5.4918 11.1049C6.91814 10.6206 8.10038 10.1363 9.10215 9.62282C10.4369 10.4101 11.2557 11.7705 11.2557 13.193C11.2557 15.6133 8.94959 17.3984 5.82486 17.3984H2.73065ZM0 20H6.06767C10.6798 20 14.1376 17.1258 14.1376 13.3134C14.1376 11.2254 13.0761 9.35025 11.1946 8.13944C12.1659 7.17083 12.621 6.05135 12.621 4.6897C12.621 1.87638 10.5882 0 7.52454 0H0V20Z"
      fill="currentColor"
    />
  </SvgIcon>
);
