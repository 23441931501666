import { IBeefreeSaving, IBeefreeTemplate } from "@/integrations/beefree/beefree.type";
import { apiClient } from "./api";

export const getBeefreeTemplateById = async (templateId?: string) => {
  if (!templateId) {
    const template = {
      aspect: 'template::approve-user-message',
      entity: 'org::x-functions',
      jsonTemplate: '{}',
      lastUpdate: new Date().toString(),
      orgId: 'x-functions',
      template: '',
      type: 'email',
      templateId: ''
    };
    return { data: template };
  }
  return apiClient.get(`/content/orgs/x-functions/templates/${templateId}`);
};

export const saveBeefreeTemplate = (template: IBeefreeTemplate) => {
  window.onBeefreeTemplateSaved(IBeefreeSaving.STARTED);
  return apiClient.post(`content/orgs/x-functions/templates`, template)
    .then(_ => {
      window.onBeefreeTemplateSaved(IBeefreeSaving.COMPLETED);
    })
    .catch(_ => {
      window.onBeefreeTemplateSaved(IBeefreeSaving.ERROR);
    });
};

export const deleteBeefreeTemplate = (templateId: string) => {
  window.onBeefreeTemplateDeleted(IBeefreeSaving.STARTED);
  return apiClient.delete(`content/orgs/x-functions/templates/${templateId}`)
    .then(_ => {
      window.onBeefreeTemplateDeleted(IBeefreeSaving.COMPLETED);
    })
    .catch(_ => {
      window.onBeefreeTemplateDeleted(IBeefreeSaving.ERROR);
    });
};