import React from 'react';
import SvgIcon, { SvgIconProps } from '../SvgIcon';

export const PagerDutyIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props} style={{ fill: 'transparent' }} viewBox="0 0 13 18">
    <path
      d="M2.64343 13.2069V18H0V13.2069H2.64343ZM5.28389 3.75429e-05C7.54893 0.00253234 8.53066 0.13104 9.92571 0.884571C11.4583 1.70743 12.4046 3.34286 12.4046 5.37943C12.4046 7.272 11.6331 8.90743 10.152 9.88457C8.79428 10.7897 7.25143 10.9131 5.19429 10.9131H0V0L5.28389 3.75429e-05ZM5.70734 2.31396L5.62629 2.31429L2.64343 2.33486V8.62971H5.80114C8.01257 8.62971 9.69943 7.74514 9.69943 5.41029C9.69943 3.24 8.34171 2.29371 5.62629 2.31429L5.70734 2.31396Z"
      fill="currentColor"
    />
  </SvgIcon>
);
