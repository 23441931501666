import React from 'react';
import SvgIcon, { SvgIconProps } from '../SvgIcon';

export const TodoistIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props} style={{ fill: 'transparent' }} viewBox="0 0 18 18">
    <path
      d="M6.17853 8.20388L17.8087 1.43236C18.0574 1.27933 18.0765 0.839374 17.7896 0.686346C17.5027 0.533317 16.9862 0.22726 16.7758 0.112488C16.4888 -0.0405405 16.1637 -0.0405451 15.8767 0.131612C15.7428 0.208126 6.44633 5.62152 6.12115 5.79367C5.7577 6.00409 5.27949 6.00409 4.91605 5.79367L0 2.90526V5.35372C1.18597 6.06148 4.17003 7.80218 4.89692 8.22301C5.33688 8.45255 5.7577 8.45255 6.17853 8.20388ZM6.17853 12.833L17.8087 6.06147C18.0574 5.90844 18.0765 5.46849 17.7896 5.31546C17.5027 5.16243 16.9862 4.85637 16.7758 4.7416C16.4888 4.58857 16.1637 4.58857 15.8767 4.76073C15.7428 4.83725 6.44633 10.2506 6.12115 10.4228C5.7577 10.6332 5.27949 10.6332 4.91605 10.4228L0 7.53438V9.98283C1.18597 10.6906 4.17003 12.4313 4.89692 12.8521C5.33688 13.0817 5.7577 13.0817 6.17853 12.833ZM6.17853 17.7682L17.8087 10.9967C18.0574 10.8436 18.0765 10.4037 17.7896 10.2506C17.5027 10.0976 16.9862 9.79155 16.7758 9.67678C16.4888 9.52375 16.1637 9.52375 15.8767 9.6959C15.7428 9.77242 6.44633 15.1858 6.12115 15.358C5.7577 15.5684 5.27949 15.5684 4.91605 15.358L0 12.4696V14.8989C1.18597 15.6066 4.17003 17.3473 4.89692 17.7682C5.33688 17.9977 5.7577 17.9977 6.17853 17.7682Z"
      fill="currentColor"
    />
  </SvgIcon>
);
