import React from 'react';
import SvgIcon, { SvgIconProps } from '../SvgIcon';

export const TwitterIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props} style={{ fill: 'transparent' }} viewBox="0 0 18 18">
    <path
      d="M10.6192 7.7051L16.9485 0.5H15.4489L9.95066 6.7548L5.56245 0.5H0.5L7.1373 9.95918L0.5 17.5142H1.99964L7.80236 10.9074L12.4375 17.5142H17.5L10.6192 7.7051ZM8.56456 10.0422L7.89104 9.09971L2.54044 1.60719H4.8442L9.16356 7.65618L9.83425 8.59869L15.4482 16.461H13.1444L8.56456 10.0422Z"
      fill="currentColor"
    />
  </SvgIcon>
);
