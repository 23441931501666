import React from 'react';
import SvgIcon, { SvgIconProps } from '../SvgIcon';

export const PineconeIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon
    {...props}
    style={{ ...props.style, fill: 'transparent' }}
    viewBox="0 0 26 29"
  >
    <path
      d="M14.72 5.35997L15.42 1.46997"
      stroke="currentColor"
      strokeWidth="1.77"
      strokeLinecap="square"
    />
    <path
      d="M17.9399 3.98L15.4999 1L12.1799 2.94"
      stroke="currentColor"
      strokeWidth="1.77"
      strokeLinecap="square"
      strokeLinejoin="round"
    />
    <path
      d="M11.8 21.96L12.48 18.07"
      stroke="currentColor"
      strokeWidth="1.77"
      strokeLinecap="square"
    />
    <path
      d="M15.02 20.57L12.56 17.6L9.25 19.55"
      stroke="currentColor"
      strokeWidth="1.77"
      strokeLinecap="square"
      strokeLinejoin="round"
    />
    <path
      d="M13.21 13.94L13.89 10.05"
      stroke="currentColor"
      strokeWidth="1.77"
      strokeLinecap="square"
    />
    <path
      d="M16.4299 12.55L13.9799 9.59003L10.6699 11.53"
      stroke="currentColor"
      strokeWidth="1.77"
      strokeLinecap="square"
      strokeLinejoin="round"
    />
    <path
      d="M10.91 28.6C11.8103 28.6 12.54 27.8703 12.54 26.97C12.54 26.0698 11.8103 25.34 10.91 25.34C10.0098 25.34 9.28003 26.0698 9.28003 26.97C9.28003 27.8703 10.0098 28.6 10.91 28.6Z"
      fill="currentColor"
    />
    <path
      d="M6.29005 21.62L3.30005 23.7"
      stroke="currentColor"
      strokeWidth="1.68"
      strokeLinecap="square"
    />
    <path
      d="M6.46994 24.99L2.93994 23.95L3.19994 20.28"
      stroke="currentColor"
      strokeWidth="1.68"
      strokeLinecap="square"
      strokeLinejoin="round"
    />
    <path
      d="M17.1499 23.57L19.2299 26.57"
      stroke="currentColor"
      strokeWidth="1.68"
      strokeLinecap="square"
    />
    <path
      d="M15.8101 26.67L19.4801 26.92L20.5201 23.41"
      stroke="currentColor"
      strokeWidth="1.68"
      strokeLinecap="square"
      strokeLinejoin="round"
    />
    <path
      d="M20.5601 17.48L24.2201 18.14"
      stroke="currentColor"
      strokeWidth="1.72"
      strokeLinecap="square"
    />
    <path
      d="M21.8201 20.69L24.6601 18.22L22.8701 14.93"
      stroke="currentColor"
      strokeWidth="1.72"
      strokeLinecap="square"
      strokeLinejoin="round"
    />
    <path
      d="M19.49 10.22L22.75 8.41998"
      stroke="currentColor"
      strokeWidth="1.72"
      strokeLinecap="square"
    />
    <path
      d="M19.6699 6.77002L23.1399 8.21002L22.4899 11.9"
      stroke="currentColor"
      strokeWidth="1.72"
      strokeLinecap="square"
      strokeLinejoin="round"
    />
    <path
      d="M5.10994 14.76L1.43994 14.12"
      stroke="currentColor"
      strokeWidth="1.72"
      strokeLinecap="square"
    />
    <path
      d="M2.82 17.34L1 14.05L3.81 11.57"
      stroke="currentColor"
      strokeWidth="1.72"
      strokeLinecap="square"
      strokeLinejoin="round"
    />
    <path
      d="M8.58989 8.28999L6.13989 5.48999"
      stroke="currentColor"
      strokeWidth="1.72"
      strokeLinecap="square"
    />
    <path
      d="M9.59994 5L5.84994 5.16L5.18994 8.85"
      stroke="currentColor"
      strokeWidth="1.72"
      strokeLinecap="square"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
