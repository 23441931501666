import React from 'react';
import SvgIcon, { SvgIconProps } from '../SvgIcon';

export const HubspotIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props} style={{ fill: 'transparent' }} viewBox="8 8 25 25">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M20 0H0V20V40H20H40V20V0H20Z"
      fill="#FF5929"
    />
    <path
      d="M24.8206 17.2048V14.9608C25.1172 14.8222 25.3682 14.6022 25.5446 14.3265C25.721 14.0507 25.8155 13.7305 25.817 13.4032V13.3517C25.817 12.3973 25.0433 11.6236 24.089 11.6236H24.0374C23.083 11.6236 22.3093 12.3973 22.3093 13.3517V13.4032C22.3108 13.7305 22.4053 14.0507 22.5817 14.3265C22.7581 14.6022 23.0092 14.8222 23.3057 14.9608V17.2048C22.4534 17.3353 21.6507 17.6887 20.9789 18.2292L14.8246 13.4356C14.8684 13.2772 14.8913 13.1143 14.8939 12.9508C14.8947 12.5656 14.7812 12.1889 14.5678 11.8683C14.3545 11.5477 14.0508 11.2976 13.6952 11.1495C13.3397 11.0015 12.9482 10.9623 12.5704 11.0368C12.1925 11.1113 11.8453 11.2961 11.5725 11.568C11.2997 11.8399 11.1137 12.1866 11.038 12.5642C10.9623 12.9418 11.0003 13.3334 11.1471 13.6894C11.294 14.0454 11.5431 14.3499 11.8631 14.5643C12.183 14.7787 12.5593 14.8934 12.9445 14.8939C13.2814 14.8923 13.612 14.8024 13.9033 14.6331L19.9636 19.3489C18.8494 21.032 18.8792 23.2256 20.0386 24.878L18.1954 26.7217C18.0463 26.6741 17.8912 26.6488 17.7347 26.6467C16.852 26.6474 16.1369 27.3633 16.1371 28.2461C16.1373 29.1287 16.8529 29.8442 17.7356 29.8445C18.6183 29.8447 19.3342 29.1296 19.335 28.2468C19.3329 28.0904 19.3076 27.9352 19.2598 27.7862L21.0833 25.9621C22.7126 27.2164 24.9503 27.3244 26.6929 26.233C28.4354 25.1415 29.3148 23.0809 28.8973 21.0676C28.4798 19.0543 26.8535 17.5134 24.8206 17.2047V17.2048ZM24.0648 24.5852C23.7279 24.5943 23.3927 24.5358 23.0788 24.4131C22.7649 24.2905 22.4788 24.1062 22.2373 23.8711C21.9958 23.636 21.8039 23.355 21.6728 23.0445C21.5418 22.734 21.4743 22.4005 21.4743 22.0635C21.4743 21.7265 21.5418 21.3929 21.6728 21.0824C21.8039 20.772 21.9958 20.4909 22.2373 20.2558C22.4788 20.0208 22.7649 19.8365 23.0788 19.7138C23.3927 19.5911 23.7279 19.5326 24.0648 19.5417C25.4224 19.5892 26.4985 20.7032 26.4993 22.0616C26.4999 23.4199 25.4249 24.535 24.0673 24.5839"
      fill="white"
    />
  </SvgIcon>
);
