import { PropsWithChildren } from "react";
import { Input } from "../cataylst/input";
import { Button } from "./button";
import { AIIcon, CalendarIcon, LogoIcon, XMarkIcon } from "./icons";

export function AgentChatLayout(props: PropsWithChildren) {
  return <div className="fixed bottom-4 left-1/2 -translate-x-1/2 z-[1000] w-[656px]">{props.children}</div>;
}

export type IAgentChatType = 'BULK_ACTIONS' | 'ALERT' | 'SUGGESTION' | 'INSIGHTS' | 'NOTIFICATIONS';

interface IAgentChatProps {
  type: IAgentChatType;
};

export default function AgentChat({ type }: IAgentChatProps) {
  return (
    <div
      className="h-[64px] p-2 flex gap-2 rounded-xl border-[rgba(0,0,0,0.1)] border border-solid font-firstext bg-white"
      style={{ boxShadow: "0px 16px 64px -12px rgba(0, 0, 0, 0.50)" }}
    >
      <div>
        <Input
          placeholder="How can I help you?"
          className="h-12 rounded-lg border border-[rgba(0,0,0,0.1)] border-solid font-normal text-sm leading-[21px] text-[rgba(0,0,0,0.4)] !w-[456px]"
          style={{ boxShadow: '0px 8px 20px -8px rgba(0, 0, 0, 0.12)' }}
        />
      </div>
      {type === 'BULK_ACTIONS' && (
        <div className="flex gap-5 items-center pl-4 pr-3 bg-[#F2F2F2] rounded-lg w-max">
          <div className="font-normal text-[rgba(0,0,0,0.6)] leading-[17.5px]">4 selected</div>
          <div className="font-normal text-black underline leading-[17.5px] cursor-pointer">Deselect all</div>
          <Button color="dark" className="flex gap-2">
            <img src={AIIcon} className="w-4 h-4 invert" />
            Actions
          </Button>
        </div>
      )}
      {type === 'ALERT' && (
        <div className="flex gap-3 items-center pl-4 pr-3 bg-[#EE2E5C1D] rounded-lg w-max">
          <div className="font-normal text-black leading-[17.5px] mr-1">Meta demo is 2 days overdue</div>
          <Button color="dark" className="flex gap-2">
            <img src={CalendarIcon} className="w-4 h-4 invert" />
            Schedule demo
          </Button>
          <img src={XMarkIcon} className="w-4 h-4 cursor-pointer" />
        </div>
      )}
      {type === 'SUGGESTION' && (
        <div className="flex gap-2 items-center pl-4 pr-3 bg-[#7A3DFA26] rounded-lg w-max">
          <img src={AIIcon} className="w-4 h-4" />
          <div className="font-normal text-black leading-[17.5px] mr-2">Which accounts need the most urgent follow-up?</div>
          <div className="font-normal text-black leading-[17.5px] underline">More suggestions</div>
        </div>
      )}
      {type === 'INSIGHTS' && (
        <div className="flex gap-2 items-center pl-4 pr-3 rounded-lg w-max" style={{ background: 'linear-gradient(90deg, rgba(254, 183, 0, 0.2) 0%, rgba(244, 146, 0, 0.2) 25%, rgba(238, 46, 92, 0.2) 60%, rgba(122, 61, 250, 0.2) 100%)' }}>
          <img src={LogoIcon} className="w-4 h-4" />
          <div className="font-normal text-black leading-[17.5px] mr-2">4 customer insights found</div>
          <Button color="dark" className="flex gap-2 mr-1">
            View insights
          </Button>
          <img src={XMarkIcon} className="w-4 h-4 cursor-pointer" />
        </div>
      )}
      {type === 'NOTIFICATIONS' && (
        <div className="flex gap-2 items-center bg-[#106EF626] pl-4 pr-3 rounded-lg w-max">
          <div className="font-normal text-black leading-[17.5px] mr-2">
            James assigned you to the <span className="underline">Meta</span> account
          </div>
          <img src={XMarkIcon} className="w-4 h-4 cursor-pointer" />
        </div>
      )}
    </div>
  );
}
