import React from 'react';
import SvgIcon, { SvgIconProps } from '../SvgIcon';

export const ZulipIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props} style={{ fill: 'transparent' }} viewBox="0 0 16 18">
    <path
      d="M16 2.66497C16 3.56278 15.5966 4.36049 14.9817 4.84442L9.01294 10.172C8.90209 10.2669 8.75797 10.1138 8.83795 9.986L11.0273 5.60494C11.0887 5.48227 11.0091 5.33151 10.8832 5.33151H2.39131C1.07609 5.33151 0 4.13179 0 2.66576C0 1.19933 1.07609 3.91288e-07 2.39131 3.91288e-07H13.6087C14.9239 -0.000790987 16 1.19894 16 2.66497ZM2.39131 17.823H13.6087C14.9239 17.823 16 16.6233 16 15.1573C16 13.6908 14.9239 12.4915 13.6087 12.4915H5.11677C4.99087 12.4915 4.91129 12.3407 4.97266 12.2181L7.16205 7.83702C7.24203 7.70921 7.09791 7.55608 6.98706 7.65104L1.01829 12.9778C0.403435 13.4613 0 14.2594 0 15.1573C0 16.6233 1.07609 17.823 2.39131 17.823Z"
      fill="currentColor"
    />
  </SvgIcon>
);
