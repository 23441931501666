export type IStorageKey = 'sidebar';

export const BloomStorage = {
  isSidebarOpen: () => {
    const value = localStorage.getItem('sidebar');
    if (value === 'closed')
      return false;
    return true;
  },
  setSidebarOpen: (isOpen: boolean) => {
    localStorage.setItem('sidebar', isOpen ? 'open' : 'closed');
  },
  saveAccessToken: (token: string) => {
    localStorage.setItem('auth0_access_token', token);
  },
  getAccessToken: () => {
    return localStorage.getItem('auth0_access_token') || '';
  }
};