import MainSidebar from "@/components/custom/sidebar";
import { Button } from "@/components/custom/button";
import { Textarea } from "@/components/cataylst/textarea";
import { PlusIcon, SearchIcon } from "@/components/custom/icons";
import { Input, InputGroup } from "@/components/cataylst/input";
import { MenuIcon } from "@/components/custom/menu-icon";
import { ReactNode, useState } from "react";
import { useNavigate } from "react-router-dom";

export interface IProduct {
  id: number;
  title: string;
  description: string;
  icon: ReactNode;
  challenges: string[];
  differentiates: string[];
  capabilities: string[];
  customerBenefits: string[];
}

interface IProductItemProps {
  product: IProduct;
  onClick?: Function;
  borderTop?: boolean;
}

function ProductItem({ product: { title, description, icon }, borderTop, onClick }: IProductItemProps) {
  return (
    <div className={`flex items-center justify-between pr-2 py-4 font-firstext ${borderTop && 'border-t border-solid border-t-gray-200'} border-b border-b-gray-200 cursor-pointer`} onClick={() => ((onClick || (() => {})))()}>
      <div className="flex gap-3 items-center w-[90%]">
        <div className="flex-1">
          {icon}
        </div>
        <div className="flex flex-col gap-1 flex-1 max-w-full">
          <div className="font-semibold text-sm leading-[17.5px]">
            {title}
          </div>
          <div className="text-sm leading-[21px] opacity-60 pr-10 truncate">
            {description}
          </div>
        </div>
      </div>
      <div>
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M5.96967 3.46967C6.26256 3.17678 6.73744 3.17678 7.03033 3.46967L11.0303 7.46967C11.3232 7.76256 11.3232 8.23744 11.0303 8.53033L7.03033 12.5303C6.73744 12.8232 6.26256 12.8232 5.96967 12.5303C5.67678 12.2374 5.67678 11.7626 5.96967 11.4697L9.43934 8L5.96967 4.53033C5.67678 4.23744 5.67678 3.76256 5.96967 3.46967Z" fill="black" fill-opacity="0.4"/>
        </svg>
      </div>
    </div>
  )
}

export const mockProducts: IProduct[] = [
  {
    id: 1,
    title: 'Autonomous Revenue Systems (ARS)',
    description: `Autonomous Revenue Systems (ARS) leverage advanced AI and generative workflows to revolutionize revenue operations. By seamlessly integrating with a company's unique business processes, knowledge bases, and branding strategies, ARS creates a unified ecosystem for sales and marketing. This technology enables real-time optimization and intelligent automation, clearing the path for autonomous revenue generation.`,
    icon: <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16Z" fill="#EE2E5C"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M12.0001 11.4167C11.8454 11.4167 11.697 11.4781 11.5876 11.5875C11.4782 11.6969 11.4167 11.8453 11.4167 12V12.5833H12.5834V11.4167H12.0001ZM13.3334 9.91666H20.0001C20.5526 9.91666 21.0825 10.1361 21.4732 10.5269C21.8639 10.9176 22.0834 11.4475 22.0834 12V13.3333V20C22.0834 20.5525 21.8639 21.0824 21.4732 21.4731C21.0825 21.8638 20.5526 22.0833 20.0001 22.0833H12.0001C11.4475 22.0833 10.9176 21.8638 10.5269 21.4731C10.1362 21.0824 9.91675 20.5525 9.91675 20V13.3333V12C9.91675 11.4475 10.1362 10.9176 10.5269 10.5269C10.9176 10.1361 11.4475 9.91666 12.0001 9.91666H13.3334ZM14.0834 11.4167V12.5833H20.5834V12C20.5834 11.8453 20.522 11.6969 20.4126 11.5875C20.3032 11.4781 20.1548 11.4167 20.0001 11.4167H14.0834ZM20.5834 20V14.0833H11.4167V20C11.4167 20.1547 11.4782 20.3031 11.5876 20.4125C11.697 20.5219 11.8454 20.5833 12.0001 20.5833H20.0001C20.1548 20.5833 20.3032 20.5219 20.4126 20.4125C20.522 20.3031 20.5834 20.1547 20.5834 20ZM12.2501 18.6633C12.2501 18.2491 12.5859 17.9133 13.0001 17.9133H15.6667C16.081 17.9133 16.4167 18.2491 16.4167 18.6633C16.4167 19.0775 16.081 19.4133 15.6667 19.4133H13.0001C12.5859 19.4133 12.2501 19.0775 12.2501 18.6633ZM13.0001 15.58C12.5859 15.58 12.2501 15.9158 12.2501 16.33C12.2501 16.7442 12.5859 17.08 13.0001 17.08H17.0001C17.4143 17.08 17.7501 16.7442 17.7501 16.33C17.7501 15.9158 17.4143 15.58 17.0001 15.58H13.0001Z" fill="white"/>
    </svg>,
    challenges: [
      'Reliance on disparate, siloed tools for CRM, marketing automation, and analytics, leading to fragmented customer data and inconsistent messaging across touchpoints.',
      'Heavy dependence on manual data entry and analysis by sales and marketing teams, resulting in delayed insights and missed opportunities for timely customer engagement.',
      'Ad-hoc decision-making based on gut feelings or outdated information, rather than real-time data-driven strategies, causing misalignment between market demands and business actions.',
      'Rigid, pre-defined customer journeys that fail to adapt to individual preferences or changing market conditions, leading to generic experiences and decreased conversion rates.'
    ],
    differentiates: [
      'Seamlessly integrates Generative AI across the entire revenue ecosystem, enabling real-time, context-aware customer interactions that adapt to individual preferences and market dynamics, surpassing traditional static engagement models.',
      'Orchestrates event-driven automation that proactively responds to market shifts and customer behaviors, allowing businesses to capitalize on opportunities faster than competitors relying on manual processes or reactive systems.',
      'Unifies fragmented customer data into a single, real-time intelligence hub, providing a holistic view of customer journeys and enabling personalized, cross-channel experiences that outperform siloed marketing and sales approaches.',
      'Empowers strategic decision-making through AI-driven insights and automated actions, elevating revenue teams from tactical executors to strategic architects of growth, unlike solutions that merely provide data without actionable intelligence.'
    ],
    customerBenefits: [
      'Empowers revenue teams to focus on high-impact activities by automating routine tasks and providing AI-driven insights.',
      'Enhances decision-making accuracy through continuous analysis of multi-source data and predictive analytics.',
      'Improves customer engagement and satisfaction through personalized, consistent interactions across all channels.',
      'Increases operational agility by enabling rapid response to market changes and emerging opportunities.'
    ],
    capabilities: [
      'Orchestrates complex, AI-driven workflows that automate and optimize every facet of revenue operations.',
      'Generates actionable insights from real-time growth data to inform strategic decision-making.',
      'Unifies sales and marketing channels through AI-powered integration of various tools and systems.',
      'Provides instant access to a dynamic, real-time knowledge base for personalized customer interactions and market adaptability.'
    ],
  },
]

export default function DemoProductsPage() {
  const [searchKeyword, setSearchKeyword] = useState('');
  const navigate = useNavigate();
  const onSearchCompanies = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchKeyword(event.target.value);
  };

  const onAddProduct = () => {
    navigate('/settings/products/new');
  };
  const onEditProduct = (product: IProduct) => {
    navigate(`/settings/products/${product.id}`);
  };

  return (
    <main>
      <MainSidebar
        pageTitle="Products"
        shouldHideActionButton
        toolbar={
          <Button color="dark" className="gap-2 flex items-center">
            <img src={PlusIcon} className="w-4 h-4" />
            Add product
          </Button>
        }
      >
        <div className="text-cell px-8 py-5">
          <div className="flex flex-col gap-2 align-center">
            <div className="w-[250px]">
              <InputGroup>
                <MenuIcon
                  src={SearchIcon}
                  data-slot="icon"
                  className="!size-4"
                />
                <Input
                  placeholder="Search products"
                  className="h-9 pl-9 pr-2 !rounded-full border-none !bg-[#F2F2F2]"
                  onChange={onSearchCompanies}
                  value={searchKeyword}
                  color=''
                />
              </InputGroup>
            </div>
          </div>
          <div className="mt-5">
            {mockProducts.map((product, index) => (
              <ProductItem product={product} key={product.id} borderTop={index === 0} onClick={() => onEditProduct(product)} />
            ))}
          </div>
          <div
            className="w-full border mt-5 border-[#00000022] text-[#00000099] border-dashed rounded-lg px-4 h-[42px] flex gap-1.5 items-center text-sm cursor-pointer"
            onClick={onAddProduct}
          >
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M8.75003 3.33331C8.75003 2.9191 8.41424 2.58331 8.00003 2.58331C7.58582 2.58331 7.25003 2.9191 7.25003 3.33331V7.25H3.33337C2.91916 7.25 2.58337 7.58579 2.58337 8C2.58337 8.41421 2.91916 8.75 3.33337 8.75H7.25003V12.6666C7.25003 13.0809 7.58582 13.4166 8.00003 13.4166C8.41424 13.4166 8.75003 13.0809 8.75003 12.6666V8.75H12.6667C13.0809 8.75 13.4167 8.41421 13.4167 8C13.4167 7.58579 13.0809 7.25 12.6667 7.25H8.75003V3.33331Z"
                fill="black"
                fill-opacity="0.6"
              />
            </svg>
            <span>Add product</span>
          </div>
        </div>
      </MainSidebar>
    </main>
  )
}
