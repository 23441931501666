import React from 'react';
import SvgIcon, { SvgIconProps } from '../SvgIcon';

export const XeroIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props} style={{ fill: 'transparent' }} viewBox="2.5 2.5 19 19">
    <path
      d="M12 24C18.6275 24 24 18.6275 24 12C24 5.37258 18.6275 -1.05451e-06 12 -1.05451e-06C5.37258 -1.05451e-06 0 5.37258 0 12C0 18.6275 5.37258 24 12 24Z"
      fill="#1AB4D7"
    />
    <path
      d="M5.74419 11.9653L7.79209 9.91233C7.85999 9.84313 7.89787 9.75152 7.89787 9.65435C7.89787 9.45186 7.73326 9.28741 7.53077 9.28741C7.43213 9.28741 7.33966 9.32607 7.26993 9.39666C7.26973 9.39707 5.22387 11.4417 5.22387 11.4417L3.16866 9.3936C3.09922 9.32513 3.0072 9.28741 2.90963 9.28741C2.70737 9.28741 2.54272 9.45177 2.54272 9.65407C2.54272 9.75274 2.58212 9.84533 2.65283 9.91511L4.70044 11.9624L2.65389 14.0128C2.58196 14.0837 2.54272 14.1765 2.54272 14.2756C2.54272 14.478 2.70737 14.6423 2.90963 14.6423C3.00736 14.6423 3.09946 14.6043 3.16866 14.5351L5.22069 12.4859L7.26491 14.5276C7.33742 14.6026 7.43095 14.6425 7.53077 14.6425C7.73306 14.6425 7.89767 14.478 7.89767 14.2756C7.89767 14.1779 7.85982 14.0861 7.79095 14.0169L5.74419 11.9653Z"
      fill="white"
    />
    <path
      d="M17.9187 11.9647C17.9187 12.3324 18.2177 12.6315 18.5858 12.6315C18.9531 12.6315 19.2521 12.3324 19.2521 11.9647C19.2521 11.5969 18.9531 11.2979 18.5858 11.2979C18.2177 11.2979 17.9187 11.5969 17.9187 11.9647Z"
      fill="white"
    />
    <path
      d="M16.6541 11.965C16.6541 10.9003 17.5203 10.0339 18.5852 10.0339C19.6495 10.0339 20.5158 10.9003 20.5158 11.965C20.5158 13.0295 19.6495 13.8954 18.5852 13.8954C17.5203 13.8954 16.6541 13.0295 16.6541 11.965ZM15.8945 11.965C15.8945 13.4484 17.1015 14.6552 18.5852 14.6552C20.0687 14.6552 21.2763 13.4484 21.2763 11.965C21.2763 10.4814 20.0687 9.27426 18.5852 9.27426C17.1015 9.27426 15.8945 10.4814 15.8945 11.965Z"
      fill="white"
    />
    <path
      d="M15.7035 9.32024L15.5906 9.31991C15.2517 9.31991 14.9249 9.4268 14.6519 9.63717C14.6159 9.47256 14.4688 9.3487 14.2935 9.3487C14.0918 9.3487 13.9302 9.51032 13.9297 9.71245C13.9297 9.71319 13.9309 14.2441 13.9309 14.2441C13.9314 14.4459 14.096 14.6099 14.2979 14.6099C14.4997 14.6099 14.6642 14.4459 14.6647 14.2437C14.6647 14.2428 14.6649 11.4573 14.6649 11.4573C14.6649 10.5286 14.7498 10.1535 15.5453 10.0541C15.6189 10.0449 15.6988 10.0464 15.6992 10.0464C15.9168 10.0389 16.0715 9.88931 16.0715 9.68714C16.0715 9.48485 15.9064 9.32024 15.7035 9.32024Z"
      fill="white"
    />
    <path
      d="M8.65804 11.5221C8.65804 11.512 8.65882 11.5016 8.65931 11.4912C8.87233 10.6491 9.635 10.026 10.5433 10.026C11.4626 10.026 12.2322 10.6644 12.4343 11.5221H8.65804ZM13.1857 11.4528C13.0276 10.7042 12.6178 10.0893 11.9937 9.69435C11.0814 9.11515 9.87669 9.1472 8.99551 9.77383C8.27673 10.2852 7.86182 11.1217 7.86182 11.9833C7.86182 12.1994 7.88786 12.4175 7.94208 12.6321C8.21349 13.6998 9.13133 14.508 10.2256 14.642C10.5504 14.6813 10.8664 14.6625 11.1937 14.5777C11.475 14.5093 11.7471 14.3952 11.9979 14.2345C12.2581 14.0672 12.4756 13.8466 12.6861 13.5825C12.6904 13.5776 12.6947 13.5734 12.6989 13.5683C12.8451 13.387 12.818 13.1292 12.6574 13.0062C12.5219 12.9023 12.2945 12.8603 12.1156 13.0894C12.0771 13.1442 12.0342 13.2006 11.987 13.257C11.8447 13.4142 11.6682 13.5665 11.4566 13.6847C11.1875 13.8284 10.8808 13.9106 10.5547 13.9125C9.48741 13.9006 8.9163 13.1556 8.71315 12.6238C8.67768 12.5245 8.65032 12.4215 8.63126 12.3155C8.62877 12.2956 8.62693 12.2765 8.6262 12.2589C8.84686 12.2589 12.4561 12.2582 12.4561 12.2582C12.9812 12.2472 13.2638 11.8766 13.1857 11.4528Z"
      fill="white"
    />
  </SvgIcon>
);
