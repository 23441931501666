import { useCallback } from 'react';
import { required } from './validation';
import { StaticLabel } from './static-label';
import { Field } from './field';
import { FormWithValidation } from './form-with-validation';

interface Props {
  readonlyKey: boolean;
  details: Record<string, string>;
  setDetails: (
    arg0:
      | Record<string, string>
      | ((arg0: Record<string, string>) => Record<string, string>)
  ) => void;
  onValidationUpdated: (arg0: () => Promise<boolean>) => void;
}

export const DetailsForm: React.FC<Props> = ({
  readonlyKey,
  onValidationUpdated,
  details,
  setDetails,
}) => {
  // const [api] = useAtom(apiAtom);
  // const [orgId] = useAtom(currentOrgIdAtom);

  const checkKeyAvailability = useCallback(
    async (key: string): Promise<string> => {
      if (!key) {
        return required(key);
      }
      if (readonlyKey) return required(key);
      return "";

      // const { available } = await api.getVaultKeyAvailability({ orgId, key });
      // return available ? '' : 'Key already in use';
    },
    [/*api, orgId, */readonlyKey]
  );

  const validateKey = useCallback(
    (val: string) => required(val) || checkKeyAvailability(val),
    [checkKeyAvailability]
  );

  const handleInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const { name, value } = e.target;
      setDetails((d) => ({
        ...d,
        [name]: value,
      }));
    },
    [setDetails]
  );

  return (
    <FormWithValidation onValidationUpdated={onValidationUpdated}>
      <StaticLabel content="Vault Key" required>
        <Field
          id="key"
          name="key"
          value={details.key}
          onChange={handleInputChange}
          onValidate={validateKey}
          required
          fullWidth
          disabled={readonlyKey}
        />
      </StaticLabel>
      <StaticLabel content="Description" required>
        <Field
          id="description"
          name="description"
          value={details.description}
          onChange={handleInputChange}
          onValidate={required}
          fullWidth
          required
        />
      </StaticLabel>
    </FormWithValidation>
  );
};
