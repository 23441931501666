import React from 'react';
import SvgIcon, { SvgIconProps } from '../SvgIcon';

export const JiraIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props} style={{ fill: 'transparent' }} viewBox="0 0 22 22">
    <path
      d="M21.7372 10.3845L11 0L0.262836 10.3845C0.0945101 10.548 0 10.7693 0 11C0 11.2307 0.0945101 11.452 0.262836 11.6155L11 22L21.7372 11.6155C21.9055 11.452 22 11.2307 22 11C22 10.7693 21.9055 10.548 21.7372 10.3845ZM11 14.2513L7.63714 10.9989L11 7.74651L14.3629 10.9989L11 14.2513Z"
      fill="white"
    />
    <path
      d="M10.9999 7.76803C9.9422 6.7452 9.34595 5.35913 9.34137 3.91254C9.33679 2.46594 9.92426 1.07638 10.9755 0.0473022L3.59451 7.14075L7.59928 11.014L10.9999 7.76803Z"
      fill="url(#paint0_linear_346_776)"
    />
    <path
      d="M14.3718 10.9903L11.0001 14.2513C11.5262 14.7599 11.9436 15.3637 12.2284 16.0283C12.5131 16.6928 12.6597 17.4052 12.6597 18.1246C12.6597 18.844 12.5131 19.5563 12.2284 20.2209C11.9436 20.8855 11.5262 21.4893 11.0001 21.9978L18.3744 14.8657L14.3718 10.9903Z"
      fill="url(#paint1_linear_346_776)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_346_776"
        x1="10.4002"
        y1="4.45761"
        x2="5.86137"
        y2="9.15056"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.18" stop-color="#7EB2FF" />
        <stop offset="1" stop-color="white" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_346_776"
        x1="11.642"
        y1="17.5038"
        x2="16.1723"
        y2="12.8197"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.18" stop-color="#7EB2FF" />
        <stop offset="1" stop-color="white" />
      </linearGradient>
    </defs>
  </SvgIcon>
);
