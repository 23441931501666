import { Input, InputGroup } from "@/components/cataylst/input";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/cataylst/table";
import { DaveIcon, EmailIcon, FormIcon, LogoIcon, PhoneIcon, SarahIcon, SearchIcon, SMSIcon, VideoIcon, WhatsAppIcon } from "@/components/custom/icons";
import { MenuIcon } from "@/components/custom/menu-icon";
import MainSidebar from "@/components/custom/sidebar";
import { useParams } from "react-router-dom";
import { Dropdown, DropdownButton, DropdownItem, DropdownMenu } from "@/components/cataylst/dropdown";
import { ChevronDownIcon } from "@heroicons/react/16/solid";
import { formatDateTime } from "@/utils/format";
import AvatarGroup from "@/components/custom/avatar-group";
import DemoCompanyProfileAside from "./DemoCompanyProfileAside";

const mockCompanyNames = {
  zoom: 'Zoom'
};

const mockEvents = [
  {
    id: 0,
    name: 'Voice call',
    icon: PhoneIcon,
    date: new Date(),
    contacts: [
      {
        name: 'David',
        avatar: DaveIcon
      },
      {
        name: 'Sarah',
        avatar: SarahIcon
      },
    ],
    summary: 'Sarah asked about seat-based pricing in relation to distributed teams'
  },
  {
    id: 1,
    name: 'Email',
    icon: EmailIcon,
    date: new Date(),
    contacts: [
      {
        name: 'Sarah Hughes',
        avatar: SarahIcon
      },
    ],
    summary: "We're expanding and sometimes struggle with consistency and efficiency across the team. I'm looking for a solution that not only offers better visibility..."
  },
  {
    id: 2,
    name: 'WhatsApp',
    icon: WhatsAppIcon,
    date: new Date(),
    contacts: [
      {
        name: 'Sarah Hughes',
        avatar: SarahIcon
      },
    ],
    summary: "I’ll send you an email where it’s easier to describe"
  },
  {
    id: 3,
    name: 'WhatsApp',
    icon: WhatsAppIcon,
    date: new Date(),
    contacts: [
      {
        name: 'David Wallace',
        avatar: DaveIcon
      },
    ],
    summary: "Hi Sarah, did you have any questions I can help you with?"
  },
  {
    id: 4,
    name: 'Video call',
    icon: VideoIcon,
    date: new Date(),
    contacts: [
      {
        name: 'David',
        avatar: DaveIcon
      },
      {
        name: 'Sarah',
        avatar: SarahIcon
      },
    ],
    summary: "David introduced Apple's suite of services tailored for large tech companies"
  },
  {
    id: 5,
    name: 'SMS',
    icon: SMSIcon,
    date: new Date(),
    contacts: [
      {
        name: 'Sarah Hughes',
        avatar: SarahIcon
      },
    ],
    summary: "Hi there. Who should I get in touch with to talk about cloud services"
  },
  {
    id: 6,
    name: 'Email',
    icon: EmailIcon,
    date: new Date(),
    contacts: [
      {
        name: 'Bloom AI',
        avatar: LogoIcon
      }
    ],
    summary: "Thanks for getting in touch Sarah. I’ll connect you with David"
  },
  {
    id: 7,
    name: 'Contact form',
    icon: FormIcon,
    date: new Date(),
    contacts: [
      {
        name: 'Sarah Hughes',
        avatar: SarahIcon
      },
    ],
    summary: "I’m interested in learning about your security features and how I can see a demo"
  },
  {
    id: 8,
    name: 'Website visit',
    icon: FormIcon,
    date: new Date(),
    contacts: [
      {
        name: 'Sarah Hughes',
        avatar: SarahIcon
      },
    ],
    summary: "Time on site: 4:56. Pages visited: Home, Use Cases, Demo. Clicks: Request a demo"
  },
];

export default function DemoCompanyDetailActivitiesPage() {
  const { companyId } = useParams();

  return (
    <main>
      <MainSidebar pageTitle={mockCompanyNames[companyId!]}>
        <div className="flex h-full gap-0.5 bg-zinc-100">
          <div className="text-cell px-8 py-5 flex-1 bg-white">
            <div className="flex mb-5 justify-between">
              <div className="flex gap-5 items-center">
                <div className="text-xl leading-5 font-p22 font-medium">Activity</div>
                <div className="flex gap-2 items-center">
                  <Dropdown>
                    <DropdownButton outline className="hover:!bg-transparent !outline-none !px-2 !py-1 h-6 !rounded-full">
                      <div className="flex gap-2 items-center">
                        <span className="font-medium text-xs">All events</span>
                        <ChevronDownIcon className="w-4 h-4 opacity-40" />
                      </div>
                    </DropdownButton>
                    <DropdownMenu className="min-w-32">
                      <DropdownItem>All events</DropdownItem>
                      <DropdownItem>Emails</DropdownItem>
                      <DropdownItem>Voice calls</DropdownItem>
                      <DropdownItem>Video calls</DropdownItem>
                      <DropdownItem>WhatsApp & SMS</DropdownItem>
                      <DropdownItem>Website visits</DropdownItem>
                      <DropdownItem>Forms</DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                  <Dropdown>
                    <DropdownButton outline className="hover:!bg-transparent !outline-none !px-2 !py-1 h-6 !rounded-full">
                      <div className="flex gap-2 items-center">
                        <span className="font-medium text-xs">Last 30 days</span>
                        <ChevronDownIcon className="w-4 h-4 opacity-40" />
                      </div>
                    </DropdownButton>
                    <DropdownMenu className="min-w-32">
                      <DropdownItem>All</DropdownItem>
                      <DropdownItem>Last 7 days</DropdownItem>
                      <DropdownItem>Last 30 days</DropdownItem>
                      <DropdownItem>Last 90 days</DropdownItem>
                      <DropdownItem>Last year</DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                </div>
              </div>
              <div className="w-[250px]">
                <InputGroup>
                  <MenuIcon src={SearchIcon} data-slot="icon" className="!size-4" />
                  <Input placeholder="Search events" className="h-9 pl-9 pr-2 !rounded-full" />
                </InputGroup>
              </div>
            </div>
            <Table grid dense={true} fixedHeader={true}>
              <TableHead>
                <TableRow>
                  <TableHeader>Event</TableHeader>
                  <TableHeader sortable sort="desc">Date</TableHeader>
                  <TableHeader>Contact</TableHeader>
                  <TableHeader>Summary</TableHeader>
                </TableRow>
              </TableHead>
              <TableBody>
                {mockEvents.map((event) => (
                  <TableRow key={event.id}>
                    <TableCell>
                      <div className="flex gap-2 items-center">
                        <img src={event.icon} className="w-4 h-4" />
                        <div>{event.name}</div>
                      </div>
                    </TableCell>
                    <TableCell>
                      {formatDateTime(event.date)}
                    </TableCell>
                    <TableCell>
                      <div className="flex gap-2 items-center">
                        <AvatarGroup srcs={event.contacts.map(contact => contact.avatar)} />
                        <div>{event.contacts.map(contact => contact.name).join(" & ")}</div>
                      </div>
                    </TableCell>
                    <TableCell className="text-ellipsis overflow-hidden max-w-[180px]">
                      {event.summary}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
          <div className="min-w-[456px] bg-white">
            <DemoCompanyProfileAside />
          </div>
        </div>
      </MainSidebar>
    </main>
  )
}