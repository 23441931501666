import React from 'react';
import SvgIcon, { SvgIconProps } from '../SvgIcon';

export const DropboxIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon
    {...props}
    style={{ ...props.style, fill: 'transparent' }}
    viewBox="0 0 22 20"
  >
    <path
      d="M11.0146 4.01531L5.50732 7.53062L11.0146 11.0459L5.50732 14.5612L0 11.0166L5.50732 7.50133L0 4.01531L5.50732 0.5L11.0146 4.01531ZM5.47803 15.6744L10.9854 12.1591L16.4927 15.6744L10.9854 19.1897L5.47803 15.6744ZM11.0146 11.0166L16.522 7.50133L11.0146 4.01531L16.4927 0.5L22 4.01531L16.4927 7.53062L22 11.0459L16.4927 14.5612L11.0146 11.0166Z"
      fill="currentColor"
    />
  </SvgIcon>
);
