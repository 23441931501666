import MainSidebar from "@/components/custom/sidebar";
import { Button } from "@/components/custom/button";
import { TagField, useTags } from "@/components/custom/tag-input";
import { Textarea } from "@/components/cataylst/textarea";
import { MultiTextGroup } from "@/components/custom/multi-text-group";
import { useParams } from "react-router-dom";
import { IUseCase, mockUseCases } from "./DemoUseCasesPage";
import { useEffect, useState } from "react";
import { apiClient } from "@/services/api";
import { Environment } from "@/utils/constants";
import axios from "axios";

export default function DemoUseCaseDetailpage() {
  const { usecaseId } = useParams();

  const [usecaseDetails, setUsecaseDetails] = useState<IUseCase>({
    id: 0,
    challenges: [],
    description: '',
    desiredOutcomes: [],
    icon: mockUseCases[0].icon,
    title: ''
  });

  useEffect(() => {
    const usecase = mockUseCases.find(
      (usecase) => String(usecase.id) === String(usecaseId)
    );
    if (usecase) {
      setUsecaseDetails(usecase);
    }
  }, [usecaseId]);

  const onUsecaseDetailsChange = (fieldName: string, texts: string[]) => {
    const newUsecaseDetails = { ...usecaseDetails };
    newUsecaseDetails[fieldName] = texts;
    setUsecaseDetails(newUsecaseDetails);
  };

  const onUsecaseDetailsSingleChange = (fieldName: string, texts: string) => {
    const newUsecaseDetails = { ...usecaseDetails };
    newUsecaseDetails[fieldName] = texts;
    setUsecaseDetails(newUsecaseDetails);
  };

  const onAddKnowledgebaseFile = async () => {
    const { data: newKnowledgebaseFile } = await apiClient.post(`/agents/orgs/x-functions/knowledgebases/${Environment.KNOWLEDGE_BASE_ID}/files/upload-url`, {
      fileName: `New-Usecase-Knowledgebase-File-${new Date().getTime()}.txt`,
      contentType: 'text'
    });
    const filePresignedUrl = newKnowledgebaseFile.uploadUrl;
    await axios.put(filePresignedUrl, JSON.stringify({...usecaseDetails, icon: undefined}), {
      headers: {
        'Content-Type': 'text/plain'
      }
    });
  }

  return (
    <main>
      <MainSidebar
        pageTitle={`UseCases/${usecaseDetails ? usecaseDetails.title : 'New'}`}
        shouldHideActionButton
        toolbar={
          <Button color="dark" size="lg" onClick={onAddKnowledgebaseFile}>
            Save
          </Button>
        }
      >
        <div className="text-cell px-8 py-5 max-w-[852px]">
          <div className="flex flex-col gap-2">
            <div className="font-medium">Use case name</div>
            <Textarea
              resizable={false}
              rows={1}
              withItems={null}
              type="text"
              value={usecaseDetails?.title}
              onChange={(e) => {
                onUsecaseDetailsSingleChange("title", e.target.value)
              }}
            />
          </div>
          <div className="flex flex-col gap-2 mt-8">
            <div className="font-medium">Description</div>
            <Textarea
              resizable={false}
              withItems={null}
              type="text"
              value={usecaseDetails?.description}
              rows={4}
              onChange={(e) => {
                onUsecaseDetailsSingleChange("description", e.target.value)
              }}
            />
          </div>
          <div className="mt-8">
            <MultiTextGroup buttonName="Add challenge" label={"Challenges"} defaultValues={usecaseDetails?.challenges} onChange={(texts: string[]) =>
                onUsecaseDetailsChange("challenges", texts)
              } />
          </div>
          <div className="mt-8">
            <MultiTextGroup buttonName="Add outcome" label={"Desired outcomes"} defaultValues={usecaseDetails?.desiredOutcomes} onChange={(texts: string[]) =>
                onUsecaseDetailsChange("desiredOutcomes", texts)
              } />
          </div>
          <div className="mt-20">

          </div>
        </div>
      </MainSidebar>
    </main>
  );
}
