import React from 'react';
import SvgIcon, { SvgIconProps } from '../SvgIcon';

export const GithubIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props} style={{ fill: 'transparent' }} viewBox="0 0 24 24">
    <path
      d="M9 19C4.7 20.4 4.7 16.5 3 16M15 21V17.5C15 16.5 15.1 16.1 14.5 15.5C17.3 15.2 20 14.1 20 9.50001C19.9988 8.30498 19.5325 7.15732 18.7 6.30001C19.0905 5.26198 19.0545 4.11164 18.6 3.10001C18.6 3.10001 17.5 2.80001 15.1 4.40001C13.0672 3.8706 10.9328 3.8706 8.9 4.40001C6.5 2.80001 5.4 3.10001 5.4 3.10001C4.94548 4.11164 4.90953 5.26198 5.3 6.30001C4.46745 7.15732 4.00122 8.30498 4 9.50001C4 14.1 6.7 15.2 9.5 15.5C8.9 16.1 8.9 16.7 9 17.5V21"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
  </SvgIcon>
);

export const GithubSourceIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props} style={{ fill: 'transparent' }} viewBox="8.5 8.5 23 23">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20 0H0V20V40H20H40V20V0H20Z"
      fill="black"
    />
    <path
      d="M20 9C13.9262 9 9 13.9253 9 20C9 24.8602 12.1515 28.9833 16.5231 30.4381C17.0722 30.5398 17.25 30.1988 17.25 29.9092V27.8613C14.1902 28.5268 13.5531 26.5633 13.5531 26.5633C13.0526 25.2919 12.3312 24.9537 12.3312 24.9537C11.3329 24.2707 12.4073 24.2854 12.4073 24.2854C13.5118 24.3624 14.093 25.4193 14.093 25.4193C15.0738 27.1005 16.6661 26.6147 17.294 26.3333C17.3921 25.6228 17.6772 25.137 17.9925 24.8629C15.5496 24.5833 12.9811 23.6401 12.9811 19.4262C12.9811 18.2244 13.411 17.2436 14.1141 16.4736C14.0004 16.1958 13.6237 15.0766 14.2213 13.5623C14.2213 13.5623 15.1453 13.2671 17.2472 14.6897C18.1245 14.4459 19.065 14.324 20 14.3194C20.935 14.324 21.8764 14.4459 22.7555 14.6897C24.8556 13.2671 25.7778 13.5623 25.7778 13.5623C26.3763 15.0775 25.9996 16.1967 25.8859 16.4736C26.5917 17.2436 27.018 18.2253 27.018 19.4262C27.018 23.6511 24.4449 24.5815 21.9956 24.8537C22.3898 25.1947 22.75 25.8639 22.75 26.8906V29.9092C22.75 30.2016 22.926 30.5453 23.4843 30.4372C27.8522 28.9806 31 24.8583 31 20C31 13.9253 26.0748 9 20 9Z"
      fill="white"
    />
  </SvgIcon>
);
