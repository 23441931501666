import { useState, useCallback, useEffect } from 'react';
import { Field, FormContext } from './field';
import { FormValidationFn } from './validation';

interface Props extends React.ComponentProps<'form'> {
  onValidationUpdated: (arg0: FormValidationFn) => void;
  children: React.ReactNode;
}

export const FormWithValidation: React.FC<Props> = ({
  onValidationUpdated,
  children,
  ...props
}) => {
  const [fields, setFields] = useState<Field[]>([]);

  const validate = useCallback(async () => {
    const resolved = await Promise.all(
      fields.map(async (f) => ({ name: f.name, error: await f.validate() }))
    );

    return resolved.every((v) => !v.error);
  }, [fields]);

  const register = useCallback((field: Field) => {
    setFields((f) => f.filter((a) => a.name !== field.name).concat(field));
  }, []);

  const unregister = useCallback((fieldName: string) => {
    setFields((f) => f.filter((a) => a.name !== fieldName));
  }, []);

  useEffect(() => {
    onValidationUpdated(validate);
  }, [validate, onValidationUpdated]);

  return (
    <FormContext.Provider value={{ register, unregister }}>
      <form {...props}>{children}</form>
    </FormContext.Provider>
  );
};
