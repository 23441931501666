import React from 'react';
import SvgIcon, { SvgIconProps } from '../SvgIcon';

export const SecurityScorecardIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props} style={{ fill: 'transparent' }} viewBox="0 0 18 21">
    <path
      d="M10.8766 13.5172L12.7497 12.4461V8.15485L9.00173 6.00747L5.25029 8.15485V12.4461L9.00173 14.5935L10.8766 13.5172ZM16.5029 10.2988L14.252 9.0138V17.5947L18 15.4507V11.156L16.5029 10.297V10.2988ZM10.4988 15.4525L9.00173 16.3114L5.25029 18.4553L9.00173 20.6027L12.7497 18.4553V14.164L10.4988 15.4525ZM5.25029 14.164L3.74971 13.3051L0 11.156V15.4507L3.74799 17.5947L5.24856 16.7374L7.49943 15.4525L5.24856 14.164H5.25029ZM0 5.14852V9.43982L1.50057 10.2988L3.74799 11.5872V3.00632L0 5.14852ZM9.00173 0L5.25029 2.14737V6.43867L7.50115 5.15025L9.00173 4.2913L12.7497 2.14737L9.00173 0ZM14.2503 3.00632L12.7497 3.86527L10.4988 5.15025L12.7497 6.43867L14.2503 7.29762L17.9983 9.44155V5.15025L14.2503 3.00632Z"
      fill="currentColor"
    />
  </SvgIcon>
);
