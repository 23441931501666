import MainSidebar from "@/components/custom/sidebar";
import Markdown from "react-markdown";
import { useParams } from "react-router-dom";
import { useAccountPlanStore } from "./account-plan/store";
import { useEffect, useState } from "react";
import { useWebsocketStore } from "@/services/websocket";
import { AIStarIcon, EllipsisIcon, EmailIcon, PenIcon } from "@/components/custom/icons";
import { Button } from "@/components/custom/button";
import "./account-plan/github-markdown.css";

const mockCompanyNames = {
  zoom: "Zoom",
};

const mockEmailData = `
Here’s a tailored email from James to the key contacts at Zoom to help progress the deal forward:

---

**Subject:** Driving AI Success Together: Next Steps for Zoom and Bloom ARS

**Hi Awinash, Shweta, and Bryan,**

I hope you're all doing well. It was great speaking with your team recently about how Bloom ARS can help accelerate Zoom's AI-driven initiatives, particularly around the Contact Center and AI Companion integration.

I wanted to follow up on a few key points from our discussions and outline the next steps:

### Key Focus Areas:
- **AI Automation & Efficiency:** Bloom ARS can streamline Zoom’s internal processes, specifically by automating customer engagement and enhancing operational scalability across your GTM and Contact Center teams.
- **Expansion Opportunities:** We see significant potential in expanding our partnership by introducing advanced AI workflows to other parts of Zoom, which could drive productivity gains of over 20%.
- **Contact Center Optimization:** Bloom’s real-time analytics will provide greater visibility into Zoom’s customer interactions, enabling faster decision-making and more personalized customer experiences.

### Proposed Next Steps:
1. **Product Demo:** I’d like to schedule a demo tailored to Zoom's specific AI needs. This will give your team a deeper look into how Bloom ARS can drive measurable outcomes across your GTM teams and Contact Center.
2. **Draft Proposal:** Following the demo, we’ll prepare a custom proposal that highlights the potential cost savings and operational improvements you can achieve with Bloom ARS.
3. **Key Executive Session:** We can arrange a strategy session to align both leadership teams on the AI roadmap and finalize a plan that supports Zoom's growth goals.

Would it be possible to find time this week for the demo? I’m happy to accommodate a time that works best for you.

Thank you again for the opportunity to collaborate. I’m confident that our partnership will lead to incredible success, and I look forward to continuing this journey together.

Best regards,  
**James Charlesworth**  
CEO, Bloom ARS  
`;

const EmailSection = () => {
  return (
    <div>
      <div className="flex justify-between items-center py-3 border-b border-gray-300">
        <div className="flex items-center gap-2 text-xs font-firstext">
          <img src={EmailIcon} className="size-4" />
          <div className="font-medium">EMAIL</div>
        </div>
        <div>
          <img src={EllipsisIcon} className="size-4" />
        </div>
      </div>
      <div className="py-4 border-b border-gray-300 text-sm font-firstext">
        <Markdown className='markdown-body markdown-sm'>{mockEmailData}</Markdown>
      </div>
      <div className="py-3 flex items-center gap-2">
        <Button color="dark" size="sm">Approve & send</Button>
        <Button color="white" size="sm">Edit</Button>
        <Button color="white" size="sm">Save as draft</Button>
      </div>
    </div>
  );
}

export default function DemoCompanyDetailAccountPlanPage() {
  const { companyId } = useParams();
  const [fetched, setFetched] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const { socket, connected, subscribe } = useWebsocketStore();
  const [isEmailSent, setEmailSent] = useState(false);
  const {
    conversation,
    messages,
    cursors,
    getOrCreateConversation,
    fetchMessages,
    requestAccountPlan,
    processWSMessage,
    deleteMessages,
    sendSlackNotification,
  } = useAccountPlanStore();

  const latestMessage = messages?.[messages?.length - 1];

  useEffect(() => {
    getOrCreateConversation();
  }, [getOrCreateConversation]);

  useEffect(() => {
    if (conversation?.conversationId) {
      fetchMessages().then(() => setFetched(true));
    }
  }, [conversation?.conversationId, fetchMessages]);

  useEffect(() => {
    if (
      socket &&
      connected &&
      conversation?.conversationId &&
      fetched &&
      !cursors.messages.fetching &&
      !cursors.newMessage.sending &&
      !cursors.newMessage.receiving &&
      !messages.length &&
      !latestMessage &&
      !deleting
    ) {
      requestAccountPlan();
    }
  }, [
    connected,
    conversation?.conversationId,
    cursors.messages.fetching,
    cursors.newMessage.receiving,
    cursors.newMessage.sending,
    deleting,
    fetched,
    latestMessage,
    messages.length,
    requestAccountPlan,
    socket,
  ]);

  useEffect(() => {
    if (socket && connected) {
      subscribe(processWSMessage);
    }
  }, [connected, processWSMessage, socket, subscribe]);

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.metaKey && e.key === "x") {
        e.preventDefault();
        setDeleting(true);
        deleteMessages();
      } else if (e.metaKey && e.key === "s") {
        e.preventDefault();
        sendSlackNotification();
      }
    };
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [deleteMessages, sendSlackNotification]);

  const isLoading =
    !fetched ||
    cursors.messages.fetching ||
    cursors.newMessage.sending ||
    latestMessage?.type !== "assistant";

  return (
    <main>
      <MainSidebar pageTitle={mockCompanyNames[companyId!]}>
        <div className="flex h-full gap-0.5 bg-zinc-100">
          <div className="flex flex-1 flex-col gap-1 grow">
            <div className="rounded pt-7 pb-8 px-8 bg-white grow">
              {deleting ? (
                <div className="flex justify-center items-center p-2">
                  <div className="text-sm text-gray-500">Deleting...</div>
                </div>
              ) : null}
              <Markdown className="markdown-body">
                {latestMessage?.type === "assistant"
                  ? latestMessage?.content ?? ""
                  : ""}
              </Markdown>
              {isLoading || cursors.newMessage.receiving ? (
                <div className="flex justify-center items-center p-2 w-full">
                  <span className="relative flex h-3 w-3">
                    <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-sky-400 opacity-75"></span>
                    <span className="relative inline-flex rounded-full h-3 w-3 bg-sky-500"></span>
                  </span>
                </div>
              ) : null}
            </div>
          </div>
          <div className="min-w-[456px] max-w-[456px] pt-7 pb-8 px-8 bg-white">
            {!isEmailSent && (
              <>
                <div className="flex gap-2 items-center tracking-widest font-firstext text-xs font-medium">
                  <img src={AIStarIcon} className="w-4 h-4" />
                  SUGGESTED ACTIONS
                </div>
                <div className="mt-2">
                  <Button color="white" className="gap-2 flex items-center" onClick={() => setEmailSent(true)}>
                    <img src={PenIcon} className="w-4 h-4" />
                    Send a follow up Email
                  </Button>
                </div>
              </>
            )}
            {isEmailSent && <EmailSection />}
          </div>
        </div>
      </MainSidebar>
    </main>
  );
}
