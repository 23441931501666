import React from 'react';
import SvgIcon, { SvgIconProps } from '../SvgIcon';

export const AsanaIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon
    {...props}
    style={{ ...props.style, fill: 'transparent' }}
    viewBox="0 0 18 18"
  >
    <path
      d="M14.0855 9.30807C11.9232 9.30807 10.1706 11.0606 10.1706 13.2226C10.1706 15.3847 11.9232 17.1376 14.0855 17.1376C16.2474 17.1376 18 15.3847 18 13.2226C18 11.0606 16.2474 9.30807 14.0855 9.30807ZM3.91469 9.30826C1.75275 9.30845 0 11.0606 0 13.2228C0 15.3847 1.75275 17.1374 3.91469 17.1374C6.0768 17.1374 7.82957 15.3847 7.82957 13.2228C7.82957 11.0606 6.0768 9.30826 3.9145 9.30826H3.91469ZM12.9147 4.41469C12.9147 6.57663 11.1621 8.32976 9.0002 8.32976C6.8379 8.32976 5.08532 6.57663 5.08532 4.41469C5.08532 2.25275 6.8379 0.5 9.00018 0.5C11.1621 0.5 12.9145 2.25275 12.9145 4.41469H12.9147Z"
      fill="currentColor"
    />
  </SvgIcon>
);
