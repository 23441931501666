import { IBeefreeTemplate } from '@/integrations/beefree/beefree.type'
import { fetcher } from '@/services/api'
import useSWR from 'swr'

export function useBeefreeTemplates() {
  const { data, error } = useSWR(`/content/orgs/x-functions/templates`, fetcher)

  return {
    data: (data?.data || []) as IBeefreeTemplate[],
    isLoading: !error && !data,
    isError: !!error
  }
};
