import React from 'react';
import SvgIcon, { SvgIconProps } from '../SvgIcon';

export const OutreachIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props} style={{ fill: 'transparent' }} viewBox="0 0 20 19">
    <path
      d="M8.8833 3.05176e-05C11.95 3.05176e-05 16.5666 0.950032 18.7333 3.10003C19.2833 3.65003 20 4.71669 20 6.30003C20 9.10003 17.75 13.3834 15.2833 15.8834C14 17.1667 11.9666 18.85 9.21664 18.85C6.5333 18.85 4.4833 17.0167 3.89997 16.4334C1.6333 14.15 -3.05176e-05 10.7 -3.05176e-05 7.46669C-3.05176e-05 5.43336 0.583303 3.65003 2.04997 2.1667C3.41664 0.783364 5.76664 3.05176e-05 8.8833 3.05176e-05ZM12.6833 11.15C13.7166 10.1167 14 8.83336 14 8.13336C14 7.61669 13.8666 6.8667 13.2333 6.23336C12.25 5.25003 11.1833 4.98336 9.99997 4.98336C8.51664 4.98336 7.51664 5.45003 6.84997 6.11669C6.3333 6.63336 5.8833 7.45003 5.8833 8.60003C5.8833 9.75003 6.4833 10.85 7.14997 11.5167C7.76664 12.1334 8.71664 12.5834 9.69997 12.5834C10.6833 12.5834 11.65 12.1834 12.6833 11.15Z"
      fill="white"
    />
  </SvgIcon>
);
