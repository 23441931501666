import React from 'react';
import SvgIcon, { SvgIconProps } from '../SvgIcon';

export const PostHogIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props} style={{ fill: 'transparent' }} viewBox="0 0 24 14">
    <path
      d="M5.44785 7.39881C5.26355 7.76746 4.73749 7.76746 4.55318 7.39881L4.11237 6.51721C4.04197 6.37642 4.04197 6.21068 4.11237 6.06989L4.55318 5.18829C4.73749 4.81964 5.26355 4.81964 5.44785 5.18829L5.88867 6.06989C5.95904 6.21068 5.95904 6.37642 5.88867 6.51721L5.44785 7.39881ZM5.44785 12.3988C5.26355 12.7674 4.73749 12.7674 4.55318 12.3988L4.11237 11.5171C4.04197 11.3763 4.04197 11.2106 4.11237 11.0698L4.55318 10.1882C4.73749 9.81953 5.26355 9.81953 5.44785 10.1882L5.88867 11.0698C5.95904 11.2106 5.95904 11.3763 5.88867 11.5171L5.44785 12.3988Z"
      fill="#1D4AFF"
    />
    <path
      d="M0 10.5009C0 10.0553 0.53872 9.83219 0.853793 10.1473L3.14612 12.4396C3.4612 12.7547 3.23805 13.2934 2.79247 13.2934H0.500139C0.22392 13.2934 0 13.0695 0 12.7933V10.5009ZM0 8.08635C0 8.21899 0.0526932 8.34622 0.146487 8.44L4.85341 13.1469C4.9472 13.2407 5.07441 13.2934 5.20705 13.2934H7.79237C8.23795 13.2934 8.46111 12.7547 8.14602 12.4396L0.853793 5.14738C0.53872 4.83229 0 5.05546 0 5.50103V8.08635ZM0 3.08645C0 3.21909 0.0526932 3.34631 0.146487 3.4401L9.8533 13.1469C9.94707 13.2407 10.0743 13.2934 10.2069 13.2934H12.7923C13.2378 13.2934 13.461 12.7547 13.1459 12.4396L0.853793 0.147493C0.538725 -0.167575 0 0.0555676 0 0.501142V3.08645ZM4.99989 3.08645C4.99989 3.21909 5.05261 3.34631 5.14639 3.4401L14.1459 12.4396C14.461 12.7547 14.9997 12.5315 14.9997 12.086V9.50064C14.9997 9.36801 14.947 9.24077 14.8532 9.147L5.85368 0.147488C5.53859 -0.16758 4.99989 0.0555676 4.99989 0.501142V3.08645ZM10.8536 0.147493C10.5385 -0.16758 9.99979 0.0555676 9.99979 0.501142V3.08645C9.99979 3.21909 10.0525 3.34631 10.1463 3.4401L14.1459 7.43972C14.461 7.75481 14.9997 7.53165 14.9997 7.08607V4.50075C14.9997 4.36811 14.947 4.24088 14.8532 4.1471L10.8536 0.147493Z"
      fill="#F9BD2B"
    />
    <path
      d="M21.2695 10.5622L16.5618 5.85458C16.2467 5.53949 15.708 5.76265 15.708 6.20822V12.7933C15.708 13.0695 15.9319 13.2934 16.2081 13.2934H23.5005C23.7767 13.2934 24.0006 13.0695 24.0006 12.7933V12.1936C24.0006 11.9174 23.7758 11.6968 23.5018 11.6611C22.6612 11.5517 21.875 11.1678 21.2695 10.5622ZM18.1079 11.6935C17.6664 11.6935 17.308 11.3351 17.308 10.8934C17.308 10.4519 17.6664 10.0935 18.1079 10.0935C18.5495 10.0935 18.9079 10.4519 18.9079 10.8934C18.9079 11.3351 18.5495 11.6935 18.1079 11.6935Z"
      fill="white"
    />
    <path
      d="M0 12.7933C0 13.0695 0.22392 13.2934 0.500139 13.2934H2.79247C3.23805 13.2934 3.4612 12.7547 3.14613 12.4396L0.853793 10.1473C0.53872 9.83219 0 10.0553 0 10.5009V12.7933ZM4.99989 4.29359L0.853793 0.147492C0.53872 -0.167581 0 0.055566 0 0.50114V3.08645C0 3.21909 0.0526932 3.34631 0.146487 3.4401L4.99989 8.29351V4.29359ZM0.853793 5.14738C0.53872 4.83229 0 5.05545 0 5.50103V8.08635C0 8.21899 0.0526932 8.34622 0.146487 8.44L4.99989 13.2934V9.29349L0.853793 5.14738Z"
      fill="#1D4AFF"
    />
    <path
      d="M10.0009 4.50075C10.0009 4.36811 9.9482 4.24088 9.85437 4.1471L5.85476 0.147492C5.53972 -0.167581 5.00098 0.055566 5.00098 0.50114V3.08645C5.00098 3.21909 5.05369 3.34631 5.14746 3.4401L10.0009 8.29351V4.50075ZM5.00098 13.2934H7.79345C8.23902 13.2934 8.46219 12.7547 8.1471 12.4396L5.00098 9.29349V13.2934ZM5.00098 4.29359V8.08635C5.00098 8.21899 5.05369 8.34622 5.14746 8.44L10.0009 13.2934V9.50064C10.0009 9.36801 9.9482 9.24077 9.85437 9.147L5.00098 4.29359Z"
      fill="#F54E00"
    />
  </SvgIcon>
);
