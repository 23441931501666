import React from 'react';
import SvgIcon, { SvgIconProps } from '../SvgIcon';

export const CalendlyIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props} style={{ fill: 'transparent' }} viewBox="8 8 24 24">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20 0H0V20V40H20H40V20V0H20Z"
      fill="#016BFF"
    />
    <path
      d="M24.3658 23.5627C23.6038 24.239 22.6522 25.0804 20.9268 25.0804H19.895C18.6464 25.0804 17.5108 24.6266 16.6979 23.8039C15.9039 23.0001 15.4667 21.9 15.4667 20.7058V19.2941C15.4667 18.0999 15.9039 16.9998 16.6979 16.196C17.5108 15.3734 18.6464 14.9202 19.895 14.9202H20.9268C22.6543 14.9202 23.6038 15.761 24.3658 16.4372C25.1571 17.1343 25.8396 17.7437 27.6598 17.7437C27.9374 17.7436 28.2146 17.7214 28.4887 17.6774L28.4825 17.6614C28.3734 17.3906 28.2455 17.1279 28.0997 16.875L26.8832 14.7675C26.3346 13.8175 25.5456 13.0286 24.5955 12.4801C23.6454 11.9316 22.5676 11.6429 21.4706 11.6429H19.0368C17.9398 11.6429 16.862 11.9316 15.9119 12.4801C14.9618 13.0286 14.1728 13.8175 13.6242 14.7675L12.4077 16.875C11.8593 17.8252 11.5706 18.9029 11.5706 20C11.5706 21.097 11.8593 22.1748 12.4077 23.1249L13.6242 25.2324C14.1728 26.1824 14.9618 26.9714 15.9119 27.5198C16.862 28.0683 17.9398 28.357 19.0368 28.357H21.4706C22.5676 28.357 23.6454 28.0683 24.5955 27.5198C25.5456 26.9714 26.3346 26.1824 26.8832 25.2324L28.0997 23.1284C28.2455 22.8756 28.3734 22.6128 28.4825 22.342L28.4887 22.3267C28.2148 22.2812 27.9376 22.2579 27.6598 22.257C25.8396 22.257 25.1571 22.8628 24.3658 23.5634"
      fill="white"
    />
    <path
      d="M20.9268 16.1995H19.895C17.9946 16.1995 16.746 17.5568 16.746 19.2941V20.7058C16.746 22.4431 17.9946 23.8005 19.895 23.8005H20.9268C23.6959 23.8005 23.4784 20.977 27.6598 20.977C28.056 20.9766 28.4514 21.013 28.8408 21.0858C28.9677 20.3675 28.9677 19.6325 28.8408 18.9142C28.4513 18.9866 28.056 19.023 27.6598 19.0229C23.477 19.0229 23.6959 16.1995 20.9268 16.1995Z"
      fill="white"
    />
    <path
      d="M31.2432 22.1176C30.5311 21.596 29.7099 21.243 28.8415 21.0851C28.8415 21.0927 28.8415 21.099 28.8415 21.106C28.7667 21.5222 28.65 21.9298 28.4929 22.3225C29.2103 22.4336 29.8909 22.7139 30.4784 23.1403C30.4784 23.1458 30.4749 23.1528 30.4728 23.1591C29.7166 25.6076 28.1073 27.7037 25.9371 29.0665C23.767 30.4293 21.1799 30.9686 18.646 30.5862C16.1121 30.2038 13.7994 28.9252 12.128 26.9827C10.4566 25.0402 9.53738 22.5626 9.53738 20C9.53738 17.4374 10.4566 14.9597 12.128 13.0172C13.7994 11.0747 16.1121 9.79611 18.646 9.41375C21.1799 9.03138 23.767 9.5706 25.9371 10.9334C28.1073 12.2963 29.7166 14.3923 30.4728 16.8409C30.4728 16.8471 30.4763 16.8541 30.4784 16.8597C29.891 17.2861 29.2103 17.5662 28.4929 17.6767C28.6498 18.0699 28.7666 18.478 28.8415 18.8946C28.8411 18.9011 28.8411 18.9077 28.8415 18.9142C29.7098 18.7565 30.5311 18.4037 31.2432 17.8824C31.9278 17.3756 31.7953 16.8032 31.6914 16.4644C30.845 13.7191 29.0418 11.3686 26.6095 9.83992C24.1772 8.31126 21.2771 7.70593 18.4364 8.13391C15.5956 8.5619 13.0027 9.9948 11.1287 12.1722C9.25477 14.3497 8.22415 17.1272 8.22415 20C8.22415 22.8728 9.25477 25.6503 11.1287 27.8277C13.0027 30.0051 15.5956 31.438 18.4364 31.866C21.2771 32.294 24.1772 31.6887 26.6095 30.16C29.0418 28.6314 30.845 26.2808 31.6914 23.5355C31.7953 23.1967 31.9278 22.6244 31.2432 22.1176Z"
      fill="white"
    />
    <path
      d="M28.4887 17.6767C28.2148 17.7222 27.9376 17.7456 27.6598 17.7464C25.8396 17.7464 25.1571 17.1406 24.3665 16.44C23.6038 15.7638 22.6543 14.9223 20.9268 14.9223H19.895C18.6457 14.9223 17.5108 15.3762 16.6979 16.1988C15.9039 17.0026 15.4668 18.1027 15.4668 19.2969V20.7086C15.4668 21.9028 15.9039 23.0029 16.6979 23.8067C17.5108 24.6294 18.6457 25.0825 19.895 25.0825H20.9268C22.6543 25.0825 23.6038 24.2418 24.3665 23.5655C25.1571 22.8684 25.8396 22.2591 27.6598 22.2591C27.9375 22.2591 28.2146 22.2813 28.4887 22.3253C28.6461 21.9327 28.7629 21.5251 28.8373 21.1088C28.8377 21.1018 28.8377 21.0948 28.8373 21.0879C28.4478 21.0157 28.0525 20.9795 27.6563 20.9798C23.4735 20.9798 23.6924 23.8039 20.9233 23.8039H19.8915C17.9911 23.8039 16.7425 22.4459 16.7425 20.7086V19.2941C16.7425 17.5568 17.9911 16.1995 19.8915 16.1995H20.9233C23.6924 16.1995 23.4749 19.0222 27.6563 19.0222C28.0525 19.0229 28.4479 18.9867 28.8373 18.9142C28.8373 18.9079 28.8373 18.9016 28.8373 18.8946C28.7624 18.478 28.6456 18.07 28.4887 17.6767Z"
      fill="#016BFF"
    />
    <path
      d="M28.4887 17.6767C28.2148 17.7222 27.9376 17.7456 27.6598 17.7464C25.8396 17.7464 25.1571 17.1406 24.3665 16.44C23.6038 15.7638 22.6543 14.9223 20.9268 14.9223H19.895C18.6457 14.9223 17.5108 15.3762 16.6979 16.1988C15.9039 17.0026 15.4668 18.1027 15.4668 19.2969V20.7086C15.4668 21.9028 15.9039 23.0029 16.6979 23.8067C17.5108 24.6294 18.6457 25.0825 19.895 25.0825H20.9268C22.6543 25.0825 23.6038 24.2418 24.3665 23.5655C25.1571 22.8684 25.8396 22.2591 27.6598 22.2591C27.9375 22.2591 28.2146 22.2813 28.4887 22.3253C28.6461 21.9327 28.7629 21.5251 28.8373 21.1088C28.8377 21.1018 28.8377 21.0948 28.8373 21.0879C28.4478 21.0157 28.0525 20.9795 27.6563 20.9798C23.4735 20.9798 23.6924 23.8039 20.9233 23.8039H19.8915C17.9911 23.8039 16.7425 22.4459 16.7425 20.7086V19.2941C16.7425 17.5568 17.9911 16.1995 19.8915 16.1995H20.9233C23.6924 16.1995 23.4749 19.0222 27.6563 19.0222C28.0525 19.0229 28.4479 18.9867 28.8373 18.9142C28.8373 18.9079 28.8373 18.9016 28.8373 18.8946C28.7624 18.478 28.6456 18.07 28.4887 17.6767Z"
      fill="#016BFF"
    />
  </SvgIcon>
);
