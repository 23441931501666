import React from 'react';
import SvgIcon, { SvgIconProps } from '../SvgIcon';

export const StrapiIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props} style={{ fill: 'transparent' }} viewBox="0 0 20 20">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.739 0H6.54395V6.72816H13.2721V13.4563H20.0003V0.261288C20.0003 0.116992 19.8833 0 19.739 0Z"
      fill="white"
    />
    <path d="M13.272 6.72816H13.0107V6.98945H13.272V6.72816Z" fill="white" />
    <path
      d="M6.54395 6.72816H13.0108C13.1551 6.72816 13.2721 6.84516 13.2721 6.98945V13.4563H6.80523C6.66094 13.4563 6.54395 13.3393 6.54395 13.195V6.72816Z"
      fill="#9593FF"
    />
    <path
      d="M13.2715 13.4563H19.9996L13.4945 19.9615C13.4122 20.0438 13.2715 19.9855 13.2715 19.8691V13.4563Z"
      fill="#9593FF"
    />
    <path
      d="M6.54369 6.72816H0.1309C0.0144963 6.72816 -0.043771 6.58746 0.0385347 6.50515L6.54369 0V6.72816Z"
      fill="#9593FF"
    />
  </SvgIcon>
);
