import { Badge } from "@/components/cataylst/badge";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/cataylst/table";
import MainSidebar from "@/components/custom/sidebar";
import { ProgressBar } from "@/components/custom/progressbar";
import { TooltipClaim } from "@/components/custom/tooltip-claim";
import { Checkbox } from "@/components/cataylst/checkbox";
import { CalendarIcon, DaveIcon, DocIcon, EllipsisIcon, EmailIcon, EmptyAvatarIcon, EmptyCompanyIcon, MetaIcon, SalesforceIcon, SarahIcon } from "@/components/custom/icons";
import { Avatar } from "@/components/cataylst/avatar";
import AvatarGroup from "@/components/custom/avatar-group";
import { Dropdown, DropdownButton, DropdownItem, DropdownMenu } from "@/components/cataylst/dropdown";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import { formatDate, formatDateTime, getRelativeDate } from "@/utils/format";
import { Switch } from "@/components/cataylst/switch";
import Notification from "@/components/custom/notification";

export default function DemoComponentPage() {
  return (
    <main>
      <MainSidebar>
        <div className="text-cell px-8 py-5">
          <div>
            <h3 className="text-[32px] font-medium font-p22 leading-[44.8px]">Image Options</h3>
            <div className="w-[432px]">
              <Notification
                logoType="user"
                logoPath={SarahIcon}
                date={new Date()}
                action={{
                  title: 'View engagement history'
                }}
                content={
                  <div>
                    <span className="underline">Sarah Hughes</span> from <span className="underline">Meta</span> just viewed the <span className="underline">product pricing page</span>.
                  </div>
                }
                borderTop
              />
              <Notification
                logoType="user"
                logoPath={MetaIcon}
                date={new Date()}
                title="Customer milestone"
                color="success"
                action={{
                  title: 'Schedule a check-in'
                }}
                content={
                  <div>
                    <span className="underline">Zoom</span> has successfully completed the onboarding process.
                  </div>
                }
                borderTop
              />
              <Notification
                logoType="user"
                logoPath={MetaIcon}
                profileIcon={DaveIcon}
                date={new Date()}
                title="New task"
                color="primary"
                action={{
                  title: 'View task'
                }}
                content={
                  <div>
                    James assigned a new task <span className="underline">Update proposal terms</span> to you for <span>Zoom</span>.
                  </div>
                }
                borderTop
              />
              <Notification
                logoType="user"
                logoPath={SalesforceIcon}
                date={new Date()}
                title="Integration alert"
                color="danger"
                action={{
                  title: 'Alert admin'
                }}
                content={
                  <div>
                    Integration with Saleforce has failed. Sync of new leads has been delayed.
                  </div>
                }
                borderTop
              />
              <Notification
                logoType="icon"
                logoPath={DocIcon}
                date={new Date()}
                action={{
                  title: 'View meeting summary'
                }}
                content={
                  <div>
                    A transcript and summary is available for your recent <span className="underline">demo meeting</span> with <span className="underline">Meta</span>.
                  </div>
                }
                iconBackgroundColor="primary"
                borderTop
              />
              <Notification
                logoType="icon"
                logoPath={DocIcon}
                date={new Date()}
                action={{
                  title: 'View meeting summary'
                }}
                content={
                  <div>
                    A transcript and summary is available for your recent <span className="underline">demo meeting</span> with <span className="underline">Meta</span>.
                  </div>
                }
                iconBackgroundColor="primary"
                borderTop
              />
              <Notification
                logoType="icon"
                logoPath={DocIcon}
                date={new Date()}
                action={{
                  title: 'View document'
                }}
                isUnread
                content={
                  <div>
                    <span className="underline">Meta-Sales-Proposal</span> for <span className="underline">Meta</span> has been updated by Amanda Guthrie.
                  </div>
                }
                iconBackgroundColor="black"
                borderTop
                borderBottom
              />
            </div>
          </div>
          <div>
            <h3 className="text-[32px] font-medium font-p22 leading-[44.8px]">Table Headers</h3>
            <Table grid dense className="mt-4">
              <TableHead>
                <TableRow>
                  <TableHeader name="first">Text only</TableHeader>
                  <TableHeader name="second" icon="linkedin">Text with icon</TableHeader>
                  <TableHeader name="third" checkbox>Text with checkbox</TableHeader>
                  <TableHeader name="fourth" checkbox icon="linkedin">Checkbox & icon</TableHeader>
                  <TableHeader name="fifth" sortable sort="desc">Sorted top down</TableHeader>
                  <TableHeader name="sixth" sortable sort="asc">Sorted bottom up</TableHeader>
                </TableRow>
              </TableHead>
            </Table>
          </div>
          <div className="mt-9 max-w-[1260px]">
            <h3 className="text-[32px] font-medium font-p22 leading-[44.8px]">Text</h3>
            <Table grid dense className="mt-4">
              <TableHead>
                <TableRow>
                  <TableHeader className="w-[14%]">Text or Number</TableHeader>
                  <TableHeader className="w-[14%]">Text with Icon</TableHeader>
                  <TableHeader className="w-[14%]">Text with Tooltip</TableHeader>
                  <TableHeader className="w-[14%]">Date</TableHeader>
                  <TableHeader className="w-[14%]">Date & Time</TableHeader>
                  <TableHeader className="w-[14%]">Relative Time</TableHeader>
                  <TableHeader className="w-[16%]">Truncated Text</TableHeader>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>
                    $3,500,000
                  </TableCell>
                  <TableCell>
                    <div className="flex gap-2 items-center">
                      <img src={EmailIcon} className="w-5 h-5 rounded-sm" />
                      <div>Email</div>
                    </div>
                  </TableCell>
                  <TableCell>
                    <div className="flex gap-2 items-center">
                      <div>Proposal sent</div>
                      <TooltipClaim id="text-with-tooltip">Proposal sent</TooltipClaim>
                    </div>
                  </TableCell>
                  <TableCell>
                    {formatDate(new Date())}
                  </TableCell>
                  <TableCell>
                    {formatDateTime(new Date())}
                  </TableCell>
                  <TableCell>
                    {getRelativeDate(new Date())}
                  </TableCell>
                  <TableCell>
                    <div className="text-ellipsis overflow-hidden max-w-[180px]">
                      Economic buyer, Primary contact
                    </div>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </div>
          <div className="max-w-[900px] mt-9">
            <h3 className="text-[32px] font-medium font-p22 leading-[44.8px]">Links & Actions</h3>
            <Table grid dense className="mt-4">
              <TableHead>
                <TableRow>
                  <TableHeader className="w-1/5">Link</TableHeader>
                  <TableHeader className="w-1/5">Link with Icon</TableHeader>
                  <TableHeader className="w-1/5">Text dropdown</TableHeader>
                  <TableHeader className="w-1/5">More Actions</TableHeader>
                  <TableHeader className="w-1/5">Switch</TableHeader>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>
                    <a className="underline" href="#">
                      meta
                    </a>
                  </TableCell>
                  <TableCell>
                    <a className="flex gap-2 items-center" href="#">
                      <img src={CalendarIcon} />
                      <div className="underline">Schedule demo</div>
                    </a>
                  </TableCell>
                  <TableCell>
                    <Dropdown>
                      <DropdownButton outline className="!border-none hover:!bg-transparent !outline-none !p-0">
                        <div className="flex gap-2 items-center">
                          <span className="font-normal">Role</span>
                          <ChevronDownIcon className="w-4 h-4" />
                        </div>
                      </DropdownButton>
                      <DropdownMenu className="min-w-32">
                        <DropdownItem className="underline">User</DropdownItem>
                        <DropdownItem className="underline">Administrator</DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                  </TableCell>
                  <TableCell>
                    <Dropdown>
                      <DropdownButton outline className="!border-none !p-0">
                        <img src={EllipsisIcon} className="w-4 h-4" />
                      </DropdownButton>
                      <DropdownMenu className="min-w-48">
                        <DropdownItem>Update sales stage</DropdownItem>
                        <DropdownItem>Assign next best actions</DropdownItem>
                        <DropdownItem>Export</DropdownItem>
                        <DropdownItem>AI analysis</DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                  </TableCell>
                  <TableCell>
                    <Switch />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </div>
          <div className="max-w-[360px] mt-9">
            <h3 className="text-[32px] font-medium font-p22 leading-[44.8px]">Company</h3>
            <Table grid dense className="mt-4">
              <TableHead>
                <TableRow>
                  <TableHeader className="w-1/2">Company</TableHeader>
                  <TableHeader className="w-1/2">Company (no logo)</TableHeader>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>
                    <div className="flex gap-2 items-center">
                      <img src={MetaIcon} className="w-5 h-5 rounded-sm" />
                      <div>Meta</div>
                    </div>
                  </TableCell>
                  <TableCell>
                    <div className="flex gap-2 items-center">
                      <img src={EmptyCompanyIcon} />
                      <div>Meta</div>
                    </div>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </div>
          <div className="max-w-[720px] mt-9">
            <h3 className="text-[32px] font-medium font-p22 leading-[44.8px]">Person</h3>
            <Table grid dense className="mt-4">
              <TableHead>
                <TableRow>
                  <TableHeader className="w-[32%]">Person</TableHeader>
                  <TableHeader className="w-[36%]">Person (no avatar)</TableHeader>
                  <TableHeader className="w-[32%]">Person (multiple)</TableHeader>
                  <TableHeader className="w-[32%]">Person (assign)</TableHeader>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>
                    <div className="flex gap-2 items-center">
                      <Avatar square={false} src={SarahIcon} outline={false} className="size-4" />
                      <div>Sarah Hughes</div>
                    </div>
                  </TableCell>
                  <TableCell>
                    <div className="flex gap-2 items-center">
                      <Avatar square={false} src={EmptyAvatarIcon} outline={false} className="size-4" />
                      <div>Sarah Hughes</div>
                    </div>
                  </TableCell>
                  <TableCell>
                    <div className="flex gap-2 items-center">
                      <AvatarGroup srcs={[DaveIcon, SarahIcon]} />
                      <div>Sarah Hughes</div>
                    </div>
                  </TableCell>
                  <TableCell>
                    <div className="flex items-center gap-2">
                      <Avatar square={false} src={SarahIcon} outline={false} className="size-4" />
                      <Dropdown>
                        <DropdownButton outline className="!border-none hover:!bg-transparent !outline-none !p-0">
                          <div className="flex gap-2 items-center">
                            <span className="font-normal">Sarah Hughes</span>
                            <ChevronDownIcon className="w-4 h-4" />
                          </div>
                        </DropdownButton>
                        <DropdownMenu className="min-w-48">
                          <DropdownItem className="underline">Thomas</DropdownItem>
                          <DropdownItem className="underline">Jerry</DropdownItem>
                          <DropdownItem className="underline">Peter</DropdownItem>
                        </DropdownMenu>
                      </Dropdown>
                    </div>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </div>
          <div className="max-w-[560px] mt-9">
            <h3 className="text-[32px] font-medium font-p22 leading-[44.8px]">Badge Progress</h3>
            <Table grid dense className="mt-4">
              <TableHead>
                <TableRow>
                  <TableHeader className="w-[32%]">Badge</TableHeader>
                  <TableHeader className="w-[36%]">Badge (multiple)</TableHeader>
                  <TableHeader className="w-[32%]">Progress</TableHeader>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>
                    <Badge color="customPurpleLight">Solution Evaluation</Badge>
                  </TableCell>
                  <TableCell>
                    <div className="flex gap-1 flex-wrap items-center">
                      <Badge color="customGreenLight">tech startup</Badge>
                      <Badge color="customBlueLight">bay area</Badge>
                    </div>
                  </TableCell>
                  <TableCell>
                    <ProgressBar value={82}>
                      82%
                    </ProgressBar>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </div>
          <div className="max-w-[540px] mt-9">
            <h3 className="text-[32px] font-medium font-p22 leading-[44.8px]">Deal Stages</h3>
            <Table grid dense className="mt-4">
              <TableHead>
                <TableRow>
                  <TableHeader>Sales Stage</TableHeader>
                  <TableHeader>Customer Journey</TableHeader>
                  <TableHeader>Deal Progress</TableHeader>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>
                    <div className="flex gap-2 items-center">
                      <Badge color="customPurpleMain">Negotiation</Badge>
                      <TooltipClaim id="negotiation-tooltip">Proposal sent!</TooltipClaim>
                    </div>
                  </TableCell>
                  <TableCell>
                    <div className="flex gap-2 items-center">
                      <Badge color="customPurpleLight">Decision</Badge>
                      <TooltipClaim id="negotiation-tooltip">Proposal sent!</TooltipClaim>
                    </div>
                  </TableCell>
                  <TableCell>
                    <ProgressBar value={55}>
                      55%
                    </ProgressBar>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </div>
          <div className="max-w-[360px] mt-9">
            <h3 className="text-[32px] font-medium font-p22 leading-[44.8px]">Docs</h3>
            <Table grid dense className="mt-4">
              <TableHead>
                <TableRow>
                  <TableHeader>Doc with Image</TableHeader>
                  <TableHeader>Doc with Icon</TableHeader>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>
                    <div className="flex gap-2 items-center">
                      <img src={SarahIcon} className="w-5 h-5 rounded-sm" />
                      <div>Proposal.pdf</div>
                    </div>
                  </TableCell>
                  <TableCell>
                    <div className="flex gap-2 items-center">
                      <img src={DocIcon} />
                      <div>Proposal.pdf</div>
                    </div>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </div>
          <div className="max-w-[360px] mt-9">
            <h3 className="text-[32px] font-medium font-p22 leading-[44.8px]">Tasks</h3>
            <Table grid dense className="mt-4">
              <TableHead>
                <TableRow>
                  <TableHeader>Task</TableHeader>
                  <TableHeader>Task Completed</TableHeader>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>
                    <div className="flex gap-2 items-center">
                      <Checkbox />
                      <div>Record demo vid</div>
                    </div>
                  </TableCell>
                  <TableCell>
                    <div className="flex gap-2 items-center">
                      <Checkbox checked />
                      <div className="line-through opacity-60">Record demo vid</div>
                    </div>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </div>
        </div>
      </MainSidebar>
    </main>
  )
}
